// Chakra imports
import {
  AspectRatio,
  Box,
  Flex,
  Grid,
  Text,
  useColorModeValue,
  Button,
  Image,
  Heading,
} from "@chakra-ui/react";
import TableTopCreators from "views/admin/nfts/marketplace/components/TableTopCreators";
// Custom components
import CourseInfo from "views/admin/main/account/coursePage/components/CourseInfo";
import Completion from "views/admin/main/account/coursePage/components/Completion";
import Card from "components/card/Card";
import tableDataTopCreators from "views/admin/nfts/marketplace/variables/tableDataTopCreators.json";
import { tableColumnsTopCreators } from "views/admin/nfts/marketplace/variables/tableColumnsTopCreators";
import Nft1 from "assets/img/nfts/Nft1.png";
import Nft2 from "assets/img/nfts/Nft2.png";
import Nft3 from "assets/img/nfts/Nft3.png";
import Nft4 from "assets/img/nfts/Nft4.png";
import Nft5 from "assets/img/nfts/Nft5.png";
import Nft6 from "assets/img/nfts/Nft6.png";
import Details from "assets/img/nfts/details.png";
import Avatar1 from "assets/img/avatars/avatar1.png";
import Avatar2 from "assets/img/avatars/avatar2.png";
import Avatar3 from "assets/img/avatars/avatar3.png";
import Avatar4 from "assets/img/avatars/avatar4.png";

import HistoryItem from "views/admin/nfts/marketplace/components/HistoryItem";
// import { SidebarPost } from "views/admin/nfts/marketplace";
import { useHistory, useParams } from "react-router-dom";
// Assets
export default function CoursePage() {
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const textColorBrand = useColorModeValue("brand.500", "white");
  // Chakra Color Mode
  const history = useHistory();
  const params = useParams();
  return (
    <Box maxW="100%">
      <Grid
        maxW="100%"
        display={{ base: "block", lg: "grid" }}
        pt={{ base: "130px", md: "80px", xl: "80px" }}
        gridTemplateColumns="2.95fr 1fr"
      >
        <Box
          gridArea="1 / 1 / 2 / 2"
          me={{ lg: "20px" }}
          mb={{ base: "20px", lg: "0px" }}
        >
          {/* <Heading mb="20px">
            {params?.id} 
          </Heading> */}
          <AspectRatio w="100%" maxW="100%" ratio={1130 / 636}>
            <Image borderRadius={"50px"} src={Details} alt="Nft3" />
            {/* <iframe
              style={{ borderRadius: "30px" }}
              src='https://www.youtube.com/embed/geyVktOxBJk'
              title='YouTube video player'
              frameborder='0'
              allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
              allowfullscreen></iframe> */}
          </AspectRatio>
          <CourseInfo />
        </Box>
        <Box gridArea="1 / 2 / 2 / 3">
          {/* <SidebarPost /> */}
          {/* <Completion /> */}
        </Box>
      </Grid>
    </Box>
  );
}
