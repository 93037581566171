export const walletAddressFormat = (wallet) => {
  return (
    wallet?.substring(0, 4) +
    "..." +
    wallet?.substring(wallet.length - 4, wallet.length)
  );
};

export const toTitleCase = (str) => {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export const hasModuleScopeEligibility = (prompt, scopes, user = {}) => {
  console.log("hasModuleScopeEligibility", prompt, scopes, user);
  if (
    user?.membership === "free" &&
    user?.usedFreeModeCredit === "yes" &&
    (prompt?.toFreeKo || prompt?.toFree)
  ) {
    return true;
  }
  let isVideo =
    prompt?.allowVideoCreate && prompt?.allowVideoCreate != "DISABLE"
      ? true
      : false;
  let isMusic =
    prompt?.promptType === "apps" && prompt?.redirectionUrl?.includes("music")
      ? true
      : false;

  let isAssistant =
    prompt?.promptType === "assistant" || prompt?.assistant ? true : false;
  let isArt =
    prompt?.promptType === "apps" && prompt?.redirectionUrl?.includes("art")
      ? true
      : false;
  if (isVideo && scopes?.video !== "yes") {
    console.log("Doesnt have video scope");
    return false;
  } else if (isMusic && scopes?.music !== "yes") {
    console.log("Doesnt have music scope");
    return false;
  } else if (isAssistant && scopes?.assistants !== "yes") {
    console.log("Doesnt have assistants scope");
    return false;
  } else if (isArt && scopes?.art !== "yes") {
    console.log("Doesnt have art scope");
    return false;
  } else if (scopes?.text !== "yes") {
    console.log("Doesnt have text scope");
    return false;
  }
  return true;
};
