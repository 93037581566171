import React, { useState, useContext } from "react";
// Chakra imports
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  useColorModeValue,
  Text,
  Spinner,
  Checkbox,
  Image,
  useDisclosure,
} from "@chakra-ui/react";
// Assets
import { FcGoogle } from "react-icons/fc";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine, RiKakaoTalkFill } from "react-icons/ri";

import ReCAPTCHA from "react-google-recaptcha";
// Custom components
import { HSeparator } from "components/separator/Separator";
import CenteredAuth from "layouts/auth/types/CenteredNew";
import { userPublicRequest } from "config/axios.config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AuthContext } from "contexts/AuthContext";
import { ConfigContext } from "contexts/ConfigContext";
import Kakaoimg from "../../../assets/img/kakao_button.png";
import CustomNavLink from "langHoc/CustomNavLink";
import useCustomHistory from "langHoc/useCustomHistory";
import { useTranslation } from "react-i18next";
import Acentlogo from "../../../assets/svg/acent-logo.svg";
import DappxLogo from "../../../assets/img/dappx-icon.png";

import metamaskIcon from "assets/img/metamask.png";
import Web3 from "web3";
import { useEffect } from "react";
import IconBox from "components/icons/IconBox";
import ConnectWalletModal from "views/admin/modals/ConnectWalletModal";
import InstallWalletNoticeModal from "views/admin/modals/InstallWalletNoticeModal";
import { AiOutlinePlusCircle } from "react-icons/ai";
function MetamaskLoginButton({ type, onCloseSignIn }) {
  const { dispatch, profileFetch } = useContext(AuthContext);
  // Chakra color mode
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const googleBg = useColorModeValue("secondaryGray.400", "whiteAlpha.200");
  const googleText = useColorModeValue("navy.700", "white");
  const googleHover = useColorModeValue(
    { bg: "gray.200" },
    { bg: "whiteAlpha.300" }
  );
  const googleActive = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.200" }
  );
  const kgoogleHover = useColorModeValue({ bg: "#fada2f" }, { bg: "#fada2f" });
  const kgoogleActive = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.200" }
  );
  const [loading, setLoading] = React.useState(false);
  const history = useCustomHistory();
  const configData = useContext(ConfigContext);

  // store url of this page after render
  const url = window.location.href;
  const urlObj = new URL(url);
  const params = new URLSearchParams(urlObj.search);
  const sourceValue = params.get("source");
  // will redirect to respective page or app
  function redirection() {
    const urlObj = new URL(url);
    const params = new URLSearchParams(urlObj.search);
    const sourceValue = params.get("source");

    if (sourceValue === "art") {
      setTimeout(() => {
        window.location.href = `${process.env.REACT_APP_ART_APP}`;
      }, 1500);

      return;
    } else if (sourceValue === "music") {
      setTimeout(() => {
        window.location.href = `${process.env.REACT_APP_MUSIC_APP}`;
      }, 1500);

      return;
    } else if (sourceValue === "agi") {
      setTimeout(() => {
        window.location.href = `${process.env.REACT_APP_AGI_APP}`;
      }, 1500);
    } else if (sourceValue === "video") {
      setTimeout(() => {
        window.location.href = `${process.env.REACT_APP_VIDEO_APP}`;
      }, 1500);
    } else {
      let defaultPage =
        configData?.config?.global?.DEFAULT_PAGE_SETTING?.value?.page ??
        "search";
      console.log("defaultPage", defaultPage);
      setTimeout(() => {
        history.push(defaultPage === "chat" ? "/chat" : "/");
      }, 1500);
    }
  }

  const { t } = useTranslation();

  const lang = window.location.pathname.split("/")[1] || "en";
  const [userAddress, setUserAddress] = useState("");
  const [nonce, setNonce] = useState("");
  const [showWalletConnectModal, setShowWalletConnectModal] = useState(false);
  const [showWalletInstallModal, setShowWalletInstallModal] = useState(false);
  const platform = process.env?.REACT_APP_PLATFORM;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const config = configData.config || {};
  const connectWallet = async () => {
    setLoading(true);
    let address = null;
    if (window.ethereum) {
      const accounts = await window.ethereum
        .request({
          method: "eth_requestAccounts",
        })
        .then((response) => {
          return response;
        })
        .catch((response) => {
          console.log("catch block: ", response.message);
        });

      address = accounts[0] || null;
      if (!address) {
        return false;
      }
      setUserAddress(address);

      userPublicRequest
        .post("/auth/requestMetamaskNonce", {
          address,
        })
        .then(async (response) => {
          let nonce = response.data?.data?.nonce ?? "";

          const web3 = new Web3(window.ethereum);
          let message = `I am signing to Chingu with my one-time random number: ${nonce}`;
          if (config?.global?.METAMASK_SIGN_MESSAGE?.value?.message) {
            message = config?.global?.METAMASK_SIGN_MESSAGE?.value?.message;
            message = message.replace("[NONCE]", nonce);
          }

          console.log("Message: " + message);
          const signature = await web3.eth.personal
            .sign(message, address, "")
            .catch((err) => {
              console.log(err.message);
            });
          if (!signature) {
            setLoading(false);
            return;
          }

          console.log("signature: " + signature);
          userPublicRequest
            .post("/auth/verifyMetamaskSignature", {
              signature,
              address: address,
            })
            .then((res) => {
              // let payload = res.data.results;

              const login = {
                data: {
                  ...res.data.data.user,
                },
                token: res.data.data.token,
                remember_me: false,
                expires_at: new Date(), //TODO: update static value
              };

              dispatch({
                type: "LOGIN",
                payload: login,
              });
              profileFetch();
              // history.push('/chat')
              // if (platform === "DappStoreAi") {
              //   onCloseSignIn();
              // } else {
              //   redirection();
              // }
              redirection();
            })
            .catch((error) => {
              // // console.log(error.response.data);
              // toast.error(error.response.data.message);
            })
            .finally((e) => {
              setLoading(false);
            });
        });
    } else {
      // setShowWalletInstallModal(true);
      onOpen(true);
      setLoading(false);
    }
  };

  // const signInWithMetaMask = async () => {
  //   const web3 = new Web3(window.ethereum);
  //   if (!userAddress) {
  //     console.error("userAddress is not assigned");
  //     return;
  //   }
  //   if (!window.ethereum) {
  //     alert("Please install MetaMask to use this feature.");
  //     return;
  //   }
  //   const message = `I am signing to Chingu with my one-time random number: ${nonce}`;
  //   const signature = await web3.eth.personal.sign(message, userAddress, "");

  //   console.log("signature", signature, nonce, userAddress);
  //   userPublicRequest
  //     .post("/auth/verifyMetamaskSignature", {
  //       signature,
  //       address: userAddress,
  //     })
  //     .then((res) => {
  //       // let payload = res.data.results;

  //       const login = {
  //         data: {
  //           ...res.data.data.user,
  //         },
  //         token: res.data.data.token,
  //         remember_me: false,
  //         expires_at: new Date(), //TODO: update static value
  //       };

  //       dispatch({
  //         type: "LOGIN",
  //         payload: login,
  //       });
  //       profileFetch();
  //       // history.push('/chat')
  //       redirection();
  //     })
  //     .catch((error) => {
  //       // // console.log(error.response.data);
  //       // toast.error(error.response.data.message);
  //     })
  //     .finally((e) => {
  //       setLoading(false);
  //     });
  // };

  return (
    <>
      {/* {showWalletConnectModal && (
        <ConnectWalletModal signInWithMetaMask={signInWithMetaMask} />
      )} */}
      <InstallWalletNoticeModal
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
      />
      {type === "signinPage" && (
        <Button
          fontSize="sm"
          me="0px"
          mb="0px"
          m="10px 0px 10px 0px"
          py="15px"
          h="44px"
          borderRadius="16px"
          bg={googleBg}
          color={googleText}
          fontWeight="600"
          _hover={googleHover}
          _active={googleActive}
          _focus={googleActive}
          onClick={connectWallet}
          disabled={loading}
          fontFamily={"DM sans"}
          w="175px"
        >
          {/* <IconBox
            w="20px"
            h="20px"
            me="10px"
            icon={<Image src={metamaskIcon} />}
          /> */}

          {loading ? (
            <>
              <Spinner />
            </>
          ) : (
            t("Connect Wallet")
          )}

          <></>
        </Button>
      )}

      {type === "navbar" && (
        <Button
          // fontSize="1rem"
          py="15px"
          h="35px"
          width={"175px"}
          borderRadius="10px"
          bg={googleBg}
          color={googleText}
          fontWeight="600"
          _hover={googleHover}
          _active={googleActive}
          _focus={googleActive}
          onClick={connectWallet}
          disabled={loading}
          fontSize="14px"
          lineHeight={"1"}
          fontFamily={"DM sans"}
        >
          {loading ? (
            <>
              <Spinner />
            </>
          ) : (
            <>
              {process.env.REACT_APP_PLATFORM === "DappStoreAi" && (
                <IconBox
                  w="18px"
                  h="18px"
                  mr="12px"
                  icon={<Image src={DappxLogo} />}
                />
              )}
              {process.env.REACT_APP_PLATFORM === "Questron" && (
                <IconBox
                  w="18px"
                  h="18px"
                  mr="12px"
                  icon={<Image src={Acentlogo} />}
                />
              )}
              {t("Connect Wallet")}
            </>
          )}
        </Button>
      )}
    </>
  );
}

export default MetamaskLoginButton;
