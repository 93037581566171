import {
  Button,
  Flex,
  Icon,
  Image,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Spinner,
  Text,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { ConfigContext } from "contexts/ConfigContext";
import { useContext, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";

import metamaskWithTitleIcon from "assets/img/metamask-with-title.png";
import { useTranslation } from "react-i18next";
import { CiCircleCheck, CiCircleRemove } from "react-icons/ci";
import Pusher from "pusher-js";
import { AuthContext } from "contexts/AuthContext";
function useQuery() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

export default function PaypalSubscriptionPaymentStatusModal({
  signInWithMetaMask,
}) {
  const configData = useContext(ConfigContext);
  const { isOpen, onOpen, onClose } = useDisclosure();
  let query = useQuery();
  const { t } = useTranslation();
  const lang = useLocation();
  const isKor = lang?.pathname.includes("/ko");
  const [confirmationModal, setConfirmationModal] = useState(true);
  const [loading, setLoading] = useState(false);
  const authData = useContext(AuthContext);
  let user = authData?.authState?.user;

  const colors = configData?.config?.global?.COLORS?.value ?? {};
  const primaryColor = useColorModeValue(
    colors?.primaryColor ? colors?.primaryColor : "navy.700",
    colors?.primaryDarkColor ? colors?.primaryDarkColor : "white"
  );
  const primaryHoverColor = useColorModeValue(
    colors?.primaryHoverColor ? colors?.primaryHoverColor : "navy.700",
    colors?.primaryDarkHoverColor ? colors?.primaryDarkHoverColor : "white"
  );

  useEffect(() => {
    onOpen(true);
  }, []);

  const [successData, setSuccessData] = useState({
    status: "pending",
  });

  const confirmedModalClose = () => {
    setConfirmationModal(false);
    if (successData?.status == "success") {
      authData.profileFetch();
    }
  };

  const WSMC =
    configData?.config?.global?.PAYPAL_PAYMENT_MODAL_CONFIGURATION?.value;
  const loadingPaymentTitle = isKor
    ? WSMC?.loadingPaymentTitleKr
    : WSMC?.loadingPaymentTitle;
  const loadingPaymentSubtitle = isKor
    ? WSMC?.loadingPaymentSubtitleKr
    : WSMC?.loadingPaymentSubtitle;
  const loadingPaymentButton = isKor
    ? WSMC?.loadingPaymentButtonKr
    : WSMC?.loadingPaymentButton;
  const confirmedPaymentTitle = isKor
    ? WSMC?.confirmedPaymentTitleKr
    : WSMC?.confirmedPaymentTitle;
  const confirmedPaymentSubtitle = isKor
    ? WSMC?.confirmedPaymentSubtitleKr
    : WSMC?.confirmedPaymentSubtitle;
  const confirmedPaymentButton = isKor
    ? WSMC?.confirmedPaymentButtonKr
    : WSMC?.confirmedPaymentButton;
  const failedPaymentTitle = isKor
    ? WSMC?.failedPaymentTitleKr
    : WSMC?.failedPaymentTitle;
  const failedPaymentSubtitle = isKor
    ? WSMC?.failedPaymentSubtitleKr
    : WSMC?.failedPaymentSubtitle;
  const failedPaymentButton = isKor
    ? WSMC?.failedPaymentButtonKr
    : WSMC?.failedPaymentButton;

  useEffect(() => {
    if (user?.subscriptionStatus) {
      var pusher = new Pusher(process.env.REACT_APP_PUSHER_APP_KEY, {
        cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
      });
      var channel = pusher.subscribe(`profile-${user?._id}`);
      // console.log(`pusher registered listener`, id);
      channel.bind(`profile-paypal-payment-${user?._id}`, function (data) {
        console.log("data received", data);
        let { status } = data;
        if (status == "success") {
          authData.profileFetch();
        }
        setSuccessData({ status });
      });
      // Cleanup

      return () => {
        channel.unbind_all();
        channel.unsubscribe();
      };
    }
  }, [user]);

  return (
    <>
      <Modal
        isOpen={confirmationModal}
        onClose={
          successData?.status !== "success"
            ? () => {}
            : () => confirmedModalClose()
        }
        isCentered
        width="800px"
        maxWidth="800px"
        className="deposit chakra-modal__content-container"
        id="deposit chakra-modal__content-container"
      >
        <ModalOverlay />
        <ModalContent
          backgroundColor="white"
          borderRadius="20px"
          width="400px"
          maxWidth="600px"
          height={"350px"}
        >
          <ModalBody p={"16px"} borderRadius={"16px"} width="400px">
            <Flex
              direction="column"
              alignItems="center"
              justifyContent="center"
              height="100%"
            >
              {successData?.status !== "success" &&
                successData?.status !== "failed" && (
                  <>
                    <Spinner w={20} h={20} mb="25px" color={primaryColor} />
                    <Text
                      fontSize="lg"
                      mb="20px"
                      fontWeight="bold"
                      textAlign="center"
                    >
                      {loadingPaymentTitle}{" "}
                    </Text>
                    <Text
                      fontSize="sm"
                      mb="20px"
                      color="gray.500"
                      textAlign="center"
                    >
                      {loadingPaymentSubtitle}
                    </Text>
                    {/* <Link
                      fontSize="sm"
                      mb="20px"
                      color="blue.500"
                      href={`https://etherscan.io/tx/$`}
                      textAlign="center"
                      target="_blank"
                    >
                      {loadingPaymentButton}
                    </Link> */}
                    {loadingPaymentButton && (
                      <Button
                        mt={4}
                        colorScheme="teal"
                        type="submit"
                        width={"60%"}
                        height={"40px"}
                        color="white"
                        fontSize="md"
                        onClick={() => confirmedModalClose()}
                        bg={primaryColor}
                        // bg='#121212'
                        _hover={{
                          bg: primaryHoverColor,
                          // bg: '#303030'
                        }}
                        _active={{
                          bg: primaryHoverColor,
                          // bg: '#303030'
                        }}
                        _focus={{
                          bg: primaryHoverColor,
                          // bg: '#303030'
                        }}
                        // disabled={walletBalance < amount || amount <= 0}
                      >
                        {loadingPaymentButton}
                      </Button>
                    )}
                  </>
                )}
              {successData?.status === "success" && (
                <>
                  <Icon
                    as={CiCircleCheck}
                    boxSize="90px"
                    mb="25px"
                    color={primaryColor}
                  />

                  <Text
                    fontSize="lg"
                    mb="20px"
                    fontWeight="bold"
                    textAlign="center"
                  >
                    {confirmedPaymentTitle}{" "}
                  </Text>
                  <Text
                    fontSize="sm"
                    mb="20px"
                    color="gray.500"
                    textAlign="center"
                  >
                    {confirmedPaymentSubtitle}
                  </Text>

                  {confirmedPaymentButton && (
                    <Button
                      mt={4}
                      colorScheme="teal"
                      type="submit"
                      width={"60%"}
                      height={"40px"}
                      color="white"
                      fontSize="md"
                      onClick={() => confirmedModalClose()}
                      bg={primaryColor}
                      // bg='#121212'
                      _hover={{
                        bg: primaryHoverColor,
                        // bg: '#303030'
                      }}
                      _active={{
                        bg: primaryHoverColor,
                        // bg: '#303030'
                      }}
                      _focus={{
                        bg: primaryHoverColor,
                        // bg: '#303030'
                      }}
                      // disabled={walletBalance < amount || amount <= 0}
                    >
                      {confirmedPaymentButton}
                    </Button>
                  )}
                </>
              )}
              {successData?.status === "failed" && (
                <>
                  <Icon
                    as={CiCircleRemove}
                    boxSize="90px"
                    mb="25px"
                    color="red.500"
                  />

                  <Text
                    fontSize="lg"
                    mb="20px"
                    fontWeight="bold"
                    textAlign="center"
                  >
                    {failedPaymentTitle}{" "}
                  </Text>
                  <Text
                    fontSize="sm"
                    mb="20px"
                    color="gray.500"
                    textAlign="center"
                  >
                    {failedPaymentSubtitle}
                  </Text>
                  {/* <Link
                    fontSize="sm"
                    mb="20px"
                    color="blue.500"
                    href={`https://etherscan.io/tx/`}
                    textAlign="center"
                    target="_blank"
                  >
                    {failedPaymentButton}
                  </Link> */}

                  {failedPaymentButton && (
                    <Button
                      mt={4}
                      colorScheme="teal"
                      type="submit"
                      width={"60%"}
                      height={"40px"}
                      color="white"
                      fontSize="md"
                      onClick={() => confirmedModalClose()}
                      bg={primaryColor}
                      // bg='#121212'
                      _hover={{
                        bg: primaryHoverColor,
                        // bg: '#303030'
                      }}
                      _active={{
                        bg: primaryHoverColor,
                        // bg: '#303030'
                      }}
                      _focus={{
                        bg: primaryHoverColor,
                        // bg: '#303030'
                      }}
                      // disabled={walletBalance < amount || amount <= 0}
                    >
                      {failedPaymentButton}
                    </Button>
                  )}
                </>
              )}
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
