import {
  Box,
  Button,
  Checkbox,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { ConfigContext } from "contexts/ConfigContext";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Image } from "@chakra-ui/react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { useLocation } from "react-router-dom";
import dayjs from "dayjs";
import { useViewportHeight } from "../useViewportHeight";

import x from "../../../assets/fonts/60e5f2de011b86ee8ac31042_x.svg";
import check from "../../../assets/fonts/60e5f2de011b865ecec30fca_Green Check.svg";
import ParsedContent from "components/new/ParsedContent";
import ShadowScrollbars from "./ShadowScrollBar";

const CloseIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="#fff"
      ariahidden="true"
      width="30"
      className="text-white absolute top-5 right-5 cursor-pointer"
    >
      <path
        strokelinecap="round"
        strokelinejoin="round"
        d="M6 18L18 6M6 6l12 12"
      ></path>
    </svg>
  );
};

const FirstModal = ({ setHomepageModalClose }) => {
  const lang = useLocation();
  const isKor = lang?.pathname.includes("/ko");
  const [isChecked, setIsChecked] = useState(false);
  const [images, setImages] = useState([]);
  const [sliderKey, setSliderKey] = useState(0);
  const [loading, setLoading] = useState(true);

  const configData = useContext(ConfigContext);
  let data = configData?.config?.global?.HOME_MODAL?.value;
  const COLORS = configData?.config?.global?.COLORS?.value;

  const primaryColor = useColorModeValue(
    COLORS.primaryColor,
    COLORS.primaryDarkColor
  );
  const secondaryColor = useColorModeValue(
    COLORS.secondaryColor,
    COLORS.secondaryDarkColor
  );
  const bgColor = useColorModeValue(COLORS.bgColor, COLORS.bgDarkColor);

  const hoverColor = useColorModeValue(
    COLORS.hoverColor,
    COLORS.hoverDarkColor
  );
  const placeholderColor = useColorModeValue(
    COLORS.placeholderColor,
    COLORS.placeholderDarkColor
  );
  const borderColor = useColorModeValue(
    COLORS.borderColor,
    COLORS.borderDarkColor
  );
  const iconColor = useColorModeValue(COLORS.iconColor, COLORS.iconDarkColor);
  const textColor = useColorModeValue(COLORS.textColor, COLORS.textDarkColor);

  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const onCloseHandler = () => {
    onClose();
    setHomepageModalClose(true);
  };

  // TODO: Replace it with real data from backend
  let sldieRes = data?.slides;
  let slidesToShow = 1;
  let expiry_days = data?.expiry_days;

  const shouldShow = () => {
    let modalDisabledDate = localStorage.getItem("modalDisabledDate");
    if (!modalDisabledDate) {
      return true;
    }
    return dayjs(modalDisabledDate) < dayjs();
  };

  const resetSlider = () => {
    setSliderKey((prevKey) => prevKey + 1);
  };

  useEffect(() => {
    if (shouldShow()) {
      onOpen(true);

      const delay = setTimeout(resetSlider, 100);

      return () => {
        clearTimeout(delay);
      };
    } else {
      setHomepageModalClose(true);
    }
  }, []);

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    fade: false,
    nextArrow: <NextButton />,
    prevArrow: <PrevButton />,
    adaptiveHeight: true,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    slidesCount: sldieRes?.length,
    initialSlide: 0,
  };

  useEffect(() => {
    if (sldieRes) {
      setTimeout(() => {
        setLoading(false);
      }, 100);
    }
  }, [sldieRes]);

  function NextButton(props) {
    const { onClick, currentSlide, slideCount } = props;
    return (
      <>
        {currentSlide !== slideCount - slidesToShow ? (
          <Button
            fontWeight="700"
            color={"#fff"}
            fontSize={"14px"}
            fontFamily={"Nunito Sans, sans-serif"}
            bg={primaryColor}
            py="5px"
            px="25px"
            borderRadius={"999px"}
            onClick={onClick}
            position={"absolute"}
            bottom={{
              base: "-90px",
              md: "-65px",
            }}
            // left="calc(100% - 112px)"
            width={{
              base: "80px",
              "2sm": "110px",
            }}
            left={{
              base: "calc(100% - 80px)",
              "2sm": "calc(100% - 112px)",
            }}
            textAlign={"center"}
            _hover={{
              bg: hoverColor,
            }}
            _active={{
              bg: primaryColor,
            }}
            _focus={{
              bg: primaryColor,
            }}
            // mt={'20px'}
          >
            {t("Next")}
          </Button>
        ) : (
          <Button
            fontWeight="700"
            color={"#fff"}
            fontSize={"14px"}
            fontFamily={"Nunito Sans, sans-serif"}
            bg={primaryColor}
            py="5px"
            px="25px"
            borderRadius={"999px"}
            onClick={onCloseHandler}
            position={"absolute"}
            bottom={{
              base: "-90px",
              md: "-65px",
            }}
            right="2px"
            width={{
              base: "80px",
              "2sm": "110px",
            }}
            textAlign={"center"}
            _hover={{
              bg: hoverColor,
            }}
            _active={{
              bg: primaryColor,
            }}
            _focus={{
              bg: primaryColor,
            }}
          >
            {t("LetGo")}
          </Button>
        )}
      </>
    );
  }

  function PrevButton(props) {
    const { onClick, currentSlide } = props;
    return (
      <>
        {currentSlide !== 0 && (
          <Button
            fontWeight="700"
            color={"#fff"}
            fontFamily={"Nunito Sans, sans-serif"}
            bg={primaryColor}
            fontSize={"14px"}
            py="5px"
            px="25px"
            position={"absolute"}
            bottom={{
              base: "-90px",
              md: "-65px",
            }}
            left="0px"
            borderRadius={"999px"}
            onClick={onClick}
            width={{
              base: "90px",
              "2sm": "110px",
            }}
            // mt={'20px'}
            textAlign={"center"}
            _hover={{
              bg: hoverColor,
            }}
            _active={{
              bg: primaryColor,
            }}
            _focus={{
              bg: primaryColor,
            }}
          >
            {t("Previous")}
          </Button>
        )}
      </>
    );
  }

  useEffect(() => {
    if (shouldShow()) {
      onOpen(true);
    }
  }, []);

  // if (isLoading) return 'Loading...'

  // if (error) return 'An error has occurred: ' + error.message
  const vh = useViewportHeight();

  function thumbVertical({ style, ...props }) {
    const finalStyle = {
      ...style,
      cursor: "pointer",
      width: "5px",
    };

    return React.createElement("div", { style: finalStyle, ...props });
  }

  return (
    <div className="first-modal" id="HOME-MODL">
      <Modal
        isOpen={isOpen}
        onClose={onCloseHandler}
        closeOnEsc={false}
        closeOnOverlayClick={false}
        isCentered
        className="first-modal-area"
      >
        <ModalOverlay
          backdropFilter={"blur(11px) opacity(0.6) hue-rotate(282deg)"}
        />
        <ModalContent
          className="first-modal"
          width={{
            md: "724px",
            base: "calc(100% - 20px)",
          }}
          maxW={"100%"}
          borderRadius={"20px"}
          display={"block"}
          my={{
            base: "auto",
            lg: "auto",
          }}
          bg={bgColor}
        >
          <Box
            style={{
              display: "flex",
              position: "relative",
            }}
          >
            <Box
              id="FIRST_MODAL_LEFT"
              className="prompt-modal-details"
              padding={sldieRes?.length === 1 ? "25px" : "25px 25px 90px 25px"}
            >
              <img
                src={data?.icon}
                alt=""
                style={{ marginBottom: "12px", height: "45px" }}
              />

              <Box
                fontSize={"24px"}
                lineHeight={"32px"}
                fontWeight={700}
                pb={"10px"}
                color={textColor}
              >
                {isKor ? data?.title_kr : data?.title_en}
                {/* {data?.title_en  } */}
              </Box>
              {!loading && (
                <>
                  <Slider key={sliderKey} {...settings}>
                    {sldieRes?.map((sm, i) => {
                      const isLastItem = i === sldieRes.length - 1;
                      return (
                        <Box
                          key={i}
                          textAlign={"left"}
                          h="300px"
                          pb="40px"
                          className="scrollbar-bg-Box"
                          // style={{ display: currentSlide === i ? 'block' : 'none'}}
                        >
                          <ShadowScrollbars
                            renderThumbVertical={thumbVertical}
                            style={{ maxWidth: 700, height: 300 }}
                          >
                            <Text
                              fontFamily={"DM Sans"}
                              fontSize={"16px"}
                              marginBottom={"6px"}
                              fontWeight={700}
                              color={textColor}
                            >
                              {/* {sm?.text_en} */}
                              {isKor ? sm?.text_kr : sm?.text_en}
                            </Text>
                            <Box id="media-box">
                              <ParsedContent
                                data={
                                  isKor ? sm?.descriptionKr : sm?.descriptionEn
                                }
                              />
                            </Box>
                            {isLastItem && (
                              <Box
                                textAlign={"center"}
                                mt="25px"
                                ml="5px"
                                display={"flex"}
                                alignItems={"center"}
                                justifyContent={"left"}
                                color={textColor}
                              >
                                <Checkbox
                                  iconColor="#fff"
                                  onChange={(event) => {
                                    if (event.target.checked)
                                      localStorage.setItem(
                                        "modalDisabledDate",
                                        dayjs().add(expiry_days, "day")
                                      );
                                    else {
                                      localStorage.removeItem(
                                        "modalDisabledDate"
                                      );
                                    }
                                  }}
                                  defaultChecked={false}
                                  sx={{
                                    "[data-checked]": {
                                      background: "#5831d4!important",
                                      backgroundColor: "#5831d4!important",
                                      borderColor: "#5831d4!important",
                                    },
                                  }}
                                  className="CHECKOUT_CHECKBOX"
                                ></Checkbox>

                                <Text
                                  ml="2"
                                  fontSize={{
                                    base: "14px",
                                    "1sm": "14px",
                                  }}
                                >
                                  {isKor
                                    ? data?.expiry_text_kr?.replace(
                                        "[EXPIRY_DAYS]",
                                        data?.expiry_days
                                      )
                                    : data?.expiry_text_en?.replace(
                                        "[EXPIRY_DAYS]",
                                        data?.expiry_days
                                      )}
                                </Text>
                              </Box>
                            )}
                          </ShadowScrollbars>
                        </Box>
                      );
                    })}
                  </Slider>
                  {sldieRes?.length === 1 && (
                    <Flex justifyContent={"center"} marginTop={"19px"}>
                      <Button
                        fontWeight="700"
                        color={"#fff"}
                        fontSize={"14px"}
                        fontFamily={"Nunito Sans, sans-serif"}
                        bg={primaryColor}
                        py="5px"
                        px="25px"
                        borderRadius={"999px"}
                        onClick={onCloseHandler}
                        position={"relative"}
                        // bottom={{
                        //   base: "-90px",
                        //   md: "-65px",
                        // }}
                        right="2px"
                        width={{
                          base: "80px",
                          "2sm": "110px",
                        }}
                        textAlign={"center"}
                        _hover={{
                          bg: hoverColor,
                        }}
                        _active={{
                          bg: primaryColor,
                        }}
                        _focus={{
                          bg: primaryColor,
                        }}
                        display={"block"}
                        // position={"relative"}
                      >
                        {t("LetGo")}
                      </Button>
                    </Flex>
                  )}
                </>
              )}
            </Box>
            <Box id="FIRST_MODAL_RIGHT" className="prompt-modal-details-right">
              <img
                style={{
                  width: data?.modalImage ? "100%" : "0px",
                  height: "100%",
                  objectFit: "cover",
                  objectPosition: "center",
                  borderTopRightRadius: "20px",
                  borderBottomRightRadius: "20px",
                }}
                src={data?.modalImage}
                alt=""
              />
            </Box>

            {/*  */}
          </Box>
        </ModalContent>
      </Modal>
      <style jsx>{`
        #media-box {
        }

        // #media-box::before {
        //   content: '';
        //   position: fixed;
        //   bottom: -20px;
        //   left: 0;
        //   width: 100%;
        //   height: 19%;
        //   background: linear-gradient(to bottom, transparent, rgba(255, 255, 255, 0.8));
        //   z-index: 2;
        // }

        #media-box h3 p {
          margin-bottom: 6px;
        }
        #media-box ul li p {
          color: 111827;
          font-size: 14px;
        }
        #media-box ul {
          padding-left: 20px;
        }
        #media-box ul li {
          // display: flex;
          align-items: center;
          gap: 0px 10px;
          margin-bottom: 7px;
        }
        #media-box ul li .icion {
          width: 14px;
        }
        .slick-dots {
          bottom: -20px !important;
          display: block;
          text-align: center;
          width: 100%;
        }

        .slick-dots li {
          position: relative;
          display: inline-block;
          height: 14px;
          width: 14px;
          margin: 0 5px;
          padding: 0;
        }

        .slick-dots li button {
          border: 0;
          background: ${secondaryColor};
          display: block;
          height: 10px;
          width: 10px;
          outline: none;
          line-height: 0;
          font-size: 0;
          color: transparent;
          padding: 5px;
          cursor: pointer;
          border-radius: 50%;
          margin-right: 0px;
        }

        .slick-dots li button:before {
          position: absolute;
          top: 0;
          left: 0;
          content: "";
          width: 16px;
          height: 16px;
          font-family: "slick";
          font-size: 6px;
          line-height: 16px;
          text-align: center;
          color: black;
          opacity: 0.25;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
        }

        .slick-dots li.slick-active button {
          background: ${primaryColor};
        }
      `}</style>
    </div>
  );
};

export default FirstModal;
