import {
  Button,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { ConfigContext } from "contexts/ConfigContext";
import { useContext, useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
function useQuery() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

export default function InstallWalletNoticeModal({ isOpen, onOpen, onClose }) {
  const lang = useLocation();
  const isKor = lang?.pathname.includes("/ko");
  const configData = useContext(ConfigContext);
  let data = configData?.config?.global?.METAMASK_INSTALL_MODAL?.value;
  let query = useQuery();

  return (
    <div className="first-modal">
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        closeOnEsc={true}
        closeOnOverlayClick={true}
        isCentered
        className="first-modal-area"
      >
        <ModalOverlay
          onClick={() => console.log("hi bro")}
          // backdropFilter={"blur(30px) brightness(100%) contrast(60%) invert(100%) opacity(0.4)"}
          backdropFilter={"blur(11px) opacity(0.6) hue-rotate(282deg)"}
        />
        <ModalContent
          className="first-modal"
          width={{
            md: "724px",
            base: "calc(100% - 20px)",
          }}
          maxW={"100%"}
          borderRadius={"20px"}
          boxShadow={
            "0 2px 1px -1px #0003, 0 1px 1px 0 #00000024, 0 1px 3px 0 #0000001f"
          }
          my={{
            base: "auto",
            lg: "auto",
          }}
          padding={"1.5rem"}
        >
          <ModalHeader
            textAlign={"center"}
            fontSize={"20px"}
            width={{
              md: "100%",
              base: "80%",
            }}
            marginX={"auto"}
          >
            {isKor ? data?.title_kr : data?.title_en}
          </ModalHeader>

          <ModalBody p={0} position={"relative"}>
            {/* <Text mt={"1.5rem"} align={"center"}>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nisi
              reiciendis inventore cumque at voluptate nam magnam animi rem
              dolore eaque
            </Text> */}

            <div
              dangerouslySetInnerHTML={{
                __html: isKor ? data?.text_kr : data?.text_en,
              }}
              className="html-text-wrapper"
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
}
