import { useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import SignInCentered from "views/auth/signIn/SignInCentered.jsx";
import SignUpCentered from "views/auth/signUp/SignUpCentered.jsx";
import VerificationCentered from "views/auth/verification/VerificationCentered.jsx";
import ForgetPasswordCentered from "views/auth/forgotPassword/ForgotPasswordCentered";
// Chakra imports
import { Box } from "@chakra-ui/react";
// Layout components
import { SidebarContext } from "contexts/SidebarContext";
import PasswordVerificationCentered from "views/auth/verification/PasswordVerificationCentered";
import GoogleSignInCentered from "views/auth/signIn/GoogleSignInCentered";
import OthersPricing from "views/admin/main/others/pricing";
import MobilePricing from "views/admin/main/others/mobile";
import CustomRoute from "langHoc/CustomRoute";
import PhoneVerificationCentered from "views/auth/verification/PhoneVerificationCentered";
// Custom Chakra theme
export default function Auth() {
  // states and functions
  const [toggleSidebar, setToggleSidebar] = useState(false);
  // functions for changing the states from components
  const getRoute = () => {
    return window.location.pathname !== "/auth/full-screen-maps";
  };
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/auth") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      }
      if (prop.collapse) {
        return getRoutes(prop.items);
      }
      if (prop.category) {
        return getRoutes(prop.items);
      } else {
        return null;
      }
    });
  };
  return (
    <>
      <Box>
        <SidebarContext.Provider
          value={{
            toggleSidebar,
            setToggleSidebar,
          }}
        >
          <Box
            float="right"
            minHeight="100vh"
            height="100%"
            position="relative"
            w="100%"
            transition="all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)"
            transitionDuration=".2s, .2s, .35s"
            transitionProperty="top, bottom, width"
            transitionTimingFunction="linear, linear, ease"
          >
            <Box mx="auto" minH="100vh">
              <Switch>
                <CustomRoute
                  path={[
                    "/auth/google-sign-in",
                    "/:lang(en|ko)/auth/google-sign-in",
                  ]}
                  component={GoogleSignInCentered}
                />
                <CustomRoute
                  path={["/auth/sign-in", "/:lang(en|ko)/auth/sign-in"]}
                  component={SignInCentered}
                />
                <CustomRoute
                  path={["/auth/sign-up", "/:lang(en|ko)/auth/sign-up"]}
                  component={SignUpCentered}
                />
                <CustomRoute
                  path={["/auth/verify", "/:lang(en|ko)/auth/verify"]}
                  component={VerificationCentered}
                />
                <CustomRoute
                  path={["/auth/verifyPhone", "/:lang(en|ko)/auth/verifyPhone"]}
                  component={PhoneVerificationCentered}
                />
                <CustomRoute
                  path={["/auth/forgot", "/:lang(en|ko)/auth/forgot"]}
                  component={ForgetPasswordCentered}
                />
                <CustomRoute
                  path={["/auth/reset", "/:lang(en|ko)/auth/reset"]}
                  component={PasswordVerificationCentered}
                />
                <CustomRoute
                  path={["/auth/pricing", "/:lang(en|ko)/auth/pricing"]}
                  component={OthersPricing}
                />
                <CustomRoute
                  path={["/auth/mobile", "/:lang(en|ko)/auth/mobile"]}
                  component={MobilePricing}
                />
                <Redirect from="/auth" to="/auth/sign-in/default" />
              </Switch>
            </Box>
          </Box>
        </SidebarContext.Provider>
      </Box>
    </>
  );
}
