import Cookies from "js-cookie";

export function storeToken(token) {
  return Cookies.set("token", token, {
    expires: 3, // 3 days expiration
    domain: process.env.REACT_APP_SUBDOMAIN,
    path: "/",
  });
}

export function removeToken() {
  return Cookies.remove("token", {
    expires: 3, // 3 days expiration
    domain: process.env.REACT_APP_SUBDOMAIN,
    path: "/",
  });
}

function redirection(url, history) {
  const urlObj = new URL(url);
  const params = new URLSearchParams(urlObj.search);
  const sourceValue = params.get("source");
  if (sourceValue === "agi") {
    setTimeout(() => {
      window.location.href = `${process.env.REACT_APP_AGI_APP}`;
    }, 2000);
  } else if (sourceValue === "music") {
    setTimeout(() => {
      window.location.href = `${process.env.REACT_APP_MUSIC_APP}`;
    }, 1500);

    return;
  } else {
    setTimeout(() => {
      history.push("/chat");
    }, 2000);
  }
}
