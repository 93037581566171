import {
  Box,
  Button,
  Checkbox,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { ConfigContext } from "contexts/ConfigContext";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Image } from "@chakra-ui/react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { useLocation } from "react-router-dom";
import dayjs from "dayjs";
import { useViewportHeight } from "layouts/HomeNew/useViewportHeight";
import useCustomHistory from "langHoc/useCustomHistory";
import MetamaskLoginButton from "views/auth/signIn/MetamaskLoginButton";
import { IoMdClose } from "react-icons/io";
// import { useViewportHeight } from "../useViewportHeight";

const InitialModal = ({ isOpen, onOpen, onClose }) => {
  const lang = useLocation();
  const isEng = lang?.pathname.includes("/en");
  const isKor = lang?.pathname.includes("/ko");
  const [isChecked, setIsChecked] = useState(false);

  const configData = useContext(ConfigContext);
  // // console.log(
  //   configData?.config?.global?.CHAT_MODAL,
  //   'configData?.config?.global?.'
  // )
  let data = configData?.config?.global?.HOME_MODAL?.value;

  const { t } = useTranslation();

  // const [closed, setClosed] = useState(false);

  // TODO: Replace it with real data from backend
  let sldieRes = data?.slides;
  let sm = configData?.config?.global?.CHAT_MODAL?.value;
  let slidesToShow = 1;
  let expiry_days = data?.expiry_days;

  const shouldShow = () => {
    let modalDisabledDate = localStorage.getItem("modalDisabledDate");
    if (!modalDisabledDate) {
      return true;
    }
    return dayjs(modalDisabledDate) < dayjs();
  };

  useEffect(() => {
    if (!shouldShow()) {
      onClose();
    }
  }, []);

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    fade: false,
    nextArrow: <NextButton />,
    prevArrow: <PrevButton />,
    adaptiveHeight: true,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    slidesCount: sldieRes?.length, //TODO Replace it with data's length
    initialSlide: 0,
  };

  function NextButton(props) {
    const { onClick, currentSlide, slideCount } = props;

    return (
      <>
        {currentSlide !== slideCount - slidesToShow ? (
          <Button
            fontWeight="700"
            color={"#fff"}
            fontFamily={"Nunito Sans, sans-serif"}
            bg="#4FDABC"
            py="5px"
            px="25px"
            borderRadius={"10px"}
            onClick={onClick}
            position={"absolute"}
            bottom="-97px"
            right="16px"
            width={{
              base: "80px",
              "2sm": "110px",
            }}
            textAlign={"center"}
            _hover={{
              bg: "rgba(79, 218, 188, .8)",
            }}
            _active={{
              bg: "#4FDABC",
            }}
            _focus={{
              bg: "#4FDABC",
            }}
          >
            Next
          </Button>
        ) : (
          <Button
            fontWeight="700"
            color={"#fff"}
            fontFamily={"Nunito Sans, sans-serif"}
            bg="#4FDABC"
            py="5px"
            px="25px"
            borderRadius={"10px"}
            onClick={onClose}
            position={"absolute"}
            bottom="-97px"
            right="16px"
            width={{
              base: "80px",
              "2sm": "110px",
            }}
            textAlign={"center"}
            _hover={{
              bg: "rgba(79, 218, 188, .8)",
            }}
            _active={{
              bg: "#4FDABC",
            }}
            _focus={{
              bg: "#4FDABC",
            }}
          >
            Close
          </Button>
        )}
      </>
    );
  }

  function PrevButton(props) {
    const { onClick, currentSlide } = props;
    return (
      <>
        {currentSlide !== 0 && (
          <Button
            fontWeight="700"
            color={"#fff"}
            fontFamily={"Nunito Sans, sans-serif"}
            bg="#4FDABC"
            py="5px"
            px="25px"
            position={"absolute"}
            bottom="-97px"
            left="16px"
            borderRadius={"10px"}
            onClick={onClick}
            width={{
              base: "90px",
              "2sm": "110px",
            }}
            textAlign={"center"}
            _hover={{
              bg: "rgba(79, 218, 188, .8)",
            }}
            _active={{
              bg: "#4FDABC",
            }}
            _focus={{
              bg: "#4FDABC",
            }}
          >
            Previous
          </Button>
        )}
      </>
    );
  }

  // useEffect(() => {
  //   onOpen(true);
  // }, []);

  // if (isLoading) return 'Loading...'

  // if (error) return 'An error has occurred: ' + error.message
  const vh = useViewportHeight();

  const fullHeightStyle = {
    maxHeight: `calc(${vh}px * 88)`,
  };
  const customHistory = useCustomHistory();
  return (
    <div className="first-modal">
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        closeOnEsc={false}
        closeOnOverlayClick={false}
        isCentered
        className="first-modal-area"
      >
        <ModalOverlay
          onClick={() => console.log("hi bro")}
          // backdropFilter={"blur(30px) brightness(100%) contrast(60%) invert(100%) opacity(0.4)"}
          backdropFilter={"blur(11px) opacity(0.6) hue-rotate(282deg)"}
        />
        <ModalContent
          className="first-modal"
          width={{
            md: "500px",
            base: "calc(100% - 20px)",
          }}
          maxW={"100%"}
          borderRadius={"20px"}
          boxShadow={
            "0 2px 1px -1px #0003, 0 1px 1px 0 #00000024, 0 1px 3px 0 #0000001f"
          }
          // style={fullHeightStyle}
          // overflowY={'auto'}
          my={{
            base: "auto",
            lg: "auto",
          }}
        >
          <Box
            position={"absolute"}
            top={"20px"}
            right={"20px"}
            cursor={"pointer"}
            display="flex"
            gap="20px"
          >
            <IoMdClose onClick={onClose} size={28} padding={"0px"} />
          </Box>

          <ModalHeader
            textAlign={"center"}
            fontSize={"20px"}
            width={{
              md: "100%",
              base: "80%",
            }}
            margin={"auto"}
            pt="25px"
            pb="0px"
            pr={"2.5rem"}
          >
            {/* {data?.title_en} */}
            {isEng ? sm?.title_en : sm?.title_kr}
          </ModalHeader>
          {/* <ModalCloseButton /> */}

          <ModalBody pt={0} pb={0} position={"relative"}>
            <Box p={"18px 16px 16px 16px"} textAlign={"center"}>
              <Text fontSize={"18px"} fontWeight={500}>
                {isEng ? sm?.text_en : sm?.text_kr}
                {/* {sm?.text_en} */}
              </Text>
              <Box id="media-box" mt="25px">
                {sm?.file?.includes(".mp4") ? (
                  <Box
                    id={""}
                    className="video-playbackk"
                    as="video"
                    src={sm?.file}
                    autoPlay
                    loop
                    controls
                    muted
                    width="100%"
                    height="auto"
                    // height={{ base: "500px", xl: "auto" }}
                  />
                ) : (
                  <Image
                    src={sm?.file}
                    alt=""
                    // maxHeight={{ base: "200px", lg: "400px" }}
                    // objectFit={"cover"}
                    width="100%"
                  />
                  // <img src={sm?.file} alt="" width="100%" height="auto" maxHeight={{base: '200px', lg: '400px'}} style={{maxHeight: '400px', objectFit: "cover"}} />
                )}
              </Box>
            </Box>

            <Box>
              {/* <Box
                textAlign={"center"}
                pb={data?.slides?.length > 1 ? "90px" : "28px"}
                mt="12px"
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                // ml="16px"
              >
                <Checkbox
                  iconColor="#fff"
                  onChange={(event) => {
                    if (event.target.checked)
                      localStorage.setItem(
                        "modalDisabledDate",
                        dayjs().add(expiry_days, "day")
                      );
                    else {
                      localStorage.removeItem("modalDisabledDate");
                    }
                  }}
                  defaultChecked={false}
                  sx={{
                    "[data-checked]": {
                      background: "#4FDABC!important",
                      backgroundColor: "#4FDABC!important",
                      borderColor: "#4FDABC!important",
                    },
                  }}
                ></Checkbox>
                <Text ml="2" fontSize={{
                  base: '14px',
                  '1sm': '16px'
                }}>
                  {isEng
                    ? data?.expiry_text_en?.replace(
                        "[EXPIRY_DAYS]",
                        data?.expiry_days
                      )
                    : data?.expiry_text_kr?.replace(
                        "[EXPIRY_DAYS]",
                        data?.expiry_days
                      )}
                </Text>
              </Box> */}
            </Box>
          </ModalBody>

          <ModalFooter
            display={"flex"}
            alignItems={"center"}
            pt="0px"
            pb="28px"
            gap="20px"
            justifyContent={"center"}
          >
            {configData?.config?.global?.PLAY_GROUND?.value === "YES" &&
              configData?.config?.global?.WEB3_AUTHENTICATION?.value ===
                "NO" && (
                <>
                  <Button
                    fontWeight="700"
                    color={"#fff"}
                    fontFamily={"Nunito Sans, sans-serif"}
                    bg="#4FDABC"
                    py="5px"
                    px="25px"
                    borderRadius={"10px"}
                    // onClick={onClose}
                    // position={"absolute"}
                    // bottom="-95px"
                    // right="16px"
                    width="110px"
                    textAlign={"center"}
                    _hover={{
                      bg: "rgba(79, 218, 188, .8)",
                    }}
                    _active={{
                      bg: "#4FDABC",
                    }}
                    _focus={{
                      bg: "#4FDABC",
                    }}
                    onClick={() => {
                      if (
                        configData?.config?.global?.PLAY_GROUND?.value === "YES"
                      ) {
                        customHistory.push("/auth/sign-in?source=chat");
                      } else {
                        customHistory.push("/");
                      }
                    }}
                  >
                    {t("Login")}
                  </Button>
                  <Button
                    fontWeight="700"
                    color={"#fff"}
                    fontFamily={"Nunito Sans, sans-serif"}
                    bg="#4FDABC"
                    py="5px"
                    px="25px"
                    borderRadius={"10px"}
                    // onClick={onClose}
                    // position={"absolute"}
                    // bottom="-95px"
                    // right="16px"
                    width="110px"
                    textAlign={"center"}
                    _hover={{
                      bg: "rgba(79, 218, 188, .8)",
                    }}
                    _active={{
                      bg: "#4FDABC",
                    }}
                    _focus={{
                      bg: "#4FDABC",
                    }}
                    onClick={() => {
                      if (
                        configData?.config?.global?.PLAY_GROUND?.value === "YES"
                      ) {
                        customHistory.push(
                          isKor ? "/auth/sign-up" : "/landing"
                        );
                      } else {
                        customHistory.push("/");
                      }
                    }}
                  >
                    {t("Signup")}
                  </Button>
                </>
              )}

            {configData?.config?.global?.PLAY_GROUND?.value === "YES" &&
              configData?.config?.global?.WEB3_AUTHENTICATION?.value ===
                "YES" && (
                <>
                  <MetamaskLoginButton
                    type={"navbar"}
                    onCloseSignIn={onClose}
                  />
                </>
              )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default InitialModal;
