import {
  Avatar,
  Box,
  Button,
  Flex,
  Icon,
  IconButton,
  Image,
  Link,
  List,
  ListItem,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Select,
  Stack,
  Text,
  useColorModeValue,
  useDisclosure,
  Badge,
} from "@chakra-ui/react";
import CustomNavLink from "langHoc/CustomNavLink";
import { useTranslation } from "react-i18next";
import { MdClose } from "react-icons/md";
import { useState } from "react";
import { WithController } from "../Search/Count";
import { AuthContext } from "contexts/AuthContext";

import { ConfigContext } from "contexts/ConfigContext";
import { useContext } from "react";
import useCustomHistory from "langHoc/useCustomHistory";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { HamburgerIcon } from "@chakra-ui/icons";
import { AiOutlineGlobal } from "react-icons/ai";
import { FiGlobe, FiSearch, FiUser } from "react-icons/fi";
import { ProfileIcon } from "components/icons/Icons";
import { UserX } from "react-feather";
import { BiUser, BiUserCircle } from "react-icons/bi";
import { walletAddressFormat } from "utils/utils";
import MetamaskLoginButton from "views/auth/signIn/MetamaskLoginButton";
import DepositButton from "views/components/DepositButton";
import { FaUserCircle } from "react-icons/fa";
export default function HeaderBanner({ isShow, setIsShow }) {
  const { dispatch, authState, isFetched } = useContext(AuthContext);
  const configData = useContext(ConfigContext);
  const [num, setNum] = useState(10000000);
  const [open, setOpen] = useState(false);
  const [isLangMenuOpen, setIsLangMenuOpen] = useState(false);
  const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false);
  const lang = useLocation();
  const isKor = lang?.pathname.includes("/ko");
  // Chakra Color Mode
  const textColor = useColorModeValue(
    "rgba(255, 255, 255, 0.8)",
    "secondaryGray.900"
  );
  const secondaryTextColor = useColorModeValue("#5f6368", "white");
  const inputColor = useColorModeValue(
    "secondaryGray.700",
    "secondaryGray.700"
  );
  const inputText = useColorModeValue("gray.700", "gray.100");
  const blockBg = useColorModeValue("secondaryGray.300", "navy.700");
  const brandButton = useColorModeValue("brand.500", "brand.400");
  const bgInput = useColorModeValue(
    "linear-gradient(1.02deg, #FFFFFF 50%, rgba(255, 255, 255, 0) 100%)",
    "linear-gradient(1.02deg, #111C44 50%, rgba(17, 28, 68, 0) 100%)"
  );
  // Ellipsis modals
  const {
    isOpen: isOpen1,
    onOpen: onOpen1,
    onClose: onClose1,
  } = useDisclosure();
  // Chakra Color Mode
  const textHover = useColorModeValue(
    { color: "secondaryGray.900", bg: "unset" },
    { color: "secondaryGray.500", bg: "unset" }
  );
  const bgList = useColorModeValue("white", "whiteAlpha.100");
  const brandColor = useColorModeValue("brand.500", "white");
  const bgShadow = useColorModeValue(
    "14px 17px 40px 4px rgba(112, 144, 176, 0.08)",
    "unset"
  );
  const { t } = useTranslation();
  const history = useCustomHistory();

  const platform = process.env?.REACT_APP_PLATFORM;

  return (
    <Box display={"block"} height="62px" >
      <Box
        display={"flex"}
        justifyContent="space-between"
        alignItems="center"
        pt={{
          base: isShow ? "0px" : "0px",
          md: isShow ? "0px" : "0px",
          lg: isShow ? "0px" : "0px",
        }}
        width="100%"
        flexDirection={{
          base: "row",
          md: "row",
        }}
      >
        <Box
          display={{
            base: "flex",
            md: "flex",
          }}
          alignItems="center"
          flex="0 0 auto"
          gap="0px 5px"
          pl="25px"
          width={{
            base: "30%",
            md: "50%",
          }}
          pt={{
            base: "7px",
            md: "20px",
          }}
          justifyContent={{
            base: "flex-star",
            md: "flex-start",
          }}
        ></Box>

        {Object.keys(configData?.config || {}).length > 0 && (
          <Box
            width={{
              base: "70%",
              md: "50%",
            }}
            // top="40px"
            left={"0"}
            bg="transparent"
            p={{
              base: "25px 25px 2px 25px",
              md: "2px 25px 2px 0",
            }}
            display={"flex"}
            justifyContent={{
              base: "flex-end",
              md: "flex-end",
            }}
            pt={{
              base: "20px",
              md: "20px",
            }}
            fontFamily={"Nunito Sans, sans-serif"}
            alignItems={"center"}
          >
            {configData?.config?.global?.LANGUAGE_ACTIVE?.value === "YES" && (
              <Stack spacing={3} >
                <Menu className="MENUUU" isOpen={isLangMenuOpen}>
                  <MenuButton
                    as={IconButton}
                    aria-label="Options"
                    icon={
                      <>
                        <FiGlobe />
                        <Text
                          color="#1B2559"
                          fontSize="14px"
                          pl="2"
                          fontWeight={600}
                        >
                          {isKor ? "한국어" : "EN"}
                        </Text>
                      </>
                    }
                    variant="outline"
                    p="0px"
                    width={"75px"}
                    height={"35px"}
                    borderRadius={"5px"}
                    border="none"
                    d="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                    _hover={{ background: "transparent" }}
                    _active={{ background: "transparent" }}
                    // onMouseEnter={() => setOpen(true)}
                    onMouseEnter={() => {
                      setIsLangMenuOpen(true);
                      setIsProfileMenuOpen(false); // Close the profile dropdown
                    }}
                    onMouseLeave={() => setIsLangMenuOpen(false)}
                    pt="6px"
                  />

                  <MenuList
                    className={`MENU_LIST ${platform === "Chingu" ? "MENU_LIST_CHINGU" : ""
                      }`}
                    borderRadius="4px"
                    bg={"#fff"}
                    border="none"
                    boxShadow={
                      "0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12)"
                    }
                    px="10px"
                    onMouseEnter={() => setIsLangMenuOpen(true)}
                    onMouseLeave={() => setIsLangMenuOpen(false)}
                  >
                    <MenuItem
                      // command="KO"
                      onClick={(e) => {
                        window.location = `/ko`;
                      }}
                      isChecked={isKor}
                      _hover={{ background: "#e9ecef" }}
                      _focus={{ bg: "none" }}
                      borderRadius="4px"
                      py="6px"
                      color="#495057"
                      fontFamily={"Nunito Sans, sans-serif"}
                    >
                      <Text fontSize="sm">{t("Korean")} </Text>
                    </MenuItem>
                    <MenuItem
                      // command="EN"
                      onClick={(e) => {
                        window.location = `/en`;
                      }}
                      isChecked={!isKor}
                      _hover={{ background: "#e9ecef" }}
                      _focus={{ bg: "none" }}
                      borderRadius="4px"
                      py="6px"
                      color="#495057"
                      fontFamily={"Nunito Sans, sans-serif"}
                    >
                      <Text fontSize="sm">{t("English")} </Text>
                    </MenuItem>
                  </MenuList>
                </Menu>
              </Stack>
            )}

            {!authState?.isAuthenticated && (
              <List display="flex" ml={"20px"}>
                {configData?.config?.global?.WEB3_AUTHENTICATION?.value !==
                  "YES" && (
                    <ListItem
                      fontWeight="500"
                      color={"#fff"}
                      fontFamily={"Nunito Sans, sans-serif"}
                      bg="#4FDABC"
                      py="5px"
                      px="20px"
                      borderRadius={"10px"}
                    >
                      <CustomNavLink to="/auth/sign-in?source=searchPage">
                        {t("Signin")}
                      </CustomNavLink>
                    </ListItem>
                  )}

                {configData?.config?.global?.WEB3_AUTHENTICATION?.value ===
                  "YES" && (
                    <>
                      <ListItem>
                        <MetamaskLoginButton type={"navbar"} />
                      </ListItem>
                    </>
                  )}
              </List>
            )}

            {authState?.isAuthenticated &&
              configData?.config?.global?.WEB3_AUTHENTICATION?.value ===
              "NO" && (
                <Menu isOpen={isProfileMenuOpen}>
                  <MenuButton
                    p="0px"
                    onMouseEnter={() => {
                      setIsProfileMenuOpen(true);
                      setIsLangMenuOpen(false); // Close the language dropdown
                    }}
                    onMouseLeave={() => setIsProfileMenuOpen(false)}
                  >
                    <Box
                      display={"flex"}
                      alignItems="center"
                      gap="10px"
                      // borderLeft={'1px solid #dee2e6'}
                      pl="16px"
                    >
                      {!authState?.user?.photo && isFetched && (
                        <>
                          <Box
                            w="35px"
                            h="35px"
                            // bg={'#121212'}
                            d="flex"
                            alignItems="center"
                            justifyContent="center"
                            borderRadius={"4px"}
                            color="#fff"
                          >
                            <FaUserCircle color="#121212" size={"35px"} />
                          </Box>
                        </>
                      )}

                      {authState?.user?.photo && (
                        <Image
                          _hover={{ cursor: "pointer" }}
                          color="white"
                          w="35px"
                          h="35px"
                          borderRadius={"5px"}
                          src={authState?.user?.photo}
                        />
                      )}
                    </Box>
                  </MenuButton>
                  <MenuList
                    boxShadow={
                      "0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12)"
                    }
                    p="0px"
                    mt="0px"
                    borderRadius="4px"
                    bg={"#fff"}
                    border="none"
                    // className="PROFILE_DROPDOWN_TWO"
                    className={`PROFILE_DROPDOWN_TWO ${platform === "Chingu" ? "PROFILE_DROPDOWN_TWO_CHINGU" : ""
                      }`}
                    onMouseEnter={() => setIsProfileMenuOpen(true)}
                    onMouseLeave={() => setIsProfileMenuOpen(false)}
                  >
                    <Flex flexDirection="column" p="10px">
                      <>
                        <MenuItem
                          _hover={{ background: "#e9ecef" }}
                          _focus={{ bg: "none" }}
                          // color='red.400'
                          borderRadius="4px"
                          py="6px"
                          onClick={() => {
                            history.push("/profile");
                          }}
                          color="#495057"
                          fontFamily={"Nunito Sans, sans-serif"}
                        >
                          <Text fontSize="sm">{t("ProfileSetting")} </Text>
                        </MenuItem>

                        <MenuItem
                          _hover={{ background: "#e9ecef" }}
                          _focus={{ bg: "none" }}
                          // color='red.400'
                          borderRadius="4px"
                          px="14px"
                          py="6px"
                          color="#495057"
                          fontFamily={"Nunito Sans, sans-serif"}
                          onClick={() => {
                            dispatch({
                              type: "LOGOUT",
                            });
                            history.push("/auth/sign-in");
                          }}
                        >
                          <Text fontSize="sm">{t("Logout")}</Text>
                        </MenuItem>
                      </>
                    </Flex>
                  </MenuList>
                </Menu>
              )}

            {/* Profile menu for metamask user */}
            {authState.isAuthenticated &&
              authState?.user?.source === "metamask" && (
                <>
                  <DepositButton />
                  {/* {authState?.user?.membership !== "pro" && } */}
                  <Menu isOpen={isProfileMenuOpen}>
                    <MenuButton
                      p="0px"
                      onMouseEnter={() => {
                        setIsProfileMenuOpen(true);
                        setIsLangMenuOpen(false); // Close the language dropdown
                      }}
                      onMouseLeave={() => setIsProfileMenuOpen(false)}
                    >
                      <IconButton
                        ml="16px"
                        bg="inherit"
                        borderRadius="inherit"
                        _hover="none"
                        _active={{
                          bg: "inherit",
                          transform: "none",
                          borderColor: "transparent",
                        }}
                        _focus={{
                          boxShadow: "none",
                        }}
                        icon={
                          <>
                            <Badge
                              h={"35px"}
                              display={"flex"}
                              alignItems="center"
                              gap={"5px"}
                            >
                              {" "}
                              <Icon
                                verticalAlign={"middle"}
                                as={BiUserCircle}
                                height={"16px"}
                                width={"16px"}
                              />{" "}
                              <Text fontSize="14px" color={"#1B2559"}>
                                {walletAddressFormat(
                                  authState?.user?.metamaskAddress
                                )}
                              </Text>
                            </Badge>
                          </>
                        }
                      />
                    </MenuButton>

                    <MenuList
                      boxShadow={
                        "0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12)"
                      }
                      p="0px"
                      mt="0px"
                      borderRadius="4px"
                      bg={"#fff"}
                      border="none"
                      className="PROFILE_DROPDOWN"
                      onMouseEnter={() => setIsProfileMenuOpen(true)}
                      onMouseLeave={() => setIsProfileMenuOpen(false)}
                    >
                      <Flex flexDirection="column" p="10px">
                        <>
                          <MenuItem
                            _hover={{ background: "#e9ecef" }}
                            _focus={{ bg: "none" }}
                            // color='red.400'
                            borderRadius="4px"
                            py="6px"
                            onClick={() => {
                              history.push("/profile");
                            }}
                            color="#495057"
                            fontFamily={"Nunito Sans, sans-serif"}
                          >
                            <Text fontSize="sm">{t("ProfileSetting")} </Text>
                          </MenuItem>
                          {/* <MenuItem
                            _hover={{ background: "#e9ecef" }}
                            _focus={{ bg: "none" }}
                            borderRadius="4px"
                            px="14px"
                            py="6px"
                            color="#495057"
                            fontFamily={"Nunito Sans, sans-serif"}
                            onClick={() => {
                              history.push("/rewards");
                            }}
                          >
                            <Text fontSize="sm">Transactions</Text>
                          </MenuItem> */}
                          <MenuItem
                            _hover={{ background: "#e9ecef" }}
                            _focus={{ bg: "none" }}
                            // color='red.400'
                            borderRadius="4px"
                            px="14px"
                            py="6px"
                            color="#495057"
                            fontFamily={"Nunito Sans, sans-serif"}
                            onClick={() => {
                              dispatch({
                                type: "LOGOUT",
                              });
                              history.push("/auth/sign-in");
                            }}
                          >
                            <Text fontSize="sm">
                              {configData?.config?.global?.WEB3_AUTHENTICATION
                                ?.value === "YES"
                                ? "Disconnect Wallet"
                                : t("Logout")}
                            </Text>
                          </MenuItem>
                        </>
                      </Flex>
                    </MenuList>
                  </Menu>
                </>
              )}
          </Box>
        )}
      </Box>
    </Box>
  );
}
