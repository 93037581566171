// Chakra imports
import { Portal, Box, useDisclosure } from "@chakra-ui/react";

import React, { useState } from "react";
import Dock from "react-osx-dock";


export default function Dashboard(props) {
  
  return (
    <Box>
    <Dock width={800} magnification={0.1} magnifyDirection="up">
  {["a", "b", "c", "d", "e"].map((item, index) => (
    <Dock.Item key={index} onClick={() =>  console.log(item)}>
      {/* <img src={`${item}.png`} /> */}

      asdfas
    </Dock.Item>
  ))}
</Dock>
    </Box>
  );
}
