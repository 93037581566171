import { useHistory, useParams } from 'react-router-dom';

const useCustomHistory = () => {
  const history = useHistory();
  const { lang } = useParams();

  const push = (path, { search = '', state } = {}) => {
    const langPrefix = lang ? `/${lang}` : '';
    history.push(`${langPrefix}${path}${search ? `?${search}` : ''}`, state);
  };

  const replace = (path, { search = '', state } = {}) => {
    const langPrefix = lang ? `/${lang}` : '';
    window.history.replaceState(state, document.title, `${langPrefix}${path}${search ? `?${search}` : ''}`);
  };

  return { ...history, push, replace };
};

export default useCustomHistory;
