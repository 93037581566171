import React, { useContext, useState } from "react";
// Chakra imports
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  useColorModeValue,
  Text,
  Spinner,
  useBreakpointValue,
  Image,
} from "@chakra-ui/react";
import { userPublicRequest } from "config/axios.config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AuthContext } from "contexts/AuthContext";
import useCustomHistory from "langHoc/useCustomHistory";
import { useTranslation } from "react-i18next";
import Card from "components/card/Card";
import Footer from "components/footer/FooterAdmin.js";
import { useViewportHeight } from "layouts/HomeNew/useViewportHeight2";
import CustomNavLink from "langHoc/CustomNavLink";
import { ConfigContext } from "contexts/ConfigContext";

const lang = window.location.pathname.split("/")[1] || "en";

function SignIn() {
  const { dispatch, profileFetch } = useContext(AuthContext);
  const textColor = useColorModeValue("navy.700", "navy.700");
  const textColorSecondary = "gray.400";
  const textColorDetails = useColorModeValue("navy.700", "navy.700");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({});
  const history = useCustomHistory();
  const { t } = useTranslation();
  const configs = useContext(ConfigContext);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    userPublicRequest
      .post("/auth/reset-password/request", { ...formData, lang })
      .then((res) => {
        history.push(`/auth/reset?email=${formData.email}`);
      })
      .catch((error) => {
        const toastId = "signin-error-toast";
        if (!toast.isActive(toastId)) {
          toast.error(error.response.data?.message??"Something went wrong!", {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            toastId: toastId,
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const changeForm = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const vh = useViewportHeight();
  const breakpoint = useBreakpointValue({ base: "base", lg: "lg" });
  const minH = {
    base: `calc(${vh}px - 40px)`,
    lg: `calc(${vh}px - 50px)`,
  };

  return (
    <Flex
      direction="column"
      overflow="hidden"
      justifyContent="center"
      alignItems="center"
      mx={{ base: "0px", lg: "0px" }}
      bg={"white"}
      height={"100vh"}
      pb="10px"
      px="20px"
    >
      <Box
        display="flex"
        justifyContent={"center"}
        alignItems={"center"}
        flexDirection="column"
        width="100%"
      >
        <CustomNavLink to="/">
          <Box position="relative">
            <Image
              src={configs?.logo}
              alt="logo"
              width={{ base: "300px", lg: "300px" }}
              marginBottom="40px"
              marginX="auto"
            />
            {/* <Box
              position="absolute"
              top="0"
              right="-60px"
              bg="#4fdabc"
              color="white"
              px="2"
              py="1"
              borderRadius="full"
              fontSize="xs"
              fontWeight="bold"
              textTransform="uppercase"
            >
              Beta
            </Box> */}
          </Box>
        </CustomNavLink>
        <Box
          height={"100%"}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          width={"100%"}
          maxW="600px"
        >
          <Card
            w={{ base: "100%", md: "max-content" }}
            h="max-content"
            mx="auto"
            maxW="100%"
            p="10px"
            pt="10px"
            bg={"transparent"}
            style={{ boxShadow: "unset" }}
          >
            <Box>
              <Flex
                maxW={{ base: "100%", md: "max-content" }}
                w="100%"
                mx={{ base: "auto", lg: "0px" }}
                me="auto"
                justifyContent="center"
                px={{ base: "0px", md: "0px" }}
                flexDirection="column"
              >
                <Box me="auto" textAlign="center">
                  <Heading
                    color={textColor}
                    fontSize="20px"
                    mb="12px"
                    lineHeight={1}
                  >
                    {t("ForgotPassword")}
                  </Heading>
                  <Text
                    color={textColorSecondary}
                    fontSize="md"
                    w={{ base: "100%", lg: "456px" }}
                    maxW="100%"
                    mx={{ base: "auto", lg: "unset" }}
                    textAlign={{ base: "center", lg: "left" }}
                    lineHeight={1.5}
                    mb={"12px"}
                  >
                    {t("forgetPasswordContent")}
                  </Text>
                </Box>
                <Flex
                  zIndex="2"
                  direction="column"
                  w={{ base: "100%", lg: "100%" }}
                  maxW="100%"
                  background="transparent"
                  borderRadius="15px"
                  mx={{ base: "auto", lg: "unset" }}
                  me="auto"
                  mb={{ base: "0", md: "auto" }}
                  align="start"
                >
                  <form
                    onSubmit={handleSubmit}
                    style={{
                      width: "100%",
                    }}
                  >
                    <FormControl>
                      <FormLabel
                        display="flex"
                        ms="4px"
                        fontSize="sm"
                        fontWeight="500"
                        color={textColor}
                        // mb="20px"
                        mb={"4px"}
                        lineHeight={1}
                      >
                        {t("EmailAddress")}
                        <Text color={brandStars}>*</Text>
                      </FormLabel>
                      <Input
                        isRequired={true}
                        variant="auth"
                        fontSize="sm"
                        type="email"
                        placeholder="Enter your email"
                        mb="12px"
                        size="lg"
                        name="email"
                        onChange={changeForm}
                        height={"40px"}
                        borderRadius={"10px"}
                        required
                      />
                      <Button
                        fontSize="sm"
                        variant="brand"
                        fontWeight="500"
                        w="100%"
                        h="50"
                        bg="#6c00ff"
                        _hover={{
                          bg: "#5b00d9",
                        }}
                        _active={{
                          bg: "#5b00d9",
                        }}
                        _focus={{
                          bg: "#5b00d9",
                        }}
                        disabled={loading}
                        style={{ borderRadius: "10px", height: "40px" }}
                        onClick={handleSubmit}
                      >
                        {loading ? (
                          <>
                            <Spinner />
                          </>
                        ) : (
                          t("EmailPasswordResetOTP")
                        )}
                      </Button>
                    </FormControl>
                  </form>
                </Flex>
              </Flex>
            </Box>
          </Card>
        </Box>
      </Box>
      <Footer />
    </Flex>
  );
}

export default SignIn;
