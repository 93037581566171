import React, { useContext, useRef, useEffect } from "react";

// Chakra imports
import {
  Box,
  Button,
  Flex,
  Image,
  Link,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";

import Slider from "react-slick";
// Assets
import { ConfigContext } from "contexts/ConfigContext";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";

export default function Banner() {
  let configs = useContext(ConfigContext);
  const lang = useLocation();
  const isKor = lang?.pathname.includes("/ko");

  const colors = configs?.config?.global?.COLORS?.value;
  const primaryColor = useColorModeValue(
    colors?.primaryColor ? colors?.primaryColor : "navy.700",
    colors?.primaryDarkColor ? colors?.primaryDarkColor : "white"
  );

  const secondaryColor = useColorModeValue(
    colors?.secondaryColor ? colors?.secondaryColor : "navy.700",
    colors?.secondaryDarkColor ? colors?.secondaryDarkColor : "white"
  );

  let settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    nextArrow: () => <></>,
    prevArrow: () => <></>,
  };
  const history = useHistory();

  const slides = configs?.config?.global?.CHATPAGE_BANNER?.value?.slides ?? [];
  // Chakra Color Mode

  return (
    <>
      <Box mt="1px" pr="22px" pl="20px" mb={"5px"}
        cursor={'pointer'}
      >
        <Slider {...settings} arrows={false}>
          {slides.map((slide) => {
            return (
              <Flex
                direction="column"
                // bgImage={slide?.file ?? null}
                // bgSize="contain" // Ensures the image covers the full container
                // backgroundRepeat={"no-repeat"}
                bgPos="center"
                // py={{ base: "30px", md: "56px" }}
                // px={{ base: "30px", md: "64px" }}
                // h="350px"
                // backgroundColor={"#f5f6fe"}
                borderRadius="30px"
                position={"relative"}
                onClick={(e) => {
                  let redirectUrl = isKor
                    ? slide?.banner_link_ko
                    : slide?.banner_link_en;
                  if (redirectUrl) {
                    // window.location.href = redirectUrl;

                    window.open(redirectUrl, "_blank");
                  }
                }}
              >
                <Image
                  src={slide?.file ?? null}
                  width={"100%"}
                  h={"100%"}
                  borderRadius="30px"
                />
                <Box
                  position={"absolute"}
                  left={"0"}
                  top={"0"}
                  height={"100%"}
                  width={"100%"}
                  zIndex={"999999999"}
                  py={{ base: "30px", md: "56px" }}
                  px={{ base: "30px", md: "64px" }}
                >
                  <Text
                    fontSize={"18px"}
                    color="white"
                    mb="14px"
                    maxW={{
                      base: "100%",
                      md: "64%",
                      lg: "66%",
                      xl: "90%",
                      "2xl": "50%",
                      "3xl": "50%",
                    }}
                    fontWeight="700"
                    lineHeight={{ base: "32px", md: "42px" }}
                  >
                    {isKor ? slide.title_ko : slide.title_en}
                  </Text>
                  <Text
                    fontSize={"14px"}
                    color="#E3DAFF"
                    maxW={{
                      base: "100%",
                      md: "64%",
                      lg: "40%",
                      xl: "56%",
                      "2xl": "46%",
                      "3xl": "34%",
                    }}
                    fontWeight="500"
                    mb="40px"
                    lineHeight="28px"
                  >
                    {isKor ? slide.description_ko : slide.description_en}
                  </Text>
                  <Flex align="center">
                    {((isKor && slide?.button_1_text_ko) ||
                      (!isKor && slide?.button_1_text_en)) && (
                        <Button
                          bg="white"
                          color="black"
                          _hover={{ bg: "whiteAlpha.900" }}
                          _active={{ bg: "white" }}
                          _focus={{ bg: "white" }}
                          fontWeight="500"
                          fontSize="14px"
                          py="20px"
                          px="27"
                          me="38px"
                          onClick={() => {
                            let path = isKor
                              ? slide?.button_1_link_ko
                              : slide.button_1_link_en;
                            history.push(path);
                          }}
                        >
                          {isKor
                            ? slide.button_1_text_ko
                            : slide.button_1_text_en}
                        </Button>
                      )}

                    {((isKor && slide?.button_2_text_ko) ||
                      (!isKor && slide?.button_2_text_en)) && (
                        <Link
                          href={
                            isKor
                              ? slide?.button_2_link_ko
                              : slide.button_2_link_en
                          }
                        >
                          <Text color="white" fontSize="md" fontWeight="500">
                            {isKor
                              ? slide.button_2_text_ko
                              : slide.button_2_text_en}
                          </Text>
                        </Link>
                      )}
                  </Flex>
                </Box>
              </Flex>
            );
          })}
        </Slider>
      </Box>

      <style jsx>{`
        .slick-dots {
          bottom: -20px !important;
          display: block;
          text-align: center;
          width: 100%;
        }

        .slick-dots li {
          position: relative;
          display: inline-block;
          height: 14px;
          width: 14px;
          margin: 0 5px;
          padding: 0;
        }

        .slick-dots li button {
          border: 0;
          background: ${secondaryColor};
          display: block;
          height: 10px;
          width: 10px;
          outline: none;
          line-height: 0;
          font-size: 0;
          color: transparent;
          padding: 5px;
          cursor: pointer;
          border-radius: 50%;
          margin-right: 0px;
        }

        .slick-dots li button:before {
          position: absolute;
          top: 0;
          left: 0;
          content: "";
          width: 16px;
          height: 16px;
          font-family: "slick";
          font-size: 6px;
          line-height: 16px;
          text-align: center;
          color: black;
          opacity: 0.25;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
        }

        .slick-dots li.slick-active button {
          background: ${primaryColor};
        }
      `}</style>
    </>
  );
}
