import React, { lazy, Suspense, useEffect } from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
const DynamicComponent = lazy(() => import('../../layouts/HomeNew/NewLanding'))

const NewHome = () => {


  const history = useHistory()
  // useEffect(() => {
  //   const currentPath = history.location.pathname;

  //   if (!currentPath.startsWith('/en') && !currentPath.startsWith('/ko')) {
  //     history.push('/en' + currentPath);
  //   }
  // }, []); // Add dependencies if needed

  return (
    <div>
      <Suspense fallback={<></>}>
        <DynamicComponent />
      </Suspense>
    </div>
  )
}

export default NewHome
