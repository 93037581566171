

// Chakra imports
import {
  Badge,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  SimpleGrid,
  Spinner,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { userPublicRequest } from "config/axios.config";
// Custom components
import PricingLayout from "layouts/auth/types/Pricing";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

function Pricing() {
  const [activeButton, setActiveButton] = useState({
    monthly: true,
    yearly: false,
  });

  const textColor = useColorModeValue("secondaryGray.900", "white");

  // Chakra color mode
  // const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const googleBg = useColorModeValue("secondaryGray.300", "whiteAlpha.200");
  const googleText = useColorModeValue("navy.700", "white");
  const googleHover = useColorModeValue(
    { bg: "gray.200" },
    { bg: "whiteAlpha.300" }
  );
  const googleActive = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.200" }
  );
  const [show, setShow] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const history = useHistory()
  const handleClick = () => setShow(!show);


  const submitHandler = async (e) => {
    e.preventDefault()

    try {
      setLoading(true)
      const payload = {
        email
      }
      const result = await userPublicRequest.post('/waitlist/submit', payload)
      // // console.log()
      toast.success('Account added successfully', {
        onClose: () => {
          setLoading(false)
          // history.push(`/auth/verify?email=${email}`)
        }
      });

    } catch (err) {
      // console.log(err)
      setLoading(false)
      toast.error(err.response.data.message);
    }

  }


  return (
    <PricingLayout
      image={"linear-gradient(135deg, #000000 0%, #ffffff 100%)"}
      contentTop={{ base: "140px", md: "14vh" }}
      contentBottom={{ base: "50px", lg: "auto" }}>
      <Flex
        direction='column'
        alignSelf='center'
        justifySelf='center'
        overflow='hidden'>
        <Flex
          direction='column'
          textAlign='center'
          justifyContent='center'
          align='center'
          mb='38px'>
          <Text
            fontSize='44px'
            color='white'
            fontWeight='700'
            maxW='550px'
            lineHeight='52px'>
            Your next "SMART" phone.
          </Text>
          <Text
            fontSize='md'
            color='white'
            fontWeight='5ormal'
            mt='10px'
            mb='26px'
            maxW='400px'>
            Join the waitlist today.
          </Text>
          <Badge
            w='max-content'
            mb='60px'
            fontSize='sm'
            bg='rgba(255,255,255,0.12)'
            color='white'
            fontWeight='bold'
            textTransform='unset'>
            Early bird access.
          </Badge>
          <form onSubmit={submitHandler}>

            <FormControl mt="200px" w="420px">
              <SimpleGrid
                columns={{ base: "1", md: "2" }}
                gap={{ sm: "10px", md: "26px" }}>


              </SimpleGrid>
              <FormLabel
                display='flex'
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                mb='8px'>
                Email<Text color={brandStars}>*</Text>
              </FormLabel>
              <Input
                isRequired={true}
                variant='auth'
                fontSize='sm'
                type='email'
                placeholder='email@example.com'
                mb='24px'
                size='lg'
                value={email}
                onChange={e => setEmail(e.target.value)}
              />


              <Button
                variant='brand'
                fontSize='14px'
                fontWeight='500'
                w='100%'
                h='50'
                mb='24px'
                type="submit"
                disabled={loading}
              >


                {loading ? (
                  <>
                    <Spinner />
                  </>
                ) : (
                  "Sign Up"
                )}
              </Button>
            </FormControl>
          </form>

        </Flex>
      </Flex>
    </PricingLayout>
  );
}

export default Pricing;
