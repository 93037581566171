const formatReferenceText = (
  response,
  googleOrganicData,
  criteria = "text"
) => {
  let references = [];
  const matchRegex = /\^\^(\d+)\^\^/g;
  let matchedData = response.match(matchRegex);
  // // console.log(matchedData)
  if (!matchedData) {
    return { text: response, references };
  }

  let text = response;

  matchedData.map((firstIteration) => {
    let matchedText = firstIteration;
    let matchedNumbers = matchedText.split(",");
    let markup = matchedNumbers.map((iteration) => {
      if (criteria == "blog" || criteria == "news" || criteria == "survey") {
        return "";
      }
      iteration = iteration.match(/\d/g)?.join("") || null;
      if (!iteration) {
        return matchedText;
      } else if (!googleOrganicData) {
        return "";
      } else if (!googleOrganicData.length) {
        return "";
      }
      let googleInfo = googleOrganicData[parseInt(iteration) - 1] || {};
      let link = googleInfo.link || null;
      if (!link) {
        return "";
      }
      references.push(iteration);

      return `<a style="color:#1c73e2" class="source-reference source-${iteration}" target="_blank" data-iteration="${iteration}" href="${
        link || "#"
      }">[${iteration}]</a>`;
    });

    text = text.replace(matchedText, markup.join(" "));
  });
  return { text, references };
};
export { formatReferenceText };
