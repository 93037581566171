// CustomNavLink.js
import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';

const CustomNavLink = ({ target, to, ...props }) => {
  const location = useLocation();
  const lang = location.pathname.split('/')[1];
  const isLang = /^(en|ko)$/.test(lang);

  const prefixedTo = isLang ? `/${lang}${to}` : to;

  return (
    <NavLink to={prefixedTo} target={target} {...props} />
  );
};

export default CustomNavLink;
