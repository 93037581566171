import { AuthContext } from 'contexts/AuthContext'
import useCustomHistory from 'langHoc/useCustomHistory'
import React, { useEffect, useContext } from 'react'
import {
  useHistory,
  useLocation,
  useParams,
} from 'react-router-dom/cjs/react-router-dom'

const Logout = () => {
  const { dispatch } = useContext(AuthContext)
  const history = useCustomHistory()
  const url = new URL(window.location.href)
  const urlObj = new URL(url)
  const params = new URLSearchParams(urlObj.search)
  const sourceValue = params.get('source')
  const { lang } = useParams();
  // // console.log(lang, 'lang')
  // // console.log(sourceValue, '___emrans')
  useEffect(() => {
    dispatch({ type: 'LOGOUT' })
    if (sourceValue == 'agi') {
      history.push(`/auth/sign-in?source=agi`)
    } else {
      history.push(`/auth/sign-in`)
    }
  }, [])

  return <div>Logout</div>
}

export default Logout
