import {
  Box,
  Flex,
  IconButton,
  Input,
  Text,
  useDisclosure,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Button,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  Select,
} from "@chakra-ui/react";
import { userPrivateRequest } from "config/axios.config";
import { ConfigContext } from "contexts/ConfigContext";
import { useEffect } from "react";
import { useState } from "react";
import { useContext } from "react";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import v from "voca";

const GetPaymentConfirmation = ({
  generateOneClickVideo,
  onClose,
  stripeCall,
  paypalCall,
}) => {
  const lang = useLocation();
  const isKor = lang?.pathname.includes("/ko");
  const configData = useContext(ConfigContext);
  const PAYMENT_CHOOSE_CONFIGURATION =
    configData?.config?.global?.PAYMENT_CHOOSE_CONFIGURATION?.value || {};
  // // console.log(PAYMENT_CHOOSE_CONFIGURATION, "config");
  const [shortGptForm, setShortGptForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [eleLanguage, setEleLanguage] = useState([
    "English",
    "Korean",
    "Spanish",
    "French",
    "Arabic",
    "German",
    "Polish",
    "Italian",
    "Portuguese",
  ]);
  const [voices, setVoices] = useState([]);

  const stripeTitle = isKor
    ? PAYMENT_CHOOSE_CONFIGURATION?.stripeTitleKr
    : PAYMENT_CHOOSE_CONFIGURATION?.stripeTitleEn;
  const paypalTitle = isKor
    ? PAYMENT_CHOOSE_CONFIGURATION?.paypalTitleKr
    : PAYMENT_CHOOSE_CONFIGURATION?.paypalTitleEn;

  return (
    <Modal id="SHARE_MODAL" onClose={onClose} isOpen={true} isCentered={true}>
      <ModalOverlay
        backdropFilter={"blur(2px) opacity(0.9) hue-rotate(20deg)"}
      />
      <ModalContent
        borderRadius={"20px"}
        boxShadow="rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px"
        my="auto"
        w="800px"
        // maxWidth={"500px"}
        maxWidth={"800px"}
      >
        <ModalCloseButton
          autoFocus={false}
          bg="transparent"
          top="16px"
          right="16px"
          _focus={{
            boxShadow: "none",
          }}
        />
        <ModalBody padding="16px 24px 16px 24px">
          <Box
            display="flex"
            flexWrap={"wrap"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            {stripeTitle && (
              <Box
                textAlign="center"
                w={"50%"}
                ml={"auto"}
                mr={"auto"}
                borderRight={
                  stripeTitle && paypalTitle ? "1px solid #ddd" : "none"
                }
                // style={}
              >
                <ModalHeader>
                  {isKor
                    ? PAYMENT_CHOOSE_CONFIGURATION?.stripeTitleKr
                    : PAYMENT_CHOOSE_CONFIGURATION?.stripeTitleEn}
                </ModalHeader>
                <Box padding="0px 0px 16px 0px">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: isKor
                        ? PAYMENT_CHOOSE_CONFIGURATION?.stripeMessageKr
                        : PAYMENT_CHOOSE_CONFIGURATION?.stripeMessageEn,
                    }}
                  />
                </Box>

                <Button
                  fontWeight="700"
                  color={"#fff"}
                  fontFamily={"Nunito Sans, sans-serif"}
                  bg="#4FDABC"
                  py="5px"
                  px="25px"
                  borderRadius={"10px"}
                  // width="110px"
                  textAlign={"center"}
                  _hover={{
                    bg: "rgba(79, 218, 188, .8)",
                  }}
                  _active={{
                    bg: "#4FDABC",
                  }}
                  _focus={{
                    bg: "#4FDABC",
                  }}
                  onClick={() => {
                    stripeCall();
                  }}
                >
                  {isKor
                    ? PAYMENT_CHOOSE_CONFIGURATION?.stripeButtonKr
                    : PAYMENT_CHOOSE_CONFIGURATION?.stripeButtonEn}
                </Button>
              </Box>
            )}

            {paypalTitle && (
              <Box textAlign="center" ml={"auto"} mr={"auto"} w={"50%"}>
                <ModalHeader>
                  {isKor
                    ? PAYMENT_CHOOSE_CONFIGURATION?.paypalTitleKr
                    : PAYMENT_CHOOSE_CONFIGURATION?.paypalTitleEn}
                </ModalHeader>
                <Box padding="0px 0px 16px 0px">
                  <p>{/* {PAYMENT_CHOOSE_CONFIGURATION?.elaiDescription} */}</p>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: isKor
                        ? PAYMENT_CHOOSE_CONFIGURATION?.paypalMessageKr
                        : PAYMENT_CHOOSE_CONFIGURATION?.paypalMessageEn,
                    }}
                  />
                </Box>

                <Button
                  fontWeight="700"
                  color={"#fff"}
                  fontFamily={"Nunito Sans, sans-serif"}
                  bg="#4FDABC"
                  py="5px"
                  px="25px"
                  borderRadius={"10px"}
                  // width="110px"
                  textAlign={"center"}
                  _hover={{
                    bg: "rgba(79, 218, 188, .8)",
                  }}
                  _active={{
                    bg: "#4FDABC",
                  }}
                  _focus={{
                    bg: "#4FDABC",
                  }}
                  onClick={() => {
                    paypalCall();
                  }}
                >
                  {isKor
                    ? PAYMENT_CHOOSE_CONFIGURATION?.paypalButtonKr
                    : PAYMENT_CHOOSE_CONFIGURATION?.paypalButtonEn}
                </Button>
              </Box>
            )}
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default GetPaymentConfirmation;
