import {
  Avatar,
  Box,
  Button,
  Flex,
  Progress,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useMemo } from "react";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import HistoryItem from "views/admin/nfts/marketplace/components/HistoryItem";
import Nft5 from "assets/img/nfts/Nft5.png";
function TopCreatorTable(props) {
  const { columnsData, tableData } = props;

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow } =
    tableInstance;

  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = useColorModeValue("secondaryGray.600", "white");

  return (
    <>
      <Flex
        direction="column"
        w="100%"
        overflowX={{ sm: "scroll", lg: "hidden" }}
      >
        <Flex
          align={{ sm: "flex-start", lg: "center" }}
          justify="space-between"
          w="100%"
          px="22px"
          pb="20px"
          mb="10px"
          boxShadow="0px 40px 58px -20px rgba(112, 144, 176, 0.26)"
        >
          <Text color={textColor} fontSize="xl" fontWeight="600">
            Top Stories
          </Text>
          <Button variant="action">See all</Button>
        </Flex>

        <HistoryItem
          name="The Ethics of AI: Balancing Progress with Responsibility"
          author="By Mark Benjamin"
          date="30s ago"
          image={Nft5}
          price="0.91 ETH"
        />

        <HistoryItem
          name="The Impact of AI on Healthcare: A Game-Changer for Patients and Providers"
          author="By Mark Benjamin"
          date="30s ago"
          image={Nft5}
          price="0.91 ETH"
        />
        <HistoryItem
          name="AI in Education: Transforming the Way We Learn and Teach"
          author="By Mark Benjamin"
          date="30s ago"
          image={Nft5}
          price="0.91 ETH"
        />
        <HistoryItem
          name="AI and Climate Change: Can Technology Save the Planet?"
          author="By Mark Benjamin"
          date="30s ago"
          image={Nft5}
          price="0.91 ETH"
        />
        <HistoryItem
          name="The Rise of Chatbots: How AI is Changing Customer Service Forever"
          author="By Mark Benjamin"
          date="30s ago"
          image={Nft5}
          price="0.91 ETH"
        />
      </Flex>
    </>
  );
}

export default TopCreatorTable;
