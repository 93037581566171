// Chakra imports
import { EditIcon } from "@chakra-ui/icons";
import {
  Avatar,
  Box,
  Button,
  Flex,
  IconButton,
  Image,
  Input,
  Select,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import Card from "components/card/Card.js";
import { userPrivateRequest } from "config/axios.config";
import { useEffect, useState } from "react";
import { FaUserCircle } from "react-icons/fa";


export default function Settings(props) {
  const { name, avatar, banner, info, selectedImage, setSelectedImage } = props;
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "secondaryGray.600";
  const [isUploading, setIsUploading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);


  const handleImageChange = (e) => {
    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      setSelectedImage({
        file: file,
        previewUrl: reader.result
      });
    };

    reader.readAsDataURL(file);
  };



  return (
    <Card align="center" p="30px" boxShadow="none" bg="transparent" >
      <Box position='relative' mx="auto" mb="0px" >
        {!info?.photo && !selectedImage?.file && (
          <Box position="relative" className="box-area " >
            {/* <Avatar
            mx="auto"
            h="87px"
            w="87px"

            name={name}
            bg='#343a40'
            color="white"
          /> */}
            <Box
              w="100px"
              h="100px"
              // bg={'#121212'}
              d="flex"
              alignItems="center"
              justifyContent="center"
              borderRadius={'4px'}
              color="#fff"
            >
              <FaUserCircle color="#121212" size={'100px'} />
            </Box>

            <div style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'transparent',
              display: 'none', // Initially hide this div
              borderRadius: '100%'
            }}
              className="hover-content">
              <IconButton
                icon={<EditIcon color={'#fff'} />}
                onClick={() => document.getElementById("imageInput").click()}
                position={'absolute'}
                bg='transparent'
                left={'50%'}
                top='50%'
                transform={'translate(-50%, -50%)'}
                _hover={{
                  bg: 'transparent' // Corrected spelling
                }}
                _active={{
                  bg: 'transparent' // Corrected spelling
                }}
                _focus={{
                  bg: 'transparent' // Corrected spelling
                }}
              />
              <Input
                id="imageInput"
                type="file"
                onChange={handleImageChange}
                hidden
              />
            </div>
          </Box>

        )}

        {selectedImage?.file && (
          <Box
            position="relative"
            display="inline-block"
            borderRadius="100%"
            overflow="hidden"
            w="100px"
            h="100px"
            _hover={{
              cursor: 'pointer',
              '&::before': {
                content: '""',
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundColor: 'rgb(169, 169, 169, 0.5)',
                borderRadius: '100%',
              },
            }}
          >
            <Image src={selectedImage?.previewUrl} h="100px"
              w="100px" borderRadius={'100%'} />
          </Box>
        )}
        {!selectedImage?.file && info?.photo && (
          <Box
            position="relative"
            display="inline-block"
            borderRadius="100%"
            overflow="hidden"
            w="100px"
            h="100px"
            _hover={{
              cursor: 'pointer',
              '&::before': {
                content: '""',
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundColor: 'rgb(169, 169, 169, 0.5)',
                borderRadius: '100%',
              },

            }}
            className="box-area"
          >
            <Image src={info?.photo} alt="Your Image" w="100%" h="100%" borderRadius="100%" />
            <div

              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundColor: 'transparent',
                display: 'none', // Initially hide this div
                borderRadius: '100%'
              }}


              className="hover-content"
            >

              <IconButton
                icon={<EditIcon color={'#fff'} />}
                onClick={() => document.getElementById("imageInput").click()}
                position={'absolute'}
                bg='transparent'
                left={'50%'}
                top='50%'
                transform={'translate(-50%, -50%)'}
                // _hover={{
                //   cursor: 'pointer',
                //   '&::before': {
                //     content: '""',
                //     position: 'absolute',
                //     top: 0,
                //     left: 0,
                //     width: '100%',
                //     height: '100%',
                //   },
                // }}

                _hover={{
                  bg: 'transparent' // Corrected spelling
                }}
                _active={{
                  bg: 'transparent' // Corrected spelling
                }}
                _focus={{
                  bg: 'transparent' // Corrected spelling
                }}
              />
              <Input
                id="imageInput"
                type="file"
                onChange={handleImageChange}
                hidden
              />
            </div>
          </Box>
        )}
      </Box>
      {name && (
        <Text fontSize="2xl" mt="30px" textColor={textColorPrimary} fontWeight="700" lineHeight={1}>
          {name}
        </Text>
      )}
      <Flex align="center" mx="auto" px="15px" display={"none"} >
        <Text
          me="4px"
          color={textColorSecondary}
          fontSize="sm"
          fontWeight="400"
          lineHeight="100%"
        >
          Account type:
        </Text>
        <Select
          id="user_type"
          w="unset"
          variant="transparent"
          display="flex"
          textColor={textColorPrimary}
          color={textColorPrimary}
          alignItems="center"
          defaultValue="Administrator"
        >
          <option value="Administrator">Administrator</option>
          <option value="Member">Member</option>
        </Select>
      </Flex>

      {/* CSS for showing the div on hover */}
      <style jsx>{`
    .hover-content {
      transition: background-color 0.3s;
    }
    .box-area:hover .hover-content {
      display: block !important;
      background-color: rgba(0, 0, 0, 0.7) !important; // Semi-transparent black background
    }
  `}</style>
    </Card>
  );
}
