import Popular from 'components/svgs/Popular'
import Refresh from 'components/svgs/Refresh'
import React, { useContext, useState } from 'react'
import { HiRefresh } from 'react-icons/hi'
import {FiGlobe, FiFacebook, FiRefreshCw} from 'react-icons/fi'
import { useEffect } from 'react'
import Axios from 'axios'
import { userPublicRequest } from 'config/axios.config'
import useCustomHistory from 'langHoc/useCustomHistory'
import { AuthContext } from 'contexts/AuthContext'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import parse from 'html-react-parser'
import useMediaQuery from 'components/useMediaQuery'
import { Fade, Grid } from 'mauerwerk'
import { ConfigContext } from 'contexts/ConfigContext'
import { useTranslation } from 'react-i18next'
import { Box } from '@chakra-ui/react'

const PerPrompt = () => {
  const { i18n } = useTranslation()
  const configData = useContext(ConfigContext)
  const config = configData.config || {}
  const history = useCustomHistory()
  const authData = useContext(AuthContext)
  const membership = authData?.authState?.membership
  const [loading, setLoading] = useState(true)
  const [prompts, setPrompts] = useState(false)
  const dvc_768 = useMediaQuery('(max-width: 768px)')

  // // console.log(i18n?.language, 'i18n')

  const [items, setItems] = useState({
    data: [],
    columns: 1,
    margin: 0,
    filter: '',
    height: false,
  })

  const limit = config?.global?.TILES_CONFIG?.value?.homepage?.itemPerPage

  const fetchData = async limit => {
    try {
      setLoading(true)
     
      let homepage = 'homepage'
      if (i18n?.language === 'ko') {
        homepage = 'homepageKo'
      }
      const results = await userPublicRequest.get(
        `/get-random-prompts?limit=${limit}&${homepage}=true`
      )
      // // console.log(results.data, 'result')
      setPrompts(results?.data?.data)
      setItems({ ...items, data: results?.data?.data })
      setLoading(false)
    } catch (err) {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (limit) {
      fetchData(limit)
    }
  }, [limit])

  function truncateText(node, characterLimit) {
    if (node.type === 'text') {
      if (node.data.length > characterLimit) {
        node.data = node.data.substring(0, characterLimit) + '...'
        return false // Stop parsing once the limit is reached
      } else {
        characterLimit -= node.data.length
      }
    }
    return characterLimit > 0
  }

  function truncateHtmlMessage(message, characterLimit) {
    return parse(message, {
      replace: node => truncateText(node, characterLimit),
    })
  }

  let space = config?.global?.TILES_CONFIG?.value?.homepage?.spacing



  return (
    <div className="perPrompt">
      <div className="">
        <div className="promp__top">
          <div className="promp__top-left">
            <div className="icon">
              {' '}
              <FiGlobe /> <h6>Popular Now</h6>
            </div>
          </div>
          <div className="promp__top-right">
            <button onClick={() => fetchData(limit)}>
              <FiRefreshCw />
            </button>
          </div>
        </div>

       {loading && (
        <Box  minH="328px">

        </Box>
       )}
        {!loading && items?.data?.length > 0 ? (
          <>
            <Grid
              className="grid"
              data={items?.data}
              keys={d => d.reference}
              heights={items.height ? d => d.height : 96}
              columns={
                dvc_768
                  ? 1
                  : Number(
                      config?.global?.TILES_CONFIG?.value?.homepage?.columns
                        ? config?.global?.TILES_CONFIG?.value?.homepage?.columns
                        : 3
                    )
              }
              margin={Number(
                config?.global?.TILES_CONFIG?.value?.homepage?.spacing
                  ? config?.global?.TILES_CONFIG?.value?.homepage?.spacing
                  : 10
              )}
              id="GRID"
              lockScroll={true}
              minH="328px"
            >
              {(data, maximized, toggle) => {
   
                const message = data?.message
                const characterLimit = 280

           
                const truncatedMessage = truncateHtmlMessage(
                  message,
                  characterLimit
                )
                return (
                  <div
                    className="cell single"
                    style={{ backgroundImage: data.css }}
                 
                  >
                    <Fade show={maximized} delay={maximized ? 400 : 0}>
                      <div className="details">
                        <div
                          className="circle"
                          style={{ background: data.css }}
                        />
                        <h1>{data.name}</h1>
                        <p>{data.description}</p>
                      </div>
                    </Fade>
                    <Fade
                      show={!maximized}
                      from={{ opacity: 0, transform: 'translate3d(0,140px,0)' }}
                      enter={{ opacity: 1, transform: 'translate3d(0,0px,0)' }}
                      leave={{
                        opacity: 0,
                        transform: 'translate3d(0,-50px,0)',
                      }}
                      delay={maximized ? 0 : 400}
                    >
                     
                      <>
                        <h5>{data?.title}</h5>
                        <p>{truncatedMessage}</p>
                      </>
                  
                    </Fade>
                  </div>
                )
              }}
            </Grid>
          </>
        ) : (
          ''
        )}
      </div>
      <style jsx>{`
        .promp__top {
          margin-top: 55px;
          display: flex;
          align-items: center;
          width: calc(100% - ${space * 2}px);
          margin-left: auto;
          margin-right: auto;
          justify-content: space-between;
        }
        .promp__top-left .icon svg {
          color: #037aff;
          width: 20px;
          height: 20px;
        }
        .promp__top-left .icon {
          width: 30px;
          height: 30px;
          display: flex;
          align-items: center;
          gap: 0px 8px;
          min-width: 200px;
        }
        .promp__top-left h6 {
          font-weight: 500;
          font-size: 1rem;
          line-height: 1.5rem;
          color: #27272a;
        }
        .perPrompt {
          min-height: 288px;
          height: auto;
        }
        .griditem {
          padding-top: 16px;
          display: grid;
          gap: 8px;
          grid-template-columns: repeat(2, minmax(0, 1fr));
        }
        .grid {
          min-height: 328px;
        }
        .single {
          padding: 16px;
          background: #f4f4f5;
          border-radius: 0.5rem;
          cursor: pointer;
        }
        .single h5 {
          font-weight: 500;
          ont-size: 0.875rem;
          line-height: 1.25rem;
          padding-bottom: 4px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          color: #27272a;
        }
        .single p {
          margin: 0px;
          -webkit-line-clamp: 2;
          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          font-size: 0.875rem;
          line-height: 1.25rem;
          color: #707078;
          min-height: 40px;
        }

        @media (max-width: 1280px) {
          .perPrompt {
            min-height: 218px;
            height: auto;
          }
        }
        @media (max-width: 767px) {
          .griditem {
            padding-top: 16px;
            display: grid;
            gap: 8px;
            grid-template-columns: repeat(1, minmax(0, 1fr));
          }
          .promp__top {
            margin-top: 60px;
          }
        }
      `}</style>
    </div>
  )
}

export default PerPrompt
