import { initializePaddle } from "@paddle/paddle-js";
import { useEffect, useState } from "react";

export default function usePaddle() {
  const [paddle, setPaddle] = useState();
  useEffect(() => {
    initializePaddle({
      environment: process.env.REACT_APP_PADDLE_ENV,
      token: process.env.REACT_APP_PADDLE_CLIENT_TOKEN,
      // seller: Number(process.env.NEXT_PUBLIC_PADDLE_SELLER_ID),
    }).then((paddleInstance) => {
      if (paddleInstance) {
        setPaddle(paddleInstance);
      }
    }).catch((error) => { console.log("Error from paddle instance: " + error.message)});
  }, []);

  return paddle;
}
