import React, { useState, useContext } from "react";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  SimpleGrid,
  Spinner,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { FcGoogle } from "react-icons/fc";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine, RiKakaoTalkFill } from "react-icons/ri";
import ReCAPTCHA from "react-google-recaptcha";
import { HSeparator } from "components/separator/Separator";
import Footer from "components/footer/FooterAdmin.js";
import { userPublicRequest } from "config/axios.config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CustomNavLink from "langHoc/CustomNavLink";
import useCustomHistory from "langHoc/useCustomHistory";
import { useTranslation } from "react-i18next";
import Card from "components/card/Card";
import { AuthContext } from "contexts/AuthContext";
import { ConfigContext } from "contexts/ConfigContext";
import { googleProvider } from "../signIn/SocialAuth/authMethods";
import socialMediaAuth from "../signIn/SocialAuth/auth";

function SignUp() {
  const { dispatch, profileFetch } = useContext(AuthContext);
  const { t } = useTranslation();
  const [accessToken, setAccessToken] = useState("");
  const textColor = useColorModeValue("navy.700", "navy.700");
  const textColorSecondary = "gray.400";
  const textColorDetails = useColorModeValue("navy.700", "navy.700");
  const textColorBrand = useColorModeValue("brand.500", "brand.500");
  const brandStars = useColorModeValue("brand.500", "brand.500");
  const googleBg = useColorModeValue("gray.100", "gray.700");
  const googleText = useColorModeValue("navy.700", "white");
  const googleHover = useColorModeValue({ bg: "gray.200" }, { bg: "gray.600" });
  const googleActive = useColorModeValue(
    { bg: "gray.300" },
    { bg: "gray.500" }
  );
  const kgoogleHover = useColorModeValue({ bg: "#fada2f" }, { bg: "#fada2f" });
  const [show, setShow] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [userName, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [country, setCountry] = useState(localStorage.getItem("country"));
  const [loading, setLoading] = useState(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);
  const history = useCustomHistory();
  const handleClick = () => setShow(!show);
  let configs = useContext(ConfigContext);
  const handleShow = (type) => {
    if (type === "password") {
      setShow(!show);
    } else if (type === "repeatPassword") {
      setShowRepeatPassword(!showRepeatPassword);
    }
  };

  const lang = window.location.pathname.split("/")[1] || "en";

  const submitHandler = async (e) => {
    e.preventDefault();
    if (!repeatPassword || repeatPassword !== password) {
      const toastId = "signin-error-toast";
      const message = "Password and Confirm Password do not match.";
      return toast.error(message, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        toastId: toastId,
      });
    }
    setLoading(true);
    const payload = {
      firstName,
      lastName,
      email,
      password,
      lang,
      username: userName,
      recaptcha,
      phone,
      country,
    };
    const result = await userPublicRequest
      .post("/auth/register", payload)
      .then((response) => {
        let statusCode = response.status;
        if (statusCode == 205) {
          history.push(`/auth/verify?email=${email}`);
          return;
        }
        history.push(`/auth/verify?email=${email}`);
      })
      .catch((error) => {
        setLoading(false);
        let message = error.response.data.message;
        if (message === "Email already exists!") {
          message = t("EmailAlreadyExists");
        }
        const toastId = "signin-error-toast";
        if (!toast.isActive(toastId)) {
          toast.error(message, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            toastId: toastId,
          });
        }
      });
  };

  const kakaoSubmit = (accessToken) => {
    setLoading(true);
    userPublicRequest
      .post("/auth/kakao", { token: accessToken })
      .then((res) => {
        const login = {
          data: {
            ...res.data.data.user,
          },
          token: res.data.data.token,
          remember_me: false,
          expires_at: "2023-01-11T10:54:32.795Z",
        };
        dispatch({
          type: "LOGIN",
          payload: login,
        });
        profileFetch();
        history.push("/chat");
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      })
      .finally((e) => {
        setLoading(false);
      });
  };

  const kakaoLogin = () => {
    const { Kakao } = window;
    Kakao.Auth.login({
      scope: "profile_nickname, account_email",
      success: (response) => {
        setAccessToken(response.access_token);
        kakaoSubmit(response.access_token);
      },
      fail: (error) => {
        alert(error.error);
      },
    });
  };

  const handleGoogleLogin = async () => {
    try {
      googleProvider.setCustomParameters({
        login_hint: "",
      });
      const res = await socialMediaAuth(googleProvider.addScope("email"));
      userPublicRequest
        .post("/auth/google", { token: res?.credential?.idToken })
        .then((res) => {
          const login = {
            data: {
              ...res.data.data.user,
            },
            token: res.data.data.token,
            remember_me: false,
            expires_at: "2023-01-11T10:54:32.795Z",
          };
          dispatch({
            type: "LOGIN",
            payload: login,
          });
          profileFetch();
          history.push("/chat");
        })
        .catch((error) => {})
        .finally((e) => {
          setLoading(false);
        });
    } catch (err) {}
  };

  const [recaptcha, setCaptchaValue] = useState(null);
  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
  };

  return (
    <Flex
      direction="column"
      overflow="hidden"
      justifyContent="center"
      alignItems="center"
      mx={{ base: "0px", lg: "0px" }}
      bg={"white"}
      height={"100vh"}
      pb="10px"
      px="20px"
    >
      <Box
        display="flex"
        justifyContent={"center"}
        alignItems={"center"}
        flexDirection="column"
        width="100%"
      >
        <CustomNavLink to="/">
          <Box position="relative">
            <Image
              src={configs?.logo}
              alt="logo"
              width={{ base: "300px", lg: "300px" }}
              marginBottom="40px"
              marginX="auto"
            />
          </Box>
        </CustomNavLink>
        <Box
          height={"100%"}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          width={"100%"}
          maxW="600px"
        >
          <Card
            w={{ base: "100%", md: "max-content" }}
            h="max-content"
            mx="auto"
            maxW="100%"
            p="10px"
            pt="10px"
            bg={"transparent"}
            style={{ boxShadow: "unset" }}
            transform={{
              md: "scale(0.8)",
              lg: "scale(1)",
            }}
          >
            <Box>
              <Flex
                maxW={{ base: "100%", md: "max-content" }}
                w="100%"
                mx={{ base: "auto", lg: "0px" }}
                me="auto"
                justifyContent="center"
                px={{ base: "0px", md: "0px" }}
                flexDirection="column"
              >
                <Box me="auto" textAlign="center">
                  <Heading
                    color={textColor}
                    fontSize="20px"
                    mb="12px"
                    lineHeight={1}
                  >
                    {t("Signup")}
                  </Heading>
                </Box>
                <Flex
                  zIndex="2"
                  direction="column"
                  w={{ base: "100%", md: "400px" }}
                  maxW="100%"
                  background="transparent"
                  borderRadius="10px"
                  mx={{ base: "auto", lg: "unset" }}
                  me="auto"
                  mb={{ base: "0px", md: "auto" }}
                >
                  <Button
                    fontSize="sm"
                    me="0px"
                    mb="12px"
                    py="8px"
                    h="42px"
                    borderRadius="10px"
                    bg={googleBg}
                    color={googleText}
                    fontWeight="500"
                    _hover={googleHover}
                    _active={googleActive}
                    _focus={googleActive}
                    onClick={handleGoogleLogin}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      border: "none",
                    }}
                  >
                    <Icon as={FcGoogle} w="14px" h="14px" me="6px" />
                    {t("GoogleSignupBtn")}
                  </Button>

                  <Button
                    fontSize="sm"
                    me="0px"
                    mb="10px"
                    py="8px"
                    h="42px"
                    borderRadius="10px"
                    bg={"#FFEB00"}
                    color={googleText}
                    fontWeight="500"
                    _hover={kgoogleHover}
                    _active={kgoogleHover}
                    _focus={kgoogleHover}
                    onClick={kakaoLogin}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      border: "none",
                    }}
                  >
                    <Icon as={RiKakaoTalkFill} w="14px" h="14px" me="6px" />
                    {t("SignUpKakao")}
                  </Button>

                  <Flex align="center" mb="10px">
                    <HSeparator />
                    <Text
                      color={textColorSecondary}
                      mx="8px"
                      fontSize={"12px"}
                      height={"12px"}
                      lineHeight={1}
                    >
                      or
                    </Text>
                    <HSeparator />
                  </Flex>

                  <form onSubmit={submitHandler}>
                    <FormControl>
                      <SimpleGrid
                        columns={{ base: "1", md: "2" }}
                        gap={{ sm: "0px", md: "20px" }}
                      >
                        <Flex direction="column">
                          <FormLabel
                            display="flex"
                            ms="4px"
                            fontSize="sm"
                            fontWeight="500"
                            color={textColor}
                            mb="4px"
                            lineHeight={1}
                          >
                            {t("FirstName")}
                            <Text color={brandStars}>*</Text>
                          </FormLabel>
                          <Input
                            isRequired={true}
                            fontSize="sm"
                            ms={{ base: "0px", md: "4px" }}
                            placeholder="Enter your first name"
                            variant="auth"
                            mb="12px"
                            size="md"
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                            color={"black"}
                            style={{
                              border: "1px solid #ccc",
                              borderRadius: "10px",
                              padding: "8px",
                            }}
                          />
                        </Flex>
                        <Flex direction="column">
                          <FormLabel
                            display="flex"
                            ms="4px"
                            fontSize="sm"
                            fontWeight="500"
                            color={textColor}
                            mb="4px"
                            lineHeight={1}
                          >
                            {t("LastName")}
                            <Text color={brandStars}>*</Text>
                          </FormLabel>
                          <Input
                            isRequired={true}
                            variant="auth"
                            fontSize="sm"
                            placeholder="Enter your last name"
                            mb="12px"
                            size="md"
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                            color={"black"}
                            style={{
                              border: "1px solid #ccc",
                              borderRadius: "10px",
                              padding: "8px",
                            }}
                          />
                        </Flex>
                      </SimpleGrid>
                      <FormLabel
                        display="flex"
                        ms="4px"
                        fontSize="sm"
                        fontWeight="500"
                        color={textColor}
                        mb="4px"
                        lineHeight={1}
                      >
                        {t("Username")}
                        <Text color={brandStars}>*</Text>
                      </FormLabel>
                      <Input
                        isRequired={true}
                        variant="auth"
                        fontSize="sm"
                        type="text"
                        placeholder="Enter your username"
                        mb="12px"
                        size="md"
                        value={userName}
                        onChange={(e) => setUsername(e.target.value)}
                        color={"black"}
                        style={{
                          border: "1px solid #ccc",
                          borderRadius: "10px",
                          padding: "8px",
                        }}
                      />
                      <FormLabel
                        display="flex"
                        ms="4px"
                        fontSize="sm"
                        fontWeight="500"
                        color={textColor}
                        mb="4px"
                        lineHeight={1}
                      >
                        {t("EmailAddress")}
                        <Text color={brandStars}>*</Text>
                      </FormLabel>
                      <Input
                        isRequired={true}
                        variant="auth"
                        fontSize="sm"
                        type="email"
                        placeholder="Enter your email"
                        mb="12px"
                        size="md"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        color={"black"}
                        style={{
                          border: "1px solid #ccc",
                          borderRadius: "10px",
                          padding: "8px",
                        }}
                      />
                      <FormLabel
                        ms="4px"
                        fontSize="sm"
                        fontWeight="500"
                        isRequired={true}
                        color={textColor}
                        display="flex"
                        mb="4px"
                        lineHeight={1}
                      >
                        {t("Password")}
                        <Text color={brandStars}>*</Text>
                      </FormLabel>
                      <InputGroup
                        size="md"
                        style={{
                          border: "1px solid #ccc",
                          borderRadius: "10px",
                        }}
                        mb="12px"
                      >
                        <Input
                          isRequired={true}
                          variant="auth"
                          fontSize="sm"
                          ms={{ base: "0px", md: "4px" }}
                          placeholder="Enter your password"
                          // mb="12px"
                          size="md"
                          type={show ? "text" : "password"}
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          color={"black"}
                          style={{
                            border: "none",
                            padding: "8px",
                            height: "40px",
                            borderRadius: "10px",
                          }}
                        />
                        <InputRightElement display="flex" alignItems="center">
                          <Icon
                            color={textColorSecondary}
                            _hover={{ cursor: "pointer" }}
                            as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                            onClick={() => handleShow("password")}
                          />
                        </InputRightElement>
                      </InputGroup>
                      <InputGroup
                        size="md"
                        style={{
                          border: "1px solid #ccc",
                          borderRadius: "10px",
                        }}
                        mb="12px"
                      >
                        <Input
                          isRequired={true}
                          variant="auth"
                          fontSize="sm"
                          ms={{ base: "0px", md: "4px" }}
                          placeholder="Confirm your password"
                          // mb="12px"
                          size="md"
                          type={showRepeatPassword ? "text" : "password"}
                          value={repeatPassword}
                          onChange={(e) => setRepeatPassword(e.target.value)}
                          color={"black"}
                          style={{
                            border: "none",
                            padding: "8px",
                            height: "40px",
                            borderRadius: "10px",
                          }}
                        />
                        <InputRightElement display="flex" alignItems="center">
                          <Icon
                            color={textColorSecondary}
                            _hover={{ cursor: "pointer" }}
                            as={
                              showRepeatPassword
                                ? RiEyeCloseLine
                                : MdOutlineRemoveRedEye
                            }
                            onClick={() => handleShow("repeatPassword")}
                          />
                        </InputRightElement>
                      </InputGroup>

                      <FormControl
                        display="flex"
                        alignItems="center"
                        mb="12px"
                        mt="12px"
                        justifyContent="center"
                      >
                        <Box style={{ transform: "scale(0.75)" }}>
                          <ReCAPTCHA
                            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                            onChange={handleCaptchaChange}
                          />
                        </Box>
                      </FormControl>

                      <Button
                        fontSize="sm"
                        variant="brand"
                        fontWeight="500"
                        w="100%"
                        h="40"
                        mb="12px"
                        type="submit"
                        bg="#6c00ff"
                        _hover={{
                          bg: "#5b00d9",
                        }}
                        _active={{
                          bg: "#5b00d9",
                        }}
                        _focus={{
                          bg: "#5b00d9",
                        }}
                        style={{ borderRadius: "10px", height: "40px" }}
                      >
                        {loading ? (
                          <>
                            <Spinner />
                          </>
                        ) : (
                          t("CreateMyAccount")
                        )}
                      </Button>
                    </FormControl>
                  </form>

                  <Text
                    color={textColorDetails}
                    fontWeight="400"
                    fontSize="13px"
                    mb="12px"
                    textAlign={"center"}
                  >
                    {t("CreateMyAccountTerms")}
                  </Text>

                  <Flex
                    flexDirection="row"
                    justifyContent="center"
                    alignItems="start"
                    maxW="100%"
                    mt="0px"
                  >
                    <Text
                      color={textColorDetails}
                      fontWeight="400"
                      fontSize="sm"
                    >
                      <CustomNavLink to="/auth/sign-in">
                        <Text
                          color={textColorBrand}
                          as="span"
                          ms="5px"
                          fontWeight="500"
                        >
                          {t("Signin")}
                        </Text>
                      </CustomNavLink>
                    </Text>
                  </Flex>
                </Flex>
              </Flex>
            </Box>
          </Card>
        </Box>
      </Box>

      <Footer />
    </Flex>
  );
}

export default SignUp;
