import React, { useState, useRef, useEffect } from "react";
import MDEditor from "@uiw/react-md-editor";
import mermaid from "mermaid";

const mdMermaid = `...`;  // As in your code

mermaid.initialize({ startOnLoad: false });

const Code = ({ inline, children = [], className, ...props }) => {
  const [svgContent, setSvgContent] = useState("");

  const code = getCode(children);

  useEffect(() => {
    if (/^language-mermaid/.test(className?.toLocaleLowerCase() || "")) {
      try {
        mermaid.render(
          "tempId",
          code,
          (svg) => setSvgContent(svg),
          null
        );
      } catch (error) {
        setSvgContent(`Error rendering diagram: ${error.message}`);
      }
    }
  }, [code, className]);

  if (svgContent) {
    return <div dangerouslySetInnerHTML={{ __html: svgContent }} />;
  }
  return <code className={String(className)}>{children}</code>;
};

const getCode = (arr = []) =>
  arr
    .map((dt) => {
      if (typeof dt === "string") {
        return dt;
      }
      if (dt.props && dt.props.children) {
        return getCode(dt.props.children);
      }
      return false;
    })
    .filter(Boolean)
    .join("");

export default function App({val}) {
  const [value, setValue] = useState(val);

  // console.log(value, 'value value')
  return (
    <MDEditor
      // onChange={(newValue = "") => setValue(newValue)}
      textareaProps={{
        placeholder: "Please enter Markdown text"
      }}
      height={500}
      value={value}
      previewOptions={{
        components: {
          code: Code
        }
      }}
    />
  );
}
