import Footer from "components/footer/FooterAdmin.js";
import { SidebarContext } from "contexts/SidebarContext";
import Search from "layouts/HomeNew/Search/index";
import "mapbox-gl/dist/mapbox-gl.css";
import React, { useContext, useEffect, useState } from "react";
import { useLanguage } from "../../useLanguage";
import HeaderBanner from "./components/HeaderBanner";
import { useViewportHeight } from "./useViewportHeight";
import { useHistory, useLocation } from "react-router-dom";
import { Box, Flex } from "@chakra-ui/react";
import FirstModal from "./Search/FirstModal";
import { userPublicRequest } from "config/axios.config";
import axios from "axios";
import Storiees from "views/admin/main/profile/newsfeed/components/Stories";
import { AuthContext } from "contexts/AuthContext";
import { ConfigContext } from "contexts/ConfigContext";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function Dashboard(props) {
  const [isShow, setIsShow] = useState(true);
  const { dispatch, authState } = useContext(AuthContext);
  useLanguage();
  const history = useHistory();
  const query = useQuery();
  const configData = useContext(ConfigContext);
  const config = configData.config || {};
  const lang = useLocation();
  const isKor = lang?.pathname.includes("/ko");

  let [country, setCountry] = useState(localStorage.getItem("country"));

  // useEffect(() => {
  //   let defaultPage =
  //   configData?.config?.global?.DEFAULT_PAGE_SETTING?.value?.page ?? "search";
  // let prefix = ["/", "/en", "/ko", "/en/", "/ko/"]

  // if (
  //   defaultPage === "chat" &&
  //   prefix.indexOf(lang?.pathname) !== -1
  // ) {
  //   isKor ? history.push("/ko/chat") : history.push("/en/chat");
  // }
  // if (
  //   defaultPage === "landing" &&
  //   lang?.pathname === '/'
  // ) {
  //   console.log(defaultPage, 'defaultPage')
  //   isKor ? history.push("/ko/landing") : history.push("/en/landing");
  //   window.location.reload();
  // }
  // console.log(defaultPage, "DEFAULT_PAGE_SETTING");

  // }, [])
  useEffect(() => {

    // console.log(country, 'country',  history.location.pathname)

    if (configData?.config?.global?.LANGUAGE_ACTIVE?.value === "YES") {
      if (country) {
        if (country === "KR" || country === "Ko") {
          if (
            history.location.pathname !== "/ko" &&
            history.location.pathname !== "/en" &&
            history.location.pathname !== "/en/" &&
            history.location.pathname !== "/ko/"
          ) {
            history.push("/ko");
          }
        } else {
          if (
            history.location.pathname !== "/en" &&
            history.location.pathname !== "/ko" &&
            history.location.pathname !== "/ko/" &&
            history.location.pathname !== "/en/"
          ) {
            if (authState.isAuthenticated) {
              history.push("/en");
            } else {
              history.push("/en/landing");
            }
          }
        }
      } else {
        axios
          .get(
            "https://api.ipdata.co/?api-key=9e273957e96877a13fee745fee7b7ab3c35306e95e332d08c3a8dc36"
          )
          .then((response) => {
            let countryCode = response?.data?.country_code || "PHF";
            // console.log(countryCode, "countryCode");
            if (countryCode != country) {
              setCountry(countryCode);
              localStorage.setItem("country", countryCode);
            }
          })
          .catch((err) => {
            console.error("Problem fetching my IP", err);
            setCountry("PH");
            return null;
          });
      }
    } else {
      console.log(lang?.pathname, "lang?.pathname");
      if (isKor || !country || lang?.pathname === "/") {
        localStorage.setItem("country", "PH");
        setCountry("PH");
        history.push("/en");
      }
    }
  }, [country]);

  const fetchInfo = async () => {
    try {
      const result = await userPublicRequest.get(`/get-url/${query.get("q")}`);
      history.push(result?.data?.url?.fullUrl);
    } catch (err) { }
  };
  useEffect(() => {
    // // console.log(query.get('q'), 'query.get')
    if (query.get("q")) {
      fetchInfo();
    }
  }, [query.get("q")]);

  const vh = useViewportHeight();

  const fullHeightStyle = {
    height: `calc(${vh}px * 100)`,
  };

  console.log(113, country, "country");
  return (
    <>
      {country && (
        <Box bg="#fff"   >
          {/* <FirstModal /> */}
          <SidebarContext.Provider value={{}} >
            <Flex flexDirection={{
              base: "column",
            }}
              justifyContent="space-between"
              minH={"100vh"}

              pb='15px'
            >
              <Box
                float="right"
                // style={fullHeightStyle}
                overflowX={"hidden"}
                overflowY={{ base: "auto", xl: "auto", "2xl": "auto" }}
                position="relative"
                w={{ base: "100%" }}
                maxWidth={{ base: "100%" }}
                transition="all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)"
                transitionDuration=".2s, .2s, .35s"
                transitionProperty="top, bottom, width"
                transitionTimingFunction="linear, linear, ease"
                bg="#fff"


              >
                <HeaderBanner isShow={isShow} setIsShow={setIsShow} />
                <Box
                  mx="auto"
                  px={{ base: "20px", md: "30px" }}
                  h={{
                    base: isShow ? "auto" : "auto",
                  }}
                  minH={{
                    xl: "calc(100vh - 120px)",
                    base: "calc(100vh - 160px)",
                  }}
                  pb={isShow ? "5px" : "5px"}
                  id="HomeHeight"
                  className={`${config?.global?.POPULARNOW?.value === "YES"
                    ? ""
                    : "make-center-searchbar"
                    } ${config?.global?.SHORTCUT?.value === "YES"
                      ? "make-center-searchbar-shortcut"
                      : ""
                    } `}

                >
                  <Search />
                </Box>



              </Box>
              <Footer />
            </Flex>

          </SidebarContext.Provider>
        </Box>
      )}
    </>
  );
}
