// Chakra imports
import {
  Box,
  Button,
  Flex,
  FormControl,
  Heading,
  PinInput,
  PinInputField,
  Spinner,
  Text,
  useBreakpointValue,
  useColorModeValue,
} from '@chakra-ui/react'
import Card from 'components/card/Card'
import { userPublicRequest } from 'config/axios.config'
import { AuthContext } from 'contexts/AuthContext'
import useCustomHistory from 'langHoc/useCustomHistory'
import { useViewportHeight } from 'layouts/HomeNew/useViewportHeight2'
import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

function useQuery() {
  const { search } = useLocation()

  return React.useMemo(() => new URLSearchParams(search), [search])
}

const lang = window.location.pathname.split('/')[1] || 'en'
function VerificationCentered({ email }) {
  // Chakra color mode
  const textColor = useColorModeValue('secondaryGray.900', 'white')
  const textColorSecondary = 'gray.400'
  const borderColor = useColorModeValue('secondaryGray.400', 'whiteAlpha.100')
  const textColorDetails = useColorModeValue('navy.700', 'secondaryGray.600')
  const textColorBrand = useColorModeValue('brand.500', 'white')
  const [otp, setOtp] = useState()
  const [loading, setLoading] = useState(false)
  const [resendLoading, setResendLoading] = useState(false)
  const history = useCustomHistory()
  let query = useQuery()
  const { dispatch, profileFetch } = useContext(AuthContext)

  const { t } = useTranslation()
  const submitHandler = async e => {
    e.preventDefault()

    try {
      setLoading(true)
      const payload = {
        otp,
        email,
        lang,
      }
      const res = await userPublicRequest.post('/auth/verify', payload)

      // // console.log()
      toast.success(t('AccountVerified'))
      const login = {
        data: {
          ...res.data.data.user,
        },
        token: res.data.data.token,
        remember_me: false,
        expires_at: '2023-01-11T10:54:32.795Z', //TODO: update static value
      }

      dispatch({
        type: 'LOGIN',
        payload: login,
      })
      profileFetch()
      history.push('/profile')
    } catch (error) {
      // // console.log(err);
      setLoading(false)

      let message = error.response.data.message
      if (message === 'Invalid otp provided!') {
        message = t('InvalidOtpProvided')
      }
      const toastId = "signin-error-toast";
        if (!toast.isActive(toastId)) {
          toast.error(message, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            toastId: toastId,
          });
        }
    }
  }
  const handleResendCode = async () => {
    setResendLoading(true)
    // // console.log()
    const payload = {
      email: query.get('email'),
      lang,
    }
    await userPublicRequest
      .post('/auth/resend-token', payload)
      .then(response => {
        setResendLoading(false)
      })
    toast.success(t('OtpSent'), {
      onClose: () => {
        // history.push(`/auth/verify?email=${payload.email}`);
      },
    })
  }
  const vh = useViewportHeight()
  const breakpoint = useBreakpointValue({ base: 'base', lg: 'lg' })

  const minH = {
    base: `calc(${vh}px - 120px)`,
    lg: `calc(${vh}px - 50px)`,
  }
  return (
    <Flex
      direction="column"
      alignSelf="center"
      justifySelf="center"
      overflow="hidden"
      mx={{ base: '10px', lg: '0px' }}
      pb={{ base: '0', lg: '0px' }}
    >
      <Card
        w={{ base: '100%', md: 'max-content' }}
        h="max-content"
        mx="auto"
        maxW="100%"
        p={{ base: '0px', md: '0px' }}
        background="transparent"
      >

        <Box>
          <Flex
            maxW={{ base: '100%', md: 'max-content' }}
            w="100%"
            mx={{ base: 'auto', lg: '0px' }}
            me="auto"
            justifyContent="center"
            px={{ base: '0px', md: '0px' }}
            flexDirection="column"
          >
            <Box mb="20px">
              <Heading
                color={'#223645'}
                fontSize="32px"
                mb="20px"
                fontWeight={700}
                textAlign={'center'}
                lineHeight={1}
              >
                {t('AccountVerification')}
              </Heading>
              <Text
                color={'#223645'}
                fontSize="md"
                maxW={{ base: '95%', md: '100%' }}
                mx={{ base: 'auto', lg: 'unset' }}
                textAlign={'center'}
                lineHeight={1}
                mb={'0'}
              >
                {t('UnlockContent')}
              </Text>
            </Box>
            <Flex
              zIndex="2"
              direction="column"
              w={{ base: '100%', md: '425px' }}
              maxW="100%"
              background="transparent"
              borderRadius="15px"
              mx={{ base: 'auto', lg: 'unset' }}
              me="auto"
              mb={{ base: '0', md: 'auto' }}
            >
              <form onSubmit={submitHandler}>
                <FormControl>
                  <Flex justify="center">
                    <PinInput
                      mx="auto"
                      size="xs"
                      otp
                      value={otp}
                      onChange={e => setOtp(e)}
                    >
                      <PinInputField
                        fontSize="28px"
                        color={textColor}
                        borderRadius="16px"
                        borderColor={borderColor}
                        h={'60px'}
                        w={'60px'}
                        me="10px"
                        mb="20px"
                        background={'#fff'}
                      />
                      <PinInputField
                        fontSize="28px"
                        color={textColor}
                        borderRadius="16px"
                        borderColor={borderColor}
                        h={'60px'}
                        w={'60px'}
                        me="10px"
                        background={'#fff'}
                      />
                      <PinInputField
                        fontSize="28px"
                        color={textColor}
                        borderRadius="16px"
                        borderColor={borderColor}
                        h={'60px'}
                        w={'60px'}
                        me="10px"
                        background={'#fff'}
                      />
                      <PinInputField
                        fontSize="28px"
                        color={textColor}
                        borderRadius="16px"
                        borderColor={borderColor}
                        h={'60px'}
                        w={'60px'}
                        me="10px"
                        background={'#fff'}
                      />
                      <PinInputField
                        fontSize="28px"
                        color={textColor}
                        borderRadius="16px"
                        borderColor={borderColor}
                        h={'60px'}
                        w={'60px'}
                        me="10px"
                        background={'#fff'}
                      />
                      <PinInputField
                        fontSize="28px"
                        color={textColor}
                        borderRadius="16px"
                        borderColor={borderColor}
                        h={'60px'}
                        w={'60px'}
                        background={'#fff'}
                      />
                    </PinInput>
                  </Flex>

                  {/* <Button
                    fontSize="14px"
                    variant="brand"
                    borderRadius="16px"
                    fontWeight="500"
                    w="100%"
                    h="50"
                    mb="20px"
                    // mt="12px"
                    type="submit"
                    disabled={loading}
                    bg="rgb(0,121,107)"
                    _hover={{
                      bg: 'rgb(0,121,107)',
                    }}
                    _active={{
                      bg: 'rgb(0,121,107)',
                    }}
                    _focus={{
                      bg: 'rgb(0,121,107)',
                    }}
                  >
                    {loading ? (
                      <>
                        <Spinner />
                      </>
                    ) : (
                      t('Unlock')
                    )}
                  </Button> */}

                  <button
                    type="submit"
                    className="button-tb button-tb--cta button-tb--nav js--open-popup w-button"
                  >
                    {/* {loading ? (
                      <>
                        <Spinner />
                      </>
                    ) : (
                      'Verify'
                    )} */}
                    Verify
                    <span className="button-text--hide-mobile"></span>
                  </button>
                </FormControl>
              </form>

              <Flex
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                maxW="100%"
                mt="0px"
              >
                <Text
                  color={textColorDetails}
                  fontWeight="400"
                  fontSize="14px"
                  mx={{ base: 'auto', lg: 'unset' }}
                  textAlign={{ base: 'center', lg: 'left' }}
                  mb="0"
                >
                  {t('DidntReceiveOTP')}
                  <Text
                    color={textColorBrand}
                    cursor={'pointer'}
                    as="span"
                    ms="5px"
                    fontWeight="500"
                    onClick={handleResendCode}
                    disabled={resendLoading}
                  >
                    {resendLoading ? 'Resending ...' : t('newOtpCode')}
                  </Text>
                </Text>
              </Flex>
            </Flex>
          </Flex>
        </Box>
      </Card>
      <style jsx>{`
        .button-tb.button-tb--cta {
          background-image: none;
          padding-left: 24px;
          margin: 0 auto;
          margin-bottom: 20px;
        }
        .button-tb.button-tb--cta:hover {
          padding-right: 24px;
        }
      `}</style>
    </Flex>
  )
}

export default VerificationCentered
