/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   ____  ____   ___  
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| |  _ \|  _ \ / _ \ 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || |  | |_) | |_) | | | |
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |  |  __/|  _ <| |_| |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___| |_|   |_| \_\\___/ 
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI Dashboard PRO - v1.0.0
=========================================================

* Product Page: https://www.horizon-ui.com/pro/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import { Box, Flex, SimpleGrid } from "@chakra-ui/react";
// Assets
import banner from "assets/img/auth/banner.png";
import React from "react";
import Info from "views/admin/profile/settings/components/Info";
import Profile from "views/admin/profile/settings/components/Profile";
import { userSuperPrivateRequest } from "config/axios.config";
import { ConfigContext } from "contexts/ConfigContext";
import { useContext, useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import { userPrivateRequest } from "config/axios.config";
import useCustomHistory from "langHoc/useCustomHistory";
import { AuthContext } from "contexts/AuthContext";
// Custom components

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}
export default function Settings({ ...rest }) {
  const authData = useContext(AuthContext);
  const configData = useContext(ConfigContext);
  const config = configData.config || {};
  const [loadingProfile, setLoadingProfile] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [data, setData] = useState({});
  const [selectedImage, setSelectedImage] = useState({
    file: null,
    previewUrl: "",
  });

  const info = authData?.authState?.user??{};

  const query = useQuery();
  const customhistory = useCustomHistory();
  const history = useCustomHistory();

  const applyExucation = async () => {
    try {
      const result = await userPrivateRequest
        .get(`/execute-agreement?token=${query.get("token")}`)
        .then((response) => {
          authData.profileFetch();
        });;
    } catch (err) {}

    customhistory.replace("/profile");
  };

  useEffect(() => {
    if (query.get("token")) {
      applyExucation();
    }
  }, [query.get("token")]);

  // useEffect(() => {
  //   userSuperPrivateRequest
  //     .get("/profile")
  //     .then((response) => {
  //       setInfo(response.data.user);
  //       setLoadingProfile(false);
  //     })
  //     .catch((error) => {
  //       if (
  //         error?.response?.status === 401 ||
  //         error?.response?.status === "401"
  //       ) {
  //         //  logout
  //         localStorage.clear();
  //         window.location = "/auth/sigh-in";
  //       }
  //     });
  // }, []);

  const handleSubmit = async () => {
    setSubmitLoading(true);
    let formData = data;
    // delete formData.email;

    if (selectedImage.file) {
      try {
        const formDatas = new FormData();
        formDatas.append("attachment", selectedImage.file);
        const result = await userSuperPrivateRequest.post(
          "/uploadV2",
          formDatas
        );
        formData.photo = result?.data?.data?.Location;
      } catch (err) {
        setSubmitLoading(false);
        const toastId = "signin-error-toast";
        if (!toast.isActive(toastId)) {
          toast.error(
            err?.response?.data?.message
              ? err?.response?.data?.message
              : "Failed to Upload Image",
            {
              position: "bottom-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              toastId: toastId,
            }
          );
        }
        return;
      }
    }

    userSuperPrivateRequest
      .post("/profile", {
        ...formData,
      })
      .then((response) => {
        setSubmitLoading(false);
        authData.profileFetch();
        console.log(
          config?.global?.PLAY_GROUND?.value,
          "config?.global?.PLAY_GROUND?.value"
        );
        if (config?.global?.PLAY_GROUND?.value === "YES") {
          history.push("/chat");
        } else {
          history.push("/");
        }
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === "401"
        ) {
          //  logout
          localStorage.clear();
          window.location = "/auth/sigh-in";
        }
        setSubmitLoading(false);
        const toastId = "signin-error-toast";
        if (!toast.isActive(toastId)) {
          toast.error(
            error?.response?.data?.message
              ? error?.response?.data?.message
              : "Failed to Update",
            {
              position: "bottom-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              toastId: toastId,
            }
          );
        }
      });
  };

  return (
    <>
      <Box
        pt={{ base: "20px", md: "20px", xl: "20px" }}
        {...rest}
        display="flex"
      >
        <SimpleGrid
          mb="20px"
          // columns={{ sm: 1 }}
          spacing={{ base: "20px", xl: "20px" }}
          width={{ base: "100%", xl: "50%" }}
          marginLeft={"auto"}
          marginRight="auto"
        >
          {/* Column Left */}
          <Flex direction="column">
            <Profile
              name={`${info?.username ?? ""}`}
              info={info}
              banner={banner}
              selectedImage={selectedImage}
              setSelectedImage={setSelectedImage}
            />
            <Info
              info={info}
              data={data}
              setData={setData}
              handleSubmit={handleSubmit}
              submitLoading={submitLoading}
              setSubmitLoading={setSubmitLoading}
            />
          </Flex>
          {/* Column Right */}
          <Flex direction="column">
            {/* <Socials />
              <Password /> */}
          </Flex>

          <Flex direction="column"></Flex>
        </SimpleGrid>
      </Box>
    </>
  );
}
