import React, { useContext, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
// chakra imports
import {
  Box,
  Flex,
  HStack,
  IconButton,
  Input,
  Spinner,
  Text,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
// Assets
import { useState } from "react";
import { AuthContext } from "contexts/AuthContext";
import { useTranslation } from "react-i18next";
import { SessionListContext } from "contexts/SessionListContext";
import { ConfigContext } from "contexts/ConfigContext";
import { userPublicRequest } from "config/axios.config";
import { FiCheck, FiEdit3, FiShare2, FiTrash2, FiX } from "react-icons/fi";
import ShareModal from "components/new/ShareModal";
import InfiniteScroll from "react-infinite-scroll-component";
import { useHistory } from "react-router-dom";

function truncateString(str, num) {
  if (str.length <= num) {
    return str;
  } else {
    return str.slice(0, num) + "...";
  }
}
function parseContent(content) {
  try {
    // Try to parse the content as JSON
    const parsed = JSON.parse(content);

    return parsed?.message;
  } catch (e) {
    // If parsing fails, it's likely a normal string
    // console.log(content, 'Content is a normal string');
    return content;
  }
}
export function SidebarMemories(props) {
  //   Chakra color mode
  let location = useLocation();
  let activeColor = useColorModeValue("gray.500", "white");
  let inactiveColor = useColorModeValue(
    "secondaryGray.600",
    "secondaryGray.600"
  );
  const history = useHistory();
  let activeIcon = useColorModeValue("brand.900", "white");
  const [deleteItemId, setDeleteItemId] = useState(null);
  const [editId, setEditId] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [hoveredId, setHoveredId] = useState(null);
  let configData = useContext(ConfigContext);
  const COLORS = configData?.config?.global?.COLORS?.value;

  const primaryColor = useColorModeValue(
    COLORS.primaryColor,
    COLORS.primaryDarkColor
  );
  const { chatList, setChatListe } = useContext(AuthContext);
  const { i18n } = useTranslation();
  const sessionListProvider = useContext(SessionListContext);
  const {
    selectedPrompt,
    setSelectedPrompt,
    setCurrentSessionInfo,
    setCurrentSessionId,
    currentSessionId,
  } = sessionListProvider;
  let sessionRecords = sessionListProvider?.records ?? [];

  const [agiSettins, setAgiSettings] = useState({});
  const fetchAgiIcon = async () => {
    try {
      const result = await userPublicRequest.get("/admin/agi-settings");
      setAgiSettings({ ...result?.data?.data[0] });
    } catch (err) {
      // console.log(err, 'agiIocnError')
    }
  };
  const [memoryId, setMemoryId] = useState("");
  const [memoryType, setMemoryType] = useState("");
  useEffect(() => {
    fetchAgiIcon();
  }, []);

  const {
    isOpen: isShareModal,
    onOpen: onShareModalOpen,
    onClose: onShareModalClose,
  } = useDisclosure();

  const handleEdit = (id) => {
    let info = sessionRecords[id];
    info = { ...info, edit: true };
    sessionListProvider.editOnChange(id, info);
    setEditId(id);
  };

  const handleTitleChange = (e, id) => {
    let title = e.target.value;
    let info = sessionRecords[id];
    info = { ...info, title };
    sessionListProvider.editOnChange(id, info);
  };

  const saveChangedTitle = (id) => {
    sessionListProvider.editSessionTitleSubmit(id);
    setEditId(null);
  };

  const handleDelete = (id) => {
    setDeleteItemId(id);
  };

  const handleConfirmDelete = (id) => {
    setIsDeleting(true);
    sessionListProvider.deleteSession(id);
    setIsDeleting(false);
    setDeleteItemId(null);
    if (currentSessionId === id) {
      setCurrentSessionId(null);
    }
  };

  const [page, setPage] = useState(1);

  const fetchData = (e) => {
    let currentPage = page + 1;
    sessionListProvider.fetchSession(currentPage);
    setPage(currentPage);
  };

  const settings = configData?.config?.global?.MEMORY_LIST_SETTING?.value;
  let currentLanguage = window.location.pathname.split("/")[1] || "en";
  // this function creates the links and collapses that appear in the sidebar (left menu)
  const createLinks = (sessionRecords) => {
    return (
      Object.keys(sessionRecords).length > 0 &&
      Object.keys(sessionRecords).map((_id, i) => {
        let mmoryType = sessionRecords[_id]?.source || "chat";
        const truncateLength = hoveredId === _id ? 13 : 25;
        return (
          <NavLink
            to={"#"}
            key={i}
            onMouseEnter={() => setHoveredId(_id)}
            onMouseLeave={() => setHoveredId(null)}
            onClick={(e) => {
              e.preventDefault();
              // if (mmoryType === "chat") {
              //   setCurrentSessionId(_id);
              //   setChatListe(false);
              // } else {
              //   window.open(
              //     `${process.env.REACT_APP_AGI_APP}/${i18n.language}/?memoryId=${sessionRecords[_id]?.agiId}`
              //   );
              // }
            }}
          >
            <Flex
              align="center"
              justifyContent="center"
              w="100%"
              px="30px"
              mb="0px"
              h="39px"
              fontSize={"14px"}
            >
              <HStack
                my="6px"
                spacing={_id == currentSessionId ? "22px" : "26px"}
              >
                <Flex w="100%" alignItems="center" justifyContent="center">
                  <Box
                    color={_id == currentSessionId ? activeIcon : inactiveColor}
                    me="6px"
                  >
                    {/* {route.icon} */}
                    <Box width={"20px"} height="auto" ps={0} pe="0">
                      <img
                        src={
                          mmoryType == "agi"
                            ? agiSettins?.icon_url
                            : configData?.config?.global?.CHAT_MEMORY_ICON
                                ?.value
                        }
                        alt=""
                      />
                    </Box>
                  </Box>
                  <Text
                    me="auto"
                    color={
                      _id === currentSessionId
                        ? activeColor
                        : "secondaryGray.600"
                    }
                    fontWeight="500"
                    _hover={{ color: "#834af4" }}
                    onClick={(e) => {
                      e.preventDefault();

                      if (editId || deleteItemId) {
                        return;
                      }
                      if (mmoryType === "chat") {
                        setCurrentSessionId(_id);
                        // setChatListe(false);
                        history.push("/en/chat");
                      } else {
                        window.open(
                          `${process.env.REACT_APP_AGI_APP}/${i18n.language}/?memoryId=${sessionRecords[_id]?.agiId}`
                        );
                      }
                    }}
                  >
                    {editId === _id ? (
                      <Input
                        autoFocus
                        value={parseContent(sessionRecords[_id].title)}
                        onChange={(e) => handleTitleChange(e, _id)}
                        p="1"
                        border="0"
                        focusBorderColor="none"
                        className="memory-input"
                        fontSize="14px!important"
                        lineHeight={"20px!important"}
                        fontWeight={500}
                        fontFamily={"'Dm Sans', sans-serif"}
                        id="__eta"
                        backgroundColor="#f2f2f2"
                        color={"#834af4"}
                      />
                    ) : (
                      <>
                        {truncateString(
                          parseContent(sessionRecords[_id].title),
                          truncateLength
                        )}{" "}
                      </>
                    )}
                  </Text>
                </Flex>
              </HStack>

              {/* Memory Icons and Actions */}
              <Flex
                // Other styles remain unchanged
                marginLeft="auto"
                opacity={0.7} // Set initial opacity to be lower
                _hover={{
                  opacity: 1, // Full opacity on hover
                }}
                //transition="opacity 1s ease-in-out" // Smooth transition for opacity over 1 second
                gap="10px"
              >
                {deleteItemId === _id ? (
                  <>
                    <IconButton
                      bg="transparent"
                      icon={<FiX />}
                      aria-label="Cancel"
                      onClick={() => setDeleteItemId(null)}
                      height="auto"
                      _hover={{ bg: "transparent", color: "#834af4" }}
                      _active={{ bg: "transparent", color: "#621af1" }}
                      style={{
                        minWidth: "16px",
                      }}
                      color="secondaryGray.600"
                    />
                    <IconButton
                      bg="transparent"
                      icon={<FiCheck />}
                      aria-label="Confirm"
                      onClick={() => handleConfirmDelete(_id)}
                      height="auto"
                      isLoading={isDeleting}
                      loadingText="Deleting"
                      _hover={{ bg: "transparent", color: "#834af4" }}
                      _active={{ bg: "transparent", color: "#621af1" }}
                      style={{
                        minWidth: "16px",
                      }}
                      color="secondaryGray.600"
                    />
                  </>
                ) : editId === _id ? (
                  <>
                    <IconButton
                      bg="transparent"
                      icon={<FiX />}
                      aria-label="Cancel"
                      onClick={() => {
                        setEditId(null);
                        saveChangedTitle(_id);
                      }}
                      height="auto"
                      _hover={{ bg: "transparent", color: "#834af4" }}
                      _active={{ bg: "transparent", color: "#621af1" }}
                      style={{
                        minWidth: "16px",
                      }}
                      color="secondaryGray.600"
                    />
                    <IconButton
                      bg="transparent"
                      icon={<FiCheck />}
                      aria-label="Confirm"
                      onClick={() => saveChangedTitle(_id)}
                      height="auto"
                      isLoading={isDeleting}
                      loadingText="Deleting"
                      _hover={{ bg: "transparent", color: "#834af4" }}
                      _active={{ bg: "transparent", color: "#621af1" }}
                      style={{
                        minWidth: "16px",
                      }}
                      color="secondaryGray.600"
                    />
                  </>
                ) : (
                  hoveredId === _id && (
                    <>
                      <IconButton
                        bg="transparent"
                        icon={<FiEdit3 />}
                        aria-label="Edit"
                        onClick={() => handleEdit(_id)}
                        // mr={2}
                        height="auto"
                        _hover={{
                          bg: "transparent",
                          //transform: "scale(1.1)", // Example transformation on hover
                          //transition: "transform 0.2s ease-in-out", // Smooth transition for transform
                          color: "#834af4",
                        }}
                        _active={{ bg: "transparent", color: "#834af4" }}
                        style={{
                          minWidth: "16px",
                        }}
                        color="secondaryGray.600"
                      />
                      <IconButton
                        bg="transparent"
                        icon={<FiShare2 />}
                        aria-label="Confirm"
                        onClick={() => {
                          setMemoryId(_id);
                          onShareModalOpen();
                          setMemoryType(mmoryType);
                        }}
                        height="auto"
                        isLoading={isDeleting}
                        loadingText=""
                        _hover={{ bg: "transparent", color: "#834af4" }}
                        _active={{ bg: "transparent", color: "#621af1" }}
                        style={{
                          minWidth: "16px",
                        }}
                        color="secondaryGray.600"
                      />
                      <IconButton
                        bg="transparent"
                        icon={<FiTrash2 />}
                        aria-label="Delete"
                        onClick={() => handleDelete(_id)}
                        // mr={2}
                        height="auto"
                        _hover={{ bg: "transparent", color: "#834af4" }}
                        _active={{ bg: "transparent", color: "#621af1" }}
                        style={{
                          minWidth: "16px",
                        }}
                        color="secondaryGray.600"
                      />
                    </>
                  )
                )}
              </Flex>
            </Flex>
          </NavLink>
        );
      })
    );
  };
  return (
    <Box>
      {isShareModal && (
        <ShareModal
          lan={currentLanguage}
          sessionId={memoryId}
          onShareModalClose={onShareModalClose}
          memoryType={memoryType === "chat" ? "chat" : "agi"}
        />
      )}
      <div
        id="scrollableDiv"
        style={{
          height: "75vh",
          // height: '80%',
          overflow: "auto",
        }}
      >
        <InfiniteScroll
          dataLength={Object.keys(sessionRecords).length} //This is important field to render the next data
          next={fetchData}
          hasMore={sessionListProvider?.hasMoreData}
          loader={
            Object.keys(sessionRecords).length > 0 && (
              <>
                <Box style={{ textAlign: "center" }}>
                  <Spinner color={primaryColor} />
                </Box>
              </>
            )
          }
          scrollableTarget="scrollableDiv"
          endMessage={
            <>
              {Object.keys(sessionRecords).length > 0 &&
                settings?.endMessage && (
                  <Text
                    color="secondaryGray.600"
                    fontWeight="500"
                    fontSize={"14px"}
                    style={{ textAlign: "center" }}
                  >
                    <b>{settings?.endMessage}</b>
                  </Text>
                )}

              {Object.keys(sessionRecords).length <= 0 &&
                settings?.noRecordMessage && (
                  <Text
                    color="secondaryGray.600"
                    fontWeight="500"
                    fontSize={"14px"}
                    style={{ textAlign: "center" }}
                  >
                    <b>{settings?.noRecordMessage}</b>
                  </Text>
                )}
            </>
          }
        >
          {createLinks(sessionRecords)}
        </InfiniteScroll>
      </div>
    </Box>
  );
}

export default SidebarMemories;
