import {
  Flex,
  Button,
  Text,
  Icon,
  useColorModeValue,
  Grid,
} from "@chakra-ui/react";
// Assets
import avatar1 from "assets/img/avatars/avatar1.png";
import avatar10 from "assets/img/avatars/avatar10.png";
import avatar2 from "assets/img/avatars/avatar2.png";
import avatar3 from "assets/img/avatars/avatar3.png";
import avatar4 from "assets/img/avatars/avatar4.png";
import avatar5 from "assets/img/avatars/avatar5.png";
import avatar6 from "assets/img/avatars/avatar6.png";
import avatar7 from "assets/img/avatars/avatar7.png";
import avatar8 from "assets/img/avatars/avatar8.png";
import avatar9 from "assets/img/avatars/avatar9.png";
import { MdAdd } from "react-icons/md";
// Custom components
import SeeStory from "components/actions/SeeStory";
import Card from "components/card/Card.js";
import React, { useContext, useEffect, useState } from "react";
import {
  storiesRenderThumb,
  storiesRenderTrack,
  storiesRenderView,
} from "components/scrollbar/Scrollbar";
import { Scrollbars } from "react-custom-scrollbars-2";
import { ConfigContext } from "contexts/ConfigContext";
import { useTranslation } from "react-i18next";
import useCustomHistory from "langHoc/useCustomHistory";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { SessionListContext } from "contexts/SessionListContext";
import PromptModal from "layouts/HomeNew/Search/PromptModal";
import { toast } from "react-toastify";
import { AuthContext } from "contexts/AuthContext";
export default function Storiees(props) {
  const { setMicModal, setAuto, ...rest } = props;
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const bgAdd = useColorModeValue("white", "navy.800");
  const configData = useContext(ConfigContext);
  const [modal, setModal] = useState("");
  const [modalData, setModalData] = useState({});
  const loading = configData?.loading;
  const prompts = configData?.prompts;
  const { i18n } = useTranslation();
  const history = useCustomHistory();
  const sessionListProvider = useContext(SessionListContext);
  const config = configData?.config;
  const lang = useLocation();
  const isKor = lang?.pathname.includes("/ko");
  const { setPagi } = sessionListProvider;
  const authData = useContext(AuthContext);

  const isPagiEnabled = (data) => {
    if (data?.slides && data?.slides?.length > 0) {
      setModal(true);
      setModalData(data);

      return;
    }
    if (data.redirectionUrl) {
      if (data?.redirectionUrl.includes("." + window.location?.hostname)) {
        // Subdomain apps
        window.open(`${data?.redirectionUrl}/${i18n.language}`);
      } else if (!data?.redirectionUrl.includes(window.location?.hostname)) {
        // Third party redirection
        window.open(`${data?.redirectionUrl}`);
      } else {
        // In app redirection
        window.location.href = data?.redirectionUrl;
      }
    } else {
      setPagi(data);
      setMicModal(true);
      setAuto(true);
    }
  };

  const modalCloseHandler = (data) => {
    if (data.redirectionUrl) {
      window.open(`${data?.redirectionUrl}/${i18n.language}`);
    } else {
      setPagi(data);
      setMicModal(true);
      setAuto(true);
    }
    setModal(false);
    setModalData({});
  };

  const clickHandler = (data) => {
    const toastId = "search-error-toast";
    console.log(config?.global?.SEARCH_PAGE?.value);
    if (
      config?.global?.WEB3_AUTHENTICATION?.value === "YES" &&
      !authData?.authState?.isAuthenticated
    ) {
      if (!toast.isActive(toastId)) {
        // Check if the toast is already active
        toast(config?.global?.SEARCH_PAGE?.value?.nonSigninMessage, {
          toastId: toastId,
        });
      }

      return;
    }
    if (
      config?.global?.WEB3_AUTHENTICATION?.value === "YES" &&
      authData?.authState?.membership !== "pro"
    ) {
      authData.setDepositModal(true);
      return;
    }

    if (data.promptType === "apps") {
      isPagiEnabled(data);
    } else {
      window.open(`/chat?selectedShortcutPrompt=${data?._id}`);
    }
  };

  return (
    <>
      {!loading && (
        <Card
          flexDirection="row"
          id="SHORTCUT"
          justifyContent={{ lg: "space-between" }}
          pb="0px"
          {...rest}
          w="auto"
          boxShadow="none"
          pl={{ base: "0px", md: "5px" }}
          pr={{ base: "0px", md: "5px" }}
          pt="0px"
          bg="white"
        >
          <Scrollbars
            autoHide
            renderTrackHorizontal={storiesRenderTrack}
            renderThumbHorizontal={storiesRenderThumb}
            renderView={storiesRenderView}
            style={{ overflowY: "hidden" }}
            className="scroll-apps"
          >
            <Flex
              justifyContent="center"
              overflow="hidden"
              w="100%"
              flexWrap={"wrap"}
              maxW="1000px"
              gap={10}
            >
              {prompts?.map((pts) => (
                <Flex key={pts._id} justify="center" align="center" w="150px">
                  <SeeStory
                    onClick={() => clickHandler(pts)}
                    my="auto"
                    name={isKor ? pts?.shortcutNameKr : pts?.shortcutName}
                    avatar={pts?.image}
                  />

                  {modal && pts._id === modalData?._id && (
                    <PromptModal
                      modalData={modalData}
                      modalCloseHandler={modalCloseHandler}
                      closeModal={() => setModal(false)}
                    />
                  )}
                </Flex>
              ))}
              {/* </Grid> */}
            </Flex>
          </Scrollbars>
        </Card>
      )}
    </>
  );
}
