import { Button, Tooltip } from "@chakra-ui/react";
import { useState } from "react";

const HoverIconButton = ({ icon: Icon, label, onClick, iconColor, hoverColor, isSvg = false }) => {
    const [isHovered, setIsHovered] = useState(false);

    return (
        <Tooltip label={label} fontSize="md" borderRadius="8px">
            <Button
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                padding="0px"
                display="flex"
                justifyContent={'center'}
                alignItems={'center'}
                bg="transparent"
                // width="40px"
                // height="40px"
                _hover={{
                    bg: 'transparent',
                }}
                _active={{
                    bg: "transparent",
                }}
                onClick={onClick}
            >
                {isSvg ?
                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill={isHovered ? hoverColor : iconColor} viewBox="0 0 256 256"><path d="M251.76,88.94l-120-64a8,8,0,0,0-7.52,0l-120,64a8,8,0,0,0,0,14.12L32,117.87v48.42a15.91,15.91,0,0,0,4.06,10.65C49.16,191.53,78.51,216,128,216a130,130,0,0,0,48-8.76V240a8,8,0,0,0,16,0V199.51a115.63,115.63,0,0,0,27.94-22.57A15.91,15.91,0,0,0,224,166.29V117.87l27.76-14.81a8,8,0,0,0,0-14.12ZM128,200c-43.27,0-68.72-21.14-80-33.71V126.4l76.24,40.66a8,8,0,0,0,7.52,0L176,143.47v46.34C163.4,195.69,147.52,200,128,200Zm80-33.75a97.83,97.83,0,0,1-16,14.25V134.93l16-8.53ZM188,118.94l-.22-.13-56-29.87a8,8,0,0,0-7.52,14.12L171,128l-43,22.93L25,96,128,41.07,231,96Z"></path></svg> : <Icon w="15px" h="15px" color={isHovered ? hoverColor : iconColor} />
                }
            </Button>
        </Tooltip>
    );
};


export default HoverIconButton