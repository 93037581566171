import React, { useState, useContext, useEffect } from "react";
// Chakra imports
import {
  Button,
  useColorModeValue,
  Spinner,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Text,
  Heading,
  Box,
  Icon,
  Flex,
  Link,
} from "@chakra-ui/react";
import { CiCircleCheck, CiCircleRemove } from "react-icons/ci";
// Assets
import { useHistory, useLocation } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import useCustomHistory from "langHoc/useCustomHistory";
import { useTranslation } from "react-i18next";
import { AuthContext } from "contexts/AuthContext";

import { ConfigContext } from "contexts/ConfigContext";

import aceTokenABI from "utils/aceAbi.json";

import InstallWalletNoticeModal from "views/admin/modals/InstallWalletNoticeModal";
import Pusher from "pusher-js";

import {
  BrowserProvider,
  Contract,
  formatUnits,
  parseEther,
  parseUnits,
  toNumber,
} from "ethers";
import Cookies from "js-cookie";
import AES from "crypto-js/aes";
import Utf8 from "crypto-js/enc-utf8";
import { userPrivateRequest } from "config/axios.config";
import { MdContentCopy } from "react-icons/md";
import { walletAddressFormat } from "utils/utils";
import { toast } from "react-toastify";
function DepositButton({ metrics, type, buttonHide = false }) {
  const {
    dispatch,
    authState,
    depositModal,
    setDepositModal,
    // confirmationModal,
    // setConfirmationModal,
    profileFetch,
  } = useContext(AuthContext);

  const [confirmationModal, setConfirmationModal] = useState(false);
  const configData = useContext(ConfigContext);
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const googleBg = useColorModeValue("secondaryGray.400", "whiteAlpha.200");
  const googleText = useColorModeValue("navy.700", "white");

  const platform = process.env?.REACT_APP_PLATFORM;
  const googleHover = useColorModeValue(
    { bg: "gray.200" },
    { bg: "whiteAlpha.300" }
  );
  const googleActive = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.200" }
  );
  const kgoogleHover = useColorModeValue({ bg: "#fada2f" }, { bg: "#fada2f" });
  const kgoogleActive = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.200" }
  );
  const [loading, setLoading] = React.useState(false);
  const history = useCustomHistory();

  // store url of this page after render
  const url = window.location.href;
  const urlObj = new URL(url);
  const params = new URLSearchParams(urlObj.search);
  const sourceValue = params.get("source");

  const { t } = useTranslation();
  const lang = useLocation();
  const isKor = lang?.pathname.includes("/ko");

  // const lang = window.location.pathname.split("/")[1] || "en";
  const [userAddress, setUserAddress] = useState("");
  const [nonce, setNonce] = useState("");
  const [showWalletConnectModal, setShowWalletConnectModal] = useState(false);
  const [showWalletInstallModal, setShowWalletInstallModal] = useState(false);
  const [equivalentChi, setEquivalentChi] = useState(0);
  const [walletBalance, setWalletBalance] = useState(0);
  const [pendingHash, setPendingHash] = useState("");
  const [successData, setSuccessData] = useState();

  // {
  //   hash: "0x2d02ee08ca0f0d41a7d21b45a4968f6faa65fa1045fd9b20e8df2c5128ac49a1",
  //   status: "confirmed",
  //   confirmationCount: 6304,
  //   createdAt: "2024-01-02T09:11:16.581Z",
  // }
  const [amount, setAmount] = useState(
    configData?.config?.global?.WEB3_STAKING_CONFIGURATION?.value
      ?.stakingAmount || 0
  );

  const [buttonColor, setButtonColor] = useState("#834af4");
  const [buttonHoverColor, setButtonHoverColor] = useState("#834af4");
  useEffect(() => {
    if (process.env.REACT_APP_PLATFORM === "Chingu") {
      setButtonColor("#834af4");
      setButtonHoverColor("#834af4");
    }

    if (process.env.REACT_APP_PLATFORM === "Questron") {
      setButtonColor("#09D809");
      setButtonHoverColor("#08a108");
    }
    if (process.env.REACT_APP_PLATFORM === "DappStoreAi") {
      setButtonColor("#0060ff");
      setButtonHoverColor("#02A3EA");
    }
  }, []);

  function replacer(key, value) {
    if (typeof value === "bigint") {
      return value.toString();
    } else {
      return value;
    }
  }

  function encryptTransaction(data) {
    let secretKey = Cookies.get("token");
    const ciphertext = AES.encrypt(
      JSON.stringify(data, replacer),
      secretKey
    ).toString();
    return ciphertext;
  }

  // 0x8ea9a6c45f4fdec4b1539580ff0e685613cc968ad8a4977feaf476d69007e4bf;
  //0x25fcbae5e41c5f0a2170049a31763546ab2c8b575a29d9636b443cd2ee131807
  const { isOpen, onOpen, onClose } = useDisclosure();
  const createTransaction = async () => {
    setLoading(true);
    let hasPendingWallet = await userPrivateRequest
      .get("/wallet/checkPendingDeposit")
      .then((response) => {
        // console.log("first transaction", response.data);
        return response?.data?.hasPendingDeposit;
      })
      .catch((error) => {
        // console.log("error.message");
        return "no";
      });
    if (hasPendingWallet === "yes") {
      toast("You have a transaction in progress", {});
      setLoading(false);
      return true;
    }
    const address = authState?.user?.walletAddress;

    if (walletBalance < amount) {
      // setDepositModal(false);
      // setConfirmationModal(true);
      // console.log("Insufficient funds");
      toast(
        platform == "Questron"
          ? "Insufficient ACE funds"
          : "Insufficient DAPPX funds",
        {}
      );
      setLoading(false);
      return 0;
    }
    if (window.ethereum) {
      const tokenAddress =
        platform == "Questron"
          ? process.env.REACT_APP_ACE_CONTRACT_ADDRESS
          : process.env.REACT_APP_DAPPX_CONTRACT_ADDRESS;
      let ethersProvider = new BrowserProvider(window.ethereum);
      let signer = await ethersProvider.getSigner();

      console.log("Token address", tokenAddress);

      let abi = aceTokenABI.abi;
      if (platform !== "Questron") {
      }
      let contract = new Contract(tokenAddress, abi, signer);
      let value = parseUnits(amount, 18);
      let transaction = await contract.transfer(address, value).catch((err) => {
        console.error("Error getting", err.message);
        setLoading(false);
        return false;
      });
      if (!transaction) {
        return false;
      }

      setPendingHash(transaction.hash);

      transaction = {
        from: transaction.from,
        gasLimit: transaction.gasLimit,
        gasPrice: transaction.gasPrice,
        hash: transaction.hash,
        maxFeePerGas: transaction.maxFeePerGas,
        maxPriorityFeePerGas: transaction.maxPriorityFeePerGas,
        nonce: transaction.nonce,
        value: transaction.value,
        // to: transaction.to,
        to: address,
        tokenAmountInDecimal: amount,
      };

      // let transaction = {
      //   from: "0x2Be1E6bB37Df39485A110Ee3d7761b79bbF3dB05",
      //   gasLimit: 51522n,
      //   gasPrice: 20432040553n,
      //   hash: "0x50445a080fb3743be7c56efbc2fc4ac74a01f3f3493cb1336b4a7baefe2e921d",
      //   maxFeePerGas: 20432040553n,
      //   maxPriorityFeePerGas: 100000000n,
      //   nonce: 4,
      //   to: "0xeC5483804e637D45cDe22Fa0869656B64b5AB1ab",
      //   tokenAmountInDecimal: "10",
      //   value: 0n,
      // };
      console.log("transaction", transaction);

      let payload = await encryptTransaction(transaction);
      console.log(payload);

      let path = "/wallet/depositACE";
      if (platform !== "Questron") {
        path = "wallet/depositDAPPX";
      }
      userPrivateRequest
        .post(path, { payload })
        .then((response) => {
          console.log("transaction", response.data);
        })
        .catch((error) => {
          console.log("error", error.message);
        })
        .finally(() => {
          setLoading(false);
          setDepositModal(false);
        });
    } else {
      // setShowWalletInstallModal(true);
      setDepositModal(true);
      setLoading(false);
    }
  };

  const CustomIcon = () => (
    <Icon
      viewBox="0 0 24 24"
      boxSize={6}
      style={{
        borderRadius: "100%",
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        x={0}
        y={0}
        height={24}
        width={24}
      >
        <rect
          x={0}
          y={0}
          rx={0}
          ry={0}
          height={24}
          width={24}
          transform="translate(-3.975973212625078 3.7885736767548837) rotate(166.5 12 12)"
          fill="#f17c02"
        />
        <rect
          x={0}
          y={0}
          rx={0}
          ry={0}
          height={24}
          width={24}
          transform="translate(10.732919221512088 -4.162495628237221) rotate(494.5 12 12)"
          fill="#15b8f2"
        />
        <rect
          x={0}
          y={0}
          rx={0}
          ry={0}
          height={24}
          width={24}
          transform="translate(-3.987759890586655 17.169357837131557) rotate(222.1 12 12)"
          fill="#fc5300"
        />
        <rect
          x={0}
          y={0}
          rx={0}
          ry={0}
          height={24}
          width={24}
          transform="translate(-10.78330584781217 21.462487604857618) rotate(185.6 12 12)"
          fill="#018c7d"
        />
      </svg>
    </Icon>
  );

  const handleSubmit = (event) => {
    event.preventDefault();
    // Your form submission logic
  };
  const getContractInfo = async () => {
    if (window.ethereum) {
      await window.ethereum.request({ method: "eth_requestAccounts" });
      const tokenAddress =
        platform === "Questron"
          ? process.env.REACT_APP_ACE_CONTRACT_ADDRESS
          : process.env.REACT_APP_DAPPX_CONTRACT_ADDRESS;
      let ethersProvider = new BrowserProvider(window.ethereum);
      let signer = await ethersProvider.getSigner();
      let abi = aceTokenABI.abi;
      if (platform !== "Questron") {
      }
      let contract = new Contract(tokenAddress, abi, signer);
      const accounts = await window.ethereum
        .request({
          method: "eth_requestAccounts",
        })
        .then((response) => {
          return response;
        })
        .catch((response) => {
          console.log("catch block: ", response.message);
        });
      let account = accounts[0] || null;
      let balance = await contract.balanceOf(account);
      let balanceInDecimal = Number(balance) / Math.pow(10, 18);
      setWalletBalance(balanceInDecimal);
    }
  };

  // useEffect(() => {
  //   getContractInfo();
  // }, []);
  useEffect(() => {
    if (pendingHash) {
      console.log(pendingHash, "pendingHash");
      setConfirmationModal(true);
      setDepositModal(false);
      let listeningEvent = `wallet-transaction-status-${pendingHash}`;
      var pusher = new Pusher(process.env.REACT_APP_PUSHER_APP_KEY, {
        cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
      });
      var channel = pusher.subscribe(`wallet-transaction-${pendingHash}`);
      channel.bind(listeningEvent, function (data) {
        //Sample Output
        setSuccessData(data);
        console.log(data, "successData");
        if (data?.status === "confirmed") {
          profileFetch();
        }
        // Please refetch the profile after getting confirmation.
        console.log(
          "pusher notification on wallet transaction",
          JSON.stringify(data)
        );
      });
      // Cleanup
      return () => {
        channel.unbind_all();
        channel.unsubscribe();
      };
    }
  }, [pendingHash]);

  const WSMC =
    configData?.config?.global?.WEB3_STAKING_MODAL_CONFIGURATION?.value;
  const title = isKor ? WSMC?.titleKr : WSMC?.title;
  const description = isKor ? WSMC?.descriptionKr : WSMC?.description;
  const bulletPoints = isKor ? WSMC?.bulletPointsKr : WSMC?.bulletPoints;
  const receivingLabel = isKor ? WSMC?.receivingLabelKr : WSMC?.receivingLabel;

  const loadingPaymentTitle = isKor
    ? WSMC?.loadingPaymentTitleKr
    : WSMC?.loadingPaymentTitleKr;
  const loadingPaymentSubtitle = isKor
    ? WSMC?.loadingPaymentSubtitleKr
    : WSMC?.loadingPaymentSubtitle;
  const loadingPaymentButton = isKor
    ? WSMC?.loadingPaymentButtonKr
    : WSMC?.loadingPaymentButton;
  const confirmedPaymentTitle = isKor
    ? WSMC?.confirmedPaymentTitleKr
    : WSMC?.confirmedPaymentTitle;
  const confirmedPaymentSubtitle = isKor
    ? WSMC?.confirmedPaymentSubtitleKr
    : WSMC?.confirmedPaymentSubtitle;
  const confirmedPaymentButton = isKor
    ? WSMC?.confirmedPaymentButtonKr
    : WSMC?.confirmedPaymentButton;
  const failedPaymentTitle = isKor
    ? WSMC?.failedPaymentTitleKr
    : WSMC?.failedPaymentTitle;
  const failedPaymentSubtitle = isKor
    ? WSMC?.failedPaymentSubtitleKr
    : WSMC?.failedPaymentSubtitle;
  const failedPaymentButton = isKor
    ? WSMC?.failedPaymentButtonKr
    : WSMC?.failedPaymentButton;

  const modalBulletTitle = isKor
    ? configData?.config?.global?.WEB3_STAKING_MODAL_CONFIGURATION?.value
        ?.modalBulletTitle
    : configData?.config?.global?.WEB3_STAKING_MODAL_CONFIGURATION?.value
        ?.modalBulletTitle;

  const confirmedModalClose = () => {
    setConfirmationModal(false);
    setPendingHash(null);
  };

  return (
    <>
      <Modal
        isOpen={confirmationModal}
        onClose={
          successData?.status !== "confirmed"
            ? () => {}
            : () => confirmedModalClose()
        }
        isCentered
        width="800px"
        maxWidth="800px"
        className="deposit chakra-modal__content-container"
        id="deposit chakra-modal__content-container"
      >
        <ModalOverlay />
        <ModalContent
          backgroundColor="white"
          borderRadius="20px"
          width="400px"
          maxWidth="600px"
          height={"350px"}
        >
          <ModalBody p={"16px"} borderRadius={"16px"} width="400px">
            {/* <Box
              textAlign="right"
              width={"100%"}
              onClick={() => setConfirmationModal(false)}
            >
              <Icon as={MdClose} boxSize="24px" mb="16px" color="gray" />
            </Box> */}
            <Flex
              direction="column"
              alignItems="center"
              justifyContent="center"
              height="100%"
            >
              {successData?.status !== "confirmed" &&
                successData?.status !== "failed" && (
                  <>
                    <Spinner w={20} h={20} mb="25px" color="#09d809" />
                    <Text
                      fontSize="lg"
                      mb="20px"
                      fontWeight="bold"
                      textAlign="center"
                    >
                      {loadingPaymentTitle} {amount}{" "}
                      {platform === "Questron" ? "ACE" : "DAPPX"}
                    </Text>
                    <Text
                      fontSize="sm"
                      mb="20px"
                      color="gray.500"
                      textAlign="center"
                    >
                      {loadingPaymentSubtitle}
                    </Text>
                    <Link
                      fontSize="sm"
                      mb="20px"
                      color="blue.500"
                      href={`https://etherscan.io/tx/${pendingHash}`}
                      textAlign="center"
                      target="_blank"
                    >
                      {loadingPaymentButton}
                    </Link>
                  </>
                )}
              {successData?.status === "confirmed" && (
                <>
                  <Icon
                    as={CiCircleCheck}
                    boxSize="90px"
                    mb="25px"
                    color="#09d809"
                  />

                  <Text
                    fontSize="lg"
                    mb="20px"
                    fontWeight="bold"
                    textAlign="center"
                  >
                    {confirmedPaymentTitle} {amount}{" "}
                    {platform === "Questron" ? "ACE" : "DAPPX"}
                  </Text>
                  <Text
                    fontSize="sm"
                    mb="20px"
                    color="gray.500"
                    textAlign="center"
                  >
                    {confirmedPaymentSubtitle}
                  </Text>
                  <Link
                    fontSize="sm"
                    mb="20px"
                    color="blue.500"
                    href={`https://etherscan.io/tx/${pendingHash}`}
                    textAlign="center"
                    target="_blank"
                  >
                    {confirmedPaymentButton}
                  </Link>
                  <Button
                    mt={4}
                    colorScheme="teal"
                    type="submit"
                    width={"60%"}
                    bg="#09d809"
                    height={"40px"}
                    color="white"
                    fontSize="md"
                    onClick={() => confirmedModalClose()}
                    _hover={{
                      bg: "#09d809", // Darker shade for hover
                    }}
                    _active={{
                      bg: "#09d809", // Darker shade for active
                    }}
                    _focus={{
                      bg: "#09d809", // Darker shade for focus
                    }}
                    // disabled={walletBalance < amount || amount <= 0}
                  >
                    {loading ? (
                      <>
                        <Spinner />
                      </>
                    ) : (
                      t("OK")
                    )}
                  </Button>
                </>
              )}
              {successData?.status === "failed" && (
                <>
                  <Icon
                    as={CiCircleRemove}
                    boxSize="90px"
                    mb="25px"
                    color="red.500"
                  />

                  <Text
                    fontSize="lg"
                    mb="20px"
                    fontWeight="bold"
                    textAlign="center"
                  >
                    {failedPaymentTitle} {amount}{" "}
                    {platform === "Questron" ? "ACE" : "DAPPX"}
                  </Text>
                  <Text
                    fontSize="sm"
                    mb="20px"
                    color="gray.500"
                    textAlign="center"
                  >
                    {failedPaymentSubtitle}
                  </Text>
                  <Link
                    fontSize="sm"
                    mb="20px"
                    color="blue.500"
                    href={`https://etherscan.io/tx/${pendingHash}`}
                    textAlign="center"
                    target="_blank"
                  >
                    {failedPaymentButton}
                  </Link>
                  <Button
                    mt={4}
                    colorScheme="teal"
                    type="submit"
                    width={"60%"}
                    bg="#09d809"
                    height={"40px"}
                    color="white"
                    fontSize="md"
                    onClick={() => confirmedModalClose()}
                    _hover={{
                      bg: "#09d809", // Darker shade for hover
                    }}
                    _active={{
                      bg: "#09d809", // Darker shade for active
                    }}
                    _focus={{
                      bg: "#09d809", // Darker shade for focus
                    }}
                    // disabled={walletBalance < amount || amount <= 0}
                  >
                    {loading ? (
                      <>
                        <Spinner />
                      </>
                    ) : (
                      t("OK")
                    )}
                  </Button>
                </>
              )}
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>

      <Modal
        isOpen={depositModal}
        onClose={() => setDepositModal(false)}
        isCentered
        width="800px"
        maxWidth="800px"
        className="deposit chakra-modal__content-container"
        id="deposit chakra-modal__content-container"
      >
        <ModalOverlay />
        <ModalContent
          backgroundColor="white"
          borderRadius="20px"
          width="800px"
          maxWidth="800px"
          // className="deposit chakra-modal__content-container"
        >
          {/* <ModalCloseButton /> */}
          <ModalBody p={"16px"} borderRadius={"16px"} width="800px">
            <Box d="flex" flexWrap={"wrap"}>
              <Box w="48%">
                <Box className="flex flex-col space-y-4 w-[320px] pr-3 border-r border-gray-300">
                  <Heading as="h2" size="lg" mb="16px" fontWeight="semibold">
                    {title}
                  </Heading>
                  <Text fontSize="lg" mb="16px">
                    {description}
                  </Text>
                  {/* <Text fontSize="sm" mb="16px">
                    {modalBulletTitle}
                  </Text> */}

                  <div
                    dangerouslySetInnerHTML={{
                      __html: bulletPoints,
                    }}
                    className="list-align-dev"
                  />
                  {/* <List spacing={1} fontSize="sm" styleType="disc" pl="20px">
                    {bulletPoints?.map(bp => {
                      return (
                        <ListItem>{bp}</ListItem>
                      )
                    })}
                  </List> */}
                </Box>
              </Box>
              <Box w="52%" pl="10px" borderLeft={"1px solid #ddd"}>
                <Box
                  className="stakeCardSection"
                  bg="linear-gradient(65.21deg, rgb(55, 57, 74) 19.1%, rgb(62, 75, 79) 100%)"
                  borderRadius="20px"
                  p="16px 0 0 0"
                >
                  <Box p="0px 16px 0 16px">
                    <Flex justify="space-between" align="center">
                      <Box>
                        <Text
                          fontSize="xl"
                          color="gray.300"
                          className="chat-active"
                          align={"center"}
                          justifyContent={"center"}
                        >
                          Available {platform === "Questron" ? "ACE" : "DAPPX"}
                        </Text>
                        <Text fontSize="xl" fontWeight="bold" color="white">
                          {walletBalance}{" "}
                          {platform === "Questron" ? "$ACE" : "$DAPPX"}
                        </Text>
                      </Box>

                      <Button
                        _hover={{
                          bg: "rgba(0, 0, 0, .2)",
                        }}
                        _active={{
                          bg: "rgba(0, 0, 0, .2)",
                        }}
                        _focus={{
                          bg: "rgba(0, 0, 0, .2)",
                        }}
                        bg="rgba(0, 0, 0, .2)"
                        color={"#fff"}
                        fontSize={"14px"}
                        p="4px 4px 4px 10px"
                        d="flex"
                        alignItems={"center"}
                        gap="5px"
                      >
                        {walletAddressFormat(authState?.user?.metamaskAddress)}{" "}
                        <CustomIcon />
                      </Button>
                    </Flex>
                  </Box>
                  <Box
                    // bg="#34343d"
                    mt="27px"
                    p="16px 16px 16px 16px"
                    // borderRadius={"20px"}
                  >
                    <form autoComplete="off" onSubmit={handleSubmit}>
                      {/* <FormControl id="amount">
                        <FormLabel mr="0px">
                          <InputGroup>
                            <InputLeftElement
                              top={"11px"}
                              pointerEvents="none"
                              children={
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width={238}
                                  height={256}
                                  viewBox="0 0 238 256"
                                  fill="none"
                                  className="ace-icon"
                                >
                                  <path
                                    d="M147.488 0L0 208.694L94.2095 167.26L147.488 0Z"
                                    fill="url(#paint0_linear_302_7)"
                                  />
                                  <path
                                    d="M0.768066 213.293L120.666 257L234.025 240.384L98.811 178.275L0.768066 213.293Z"
                                    fill="url(#paint1_linear_302_7)"
                                  />
                                  <path
                                    d="M237.37 237.96L151.218 0L105.966 168.82L237.37 237.96Z"
                                    fill="url(#paint2_linear_302_7)"
                                  />
                                  <defs>
                                    <linearGradient
                                      id="paint0_linear_302_7"
                                      x1="32.7423"
                                      y1="216.833"
                                      x2="174.212"
                                      y2="74.6989"
                                      gradientUnits="userSpaceOnUse"
                                    >
                                      <stop stopColor="#502B86" />
                                      <stop offset="0.278" stopColor="#3B54A1" />
                                      <stop offset="0.586" stopColor="#00A1C3" />
                                      <stop offset={1} stopColor="#8ACFDD" />
                                    </linearGradient>
                                    <linearGradient
                                      id="paint1_linear_302_7"
                                      x1="104.567"
                                      y1="252.513"
                                      x2="115.541"
                                      y2="58.5607"
                                      gradientUnits="userSpaceOnUse"
                                    >
                                      <stop stopColor="#502B86" />
                                      <stop offset="0.278" stopColor="#3B54A1" />
                                      <stop offset="0.586" stopColor="#00A1C3" />
                                      <stop offset={1} stopColor="#8ACFDD" />
                                    </linearGradient>
                                    <linearGradient
                                      id="paint2_linear_302_7"
                                      x1="123.311"
                                      y1="261.756"
                                      x2="275.991"
                                      y2="168.141"
                                      gradientUnits="userSpaceOnUse"
                                    >
                                      <stop stopColor="#502B86" />
                                      <stop offset="0.278" stopColor="#3B54A1" />
                                      <stop offset="0.586" stopColor="#00A1C3" />
                                      <stop offset={1} stopColor="#8ACFDD" />
                                    </linearGradient>
                                  </defs>
                                </svg>
                              }
                            />
                            <Input
                              // placeholder="0"
                              aria-invalid="false"
                              type="text"
                              name="amount"
                              inputMode="decimal"
                              defaultValue=""
                              placeholder="ACE Amount"
                              color={"#fff"}
                              border={"1px solid rgba(255, 255, 255, 0.12)"}
                              bg="#2a2a32"
                              height={"60px"}
                              value={amount}
                              onChange={(e) => {
                                onAmountChange(e.target.value);
                              }}
                            />
                            <InputRightElement width="4.5rem" top="11px">
                              <Button
                                borderRadius={"4px"}
                                h="32px"
                                w="55px"
                                size="sm"
                                // disabled
                                onClick={() => {
                                  onAmountChange(walletBalance);
                                }}
                                bg="rgba(0, 163, 255, 0.1)"
                                color={"#00a3ff"}
                              >
                                MAX
                              </Button>
                            </InputRightElement>
                          </InputGroup>
                        </FormLabel>
                      </FormControl> */}

                      <Text
                        fontSize="5xl"
                        fontWeight="bold"
                        color="white"
                        textAlign={"center"}
                      >
                        {amount} {platform === "Questron" ? "$ACE" : "$DAPPX"}
                      </Text>
                      <Box mt="20px">
                        <Flex justifyContent={"center"} mb="16px">
                          <Text fontSize="xs" color="rgba(255, 255, 255, .8)">
                            {receivingLabel}
                          </Text>
                        </Flex>
                      </Box>
                      <Button
                        mt={4}
                        colorScheme="teal"
                        type="submit"
                        isFullWidth
                        bg={buttonColor}
                        fontSize="xl"
                        height={"56px"}
                        onClick={createTransaction}
                        _hover={{
                          bg: buttonHoverColor, // Darker shade for hover
                        }}
                        _active={{
                          bg: buttonHoverColor, // Darker shade for active
                        }}
                        _focus={{
                          bg: buttonHoverColor, // Darker shade for focus
                        }}
                        // disabled={walletBalance < amount || amount <= 0}
                      >
                        {loading ? (
                          <>
                            <Spinner />
                          </>
                        ) : (
                          t("Deposit")
                        )}
                      </Button>
                      <Button
                        mt={4}
                        colorScheme="teal"
                        type="submit"
                        isFullWidth
                        bg="#EDF2F7"
                        height={"50px"}
                        color="#6D4C77"
                        fontSize="md"
                        onClick={() => setDepositModal(false)}
                        _hover={{
                          bg: "#dcddde", // Darker shade for hover
                        }}
                        _active={{
                          bg: "#dcddde", // Darker shade for active
                        }}
                        _focus={{
                          bg: "#dcddde", // Darker shade for focus
                        }}
                        // disabled={walletBalance < amount || amount <= 0}
                      >
                        Cancel
                      </Button>
                    </form>
                  </Box>
                </Box>
              </Box>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
      {authState?.user?.membership !== "pro" && !buttonHide && (
        <Button
          fontSize="sm"
          me="0px"
          mb="0px"
          // pt="15px"
          // pb="12px"
          h="35px"
          ml="20px"
          alignItems={"center"}
          borderRadius={metrics == "chatpage" ? "30px" : "10px"}
          bg={buttonColor}
          color={"#fff"}
          fontWeight="700"
          // _active={googleActive}
          // _focus={googleActive}
          onClick={() => {
            setDepositModal(true);
            console.log("Render");
            getContractInfo();
          }}
          disabled={loading}
          _hover={{
            bg: buttonColor,
          }}
          _active={{
            bg: buttonColor,
          }}
          _focus={{
            bg: buttonColor,
          }}
          textTransform={"uppercase"}
        >
          {platform === "Questron"
            ? "Activate Questron"
            : `JOIN ${configData?.appName} PRO`}
          {/* {t("JOIN CHINGU PRO")} */}
        </Button>
      )}
    </>
  );
}

export default DepositButton;
