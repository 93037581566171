import { Icon } from "@chakra-ui/react";
import { MdHome, MdOutlineShoppingCart } from 'react-icons/md';
import Messages from "views/admin/main/others/NewMessage";
import { BsChatSquare } from "react-icons/bs";



// NFT Imports
import NFTMarketplace from 'views/admin/nfts/marketplace';

const routes = [
  // --- Dashboards ---
  {
    name: "Home",
    path: "/",
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    collapse: false,
  },
  // --- NFTs-- -
  // {
  // name: 'NFTs',
  // path: '/chat',
  // icon: <Icon as={MdOutlineShoppingCart} width='20px' height='20px' color='inherit' />,
  // collapse: false,
  // component: NFTMarketplace,
  // layout: "/",
  // items: [
  //   {
  //     name: 'Marketplace',
  //     layout: "/",
  //     path: '/chat',
  //     component: NFTMarketplace,
  //     secondary: true
  //   },
  // ]
  // },
  {
    name: "Chat",
    path: "/chat",
    icon: <Icon as={BsChatSquare} width="20px" height="20px" color="#767676" />,
    collapse: false,
    // component: Messages,
    component: NFTMarketplace,
    layout: "/",


  },

];

export default routes;
