import React, { useContext } from 'react'
import { NavLink, useHistory } from 'react-router-dom'
// Chakra imports
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  useColorModeValue,
  Text,
} from '@chakra-ui/react'
// Assets
import { FcGoogle } from 'react-icons/fc'
import { MdOutlineRemoveRedEye } from 'react-icons/md'
import { RiEyeCloseLine } from 'react-icons/ri'

// Custom components
import { HSeparator } from 'components/separator/Separator'
import CenteredAuth from 'layouts/auth/types/CenteredNew'
import { userPublicRequest } from 'config/axios.config'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { AuthContext } from 'contexts/AuthContext'
import GoogleLogin from 'react-google-login'
import CustomNavLink from 'langHoc/CustomNavLink'
import { googleProvider } from './SocialAuth/authMethods'
import socialMediaAuth from './SocialAuth/auth'
import useCustomHistory from 'langHoc/useCustomHistory'

function GoogleSignInCentered() {
  const { dispatch, profileFetch } = useContext(AuthContext)
  // Chakra color mode
  const textColor = useColorModeValue('navy.700', 'white')
  const textColorSecondary = 'gray.400'
  const textColorDetails = useColorModeValue('navy.700', 'secondaryGray.600')
  const textColorBrand = useColorModeValue('brand.500', 'white')
  const brandStars = useColorModeValue('brand.500', 'brand.400')
  const googleBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.200')
  const googleText = useColorModeValue('navy.700', 'white')
  const googleHover = useColorModeValue(
    { bg: 'gray.200' },
    { bg: 'whiteAlpha.300' }
  )
  const googleActive = useColorModeValue(
    { bg: 'secondaryGray.300' },
    { bg: 'whiteAlpha.200' }
  )
  const [show, setShow] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [formData, setFormData] = React.useState({})
  const handleClick = () => setShow(!show)
  const history = useCustomHistory()
  const handleSubmit = e => {
    e.preventDefault()
    setLoading(true)

    userPublicRequest
      .post('/auth/login', formData)
      .then(res => {
        // let payload = res.data.results;
        const login = {
          data: {
            // "status": true,
            // "role": "user",
            // "reset_password_token": "ca2241059841411a1c0adb8d132da295",
            // "reset_password_expires": "2022-12-13T10:53:54.224Z",
            // "auth_token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6InJhYmJpLmdvbGVtQGRlY2VudGVybmV0Lm5ldCIsImlkIjoiNjM4OTdiODhiMmI2MWQwMGJlYTQyODg1IiwiaWF0IjoxNjczNDExNTM5LCJleHAiOjE2NzM0OTc5Mzl9.H4ccOdP6H7ldMvnht9b-RLp1SPAAYL6-epFaBSur-_k",
            // "_id": "63897b88b2b61d00bea42885",
            // "email": "rabbi.golem@decenternet.net",
            // "password": "$2a$12$BWocEXgmz62yzt3QxQaBoe8P.NXw9NUdBOCBzWtBJGkFRkH9nnKMG",
            // "createdAt": "2022-12-13T09:53:54.231Z",
            // "updatedAt": "2023-01-11T04:32:19.115Z",
            ...res.data.data.user,
          },
          token: res.data.data.token,
          remember_me: false,
          expires_at: '2023-01-11T10:54:32.795Z',
        }

        dispatch({
          type: 'LOGIN',
          payload: login,
        })
        profileFetch()
        history.push('/chat')
      })
      .catch(error => {
        toast.error(error.response.data.message)
      })
      .finally(e => {
        setLoading(false)
      })
  }

  const changeForm = event => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    })
  }

  const responseGoogle = response => {
  }

  const handleGoogleLogin = async () => {
    try {
      googleProvider.setCustomParameters({
        login_hint: '',
      })
      const res = await socialMediaAuth(googleProvider.addScope('email'))

      // const result = await Axios.get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${res.credential.accessToken}`)

      // // console.log(result, '838383')
    } catch (err) {
      // // console.log(err)
    }

    // googleLogin(token)
  }
  return (
    <CenteredAuth
      // image={"linear-gradient(135deg, #868CFF 0%, #4318FF 100%)"}
      cardTop={{ base: '140px', md: '14vh' }}
      cardBottom={{ base: '50px', lg: 'auto' }}
      mx="0px"
    >
      <Flex
        maxW={{ base: '100%', md: 'max-content' }}
        w="100%"
        mx={{ base: 'auto', lg: '0px' }}
        me="auto"
        justifyContent="center"
        px={{ base: '20px', md: '0px' }}
        flexDirection="column"
      >
        <Box me="auto">
          <Heading color={textColor} fontSize="36px" mb="10px">
            Sign In
          </Heading>
          <Text
            mb="36px"
            ms="4px"
            color={textColorSecondary}
            fontWeight="400"
            fontSize="md"
          >
            Enter your email and password to sign in!
          </Text>

          <Button onClick={handleGoogleLogin}>Login Google</Button>
        </Box>
        <Flex
          zIndex="2"
          direction="column"
          w={{ base: '100%', md: '420px' }}
          maxW="100%"
          background="transparent"
          borderRadius="15px"
          mx={{ base: 'auto', lg: 'unset' }}
          me="auto"
          mb={{ base: '20px', md: 'auto' }}
        >
          <Button
            fontSize="sm"
            me="0px"
            mb="26px"
            py="15px"
            h="50px"
            borderRadius="16px"
            bg={googleBg}
            color={googleText}
            fontWeight="500"
            _hover={googleHover}
            _active={googleActive}
            _focus={googleActive}
          >
            <Icon as={FcGoogle} w="20px" h="20px" me="10px" />
            Sign in with Google
          </Button>
          <Flex align="center" mb="25px">
            <HSeparator />
            <Text color="gray.400" mx="14px">
              or
            </Text>
            <HSeparator />
          </Flex>
          <div>
            <GoogleLogin
              clientId={process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID}
              buttonText="Sign in with Google"
              onSuccess={responseGoogle}
              onFailure={responseGoogle}
              cookiePolicy={'single_host_origin'}
            />
          </div>
          <form onSubmit={handleSubmit}>
            <FormControl>
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="8px"
              >
                Email<Text color={brandStars}>*</Text>
              </FormLabel>
              <Input
                isRequired={true}
                variant="auth"
                fontSize="sm"
                ms={{ base: '0px', md: '0px' }}
                type="email"
                placeholder="email@example.com"
                mb="24px"
                fontWeight="500"
                size="lg"
                name="email"
                onChange={changeForm}
                value={formData.email}
              />
              <FormLabel
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                isRequired={true}
                display="flex"
              >
                Password<Text color={brandStars}>*</Text>
              </FormLabel>
              <InputGroup size="md">
                <Input
                  isRequired={true}
                  fontSize="sm"
                  ms={{ base: '0px', md: '4px' }}
                  placeholder="Min. 8 characters"
                  mb="24px"
                  size="lg"
                  type={show ? 'text' : 'password'}
                  variant="auth"
                  onChange={changeForm}
                  name="password"
                  value={formData.password}
                />
                <InputRightElement display="flex" alignItems="center" mt="4px">
                  <Icon
                    color={textColorSecondary}
                    _hover={{ cursor: 'pointer' }}
                    as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                    onClick={handleClick}
                  />
                </InputRightElement>
              </InputGroup>
              <Flex justifyContent="space-between" align="center" mb="24px">
                <FormControl display="flex" alignItems="center">
                  {/* <Checkbox
                  id='remember-login'
                  colorScheme='brandScheme'
                  me='10px'
                />
                <FormLabel
                  htmlFor='remember-login'
                  mb='0'
                  fontWeight='normal'
                  color={textColor}
                  fontSize='sm'>
                  Keep me logged in
                </FormLabel> */}
                </FormControl>
                <CustomNavLink to="/auth/forgot">
                  <Text
                    color={textColorBrand}
                    fontSize="sm"
                    w="124px"
                    fontWeight="500"
                  >
                    Forgot password?
                  </Text>
                </CustomNavLink>
              </Flex>

              <Button
                fontSize="sm"
                variant="brand"
                fontWeight="500"
                w="100%"
                h="50"
                mb="24px"
                type="submit"
              >
                Sign In
              </Button>
            </FormControl>
          </form>

          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="start"
            maxW="100%"
            mt="0px"
          >
            <Text color={textColorDetails} fontWeight="400" fontSize="14px">
              Not registered yet?
              <CustomNavLink to="/auth/sign-up">
                <Text
                  color={textColorBrand}
                  as="span"
                  ms="5px"
                  fontWeight="500"
                >
                  Create an Account
                </Text>
              </CustomNavLink>
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </CenteredAuth>
  )
}

export default GoogleSignInCentered
