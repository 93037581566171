import React, { useContext } from "react";
import { ConfigContext } from "contexts/ConfigContext";
import { useColorModeValue } from "@chakra-ui/system";

function ParsedContent({ data }) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(data, "text/html");
  const configData = useContext(ConfigContext);
  const COLORS = configData?.config?.global?.COLORS?.value;
  const textColor = useColorModeValue(COLORS.textColor, COLORS.textDarkColor);

  Array.from(doc.getElementsByTagName("img")).forEach((img) => {
    const newImg = document.createElement("img");
    newImg.src = img.src;
    newImg.width = 250;
    img.parentNode.replaceChild(newImg, img);
  });

  Array.from(doc.getElementsByTagName("a")).forEach((a) => {
    let href = a.getAttribute("href");
    if (href) {
      if (!href.startsWith("http://") && !href.startsWith("https://")) {
        href = `http://${href}`;
      }
      a.setAttribute("href", href);
      a.style.color = "#0000EE"; // This sets all links to a specific blue color.
      a.target = "_blank";
    }
  });

  const updateStyles = (element) => {
    if (element.style) {
      element.style.color = textColor; // Override text color
    }
    Array.from(element.children).forEach(updateStyles);
  };

  updateStyles(doc.body);

  const sanitizedHTML = doc.body.innerHTML;

  // Apply textColor to the h3 element using the style prop
  return (
    <h3
      dangerouslySetInnerHTML={{ __html: sanitizedHTML }}
      style={{ color: textColor, paddingRight: 20, fontSize: "14px" }}
    />
  );
}

export default ParsedContent;
