import { Button, Icon, Stack, useColorModeValue, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";

function GoogleSearchPagination({
  limit,
  offset,
  page,
  totalRow,
  handlePageChange,
}) {
  const textColor = "#495057";
  const brandColor = useColorModeValue("brand.500", "brand.400");

  const [currentPage, setCurrentPage] = useState(1);
  const [hasHiddenPrev, setHasHiddenPrev] = useState(false);
  const [hasHiddenNext, setHasHiddenNext] = useState(false);

  const createPages = (count, cPage) => {
    let arrPageCount = [];
    let startPoint = cPage - 1;
    if (cPage <= 1) {
      startPoint = cPage;
    } else if (cPage == count) {
      startPoint = count > 2 ? cPage - 2 : cPage - 1;
    }
    let endpoint = startPoint + 2;
    if (startPoint + 2 > count) {
      endpoint = count;
    }

    for (let i = startPoint; i <= endpoint; i++) {
      arrPageCount.push(i);
    }
    return arrPageCount;
  };
  const pageCount = Math.ceil(totalRow / limit);
  const gotoPage = (page) => {
    setCurrentPage(page);
    let newOffset = (page - 1) * limit;
    handlePageChange(limit, newOffset, page);
  };

  useEffect(() => {
    let startPoint = currentPage - 1;
    if (currentPage <= 1) {
      startPoint = currentPage;
    } else if (currentPage == pageCount) {
      startPoint = pageCount > 2 ? currentPage - 2 : currentPage - 1;
    }

    let endpoint = startPoint + 2;
    if (startPoint + 2 > pageCount) {
      endpoint = pageCount;
    }

    if (startPoint > 1) {
      setHasHiddenPrev(true);
    } else {
      setHasHiddenPrev(false);
    }
    if (endpoint < pageCount) {
      setHasHiddenNext(true);
    } else {
      setHasHiddenNext(false);
    }
  }, [currentPage]);

  return (
    <Stack
      direction="row"
      justifyContent={"center"}
      spacing="4px"
      ms="auto"
      display={pageCount > 1 ? "flex" : "none"}
      mt="30px"
      // position="absolute"
      // bottom="-20px"
      // left={'50%'}
      // transform="translateX(-50%)"
      id="PAGINATION"
    >
      <Button
        variant="no-effects"
        onClick={() => gotoPage(1)}
        transition="all .5s ease"
        w="22px"
        minW="22px"
        h="22px"
        borderRadius="5px"
        bg="transparent"
        disabled={currentPage <= 1}
        _hover={{
          bbg: "#e9ecef",
          opacity: "0.7",
        }}
        p="0px"
      >
        {"<<"}
      </Button>
      <Button
        variant="no-effects"
        onClick={() => gotoPage(currentPage - 1)}
        transition="all .5s ease"
        w="22px"
        minW="22px"
        h="22px"
        borderRadius="5px"
        bg="transparent"
        disabled={currentPage <= 1}
        _hover={{
          bbg: "#e9ecef",
          opacity: "0.7",
        }}
        p="0px"
      >
        {"<"}
      </Button>

      {hasHiddenPrev && (
        <>
          <div marginLeft={"10px"}></div>
        </>
      )}

      {createPages(pageCount, currentPage).map((pageNumber, index) => {
        return (
          <Button
            variant="no-effects"
            transition="all .5s ease"
            onClick={() => gotoPage(pageNumber)}
            w="22px"
            h="22px"
            minW="22px"
            borderRadius="5px"
            bg={pageNumber === currentPage ? "transparent" : "transparent"}
            border={pageNumber === currentPage ? "none" : "none"}
            _hover={
              pageNumber === currentPage
                ? {
                    opacity: "0.7",
                  }
                : {
                    bg: "#e9ecef",
                  }
            }
            key={index}
            fontFamily={"Nunito Sans, sans-serif"}
            p="0px"
          >
            <Text
              fontSize="12px"
              color={pageNumber === currentPage ? "#1c73e2" : textColor}
            >
              {pageNumber}
            </Text>
          </Button>
        );
      })}

      {hasHiddenNext && (
        <>
          <>
            <div marginRight={"5px"}></div>
          </>
        </>
      )}
      <Button
        variant="no-effects"
        onClick={() => gotoPage(currentPage + 1)}
        transition="all .5s ease"
        w="22px"
        h="22px"
        minW="22px"
        borderRadius="5px"
        bg="transparent"
        disabled={currentPage >= pageCount}
        _hover={{
          bg: "#e9ecef",
          opacity: "0.7",
        }}
        p="0px"
      >
        {">"}
      </Button>
      <Button
        variant="no-effects"
        onClick={() => gotoPage(Math.round(totalRow / limit))}
        transition="all .5s ease"
        w="22px"
        h="22px"
        minW="22px"
        borderRadius="5px"
        bg="transparent"
        disabled={currentPage >= pageCount}
        _hover={{
          bg: "#e9ecef",
          opacity: "0.7",
        }}
        p="0px"
      >
        {">>"}
      </Button>
    </Stack>
  );
}

export default GoogleSearchPagination;
