import { Box, Portal, useDisclosure } from '@chakra-ui/react'
import { SidebarContext } from 'contexts/SidebarContext'

import { useContext, useState } from 'react'
import { Route } from 'react-router-dom'
import 'mapbox-gl/dist/mapbox-gl.css'
import { useLanguage } from 'useLanguage'
import { AuthContext } from 'contexts/AuthContext'
import { useViewportHeight } from 'layouts/HomeNew/useViewportHeightChat'
import Messages from 'views/admin/main/others/NewMessage'
import Rewards from 'views/admin/rewards'
import Settings from 'views/admin/profile/settings'
import NewMessage2 from 'views/admin/main/others/NewMessage/NewMessage2'
import Sidebar from "components/sidebar/Sidebar.js";
import Navbar from "components/navbar/NavbarAdmin.js";
import routes from 'routes'
import Footer from 'components/footer/FooterAdmin'


export default function Dashboard(props) {
  const { ...rest } = props

  const [fixed] = useState(false)
  const [toggleSidebar, setToggleSidebar] = useState(false)
  const { onOpen } = useDisclosure();
  const getRoute = () => {
    return window.location.pathname !== '/admin/full-screen-maps'
  }

  const getActiveRoute = (routes) => {
    let activeRoute = "CHAT PAGE";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].items);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else if (routes[i].category) {
        let categoryActiveRoute = getActiveRoute(routes[i].items);
        if (categoryActiveRoute !== activeRoute) {
          return categoryActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };
  const getActiveNavbar = (routes) => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveNavbar = getActiveNavbar(routes[i].items);
        if (collapseActiveNavbar !== activeNavbar) {
          return collapseActiveNavbar;
        }
      } else if (routes[i].category) {
        let categoryActiveNavbar = getActiveNavbar(routes[i].items);
        if (categoryActiveNavbar !== activeNavbar) {
          return categoryActiveNavbar;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].secondary;
        }
      }
    }
    return activeNavbar;
  };
  const getActiveNavbarText = (routes) => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveNavbar = getActiveNavbarText(routes[i].items);
        if (collapseActiveNavbar !== activeNavbar) {
          return collapseActiveNavbar;
        }
      } else if (routes[i].category) {
        let categoryActiveNavbar = getActiveNavbarText(routes[i].items);
        if (categoryActiveNavbar !== activeNavbar) {
          return categoryActiveNavbar;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].messageNavbar;
        }
      }
    }
    return activeNavbar;
  };

  document.documentElement.dir = 'ltr'
  useLanguage()


  const vh = useViewportHeight()
  return (
    <>
      <SidebarContext.Provider
        value={{
          toggleSidebar,
          setToggleSidebar,
        }}
      >
        <Sidebar routes={routes} display='none' {...rest} />
        <Box
          float='right'
          minHeight='100vh'
          height='100%'
          overflow='auto'
          position='relative'
          maxHeight='100%'
          w={{ base: "100%", xl: "calc( 100% - 300px )" }}
          maxWidth={{ base: "100%", xl: "calc( 100% - 300px )" }}
          transition='all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)'
          transitionDuration='.2s, .2s, .35s'
          transitionProperty='top, bottom, width'
          transitionTimingFunction='linear, linear, ease'>
          {/* <Portal>
            <Box>
              <Navbar
                onOpen={onOpen}
                logoText={"Horizon UI Dashboard PRO"}
                brandText={getActiveRoute(routes)}
                secondary={getActiveNavbar(routes)}
                message={getActiveNavbarText(routes)}
                fixed={fixed}
                {...rest}
              />
            </Box>
          </Portal> */}
          {getRoute() ? (
            <>
              {window.location.pathname.includes('/profile') ? (
                <Settings />
              ) : window.location.pathname.includes('/chat-2') ? (
                <Messages />
              ) : window.location.pathname.includes('rewards') ? (
                <Rewards />
              ) : (
                <>

                  <NewMessage2 />
                </>
              )}
            </>
          ) : null}
          <Box>
            {/* <Footer /> */}
          </Box>
        </Box>
      </SidebarContext.Provider>
    </>
  )
}
