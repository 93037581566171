import axios from "axios";
import Cookies from "js-cookie";
const userPublicApi = process.env.REACT_APP_API_URL || "";
const baseAdminApi = process.env.REACT_APP_ADMIN_BASE_API_URL || "";
const DappxAdminApi = process.env.REACT_APP_ADMIN_API_URL || "";

// common config
axios.defaults.headers.post["Content-Type"] = "application/json";

// const baseRequest = axios.create({
//   baseURL: baseAdminApi,
// });

const userPublicRequest = axios.create({
  baseURL: userPublicApi,
});

// const dappxAdminPrivateRequest = axios.create({
//   baseURL: baseAdminApi,
// });
const userPrivateRequest = axios.create({
  baseURL: userPublicApi,
});
// // Add a request interceptor
userPrivateRequest.interceptors.request.use(
  async (config) => {
    const token = Cookies.get("token");
    if (config.headers === undefined) {
      config.headers = {};
    }
    if (token) {
      config.headers = { authorization: `Bearer ${token}` };
    }
    return config;
  },
  (err) => {
    // // console.log"error", err.response);
    Promise.reject(err);
  }
);

const userSuperPrivateRequest = axios.create({
  baseURL: userPublicApi,
});
userSuperPrivateRequest.interceptors.request.use(
  async (config) => {
    
    // // console.log"super protexted");
    const token = Cookies.get("token");
    if (config.headers === undefined) {
      config.headers = {};
    }
    if (token) {
      config.headers = { authorization: `Bearer ${token}` };
    }
    return config;
  },
  (err) => {
    // // console.log"super protexted", err.response);
    if (err?.response?.status === 401 || err?.response?.status === "401") {
      //  logout
      localStorage.clear();
      window.location = "/auth/sigh-in";
    }

    Promise.reject(err);
  }
);

// // Add a response interceptor
// dappxAdminPrivateRequest.interceptors.response.use(
//   function (response) {
//     // Any status code that lie within the range of 2xx cause this function to trigger
//     // Do something with response data
//     return response;
//   },
//   function (error) {
//     // Any status codes that falls outside the range of 2xx cause this function to trigger
//     // Do something with response error
//     console.error("error", error.status);
//     // if (error?.response?.status === 401 || error?.response?.status === "401") {
//     //   //  logout
//     //   localStorage.clear();
//     //   window.location = "/auth/sigh-in"
//     // }

//     return Promise.reject(error);
//   }
// );

export {
  userPrivateRequest,
  // baseAdminRequest,
  userPublicRequest,
  userSuperPrivateRequest,
};
