import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Pack from './Pack'
import { userPrivateRequest } from 'config/axios.config'
import { result } from 'lodash'
import { toast } from 'react-toastify'
import { AuthContext } from 'contexts/AuthContext'
import useCustomHistory from 'langHoc/useCustomHistory'

const PaymentPack = () => {
  const { dispatch, authState } = useContext(AuthContext)
  const router = useCustomHistory()
  const { t, i18n } = useTranslation()
  const [plan, setPlan] = useState()
  const [loading, setLoading] = useState(false)

  const fetchData = async () => {
    try {
      const result = await userPrivateRequest.get('/get-plans')
      setPlan(result?.data?.data[0])
    } catch (err) {}
  }

  useEffect(() => {
    fetchData()
  }, [])


  const billingAgreement = async () => {
    setLoading(true)
    try {
      const result = await userPrivateRequest.post('/subscriptions', {
        planId: plan?.paypalPlanId,
      })
      window.location.href = result.data.data.approval_url
    } catch (err) {
      toast.error(err.message || 'Something went wrong!')
    } finally {
      setLoading(false)
    }
  }

  return (
    <Pack
      title={t('ChinguPro')}
      className="package-styling"
      desc={t('ProDesc')}
      // button={t("ProBtn")}
      button={'Subscribe Now'}
      highlighted
      buttonOnClick={() => {
        if (!authState?.isAuthenticated) {
          router.push('/auth/sign-up')

          return
        }
        if (authState?.membership === 'pro') {
          router.push('/profile')
        } else {
          billingAgreement()
        }

        // router.push("/auth/sign-up");
      }}
      price={plan?.price}
      benefits={[
        t('ProFeature1'),
        t('ProFeature2'),
        t('ProFeature3'),
        t('ProFeature4'),
        t('ProFeature5'),
        t('ProFeature6'),
        t('ProFeature7'),
        t('ProFeature8'),
        // t("ProFeature9"),
        // t("ProFeature11"),
        // t("ProFeature12"),
        // t("ProFeature13"),
        // t("ProFeature14"),
        // t("ProFeature15"),
        // t("ProFeature16"),
        // t("ProFeature17"),
        // t("ProFeature18"),
        // t("ProFeature19"),
      ]}
      pt={'60px'}
    />
  )
}

export default PaymentPack
