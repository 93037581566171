import {
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  useColorModeValue,
  InputRightElement,
  Textarea,
} from "@chakra-ui/react";
import MicV2 from "components/audio/MicV2";
import { PagiContext } from "contexts/PagiContext";
import React, { useContext, useState } from "react";
import { FiSearch } from "react-icons/fi";
export function SearchBar(props) {
  // Pass the computed styles into the `__css` prop
  const {
    variant,
    background,
    children,
    placeholder,
    borderRadius,
    setQuestion,
    question,
    setMicModal,
    micModal,
    textareaEnterHandler,
    handleSend,
    selectedPrompt,
    submitHandler,
    ...rest
  } = props;
  // Chakra Color Mode

  const [recording, setRecording] = useState(false);
  const [auto, setAuto] = useState(true);
  const color = useColorModeValue("#a0aec0", "white");
  const searchIconColor = useColorModeValue("gray.700", "white");
  const inputBg = useColorModeValue("secondaryGray.300", "navy.900");
  const inputText = useColorModeValue("gray.700", "gray.100");

  let pagiContextHandler = useContext(PagiContext);
  return (
    <InputGroup
      // w={{ base: "100%", md: "200px" }}

      {...rest}
    >
      <InputLeftElement
        children={
          <IconButton
            bg="inherit"
            borderRadius="inherit"
            _hover="none"
            _active={{
              bg: "inherit",
              transform: "none",
              borderColor: "transparent",
            }}
            _focus={{
              boxShadow: "none",
            }}
            icon={
              <Icon as={FiSearch} color={searchIconColor} w="15px" h="15px" />
            }
            onClick={handleSend}
          />
        }
      />
      <Input
        variant="search"
        fontSize="12px"
        bg={background ? background : inputBg}
        color={inputText}
        fontWeight="500"
        _placeholder={{ color: "gray.400", fontSize: "14px" }}
        borderRadius={borderRadius ? borderRadius : "30px"}
        placeholder={placeholder ? placeholder : "Search..."}
        onChange={(e) => {
          setQuestion(e.target.value);
        }}
        onKeyDown={textareaEnterHandler}
        value={question}
      />
      <InputRightElement
        children={
          <MicV2
            audioTextBack={(text) => {
              console.log("Audio call", text);
              submitHandler(null,text , "text", true);
              // setQuestion(text);
            }}
            recording={recording}
            setRecording={setRecording}
            setMicModal={setMicModal}
            micModal={micModal}
            // hideIcon={true}
            question={question}
            setQuestion={setQuestion}
            auto={question ? false : auto}
            setAuto={setAuto}
            isSetQuestion={Object.keys(selectedPrompt).length ? true : false}
            w="15px"
            h="15px"
            color={color}
          />
        }
      />
    </InputGroup>
  );
}
