// Chakra imports
import {
  Box,
  Button,
  DarkMode,
  Flex,
  Heading,
  LightMode,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Spinner,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import Card from "components/card/Card.js";
import GetPaymentConfirmation from "components/new/GetPaymentConfirmation";
import { userPrivateRequest } from "config/axios.config";
import { AuthContext } from "contexts/AuthContext";
import { ConfigContext } from "contexts/ConfigContext";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaAws, FaStar } from "react-icons/fa";
import { toast } from "react-toastify";

export default function TopUpCredit() {

  const { t } = useTranslation()
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const [lists, setLists] = useState([]);
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [active, setActive] = useState('');

  let config = useContext(ConfigContext)
  const authData = useContext(AuthContext)
  let membership = authData?.authState?.membership
  const fetchInfo = async () => {
    try {
      const result = await userPrivateRequest.get(
        "/credit-packages/list?status=active"
      );
      setLists(result?.data?.data?.docs);
    } catch (err) {
      toast.error(err?.message);
    } finally {
      setLoading(false);
    }
  };

  const handlePackageClick = async () => {
    setSubmitting(true);
    try {
      const result = await userPrivateRequest
        .post("/credit/topUp", {
          id: active,
        })
        .then((response) => {
          let url = response.data?.url;
          window.location.replace(url);

          setSubmitting(false);
        });
    } catch (err) {
      toast.error(err?.message);
    } finally {
      setLoading(false);
    }
  };

  const stripeBillingAgreement = async (id) => {
    setSubmitting(true);
    try {
      const result = await userPrivateRequest
        .post("/credit/stripe-topUp", {
          id: id,
        })
        .then((response) => {
          let url = response.data?.url;
          window.location.replace(url);

          setSubmitting(false);
        });
    } catch (err) {
      toast.error(err?.message);
    } finally {
      setLoading(false);
    }
  }

  const handleActivePackageClick = async (id) => {
    setActive(id)
  };

  useEffect(() => {
    fetchInfo();
  }, []);

  const onClose = () => {
    setActive('')
  }



  const colors = config?.config?.global?.COLORS?.value ?? {};
  const primaryColor = useColorModeValue(
    colors?.primaryColor ? colors?.primaryColor : "navy.700",
    colors?.primaryDarkColor ? colors?.primaryDarkColor : "white"
  );
  const primaryHoverColor = useColorModeValue(
    colors?.primaryHoverColor ? colors?.primaryHoverColor : "navy.700",
    colors?.primaryDarkHoverColor ? colors?.primaryDarkHoverColor : "white"
  );
  

  let creditLabel =
    config?.config?.global?.CREDIT_CONFIGURATION?.value?.creditLabel ?? "CHI";
  return (
    <>

      {/* <Modal isOpen={active} onClose={onClose} w={'700px'}>
        <ModalOverlay />
        <ModalContent mx='8px' >
          <ModalBody p='0px' >

            <Box d="flex" gap={'20px'} p={'50px'}>

            <Box border="1px solid #ddd" p="20px" cursor={'pointer'} onClick={() => stripeBillingAgreement(active)}>
              <Heading fontSize={'20px'}> Pay Using Card </Heading>
            </Box>

            <Box border="1px solid #ddd" p="20px" cursor={'pointer'} onClick={handlePackageClick}>
              <Heading fontSize={'20px'}> Pay Using PayPal </Heading>
            </Box>
            </Box>
            
          </ModalBody>

          <DarkMode>
            <ModalCloseButton  zIndex='99' />
          </DarkMode>{" "}
        </ModalContent>
      </Modal> */}
      {active && (
        <GetPaymentConfirmation 
        onClose={() => onClose()} 
        stripeCall={() =>  stripeBillingAgreement(active)} 
        paypalCall={handlePackageClick}
        />
      )}
      <Card mt={"20px"}>
        <Text
          fontSize="xl"
          color={textColorPrimary}
          mb="15px"
          fontWeight="bold"
        >
          {t('TopUpCredit')}
        </Text>
        {loading && <Text>Loading</Text>}

        {!loading && (
          <Box d="flex" gap="20px" flexWrap={"wrap"}>
            {lists?.map((lst) => {
              return (
                <Box
                  className={`single `}
                  onClick={() => {
                    // stripeBillingAgreement(lst._id);
                    handleActivePackageClick(lst._id);
                  }}
                  bg={primaryColor}
                  _hover={{
                    bg: primaryHoverColor,
                    // bg: '#303030'
                  }}
                >
                  <div className="single-thumb">
                    <div className="left">
                      <img src={lst?.image} width={24} alt="" />
                    </div>
                  </div>

                  <div className="single-content">
                    <div>
                      <h2 style={{ color: "#fff" }}>
                        {(lst?.credit).toLocaleString()} {creditLabel}
                      </h2>
                      <h3 style={{ color: "#fff" }}>${lst?.usdPrice} </h3>
                    </div>
                  </div>
                </Box>
              );
            })}
          </Box>
        )}

        {/* {submitting && (
          <Box d="flex" gap="20px" flexWrap={"wrap"}>
            <Spinner />
          </Box>
        )} */}
      </Card>

      <style jsx>{`
        .single.active {
          // background: #ccc8c8ba;
        }
        .single {
          // background: #fff;
          padding: 24px;
          cursor: pointer;
          width: 100%;
          height: 100%;
          border-radius: 1rem;
          // box-shadow: rgb(255, 255, 255) 0px 0px 0px 0px, rgb(229, 231, 235) 0px 0px 0px 1px, rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
          // border: 1px solid #e5e7eb;
          transition: 0.3 linear;
          width: calc(33.33% - 20px);
        }
        .single:hover .single-thumb > .right {
          opacity: 0.6;
        }
        .single-thumb > .right {
          opacity: 0;
        }
        .single-thumb > .left {
          width: 64px;
          height: 64px;
          background: rgb(243 244 246 / 75%);
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .left img {
          width: 24px;
        }
        .single-thumb {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 1.5rem;
        }
        .single-thumb img {
          //   width: 100%;
          //   object-fit: cover;
          //   border-radius: 8px;
          //   height: 100%;
        }
        .single-content {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }
        .details_content h2,
        .single h2 {
          color: #27272a;
          font-weight: 500;
          font-size: 1.125rem;
          line-height: 1.75rem;
          margin-bottom: 10px;
        }
        .details_content h3,
        .single h3 {
          color: #707078;
          font-weight: 500;
          font-size: 1rem;
          line-height: 1.5rem;
          margin-bottom: 0px;
          -webkit-line-clamp: 2;
          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
        }
        .single .macro-text {
          display: flex;
          align-items: center;
          gap: 0px 4px;
          margin-bottom: 10px;
          min-height: 24px;
        }
        .single .macro-text .icon {
        }
        .single .macro-text .icon svg {
          color: #9b9ca9;
          fill: #9b9ca9;
          stroke: #9b9ca9;
        }
        .closeIcon {
          position: absolute;
          right: 10px;
          top: 10px;
          z-index: 9;
          cursor: pointer;
        }

        .single .macro-text p {
          color: #707078;
          font-weight: 400;
          font-size: 0.875rem;
          line-height: 1.5rem;
          margin-bottom: 0px;
          display: flex;
          align-items: center;
          gap: 0px 4px;
        }

        @media (max-width: 340px) {
          .single {
            padding: 20px 15px;
          }
        }
      `}</style>
    </>
  );
}
