// Chakra imports
import {
  Box,
  Flex,
  SimpleGrid,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { ConfigContext } from "contexts/ConfigContext";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import Footer from "components/footer/FooterAdmin.js";

export default function ChinguTerms(props) {
  const platform = process.env?.REACT_APP_PLATFORM;
  let configs = useContext(ConfigContext);
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const history = useHistory();
  const { t } = useTranslation();
  function handleClick() {
    history.push("/"); // navigate to the main page
  }
  return (
    <>
      <div>
        <img
          src={configs?.logo}
          alt=""
          width="300px"
          style={{ display: "block", margin: "30px auto", cursor: "pointer" }}
          onClick={handleClick}
        />
        <Flex
          direction="column"
          alignSelf="center"
          justifySelf="center"
          overflow="hidden"
          id="__etaaaa"
        >
          <Flex
            direction="column"
            textAlign="center"
            justifyContent="center"
            align="center"
          >
            {/* <Text
              color={textColor}
              fontWeight="bold"
              fontSize="34px"
              mb="30px"
            >
              {t("ChinguAI")}
            </Text> */}
            <Text
              color={textColor}
              fontWeight="bold"
              fontSize={{ base: "24px", xl: "34px" }}
              mb="30px"
            >
              {t("TermHead")}
            </Text>
            <SimpleGrid
              columns={{ md: "1", lg: "1" }}
              mx="auto"
              width={{ base: "95%", lg: "80%", "2xl": "1170px" }}
              maxW={"100%"}
              padding={{ base: "0px 20px", xl: "0px 30px" }}
              id="WP___terms"
            >
              <Box>
                <Box>
                  <Text
                    textAlign="start"
                    color={textColor}
                    fontWeight="500"
                    fontSize="2xl"
                    mb="30px"
                  >
                    {t("TermQ1")}
                  </Text>
                  <Text
                    textAlign="start"
                    color="secondaryGray.600"
                    fontWeight="500"
                    fontSize="md"
                    mb="30px"
                    dangerouslySetInnerHTML={{ __html: t("TermA1") }}
                  ></Text>
                </Box>
                <Box>
                  <Text
                    textAlign="start"
                    color={textColor}
                    fontWeight="500"
                    fontSize="2xl"
                    mb="30px"
                  >
                    {t("TermQ2")}
                  </Text>
                  <Text
                    textAlign="start"
                    color="secondaryGray.600"
                    fontWeight="500"
                    fontSize="md"
                    mb="30px"
                    dangerouslySetInnerHTML={{ __html: t("TermA2") }}
                  ></Text>
                </Box>
                <Box>
                  <Text
                    textAlign="start"
                    color={textColor}
                    fontWeight="500"
                    fontSize="2xl"
                    mb="30px"
                  >
                    {t("TermQ3")}
                  </Text>
                  <Text
                    textAlign="start"
                    color="secondaryGray.600"
                    fontWeight="500"
                    fontSize="md"
                    mb="30px"
                    dangerouslySetInnerHTML={{ __html: t("TermA3") }}
                  ></Text>
                </Box>
                <Box>
                  <Text
                    textAlign="start"
                    color={textColor}
                    fontWeight="500"
                    fontSize="2xl"
                    mb="30px"
                  >
                    {t("TermQ4")}
                  </Text>
                  <Text
                    textAlign="start"
                    color="secondaryGray.600"
                    fontWeight="500"
                    fontSize="md"
                    mb="30px"
                    dangerouslySetInnerHTML={{ __html: t("TermA4") }}
                  ></Text>
                </Box>

                <Box>
                  <Text
                    textAlign="start"
                    color={textColor}
                    fontWeight="500"
                    fontSize="2xl"
                    mb="30px"
                  >
                    {t("TermQ5")}
                  </Text>
                  <Text
                    textAlign="start"
                    color="secondaryGray.600"
                    fontWeight="500"
                    fontSize="md"
                    mb="30px"
                    dangerouslySetInnerHTML={{ __html: t("TermA5") }}
                  ></Text>
                </Box>
              </Box>
              <Box>
                <Box>
                  <Text
                    textAlign="start"
                    color={textColor}
                    fontWeight="500"
                    fontSize="2xl"
                    mb="30px"
                  >
                    {t("TermQ6")}
                  </Text>
                  <Text
                    textAlign="start"
                    color="secondaryGray.600"
                    fontWeight="500"
                    fontSize="md"
                    mb="30px"
                    dangerouslySetInnerHTML={{ __html: t("TermA6") }}
                  ></Text>
                </Box>
                <Box>
                  <Text
                    textAlign="start"
                    color={textColor}
                    fontWeight="500"
                    fontSize="2xl"
                    mb="30px"
                  >
                    {t("TermQ7")}
                  </Text>
                  <Text
                    textAlign="start"
                    color="secondaryGray.600"
                    fontWeight="500"
                    fontSize="md"
                    mb="30px"
                    dangerouslySetInnerHTML={{ __html: t("TermA7") }}
                  ></Text>
                </Box>
                <Box>
                  <Text
                    textAlign="start"
                    color={textColor}
                    fontWeight="500"
                    fontSize="2xl"
                    mb="30px"
                  >
                    {t("TermQ8")}
                  </Text>
                  <Text
                    textAlign="start"
                    color="secondaryGray.600"
                    fontWeight="500"
                    fontSize="md"
                    mb="30px"
                    dangerouslySetInnerHTML={{ __html: t("TermA8") }}
                  ></Text>
                </Box>
                <Box>
                  <Text
                    textAlign="start"
                    color={textColor}
                    fontWeight="500"
                    fontSize="2xl"
                    mb="30px"
                  >
                    {t("TermQ9")}
                  </Text>
                  <Text
                    textAlign="start"
                    color="secondaryGray.600"
                    fontWeight="500"
                    fontSize="md"
                    mb="30px"
                    dangerouslySetInnerHTML={{ __html: t("TermA9") }}
                  ></Text>
                </Box>
                <Box>
                  <Text
                    textAlign="start"
                    color={textColor}
                    fontWeight="500"
                    fontSize="2xl"
                    mb="30px"
                  >
                    {t("TermQ10")}
                  </Text>
                  <Text
                    textAlign="start"
                    color="secondaryGray.600"
                    fontWeight="500"
                    fontSize="md"
                    mb="30px"
                    dangerouslySetInnerHTML={{ __html: t("TermA10") }}
                  ></Text>
                </Box>
                <Box>
                  <Text
                    textAlign="start"
                    color={textColor}
                    fontWeight="500"
                    fontSize="2xl"
                    mb="30px"
                  >
                    {t("TermQ11")}
                  </Text>
                  <Text
                    textAlign="start"
                    color="secondaryGray.600"
                    fontWeight="500"
                    fontSize="md"
                    mb="30px"
                    dangerouslySetInnerHTML={{ __html: t("TermA11") }}
                  ></Text>
                </Box>
                <Box>
                  <Text
                    textAlign="start"
                    color={textColor}
                    fontWeight="500"
                    fontSize="2xl"
                    mb="30px"
                  >
                    {t("TermQ12")}
                  </Text>
                  <Text
                    textAlign="start"
                    color="secondaryGray.600"
                    fontWeight="500"
                    fontSize="md"
                    mb="30px"
                    dangerouslySetInnerHTML={{ __html: t("TermA12") }}
                  ></Text>
                </Box>
                <Box>
                  <Text
                    textAlign="start"
                    color={textColor}
                    fontWeight="500"
                    fontSize="2xl"
                    mb="30px"
                  >
                    {t("TermQ13")}
                  </Text>
                  <Text
                    textAlign="start"
                    color="secondaryGray.600"
                    fontWeight="500"
                    fontSize="md"
                    mb="30px"
                    dangerouslySetInnerHTML={{ __html: t("TermA13") }}
                  ></Text>
                </Box>
                <Box>
                  <Text
                    textAlign="start"
                    color={textColor}
                    fontWeight="500"
                    fontSize="2xl"
                    mb="30px"
                  >
                    {t("TermQ14")}
                  </Text>
                  <Text
                    textAlign="start"
                    color="secondaryGray.600"
                    fontWeight="500"
                    fontSize="md"
                    mb="30px"
                    dangerouslySetInnerHTML={{ __html: t("TermA14") }}
                  ></Text>
                </Box>
                <Box>
                  <Text
                    textAlign="start"
                    color={textColor}
                    fontWeight="500"
                    fontSize="2xl"
                    mb="30px"
                  >
                    {t("TermQ15")}
                  </Text>
                  <Text
                    textAlign="start"
                    color="secondaryGray.600"
                    fontWeight="500"
                    fontSize="md"
                    mb="30px"
                    dangerouslySetInnerHTML={{ __html: t("TermA15") }}
                  ></Text>
                </Box>
                <Box>
                  <Text
                    textAlign="start"
                    color={textColor}
                    fontWeight="500"
                    fontSize="2xl"
                    mb="30px"
                  >
                    {t("TermQ16")}
                  </Text>
                  <Text
                    textAlign="start"
                    color="secondaryGray.600"
                    fontWeight="500"
                    fontSize="md"
                    mb="30px"
                    dangerouslySetInnerHTML={{ __html: t("TermA16") }}
                  ></Text>
                </Box>
                <Box>
                  <Text
                    textAlign="start"
                    color={textColor}
                    fontWeight="500"
                    fontSize="2xl"
                    mb="30px"
                  >
                    {t("TermQ17")}
                  </Text>
                  <Text
                    textAlign="start"
                    color="secondaryGray.600"
                    fontWeight="500"
                    fontSize="md"
                    mb="30px"
                    dangerouslySetInnerHTML={{ __html: t("TermA17") }}
                  ></Text>
                </Box>
                <Box>
                  <Text
                    textAlign="start"
                    color={textColor}
                    fontWeight="500"
                    fontSize="2xl"
                    mb="30px"
                  >
                    {t("TermQ18")}
                  </Text>
                  <Text
                    textAlign="start"
                    color="secondaryGray.600"
                    fontWeight="500"
                    fontSize="md"
                    mb="30px"
                    dangerouslySetInnerHTML={{ __html: t("TermA18") }}
                  ></Text>
                </Box>
                <Box>
                  <Text
                    textAlign="start"
                    color={textColor}
                    fontWeight="500"
                    fontSize="2xl"
                    mb="30px"
                  >
                    {t("TermQ19")}
                  </Text>
                  <Text
                    textAlign="start"
                    color="secondaryGray.600"
                    fontWeight="500"
                    fontSize="md"
                    mb="30px"
                    dangerouslySetInnerHTML={{ __html: t("TermA19") }}
                  ></Text>
                </Box>
                <Box>
                  <Text
                    textAlign="start"
                    color={textColor}
                    fontWeight="500"
                    fontSize="2xl"
                    mb="30px"
                  >
                    {t("TermQ20")}
                  </Text>
                  <Text
                    textAlign="start"
                    color="secondaryGray.600"
                    fontWeight="500"
                    fontSize="md"
                    mb="30px"
                    dangerouslySetInnerHTML={{ __html: t("TermA20") }}
                  ></Text>
                </Box>
                <Box>
                  <Text
                    textAlign="start"
                    color={textColor}
                    fontWeight="500"
                    fontSize="2xl"
                    mb="30px"
                  >
                    {t("TermQ21")}
                  </Text>
                  <Text
                    textAlign="start"
                    color="secondaryGray.600"
                    fontWeight="500"
                    fontSize="md"
                    mb="30px"
                    dangerouslySetInnerHTML={{ __html: t("TermA21") }}
                  ></Text>
                </Box>
                <Box>
                  <Text
                    textAlign="start"
                    color={textColor}
                    fontWeight="500"
                    fontSize="2xl"
                    mb="30px"
                  >
                    {t("TermQ22")}
                  </Text>
                  <Text
                    textAlign="start"
                    color="secondaryGray.600"
                    fontWeight="500"
                    fontSize="md"
                    mb="30px"
                    dangerouslySetInnerHTML={{ __html: t("TermA22") }}
                  ></Text>
                </Box>
                <Box>
                  <Text
                    textAlign="start"
                    color={textColor}
                    fontWeight="500"
                    fontSize="2xl"
                    mb="30px"
                  >
                    {t("TermQ23")}
                  </Text>
                  <Text
                    textAlign="start"
                    color="secondaryGray.600"
                    fontWeight="500"
                    fontSize="md"
                    mb="30px"
                    dangerouslySetInnerHTML={{ __html: t("TermA23") }}
                  ></Text>
                </Box>
                <Box>
                  <Text
                    textAlign="start"
                    color={textColor}
                    fontWeight="500"
                    fontSize="2xl"
                    mb="30px"
                  >
                    {t("TermQ24")}
                  </Text>
                  <Text
                    textAlign="start"
                    color="secondaryGray.600"
                    fontWeight="500"
                    fontSize="md"
                    mb="30px"
                    dangerouslySetInnerHTML={{ __html: t("TermA24") }}
                  ></Text>
                </Box>
                <Box>
                  <Text
                    textAlign="start"
                    color={textColor}
                    fontWeight="500"
                    fontSize="2xl"
                    mb="30px"
                  >
                    {t("TermQ25")}
                  </Text>
                  <Text
                    textAlign="start"
                    color="secondaryGray.600"
                    fontWeight="500"
                    fontSize="md"
                    mb="30px"
                    dangerouslySetInnerHTML={{ __html: t("TermA25") }}
                  ></Text>
                </Box>
                <Box>
                  <Text
                    textAlign="start"
                    color={textColor}
                    fontWeight="500"
                    fontSize="2xl"
                    mb="30px"
                  >
                    {t("TermQ26")}
                  </Text>
                  <Text
                    textAlign="start"
                    color="secondaryGray.600"
                    fontWeight="500"
                    fontSize="md"
                    mb="30px"
                    dangerouslySetInnerHTML={{ __html: t("TermA26") }}
                  ></Text>
                </Box>
              </Box>
            </SimpleGrid>
          </Flex>
        </Flex>
        <Footer />
      </div>
    </>
  );
}
