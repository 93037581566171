// Chakra imports
import {
  Box,
  Button,
  Flex,
  Image,
  List,
  SkeletonText,
  Text,
  useColorModeValue,
} from '@chakra-ui/react'
import 'react-loading-skeleton/dist/skeleton.css'
import logoImage from 'assets/img/favicon-3.png'
import { AuthContext } from 'contexts/AuthContext'
import { useContext } from 'react'
import { ConfigContext } from 'contexts/ConfigContext'
import * as marked from 'marked';

import DOMPurify from 'dompurify';
import EditorMDComponent from './EditorMDComponent'
import MDEditor from "@uiw/react-md-editor";
import { ListItem, Link } from '@chakra-ui/react';
import mermaid from "mermaid";
import { useState } from 'react'
import { useEffect } from 'react'
import { userPrivateRequest } from 'config/axios.config'
import { ImSpinner2 } from 'react-icons/im'
import { FaCheckCircle, FaUserCircle } from "react-icons/fa";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
mermaid.initialize({ startOnLoad: false });




const FileLinkItem = ({ file, type }) => {
  const [downloadUrl, setDownloadUrl] = useState('');
  // console.log(file, 39393939)
  useEffect(() => {
    if (type !== 'question') {
      fetchDownloadUrl();
    }

  }, [file]);

  const fetchDownloadUrl = async () => {
    try {
      const response = await userPrivateRequest(`/assistant/file/getFileDownloadLink/${file.file_path.file_id}`);

      setDownloadUrl(response?.data?.data);
    } catch (error) {
      console.error('Error fetching file download URL:', error);
      // Handle error appropriately
    }
  };

  return (

    <>
      {type === 'question' && (
        <ListItem marginBottom={'10px'}>
          <Link href={downloadUrl} isExternal color={'#0969da'} bg="#e4e8ec" p="3px 10px" display={'inline-block'} borderRadius={'5px'} fontSize={'13px'}>
            {file.name}
          </Link>
        </ListItem>
      )}
      {type === 'answer' && (
        <ListItem marginBottom={'10px'}>
          <Link href={downloadUrl} isExternal color={'#0969da'} bg="#e4e8ec" p="3px 10px" display={'inline-block'} borderRadius={'5px'} fontSize={'13px'}>
            {file?.text?.replace('sandbox:/mnt/data/', '')}
          </Link>
        </ListItem>
      )}

    </>

  );
};




const Code = ({ inline, children = [], className, ...props }) => {
  const [svgContent, setSvgContent] = useState("");

  const code = getCode(children);

  useEffect(() => {
    if (/^language-mermaid/.test(className?.toLocaleLowerCase() || "")) {
      try {
        mermaid.render(
          "tempId",
          code,
          (svg) => setSvgContent(svg),
          null
        );
      } catch (error) {
        setSvgContent(`Error rendering diagram: ${error.message}`);
      }
    }
  }, [code, className]);

  if (svgContent) {
    return <div dangerouslySetInnerHTML={{ __html: svgContent }} />;
  }
  return <code className={String(className)}>{children}</code>;
};

const getCode = (arr = []) =>
  arr
    ?.map((dt) => {
      if (typeof dt === "string") {
        return dt;
      }
      if (dt.props && dt.props.children) {
        return getCode(dt.props.children);
      }
      return false;
    })
    .filter(Boolean)
    .join("");


export default function MessageBlockAssistant(props) {
  const authData = useContext(AuthContext)
  const configData = useContext(ConfigContext)
  const config = configData.config || {}
  const {
    content,
    time,
    side,
    isLast,
    seen,
    load,
    google,
    googleHandler,
    responseType,
    copyHandler,
    criteria,
    extra,
    fullLoaded,
    // files,
    ...rest
  } = props
  // console.log(props, 129129129)
  const [isAssistant, setIsAssistant] = useState(false)

  const [contentObj, setContentObj] = useState([])
  const [activeIds, setActiveIds] = useState([])
  // Example usage
  const [questionObj, setQuestionObj] = useState([])
  const [files, setFiles] = useState([])
  useEffect(() => {
    // console.log(responseType, 129129129)
    if (responseType === 'answer') {
      // console.log(129129129)
      const parseContent = JSON.parse(content)
      setContentObj(JSON.parse(content))

      const files = []

      if (parseContent && parseContent?.length > 0) {
        parseContent.forEach(pc => {
          if (pc?.annotations && pc?.annotations?.length) {
            files.push(...pc?.annotations)
          }

        })
      }

      setFiles(files)
    }

    if (responseType === 'question') {
      const parseContent = JSON.parse(content)
      setQuestionObj(parseContent)



    }


  }, [content, responseType])
  // console.log(contentObj, 129129129)
  const textColor = useColorModeValue("navy.700", "white");
  if (fullLoaded) {
    // // console.log(JSON.stringify(content), 'openai response checking with Boss Sean')
  }

  // // console.log(contentObj, 'Assistant body')

  let finalContent = content
  if (responseType == 'question') {
    if (criteria == 'blog') {
      finalContent = 'Blog Post Creation '
    } else if (criteria == 'blog-pro') {
      finalContent = 'Blog Post Creation  Pro'
    } else if (criteria == 'news') {
      finalContent = 'Video Script Creation'
    } else if (criteria == 'news-pro') {
    } else if (criteria == 'survey') {
      finalContent = 'Survey Result Summary'
    } else if (criteria == 'news-pro') {
      finalContent = 'Video Script Creation Pro'
    } else if (criteria == 'deep-studies') {
      finalContent = 'Perform Deep Studies'
    }
  }
  if (responseType === "answer" && !fullLoaded && !load)
    finalContent += ` <span className="typing-indicator">
  <span></span>
  <span></span>
  <span></span>
</span>`;
  // console.log(finalContent, 'finalContent')
  // Convert markdown to HTML using `marked`
  const rawHtml = marked.parse(finalContent);
  // console.log(rawHtml, 'finalContent rawHtml')
  // Sanitize the HTML using `DOMPurify`
  // const cleanHtml = DOMPurify.sanitize(rawHtml);
  const cleanHtml = DOMPurify.sanitize(rawHtml, {
    ADD_ATTR: ['target'],
    FORBID_TAGS: ['style'], // Example: if you want to forbid certain tags
  });
  // console.log(cleanHtml, 'finalContent cleanHtml')

  // const rawHtmlInput = marked.parse(contentObj?.input);
  // const cleanHtmlInput = DOMPurify.sanitize(rawHtmlInput, {
  //   ADD_ATTR: ['target'],
  //   FORBID_TAGS: ['style'], // Example: if you want to forbid certain tags
  // });
  if (fullLoaded) {
    // console.log(cleanHtml, 'HTML CHECK')
  }

  const renderFiles = () => {
    // console.log(files, 'contentObj contentObj')
    return (
      <List ml="64px">
        
        {files?.map((file, index) => (
          <FileLinkItem key={index} file={file} type={'answer'} />
        ))}
      </List>
    );
  };


  // console.log(contentObj, files, 'contentObj contentObj')

  const toggleEditor = (id) => {
    if (activeIds?.find(sai => sai === id)) {
      setActiveIds(activeIds.filter(aid => aid !== id))
    } else {
      const updateActives = [...activeIds, id]
      setActiveIds(updateActives)
    }
  }
  let toopCall = ''

  if (contentObj && contentObj.length > 0) {
    let filterArray = contentObj.filter(cto => cto.type === 'tool_call')
    toopCall = filterArray[filterArray.length - 1]?.refId
  }

  // console.log(toopCall, 'toopCall toopCall')

  return (

    <>
      <Box
        borderRadius={
          side === 'left' ? '20px 20px 20px 20px' : '20px 20px 20px 20px'
        }
        bg={side === 'left' ? 'transparent' : 'transparent'}
        justifyContent="space-between"
        // alignItems="center"
        w={{ base: '100%', lg: '100%', xl: '100%' }}
        {...rest}
        pt={responseType === 'question' ? '10px' : '0px'}
        pb="9px"
        display="flex"
        alignItems="flex-start"
        flexWrap={'wrap'}
        className={
          responseType === 'question' ? 'question-type' : 'question-anwser'
        }
        color={textColor}

      >
        {responseType === 'question' && (
          <div style={{ marginRight: '20px' }}>
            {authData?.authState?.user?.photo && (
              <Box w="30px" h="30px" borderRadius={'4px'} color={textColor}>
                <Image
                  src={authData?.authState?.user?.photo}
                  borderRadius={'4px'}
                  height={'100%'}
                  alt="logoImage"
                  width="100%"
                />
              </Box>
            )}

            {!authData?.authState?.user?.photo && (
              <>
                <Box
                  w="30px"
                  h="30px"
                  // bg={'#121212'}
                  d="flex"
                  alignItems="center"
                  justifyContent="center"
                  borderRadius={'4px'}
                  color={textColor}
                >
                  <FaUserCircle color="#121212" size={'30px'} />
                </Box>


              </>

            )}
          </div>
        )}

        {responseType !== 'question' && (
          <Box
            w="30px"
            h="30px"
            // bg={"green"}
            d="flex"
            alignItems="center"
            justifyContent="center"
            // borderRadius={"4px"}
            // color="#fff"
            mr="20px"
          >
            <img
              src={
                config?.global?.CHAT_PAGE_CONFIG?.value?.file
                  ? config?.global?.CHAT_PAGE_CONFIG?.value?.file
                  : logoImage
              }
              alt="logoImage"
              width="30px"
            />
          </Box>
        )}

        <Box w="calc(100% - 64px)">

          {/* {responseType === 'answer' && (
            <Box w="100%" d='flex' alignItems={'center'} marginBottom={'30px'} gap={'10px'}>
              <FaCheckCircle />   Analyzing Finished
               <FaCheckCircle />  
               <ImSpinner2 className="spinner" />     Analyzing 
            </Box>
          )} */}


          <Text
            color={textColor}
            fontSize={{ base: 'md', '2xl': 'md' }}
            me="6px"
            fontFamily="'Noto Sans', sans-serif"
            fontWeight="400"
            // display={"flex"}
            w="calc(100% - 60px)"
          // border={'1px solid #dee2e6'}
          >

            {contentObj && contentObj.length > 0 && contentObj?.map(co => {

              if (co.type === "message")
                return (
                  <MDEditor
                    // onChange={(newValue = "") => setValue(newValue)}
                    textareaProps={{
                      placeholder: "Please enter Markdown text"
                    }}
                    height={500}
                    value={co?.content}
                    data-color-mode='light'
                    className='response-markdown'
                    previewOptions={{
                      components: {
                        code: Code
                      }
                    }}
                  />
                )

              if (co.type === "tool_call") {
                return (
                  <Box marginBottom={'20px'}>

                    <Box w="100%" d='flex' alignItems={'center'} gap={'10px'}>
                      <Button p="0"
                        fontSize={'16px'} fontWeight={'400'} background={'transparent'} d="flex" gap="10px" color={textColor} onClick={() => toggleEditor(co?.refId)} _hover={{
                          background: 'transparent'
                        }}
                        _active={{
                          background: 'transparent'
                        }}
                        _focus={{
                          background: 'transparent'
                        }}
                      >
                        {fullLoaded && (
                          <>
                            <FaCheckCircle className='cycle-bottom' />
                            <Text className='text-mb-0' marginBottom="0px" fontFamily="-apple-system, 'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji'">
                              {'Completed'}
                            </Text>   {activeIds?.find(aid => aid === co?.refId) ? (<MdKeyboardArrowUp />) : (<MdKeyboardArrowDown />)}
                          </>
                        )}
                        {co?.analyzeStatus === 'completed' && !fullLoaded && (
                          <>
                            {toopCall === co?.refId && !fullLoaded ? <ImSpinner2 className="spinner" /> : <FaCheckCircle className='cycle-bottom' />}


                            <Text className='text-mb-0' marginBottom="0px" fontFamily="-apple-system, 'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji'">
                              {toopCall === co?.refId && !fullLoaded ? 'Summarizing' : 'Completed'}
                            </Text>   {activeIds?.find(aid => aid === co?.refId) ? (<MdKeyboardArrowUp />) : (<MdKeyboardArrowDown />)}
                          </>
                        )}
                        {co?.analyzeStatus !== 'completed' && !fullLoaded && (
                          <>
                            <ImSpinner2 className="spinner" />     Analyzing   {activeIds?.find(aid => aid === co?.refId) ? (<MdKeyboardArrowUp />) : (<MdKeyboardArrowDown />)}
                          </>
                        )}

                      </Button>

                    </Box>

                    {activeIds?.find(aid => aid === co?.refId) && (
                      <>
                        {co?.input && (
                          <MDEditor
                            textareaProps={{
                              placeholder: "Please enter Markdown text"
                            }}
                            height={500}
                            value={co?.input}
                            data-color-mode='light'
                            className='response-markdown'
                            previewOptions={{
                              components: {
                                code: Code
                              }
                            }}
                          />
                        )}

                        {co?.output && (
                          <MDEditor
                            // onChange={(newValue = "") => setValue(newValue)}
                            textareaProps={{
                              placeholder: "Please enter Markdown text"
                            }}
                            height={500}
                            value={co?.output}
                            data-color-mode='light'
                            className='response-markdown'
                            previewOptions={{
                              components: {
                                code: Code
                              }
                            }}
                          />
                        )}

                      </>
                    )}


                  </Box>
                )
              }
            })}


            {responseType === 'question' && (
              <MDEditor
                // onChange={(newValue = "") => setValue(newValue)}
                textareaProps={{
                  placeholder: "Please enter Markdown text"
                }}
                height={500}
                value={questionObj?.message}
                data-color-mode='light'
                className='response-markdown'
                previewOptions={{
                  components: {
                    code: Code
                  }
                }}
              />
            )}

            {responseType === 'question' && (
              <>
                <List marginLeft={'0px !important'} marginTop={'10px'}>
                  {questionObj?.files?.map((file, index) => (
                    <FileLinkItem key={index} file={file} type={'question'} />
                  ))}
                </List>
              </>
            )}
            {responseType !== 'question' && (
              // <EditorMDComponent val={content} />

              <MDEditor
                // onChange={(newValue = "") => setValue(newValue)}
                textareaProps={{
                  placeholder: "Please enter Markdown text"
                }}
                height={500}
                value={contentObj?.input}
                data-color-mode='light'
                className='response-markdown'
                previewOptions={{
                  components: {
                    code: Code
                  }
                }}
              />
            )}

            {responseType !== 'question' && (
              // <EditorMDComponent val={content} />

              <MDEditor
                // onChange={(newValue = "") => setValue(newValue)}
                textareaProps={{
                  placeholder: "Please enter Markdown text"
                }}
                height={500}
                value={contentObj?.output}
                data-color-mode='light'
                className='response-markdown'
                previewOptions={{
                  components: {
                    code: Code
                  }
                }}
              />
            )}

            {responseType !== 'question' && (
              // <EditorMDComponent val={content} />

              <MDEditor
                // onChange={(newValue = "") => setValue(newValue)}
                textareaProps={{
                  placeholder: "Please enter Markdown text"
                }}
                height={500}
                value={contentObj?.content}
                data-color-mode='light'
                className='response-markdown'
                previewOptions={{
                  components: {
                    code: Code
                  }
                }}
              />
            )}



            {extra && (
              <Text
                fontFamily="'Noto Sans', sans-serif"
                fontWeight="400"
                color={textColor}
                mb={'8px'}
                fontSize={'12px'}
              >
                {extra}
              </Text>
            )}

            {load && (
              <div className="loading-area update-area">
                {/* <Box paddingTop="20px">
                  <SkeletonText
                    mt="0"
                    noOfLines={1}
                    spacing="5"
                    skeletonHeight="10"
                  />
                  <SkeletonText
                    mt="4"
                    noOfLines={5}
                    spacing="5"
                    skeletonHeight="3"
                  />
                </Box> */}
              </div>
            )}
          </Text>

        </Box>




        <Flex></Flex>

        <Box id="FILE_WRAPPER">

          {fullLoaded && renderFiles()}
        </Box>

      </Box>




    </>

  )
}
