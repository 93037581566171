// withLanguage.js
import React, { useEffect } from 'react';
import i18n from '../i18n';

const withLanguage = (WrappedComponent) => {
  return (props) => {
    const { lang } = props.match.params;

    useEffect(() => {
      if (lang) {
        i18n.changeLanguage(lang);
      }
    }, [lang]);

    return <WrappedComponent {...props} />;
  };
};

export default withLanguage;
