// Chakra imports
import {
  Badge,
  Box,
  Button,
  Flex,
  SimpleGrid,
  Stack,
  Text,
  useColorModeValue,
  FormControl,
  Input,
  Spinner,
  Heading,
} from "@chakra-ui/react";
// Custom components
import Footer from "components/footer/FooterAdmin.js";
import Navbar from "components/navbar/NavbarPricing";
import PricingLayout from "layouts/auth/types/Pricing";
import { userPublicRequest } from "config/axios.config";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Pack from "views/admin/main/others/pricing/components/Pack";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import useCustomHistory from "langHoc/useCustomHistory";
import PaymentPack from "./components/PaymentPack";
import HeaderBanner from "layouts/HomeNew/components/HeaderBanner";
import { useViewportHeight } from "layouts/HomeNew/useViewportHeight";
import React from 'react'
import { userPrivateRequest } from "config/axios.config";
function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}


function Pricing() {
  const router = useCustomHistory();
  const [isShow, setIsShow] = useState(true);



  const { t, i18n } = useTranslation();
  const query = useQuery()
  const location = useLocation();
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const history = useHistory();
  const customhistory = useCustomHistory();
  const vh = useViewportHeight();
  const updateVH = Number(vh) * 100;
  const fullHeightStyle = {
    height: `calc(${updateVH}px - 200px)`,
  };
  const applyExucation = async () => {
    const result = await userPrivateRequest.get(`/execute-agreement?token=${query.get('token')}`)
    customhistory.replace('/execute-agreement')
  }

  useEffect(() => {
    if(query.get('token')){
      applyExucation()
    }
    
  }, [query.get('token')])



  return (
    <Box>
      <Box position={"relative"}>
      <HeaderBanner isShow={isShow} setIsShow={setIsShow} />
      <Box   style={fullHeightStyle} d="flex" alignItems="center" justifyContent={'center'}>
        <Heading>
          Payment request is done. please wait until paypal payment success
        </Heading>

      </Box>
      <Footer />
    </Box>
    </Box>
  );
}

export default Pricing;
