import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import i18n from "./i18n";

export function useLanguage() {
  const location = useLocation();

  useEffect(() => {
    const lang = location.pathname.split("/")[1];

    if (!lang || !["en", "ko"].includes(lang)) {
      // Default language, e.g., 'en'
      i18n.changeLanguage("en");
    } else {
      i18n.changeLanguage(lang);
    }
  }, [location]);

  return i18n.language;
}
