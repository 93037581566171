import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import translationEN from './locales/en/translation.json';
import translationKR from './locales/ko/translation.json';

const languageDetector = new LanguageDetector(null, {
  order: ['querystring', 'cookie', 'localStorage', 'navigator', 'htmlTag'],
  lookupQuerystring: 'lng',
  lookupCookie: 'i18next',
  lookupLocalStorage: 'i18nextLng',
  lookupFromPathIndex: 0, // index of the language prefix in the URL path
});

// add custom detection for users from Bangladesh
languageDetector.addDetector({
  name: 'bnDetector',
  lookup(options) {
    const path = options.lookupFromPathIndex === 0 ? window.location.pathname : window.location.href.split('/')[options.lookupFromPathIndex];
    if (path.startsWith('/ko')) {
      return 'ko';
    }
    // check if user is from Bangladesh
    if (window.location.hostname.endsWith('.bd')) {
      return 'bn'; // set language to 'bn' with prefix '/bn' if user is from Bangladesh
    }
    // if not from Bangladesh or not specified in the URL path, fallback to default detection methods
    return undefined;
  },
  cacheUserLanguage(lng) {
    // do not cache the language for this detector
  },
});

const resources = {
  en: {
    translation: translationEN
  },
  ko: {
    translation: translationKR
  },
};
i18n
  // load translation using http -> see /public/locales
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(languageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources,
    fallbackLng: 'en',
    debug: true,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;