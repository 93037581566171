// Chakra imports
import {
  Box,
  Flex,
  SimpleGrid,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { ConfigContext } from "contexts/ConfigContext";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import Footer from "components/footer/FooterAdmin.js";

export default function DAPPXTerms(props) {
  const platform = process.env?.REACT_APP_PLATFORM;
  let configs = useContext(ConfigContext);
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const history = useHistory();
  const { t } = useTranslation();
  function handleClick() {
    history.push("/"); // navigate to the main page
  }
  return (
    <>
      <div>
        <img
          src={configs?.logo}
          alt=""
          width="300px"
          style={{ display: "block", margin: "30px auto", cursor: "pointer" }}
          onClick={handleClick}
        />
        <Flex
          direction="column"
          alignSelf="center"
          justifySelf="center"
          overflow="hidden"
          id="__etaaaa"
        >
          <Flex
            direction="column"
            textAlign="center"
            justifyContent="center"
            align="center"
          >
            {/* <Text
            color={textColor}
            fontWeight="bold"
            fontSize="34px"
            mb="30px"
          >
            {t("ChinguAI")}
          </Text> */}
            <Text
              color={textColor}
              fontWeight="bold"
              fontSize={{ base: "24px", xl: "34px" }}
              mb="30px"
            >
              {t("TermHead")}
            </Text>
            <SimpleGrid
              columns={{ md: "1", lg: "1" }}
              mx="auto"
              width={{ base: "95%", lg: "80%", "2xl": "1170px" }}
              maxW={"100%"}
              padding={{ base: "0px 20px", xl: "0px 30px" }}
              id="WP___terms"
            >
              <Box>{/*  */}</Box>
            </SimpleGrid>
          </Flex>
        </Flex>
        <Footer />
      </div>
    </>
  );
}
