import React, { useContext, useState } from "react";

// Chakra imports
import { Box, Flex, Link, Tooltip, useColorModeValue } from "@chakra-ui/react";

// Custom components
import { HorizonLogo } from "components/icons/Icons";
import { HSeparator } from "components/separator/Separator";
import logoImage from "assets/img/logo.svg";
import { ConfigContext } from "contexts/ConfigContext";
import { FiHome, FiMessageCircle, FiPhone, FiSearch } from "react-icons/fi";
import useCustomHistory from "langHoc/useCustomHistory";
import { ModalContext } from "contexts/ModalContext";
import { AuthContext } from "contexts/AuthContext";
import HoverIconButton from "components/icons/HoverIconButton";
export function Navigation() {
  //   Chakra color mode
  let logoColor = useColorModeValue("navy.700", "white");
  let configData = useContext(ConfigContext);
  const customHistory = useCustomHistory();
  const modalContextData = useContext(ModalContext);
  const authData = useContext(AuthContext);
  const { setDepositModal } = authData;
  let membership = authData?.authState?.membership;
  const [isHovered, setIsHovered] = useState(false);
  const COLORS = configData?.config?.global?.COLORS?.value;

  const primaryColor = useColorModeValue(
    COLORS.primaryColor,
    COLORS.primaryDarkColor
  );
  const textColor = useColorModeValue(COLORS.textColor, COLORS.textDarkColor);
  const iconColor = useColorModeValue(COLORS.iconColor, COLORS.iconDarkColor);
  const hoverColor = useColorModeValue(
    COLORS.hoverColor,
    COLORS.hoverDarkColor
  );
  return (
    <Flex align="center" direction="column">
      <Box
        display="flex"
        justifyContent={"center"}
        gap="15%"
        w="100%"
        // borderWidth={1}
        //borderColor={"#aea3d0"}
        //borderRadius={"10px"}
        //mb="15px"
        // boxShadow={'md'}
        sx={{
          //boxShadow: "0px 1px 3px -1px #aea3d0",
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {configData?.config?.global?.HOME_BUTTON?.value === "NO" &&
          configData?.config?.global?.SEARCH_BUTTON?.value === "NO" &&
          configData?.config?.global?.VOICE_MODE_SWITCH?.value === "NO" && (
            <Box
              display={"flex"}
              alignItems={"center"}
              gap={"20px"}
              height={"40px"}
              padding={"4px 12px"}
              position={"relative"}
              _hover={{
                background: "rgba(255, 255, 255, 0.1)",
                borderRadius: "5px",
              }}
            ></Box>
          )}

        {configData?.config?.global?.HOME_BUTTON?.value !== "NO" && (
          <HoverIconButton
            icon={FiHome}
            label="Home"
            onClick={(e) => {
              e.preventDefault();
              customHistory.push("/chat");
              window.location.reload();
              // handleClick()
              // setSelectedPrompt({})
              // setCurrentSessionInfo({})
            }}
            iconColor={iconColor}
            hoverColor={hoverColor}
          />
          // <Tooltip
          //   label="Home"
          //   fontSize="md"
          //   placement="top"
          //   borderRadius="16px"
          // >

          //   <Box
          //     display={"flex"}
          //     alignItems={"center"}
          //     gap={"20px"}
          //     height={"40px"}
          //     padding={"4px 12px"}
          //     position={"relative"}
          //     _hover={{
          //       background: primaryColor,
          //       borderRadius: "5px",
          //     }}
          //   >
          //     <Link
          //       to="/"
          //       // style={LinkColor("", true)}
          //       onClick={(e) => {
          //         e.preventDefault();
          //         customHistory.push("/chat");
          //         window.location.reload();
          //         // handleClick()
          //         // setSelectedPrompt({})
          //         // setCurrentSessionInfo({})
          //       }}
          //     >
          //       <FiMessageCircle color={isHovered ? hoverColor : iconColor} size={20} />
          //     </Link>
          //   </Box>
          // </Tooltip>
        )}
        {configData?.config?.global?.SEARCH_BUTTON?.value !== "NO" && (
          <HoverIconButton
            icon={FiSearch}
            label="Search"
            onClick={(e) => {
              e.preventDefault();
              customHistory.push("/");
            }}
            iconColor={iconColor}
            hoverColor={hoverColor}
          />
          // <Tooltip
          //   label="Search"
          //   fontSize="md"
          //   placement="top"
          //   borderRadius="16px"
          // >
          //   <Box
          //     display={"flex"}
          //     alignItems={"center"}
          //     gap={"20px"}
          //     height={"40px"}
          //     padding={"4px 12px"}
          //     position={"relative"}
          //     _hover={{
          //       background: "rgba(255, 255, 255, 0.1)",
          //       borderRadius: "5px",
          //     }}
          //   >
          //     <Link
          //       to="/"
          //       onClick={(e) => {
          //         e.preventDefault();
          //         // window.location.reload()
          //         customHistory.push("/");
          //         // handleClick()
          //       }}
          //     // style={LinkColor("", true)}
          //     >
          //       <FiSearch color={iconColor} size={20} />
          //       {/* Search */}
          //     </Link>
          //   </Box>
          // </Tooltip>
        )}

        {configData?.config?.global?.VOICE_MODE_SWITCH?.value === "YES" && (
          <HoverIconButton
            icon={FiSearch}
            label="Genius"
            onClick={(e) => {
              let web3Auth =
                configData?.config?.global?.WEB3_AUTHENTICATION?.value === "YES"
                  ? true
                  : false;
              let balance = authData?.authState?.user?.creditBalance ?? 0;
              // if (membership === "pro") {
              //   customHistory.push("/chat?mode=voice");
              // } else {
              //   modalContextData.setCurrentModalName(
              //     "moduleEligibilityException"
              //   );
              // }

              if (!web3Auth && balance <= 0) {
                modalContextData.setCurrentModalName(
                  "moduleEligibilityException"
                );
                return;
              } else if (web3Auth && balance <= 0) {
                setDepositModal(true);
                return;
              }
              customHistory.push("/chat?mode=voice");
            }}
            iconColor={iconColor}
            hoverColor={hoverColor}
            isSvg={true}
          />
          // <Tooltip
          //   label="Voice"
          //   fontSize="md"
          //   placement="top"
          //   borderRadius="16px"
          // >
          //   <Box
          //     display={"flex"}
          //     alignItems={"center"}
          //     gap={"20px"}
          //     height={"40px"}
          //     padding={"4px 12px"}
          //     position={"relative"}
          //     _hover={{
          //       background: "rgba(255, 255, 255, 0.1)",
          //       borderRadius: "5px",
          //     }}
          //     cursor={"pointer"}
          //     onClick={() => {
          //       if (membership === "pro") {
          //         customHistory.push("/chat?mode=voice");
          //       } else {
          //         modalContextData.setCurrentModalName(
          //           "moduleEligibilityException"
          //         );
          //       }
          //     }}
          //   >
          //     {/* <FiPhone color="#a3aed0" size={20} /> */}
          //     <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill={iconColor} viewBox="0 0 256 256"><path d="M251.76,88.94l-120-64a8,8,0,0,0-7.52,0l-120,64a8,8,0,0,0,0,14.12L32,117.87v48.42a15.91,15.91,0,0,0,4.06,10.65C49.16,191.53,78.51,216,128,216a130,130,0,0,0,48-8.76V240a8,8,0,0,0,16,0V199.51a115.63,115.63,0,0,0,27.94-22.57A15.91,15.91,0,0,0,224,166.29V117.87l27.76-14.81a8,8,0,0,0,0-14.12ZM128,200c-43.27,0-68.72-21.14-80-33.71V126.4l76.24,40.66a8,8,0,0,0,7.52,0L176,143.47v46.34C163.4,195.69,147.52,200,128,200Zm80-33.75a97.83,97.83,0,0,1-16,14.25V134.93l16-8.53ZM188,118.94l-.22-.13-56-29.87a8,8,0,0,0-7.52,14.12L171,128l-43,22.93L25,96,128,41.07,231,96Z"></path></svg>
          //   </Box>
          // </Tooltip>
        )}
      </Box>
    </Flex>
  );
}

export default Navigation;
