import { Box } from "@chakra-ui/react";

import React from "react";

export const renderTrack = ({ style, ...props }) => {
  const trackStyle = {
    position: "absolute",
    maxWidth: "100%",
    width: 6,
    transition: "opacity 200ms ease 0s",
    opacity: 0,
    background: "transparent",
    bottom: 2,
    top: 2,
    borderRadius: 3,
    right: 0,
  };
  return <div style={{ ...style, ...trackStyle }} {...props} />;
};
export const renderThumb = ({ style, ...props }) => {
  const thumbStyle = {
    borderRadius: 15,
    background: "rgba(222, 222, 222, .1)",
  };
  return <div style={{ ...style, ...thumbStyle }} {...props} />;
};
export const renderView = ({ style, ...props }) => {
  const viewStyle = {
    marginBottom: -22,
  };
  return (
    <Box
      me={{ base: "0px !important", lg: "-16px !important" }}
      style={{ ...style, ...viewStyle }}
      className="dasfdsf" 
      {...props}
    />
  );
};
export const renderViewSidebar = ({ style, ...props }) => {
  const viewStyle = {
    marginBottom: -22,
  };
  return (
    <Box
      me={{ base: "0px !important", lg: "-16px !important" }}
      style={{ ...style, ...viewStyle }}
      className="sidebar-scroll" 
      {...props}
    />
  );
};
export const kanbanRenderTrack = ({ style, ...props }) => {
  const trackStyle = {
    width: 6,
    transition: "opacity 200ms ease 0s",
    opacity: 0,
    bottom: 2,
    top: 2,
    borderRadius: 3,
    right: 0,
  };
  return <div style={{ ...style, ...trackStyle }} {...props} />;
};
export const kanbanRenderThumb = ({ style, ...props }) => {
  const thumbStyle = {
    borderRadius: 15,
    background: "rgba(222, 222, 222, .1)",
  };
  return <div style={{ ...style, ...thumbStyle }} {...props} />;
};
export const kanbanRenderView = ({ style, ...props }) => {
  const viewStyle = {
    position: "relative",
    marginRight: -15,
  };
  return <div style={{ ...style, ...viewStyle }} {...props} />;
};

export const storiesRenderTrack = ({ style, ...props }) => {
  const trackStyle = {
    width: 6,
    transition: "opacity 200ms ease 0s",
    opacity: 0,
    bottom: 2,
    top: 2,
    borderRadius: 3,
    right: 0,
  };
  return <div style={{ ...style, ...trackStyle }} {...props} />;
};
export const storiesRenderThumb = ({ style, ...props }) => {
  const thumbStyle = {
    borderRadius: 15,
    background: "rgba(222, 222, 222, .1)",
  };
  return <div style={{ ...style, ...thumbStyle }} {...props} />;
};
export const storiesRenderView = ({ style, ...props }) => {
  const viewStyle = {
    position: "relative",
    marginRight: -15,
  };
  return <div style={{ ...style, ...viewStyle }} {...props} />;
};

export const messagesRenderTrack = ({ style, ...props }) => {
  const trackStyle = {
    position: "absolute",
    maxWidth: "100%",
    width: 10,
    transition: "opacity 200ms ease 0s",
    opacity: .8,
    background: "transparent",
    bottom: 10,
    top: 10,
    borderRadius: 3,
    right: 2,
  };
  return <div style={{ ...style, ...trackStyle }} {...props} />;
};
export const messagesRenderThumb = ({ style, ...props }) => {
  const thumbStyle = {
    borderRadius: 1000,
    background: "linear-gradient(rgb(52, 58, 64), rgb(92, 96, 101), rgb(52, 58, 64))",
    border: "rgb(166, 167, 171)",
    width: '50%'
  };
  return <div style={{ ...style, ...thumbStyle }} {...props} />;
};
export const messagesRenderView = ({ style, ...props }) => {
  const viewStyle = {
    marginBottom: -22,
  };
  return <Box style={{ ...style, ...viewStyle }} {...props} className="fc-scroller" />;
};
