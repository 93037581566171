import {
  Box,
  Button,
  Checkbox,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { ConfigContext } from "contexts/ConfigContext";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Image } from "@chakra-ui/react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { useLocation } from "react-router-dom";
import dayjs from "dayjs";
import { useViewportHeight } from "../useViewportHeight";

import x from "../../../assets/fonts/60e5f2de011b86ee8ac31042_x.svg";
import check from "../../../assets/fonts/60e5f2de011b865ecec30fca_Green Check.svg";
import ParsedContent from "components/new/ParsedContent";
import ShadowScrollbars from "./ShadowScrollBar";

const CloseIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="#fff"
      ariahidden="true"
      width="30"
      className="text-white absolute top-5 right-5 cursor-pointer"
    >
      <path
        strokelinecap="round"
        strokelinejoin="round"
        d="M6 18L18 6M6 6l12 12"
      ></path>
    </svg>
  );
};

const TutorialModal = ({ modalClose }) => {
  const lang = useLocation();
  const { i18n } = useTranslation();

  const isKor = lang?.pathname.includes("/ko");
  const [isChecked, setIsChecked] = useState(false);
  const [images, setImages] = useState([]);
  const [sliderKey, setSliderKey] = useState(0);
  const [loading, setLoading] = useState(true);

  const configData = useContext(ConfigContext);
  let data = configData?.config?.global?.TUTORIAL_MODAL_CONFIGURATION?.value;
  const { isOpen, onOpen, onClose } = useDisclosure();
  // const [closed, setClosed] = useState(false);

  const shouldShow = () => {
    let modalDisabledDate = localStorage.getItem("tutorialModal");
    if (!modalDisabledDate) {
      return true;
    }
    return false;
  };
  const [isAgreed, setIsAgreed] = useState(false);

  const [waitingTime, setWaitingTime] = useState(15);
  useEffect(() => {
    setWaitingTime(data?.waitingTimeInSec);
  }, [data?.waitingTimeInSec]);

  useEffect(() => {
    let ssss = setTimeout((e) => {
      if (waitingTime > 0) {
        setWaitingTime(waitingTime - 1);
      }
    }, 1000);

    return () => {
      clearTimeout(ssss);
    };
  }, [waitingTime]);
  useEffect(() => {
    if (shouldShow()) {
      onOpen(true);
    }
  }, []);
  const vh = useViewportHeight();
  return (
    <div className="first-modal" id="HOME-MODL">
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        closeOnEsc={false}
        closeOnOverlayClick={false}
        isCentered
        className="first-modal-area"
      >
        <ModalOverlay
          backdropFilter={"blur(11px) opacity(0.6) hue-rotate(282deg)"}
        />
        <ModalContent
          className="first-modal"
          width={{
            md: "724px",
            base: "calc(100% - 20px)",
          }}
          maxW={"100%"}
          borderRadius={"20px"}
          display={"block"}
          my={{
            base: "auto",
            lg: "auto",
          }}
          background="#fff"
        >
          <Box style={{}}>
            <Box
              id="FIRST_MODAL_LEFT"
              style={{
                padding: "25px",
                width: '100%'
              }}
              className="prompt-modal-details"
            >
              {/* <img
                src={data?.icon}
                alt=""
                style={{ marginBottom: "12px", height: "45px" }}
              /> */}

              <Text
                fontSize={"24px"}
                lineHeight={"32px"}
                color={"#000"}
                fontWeight={700}
                pb={"10px"}
              >
                {i18n?.language === "en" ? data?.title : data?.titleKo}
              </Text>

              <Text id="description">
                <div
                  dangerouslySetInnerHTML={{
                    __html:
                      i18n?.language === "en"
                        ? data?.subTitle
                        : data?.subTitleKo,
                  }}
                />
              </Text>

              <Box
                mb={"5px"}
                mt={"5px"}
                as="iframe"
                width="100%"
                height="320px"
                src={
                  i18n?.language === "en" ? data?.videoLink : data?.videoLinkKo
                }
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />

              <Text id="description">
                <div
                  dangerouslySetInnerHTML={{
                    __html:
                      i18n?.language === "en"
                        ? data?.description
                        : data?.descriptionKo,
                  }}
                />
              </Text>
              <Box textAlign={"center"}>
                <Box
                  mt="25px"
                  ml="5px"
                  alignItems={"center"}
                  justifyContent={"center"}
                  background="#fff"
                  display={"flex"}
                >
                  <Checkbox
                    iconColor="#fff"
                    onChange={(event) => {
                      if (event.target.checked) {
                        setIsAgreed(true);
                      } else {
                        setIsAgreed(false);
                      }
                    }}
                    defaultChecked={isAgreed}
                    sx={{
                      "[data-checked]": {
                        background: "#5831d4!important",
                        backgroundColor: "#5831d4!important",
                        borderColor: "#5831d4!important",
                      },
                    }}
                  ></Checkbox>

                  <Text ml="2">
                    {i18n?.language === "en"
                      ? data?.agreementText
                      : data?.agreementTextKo}
                  </Text>
                </Box>
              </Box>
              <Box marginTop={"10px"} textAlign={"center"}>
                <Button
                  fontWeight="700"
                  color={"#fff"}
                  fontSize={"14px"}
                  fontFamily={"Nunito Sans, sans-serif"}
                  bgImage={
                    "linear-gradient(90deg,#00cdfa -20.67%,#9058ff 42.73%,#ff76df 105.77%)"
                  }
                  py="5px"
                  px="35px"
                  borderRadius={"999px"}
                  bottom="-65px"
                  left="calc(100% - 112px)"
                  position={"inherit"}
                  textAlign={"center"}
                  _hover={{
                    bgImage:
                      "linear-gradient(90deg,#00cdfa -20.67%,#9058ff 42.73%,#ff76df 105.77%)",
                  }}
                  _active={{
                    bgImage:
                      "linear-gradient(90deg,#00cdfa -20.67%,#9058ff 42.73%,#ff76df 105.77%)",
                  }}
                  _focus={{
                    bgImage:
                      "linear-gradient(90deg,#00cdfa -20.67%,#9058ff 42.73%,#ff76df 105.77%)",
                  }}
                  disabled={waitingTime > 0 || !isAgreed}
                  onClick={(event) => {
                    localStorage.setItem("tutorialModal", "done");

                    onClose(true);
                    modalClose(true);
                  }}
                >
                  {/* {waitingTime === 0
                    ? data?.buttonText
                    : "Wait " + waitingTime + " seconds to get started."} */}
                  {/* {i18n?.language === "en"
                    ? data?.buttonText
                    : data?.buttonTextKo} */}

                  {waitingTime === 0
                    ? i18n?.language === "en"
                      ? data?.buttonText
                      : data?.buttonTextKo
                    : i18n?.language === "en"
                    ? data?.countdownButtonText?.replace(
                        "[COUNTDOWN]",
                        waitingTime
                      )
                    : data?.countdownButtonTextKo?.replace(
                        "[COUNTDOWN]",
                        waitingTime
                      )}
                </Button>
              </Box>
            </Box>
            {/*  */}
          </Box>
        </ModalContent>
      </Modal>
      <style jsx>{`
        #media-box {
        }

        // #media-box::before {
        //   content: '';
        //   position: fixed;
        //   bottom: -20px;
        //   left: 0;
        //   width: 100%;
        //   height: 19%;
        //   background: linear-gradient(to bottom, transparent, rgba(255, 255, 255, 0.8));
        //   z-index: 2;
        // }

        #media-box h3 p {
          margin-bottom: 6px;
        }
        #media-box ul li p {
          color: 111827;
          font-size: 14px;
        }
        #media-box ul li {
          display: flex;
          align-items: center;
          gap: 0px 10px;
          margin-bottom: 7px;
        }

        #media-box ul li .icion {
          width: 14px;
        }
        #description ul {
          margin-left: 20px !important;
        }
      `}</style>
    </div>
  );
};

export default TutorialModal;
