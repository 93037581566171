import React, { useState, useContext } from "react";
import {
  Box,
  Button,
  Flex,
  FormControl,
  PinInput,
  PinInputField,
  Heading,
  Text,
  useColorModeValue,
  Spinner,
  InputGroup,
  Input,
  InputRightElement,
  Icon,
  useBreakpointValue,
  Image,
} from "@chakra-ui/react";
import { userPublicRequest } from "config/axios.config";
import useCustomHistory from "langHoc/useCustomHistory";
import { useViewportHeight } from "layouts/HomeNew/useViewportHeight2";
import { useTranslation } from "react-i18next";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Footer from "components/footer/FooterAdmin.js";
import CustomNavLink from "langHoc/CustomNavLink";
import { ConfigContext } from "contexts/ConfigContext";
import Card from "components/card/Card";

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const lang = window.location.pathname.split("/")[1] || "en";

function PasswordVerificationCentered() {
  const textColor = useColorModeValue("navy.700", "navy.700");
  const textColorSecondary = "gray.400";
  const textColorDetails = useColorModeValue("navy.700", "navy.700");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const [otp, setOtp] = useState();
  const [loading, setLoading] = useState(false);
  const [show, setShow] = React.useState(false);
  const [formData, setFormData] = React.useState({});
  const history = useCustomHistory();
  let query = useQuery();
  const [resendLoading, setResendLoading] = useState(false);
  const { t } = useTranslation();
  let configs = useContext(ConfigContext);

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const payload = {
        otp,
        password: formData.password,
        lang,
      };
      const result = await userPublicRequest.post(
        "/auth/reset-password/verify",
        payload
      );
      toast.success("Password updated successfully.", {
        onClose: () => {
          setLoading(false);
          history.push(`/auth/sign-in`);
        },
      });
    } catch (err) {
      setLoading(false);
      const toastId = "signin-error-toast";
      if (!toast.isActive(toastId)) {
        toast.error(err.response.data.message, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          toastId: toastId,
        });
      }
    }
  };

  const changeForm = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleClick = () => setShow(!show);
  const vh = useViewportHeight();
  const breakpoint = useBreakpointValue({ base: "base", lg: "lg" });

  const handleResendCode = async () => {
    setResendLoading(true);
    const payload = {
      email: query.get("email"),
      lang,
    };
    userPublicRequest
      .post("/auth/reset-password/request", payload)
      .then((res) => {
        setResendLoading(false);
        toast.success(t('AnOTPSentToYourEmail'));
      })
      .catch((error) => {

        let message = error.response.data?.message ?? "Something went wrong!";
        const toastId = "signin-error-toast";
        if (!toast.isActive(toastId)) {
          toast.error(message, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            toastId: toastId,
          });
        }
      });
  };

  const minH = {
    base: `calc(${vh}px - 40px)`,
    lg: `calc(${vh}px - 50px)`,
  };

  return (
    <Flex
      direction="column"
      overflow="hidden"
      justifyContent="center"
      alignItems="center"
      mx={{ base: "0px", lg: "0px" }}
      bg={"white"}
      height={"100vh"}
      pb="10px"
      px="20px"
    >
      <Box
        display="flex"
        justifyContent={"center"}
        alignItems={"center"}
        flexDirection="column"
        width="100%"
      >
        <CustomNavLink to="/">
          <Box position="relative">
            <Image
              src={configs?.logo}
              alt="logo"
              width={{ base: "300px", lg: "300px" }}
              marginBottom="40px"
              marginX="auto"
            />
          </Box>
        </CustomNavLink>
        <Box
          height={"100%"}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          width={"100%"}
          maxW="600px"
        >
          <Card
            w={{ base: "100%", md: "max-content" }}
            h="max-content"
            mx="auto"
            maxW="100%"
            p="10px"
            pt="10px"
            bg={"transparent"}
            style={{ boxShadow: "unset" }}
          >
            <Box>
              <Flex
                maxW={{ base: "100%", md: "max-content" }}
                w="100%"
                mx={{ base: "auto", lg: "0px" }}
                me="auto"
                justifyContent="center"
                px={{ base: "0px", md: "0px" }}
                flexDirection="column"
              >
                <Box textAlign="center">
                  <Heading
                    color={textColor}
                    fontSize="20px"
                    mb="12px"
                    lineHeight={1}
                  >
                    {t("PasswordReset")}
                  </Heading>
                  <Text
                    color={textColorSecondary}
                    fontSize="md"
                    maxW={{ base: "95%", md: "100%" }}
                    mx={{ base: "auto", lg: "unset" }}
                    textAlign={{ base: "center", lg: "left" }}
                    lineHeight={1.5}
                    mb={"4px"}
                  >
                    {t("EnterPasswordResetOTP")}
                  </Text>
                </Box>
                <Flex
                  zIndex="2"
                  direction="column"
                  w={{ base: "100%", md: "400px" }}
                  maxW="100%"
                  background="transparent"
                  borderRadius="10px"
                  mx={{ base: "auto", lg: "unset" }}
                  me="auto"
                  mb={{ base: "0px", md: "auto" }}
                >
                  <form onSubmit={submitHandler}>
                    <FormControl>
                      <Flex justify="center" justifyContent={"space-between"}>
                        <PinInput
                          mx="auto"
                          otp
                          value={otp}
                          onChange={(e) => setOtp(e)}
                        >
                          <PinInputField
                            fontSize="36px"
                            color={textColor}
                            borderRadius="10px"
                            borderColor={textColorSecondary}
                            // h={{ base: "63px", md: "95px" }}
                            // w={{ base: "63px", md: "95px" }}
                            w={"55px"}
                            h={"55px"}
                            minW={"unset"}
                            me="10px"
                          />
                          <PinInputField
                            fontSize="36px"
                            color={textColor}
                            borderRadius="10px"
                            borderColor={textColorSecondary}
                            // h={{ base: "63px", md: "95px" }}
                            // w={{ base: "63px", md: "95px" }}
                            w={"55px"}
                            h={"55px"}
                            minW={"unset"}
                            me="10px"
                          />
                          <PinInputField
                            fontSize="36px"
                            color={textColor}
                            borderRadius="10px"
                            borderColor={textColorSecondary}
                            // h={{ base: "63px", md: "95px" }}
                            // w={{ base: "63px", md: "95px" }}
                            w={"55px"}
                            h={"55px"}
                            minW={"unset"}
                            me="10px"
                          />
                          <PinInputField
                            fontSize="36px"
                            color={textColor}
                            borderRadius="10px"
                            borderColor={textColorSecondary}
                            // h={{ base: "63px", md: "95px" }}
                            // w={{ base: "63px", md: "95px" }}
                            w={"55px"}
                            h={"55px"}
                            minW={"unset"}
                            me="10px"
                          />
                          <PinInputField
                            fontSize="36px"
                            color={textColor}
                            borderRadius="10px"
                            borderColor={textColorSecondary}
                            // h={{ base: "63px", md: "95px" }}
                            // w={{ base: "63px", md: "95px" }}
                            w={"55px"}
                            h={"55px"}
                            minW={"unset"}
                            me="10px"
                          />
                          <PinInputField
                            fontSize="36px"
                            color={textColor}
                            borderRadius="10px"
                            borderColor={textColorSecondary}
                            // h={{ base: "63px", md: "95px" }}
                            // w={{ base: "63px", md: "95px" }}
                            w={"55px"}
                            h={"55px"}
                            minW={"unset"}
                          />
                        </PinInput>
                      </Flex>

                      <InputGroup size="md" mt="12px">
                        <Input
                          isRequired={true}
                          fontSize="sm"
                          // ms={{ base: "0px", md: "4px" }}
                          placeholder="Enter your password"
                          mb="12px"
                          size="md"
                          type={show ? "text" : "password"}
                          variant="auth"
                          onChange={changeForm}
                          name="password"
                          value={formData.password}
                          style={{
                            border: "1px solid #ccc",
                            borderRadius: "10px",
                            padding: "8px",
                          }}
                        />
                        <InputRightElement
                          display="flex"
                          alignItems="center"
                          // mt="4px"
                        >
                          <Icon
                            color={textColorSecondary}
                            _hover={{ cursor: "pointer" }}
                            as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                            onClick={handleClick}
                          />
                        </InputRightElement>
                      </InputGroup>

                      <Button
                        fontSize="14px"
                        variant="brand"
                        borderRadius="16px"
                        fontWeight="500"
                        w="100%"
                        h="50"
                        mb="12px"
                        type="submit"
                        bg="#6c00ff"
                        _hover={{
                          bg: "#5b00d9",
                        }}
                        _active={{
                          bg: "#5b00d9",
                        }}
                        _focus={{
                          bg: "#5b00d9",
                        }}
                        style={{ borderRadius: "10px", height: "40px" }}
                      >
                        {loading ? (
                          <>
                            <Spinner />
                          </>
                        ) : (
                          t("ResetPassword")
                        )}
                      </Button>
                    </FormControl>
                  </form>

                  <Flex
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="start"
                    maxW="100%"
                    // mt="20px"
                  >
                    <Text
                      color={textColorDetails}
                      fontWeight="400"
                      fontSize="14px"
                      mx={{ base: "auto", lg: "unset" }}
                      textAlign={{ base: "center", lg: "left" }}
                    >
                      {t("DidntReceiveOTP")}
                      <Text
                        color={textColorBrand}
                        cursor={"pointer"}
                        as="span"
                        ms="5px"
                        fontWeight="500"
                        onClick={handleResendCode}
                        disabled={resendLoading}
                      >
                        {resendLoading ? "Resending ..." : t("newOtpCode")}
                      </Text>
                    </Text>
                  </Flex>
                </Flex>
              </Flex>
            </Box>
          </Card>
        </Box>
      </Box>

      <Footer />
    </Flex>
  );
}

export default PasswordVerificationCentered;
