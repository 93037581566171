// chakra imports
import {
  Box,
  Flex,
  Avatar,
  Text,
  useColorModeValue,
  Stack,
} from "@chakra-ui/react";
//   Custom components
import SidebarCard from "components/sidebar/components/SidebarCard";
import Brand from "components/sidebar/components/Brand";
import Links from "components/sidebar/components/Links";
import React, { useContext } from "react";
import avatar4 from "assets/img/avatars/avatar4.png";
import SidebarMemories from "./SidebarMemories";
import { AuthContext } from "contexts/AuthContext";
import { walletAddressFormat } from "utils/utils";
import { titleCase } from "voca";
import { toTitleCase } from "utils/utils";
import useCustomHistory from "langHoc/useCustomHistory";
import Navigation from "./Navigation";

// FUNCTIONS

function SidebarContent(props) {
  const history = useCustomHistory();
  const { sessionRecords } = props;
  const textColor = useColorModeValue("navy.700", "white");

  const authData = useContext(AuthContext);
  const user = authData?.authState?.user;


  let displayText;
  if (user?.source === "metamask") {
    displayText = walletAddressFormat(
      user?.metamaskAddress
    );
  } else {
    const username = user?.username;
    displayText =
      username?.length > 12 ? username?.substring(0, 12) + "..." : username;
  }
  return (
    <Flex
      direction="column"
      minH="100%"
      height="max-content"
      // pt="25px"
      borderRadius="30px"
      px='10px'
      overflow={'hidden'}

    >
      <Brand />
      <Navigation />
      <Stack direction="column" mb="auto" mt="4px"

        h='100%'

      >

        <SidebarMemories />

      </Stack>
    </Flex>
  );
}

export default SidebarContent;
