const { userPrivateRequest } = require("config/axios.config");

module.exports = (channel, event, data) => {
  userPrivateRequest
    .post(`/utils/push-notification/${channel}/${event}`, data)
    .then((response) => {
      // console.log("push notification", response.data);
    })
    .catch((err) => {
      // console.log("failed to push notification: ", err.message);
    });
};
