import axios from "axios";
const apiKey =
  process.env.REACT_APP_SERPER_KEY ||
  "450aefb1d42258abd00d40f8c5409047e7916d05";

const getGoogleData = async (query, limit = 10) => {
  let data = await axios({
    method: "post",
    url: "https://google.serper.dev/search",
    headers: {
      "X-API-KEY": apiKey,
      "Content-Type": "application/json",
    },
    data: JSON.stringify({
      q: query,
      // gl: "us",
      hl: "en",
      autocorrect: true,
      num: limit,
    }),
  })
    .then((response) => {
      // // console.log("Response: ", response);
      return response.data;
    })
    .catch((error) => {
      // // console.log"error", error);
      return {};
    });

  return data;
};

export { getGoogleData };
