import { Box, Heading, Image, Text } from "@chakra-ui/react";
import { AuthContext } from "contexts/AuthContext";
import useCustomHistory from "langHoc/useCustomHistory";
import React, { useEffect, useContext, useState } from "react";
import { ConfigContext } from "contexts/ConfigContext";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const Maintaince = () => {
  const configData = useContext(ConfigContext);
  const config = configData.config || {};
  const history = useHistory()
  const lang = useLocation()
  const isKor = lang?.pathname.includes('/ko')

  const [bgColor, setBgColor] = useState("rgb(83, 42, 124)");
  const [textColor, setTextColor] = useState("#834af4");

  const platform = process.env.REACT_APP_PLATFORM;
  console.log("platform:", platform)

  useEffect(() => {
    if (process.env.REACT_APP_PLATFORM === "Chingu") {
      setBgColor("rgb(83, 42, 124)");
      setTextColor("#fff");
    }

    if (process.env.REACT_APP_PLATFORM === "Questron") {
      setBgColor("#fff");
      setTextColor('#5f6368');
    }
    if (process.env.REACT_APP_PLATFORM === "DappStoreAi") {
      setBgColor("#0060ff");
      setTextColor("#fff");
    }
  }, []);

  useEffect(() => {
    if (config?.global?.MAINTENANCE_MODE?.value === 'NO') {
      history.push('/')
    }
    // // console.log(config?.global?.MAINTENANCE_MODE?.value )
  }, [config]);






  console.log('maintainance')


  return (
    <Box
      h="100vh"
      bg={bgColor}
      d="flex"
      flexDirection={"column"}
      // justifyContent={"center"}
      alignItems={"center"}
      pt="200px"
    >
      <Image src={configData?.logo} alt={configData?.logo} w="300px" mb="30px" />
      <Heading color={textColor} mb="30px"> {isKor ? "Maintenance & Upgrade ko" : "Maintenance & Upgrade"} </Heading>

      <Text color={textColor} fontSize={'18px'} textAlign={'center'} width={'500px'}>
        {configData?.appName} is undergoing maintenance and upgrades to provide you with a
        better experience! We apologize for the inconvenience this has caused.
        We will be back shortly. Thank you for your understanding.
      </Text>
    </Box>
  );
};

export default Maintaince;
