import { Image, Link, ListItem } from '@chakra-ui/react'

const GeneralSearch = ({ gs }) => {
  return (
    <ListItem className="search-root" m="0">
      <Link
        color="#1c73e2"
        href={gs.link}
        target="_blank"
        fontWeight={'400'}
        fontFamily={'Noto Sans'}
        display={'flex'}
        alignItems={{ base: 'baseline', lg: 'flex-start' }}
        className="google-search-item"
        lineHeight={2}
      >
        <span>{`[${gs.position}]  `}</span> {gs.title}
        <Image
          height={'15px'}
          // marginBottom="auto"
          marginTop={'10px !important'}
          marginLeft="10px"
          src={`https://t2.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=${gs.link}&size=16`}
        />
      </Link>
    </ListItem>
  )
}
export default GeneralSearch
