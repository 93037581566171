// Chakra imports
import {
  Box,
  Flex,
  SimpleGrid,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import Footer from "components/footer/FooterAdmin.js";
import { useTranslation } from "react-i18next";
import { useContext } from "react";
import { ConfigContext } from "contexts/ConfigContext";

function Terms() {
  const platform = process.env?.REACT_APP_PLATFORM;
  let configs = useContext(ConfigContext);
  const brandStars = useColorModeValue("brand.500", "brand.400");

  const textColor = useColorModeValue("secondaryGray.900", "white");

  const history = useHistory();

  const { t } = useTranslation();
  function handleClick() {
    history.push("/"); // navigate to the main page
  }

  const isKorean = history?.location?.pathname?.includes("ko");
  if (isKorean) {
    return (
      <div>
        <img
          src={configs?.logo}
          alt=""
          width="300px"
          style={{ display: "block", margin: "30px auto", cursor: "pointer" }}
          onClick={handleClick}
        />
        <Flex
          direction="column"
          alignSelf="center"
          justifySelf="center"
          // marginTop="50px"
          overflow="hidden"
        >
          <Flex
            direction="column"
            textAlign="center"
            justifyContent="center"
            align="center"
            // mb="38px"
          >
            <Text
              color={textColor}
              fontWeight="bold"
              fontSize="34px"
              mb="30px"
              textTransform="uppercase"
              // margin="0"
            >
              {t("PrivacyPolicy")}
            </Text>
            <Text
              color={textColor}
              fontWeight="normal"
              fontSize="16px"
              mb="30px"
            >
              {t("PrivacyDate")}
            </Text>
            <SimpleGrid
              columns={{ md: "1", lg: "1" }}
              spacing="60px"
              width={{ base: "95%", lg: "80%", "2xl": "1170px" }}
              maxW={"100%"}
              padding={{ base: "0px 20px", xl: "0px 30px" }}
              mx="auto"
              id="WP___privacy"
            >
              <Box>
                <Box className="content-page">
                  <Text
                    textAlign="start"
                    color="secondaryGray.600"
                    fontWeight="500"
                    fontSize="md"
                    mb="30px"
                    dangerouslySetInnerHTML={{
                      __html: t(
                        "<b class='bold-text'>주식회사 메타트랜드(이하 '회사')는 이용자의 개인 정보를 매우 중요하게 생각하며 정보 통신서비스 제공자가 준수하여야 하는 관련 법령 및 규정을 준수하고 있습니다.</b>"
                      ),
                    }}
                  ></Text>
                  <Text
                    textAlign="start"
                    color="secondaryGray.600"
                    fontWeight="500"
                    fontSize="md"
                    mb="30px"
                    dangerouslySetInnerHTML={{
                      __html: t(
                        "<b class='bold-text'>개인 정보의 수집∙이용 목적</b>"
                      ),
                    }}
                  ></Text>
                  <Text
                    textAlign="start"
                    color="secondaryGray.600"
                    fontWeight="500"
                    fontSize="md"
                    mb={"30px"}
                    // dangerouslySetInnerHTML={{ __html: t("PrivacyThree") }}
                  >
                    "회사"는 이용자의 신분과 서비스 이용 의사를 확인하여
                    최적화되고 맞춤화된 서비스를 제공하기 위해 아래와 같은
                    목적으로 최소한의 개인 정보만을 수집하고 있습니다.
                  </Text>

                  {/* <Text
                    textAlign="start"
                    color="secondaryGray.600"
                    fontWeight="500"
                    fontSize="md"
                    mb="30px"
                    // dangerouslySetInnerHTML={{
                    //   __html: t("PrivacyTopic1.content1"),
                    // }}
                  >수집한 개인 정보를 목적 외로 사용하거나, 이용자의 동의 없이 제3자에게 이를 공개하지 않습니다. </Text> */}

                  {/* DONE ======= DONE */}

                  {/* <Text
                    textAlign="start"
                    // color="secondaryGray.600"
                    fontWeight="500"
                    fontSize="md"
                    mb="30px"
                    dangerouslySetInnerHTML={{
                      __html: t("PrivacyTopic1.point2"),
                    }}
                  ></Text> */}
                </Box>
                <Box>
                  <Text
                    textAlign="start"
                    color="secondaryGray.600"
                    fontWeight="500"
                    fontSize="md"
                    mb="30px"
                    dangerouslySetInnerHTML={{
                      __html: t("PrivacyTopic1.content1"),
                    }}
                  ></Text>

                  <Text
                    textAlign="start"
                    // color="secondaryGray.600"
                    fontWeight="500"
                    fontSize="md"
                    mb="30px"
                    dangerouslySetInnerHTML={{
                      __html: t("PrivacyTopic1.point2"),
                    }}
                  ></Text>

                  <Text
                    textAlign="start"
                    color="secondaryGray.600"
                    fontWeight="500"
                    fontSize="md"
                    mb="30px"
                    dangerouslySetInnerHTML={{
                      __html: t("PrivacyTopic1.content2"),
                    }}
                  ></Text>
                  <Text
                    textAlign="start"
                    // color="secondaryGray.600"
                    fontWeight="500"
                    fontSize="md"
                    mb="30px"
                    dangerouslySetInnerHTML={{
                      __html: t("PrivacyTopic1.point3"),
                    }}
                  ></Text>

                  <Text
                    textAlign="start"
                    color="secondaryGray.600"
                    fontWeight="500"
                    fontSize="md"
                    mb="30px"
                    dangerouslySetInnerHTML={{
                      __html: t("PrivacyTopic1.content3"),
                    }}
                  ></Text>

                  <Text
                    textAlign="start"
                    // color="secondaryGray.600"
                    fontWeight="500"
                    fontSize="md"
                    mb="30px"
                    dangerouslySetInnerHTML={{
                      __html: t("PrivacyTopic1.point4"),
                    }}
                  ></Text>

                  <Text
                    textAlign="start"
                    color="secondaryGray.600"
                    fontWeight="500"
                    fontSize="md"
                    mb="30px"
                    dangerouslySetInnerHTML={{
                      __html: t("PrivacyTopic1.content4"),
                    }}
                  ></Text>
                  <Text
                    textAlign="start"
                    // color="secondaryGray.600"
                    fontWeight="500"
                    fontSize="md"
                    mb="30px"
                    dangerouslySetInnerHTML={{
                      __html: t("PrivacyTopic1.point5"),
                    }}
                  ></Text>

                  <Text
                    textAlign="start"
                    color="secondaryGray.600"
                    fontWeight="500"
                    fontSize="md"
                    mb="30px"
                    dangerouslySetInnerHTML={{
                      __html: t("PrivacyTopic1.content5"),
                    }}
                  ></Text>

                  <Text
                    textAlign="start"
                    // color="secondaryGray.600"
                    fontWeight="500"
                    fontSize="md"
                    mb="30px"
                    dangerouslySetInnerHTML={{
                      __html: t("PrivacyTopic1.point6"),
                    }}
                  ></Text>

                  <Text
                    textAlign="start"
                    color="secondaryGray.600"
                    fontWeight="500"
                    fontSize="md"
                    mb="30px"
                    dangerouslySetInnerHTML={{
                      __html: t("PrivacyTopic1.content6"),
                    }}
                  ></Text>

                  {/* <Text
                    textAlign="start"
                    color={textColor}
                    fontWeight="500"
                    fontSize="20px"
                    mb="30px"
                  >
                    {t("PrivacyTopic1.subTitle2")}
                  </Text> */}

                  <Text
                    textAlign="start"
                    // color="secondaryGray.600"
                    fontWeight="500"
                    fontSize="md"
                    mb="30px"
                    dangerouslySetInnerHTML={{
                      __html: t("PrivacyTopic1.point7"),
                    }}
                  ></Text>

                  <Text
                    textAlign="start"
                    color="secondaryGray.600"
                    fontWeight="500"
                    fontSize="md"
                    mb="30px"
                    dangerouslySetInnerHTML={{
                      __html: t("PrivacyTopic1.content7"),
                    }}
                  ></Text>

                  <Text
                    textAlign="start"
                    // color="secondaryGray.600"
                    fontWeight="500"
                    fontSize="md"
                    mb="30px"
                    dangerouslySetInnerHTML={{
                      __html: t("PrivacyTopic1.point8"),
                    }}
                  ></Text>

                  <Text
                    textAlign="start"
                    color="secondaryGray.600"
                    fontWeight="500"
                    fontSize="md"
                    mb="30px"
                    dangerouslySetInnerHTML={{
                      __html: t("PrivacyTopic1.content8"),
                    }}
                  ></Text>

                  <Text
                    textAlign="start"
                    // color="secondaryGray.600"
                    fontWeight="500"
                    fontSize="md"
                    mb="30px"
                    dangerouslySetInnerHTML={{
                      __html: t("PrivacyTopic1.point9"),
                    }}
                  ></Text>

                  <Text
                    textAlign="start"
                    color="secondaryGray.600"
                    fontWeight="500"
                    fontSize="md"
                    mb="30px"
                    dangerouslySetInnerHTML={{
                      __html: t("PrivacyTopic1.content9"),
                    }}
                  ></Text>

                  <Text
                    textAlign="start"
                    // color="secondaryGray.600"
                    fontWeight="500"
                    fontSize="md"
                    mb="30px"
                    dangerouslySetInnerHTML={{
                      __html: t("PrivacyTopic1.point10"),
                    }}
                  ></Text>

                  <Text
                    textAlign="start"
                    color="secondaryGray.600"
                    fontWeight="500"
                    fontSize="md"
                    mb="30px"
                    dangerouslySetInnerHTML={{
                      __html: t("PrivacyTopic1.content10"),
                    }}
                  ></Text>

                  <Text
                    textAlign="start"
                    color={textColor}
                    fontWeight="500"
                    fontSize="md"
                    mb="30px"
                    dangerouslySetInnerHTML={{
                      __html: t("PrivacyTopic2.title"),
                    }}
                  >
                    {/* {t("PrivacyTopic2.title")} */}
                  </Text>

                  <Text
                    textAlign="start"
                    color="secondaryGray.600"
                    fontWeight="500"
                    fontSize="md"
                    mb="30px"
                    dangerouslySetInnerHTML={{
                      __html: t("PrivacyTopic2.content1"),
                    }}
                  ></Text>
                  <Text
                    textAlign="start"
                    color={textColor}
                    fontWeight="500"
                    fontSize="md"
                    mb="30px"
                    dangerouslySetInnerHTML={{
                      __html: t("PrivacyTopic3.title"),
                    }}
                    // textDecoration={"underline"}
                  >
                    {/* {t("PrivacyTopic3.title")} */}
                  </Text>

                  <Text
                    textAlign="start"
                    color="secondaryGray.600"
                    fontWeight="500"
                    fontSize="md"
                    mb="30px"
                    dangerouslySetInnerHTML={{
                      __html: t("PrivacyTopic3.content1"),
                    }}
                  ></Text>
                  <Text
                    textAlign="start"
                    color={textColor}
                    fontWeight="500"
                    fontSize="md"
                    mb="30px"
                    dangerouslySetInnerHTML={{
                      __html: t("PrivacyTopic4.title"),
                    }}
                    // textDecoration={"underline"}
                  >
                    {/* {t("PrivacyTopic4.title")} */}
                  </Text>

                  <Text
                    textAlign="start"
                    color="secondaryGray.600"
                    fontWeight="500"
                    fontSize="md"
                    mb="30px"
                    dangerouslySetInnerHTML={{
                      __html: t("PrivacyTopic4.content1"),
                    }}
                  ></Text>
                  <Text
                    textAlign="start"
                    color={textColor}
                    fontWeight="500"
                    fontSize="2xl"
                    mb="30px"
                    // textDecoration={"underline"}
                  >
                    {t("PrivacyTopic5.title")}
                  </Text>

                  <Text
                    textAlign="start"
                    color="secondaryGray.600"
                    fontWeight="500"
                    fontSize="md"
                    mb="30px"
                    dangerouslySetInnerHTML={{
                      __html: t("PrivacyTopic5.content1"),
                    }}
                  ></Text>
                  <Text
                    textAlign="start"
                    color="secondaryGray.600"
                    fontWeight="500"
                    fontSize="md"
                    mb="30px"
                    dangerouslySetInnerHTML={{
                      __html: t("PrivacyTopic6.content1"),
                    }}
                  ></Text>
                  <Text
                    textAlign="start"
                    color="secondaryGray.600"
                    fontWeight="500"
                    fontSize="md"
                    mb="30px"
                    dangerouslySetInnerHTML={{
                      __html: t("PrivacyTopic7.content1"),
                    }}
                  ></Text>
                  <Text
                    textAlign="start"
                    color="secondaryGray.600"
                    fontWeight="500"
                    fontSize="md"
                    mb="30px"
                    dangerouslySetInnerHTML={{
                      __html: t("PrivacyTopic8.content1"),
                    }}
                  ></Text>
                  <Text
                    textAlign="start"
                    color="secondaryGray.600"
                    fontWeight="500"
                    fontSize="md"
                    mb="30px"
                    dangerouslySetInnerHTML={{
                      __html: t("PrivacyTopic9.content1"),
                    }}
                  ></Text>
                </Box>
              </Box>
            </SimpleGrid>
          </Flex>
        </Flex>
        <Footer />
      </div>
    );
  }
  if (platform === "Chingu") {
    return (
      // <PricingLayout
      //   // contentTop={{ base: "140px", md: "14vh" }}
      //   contentTop={{ base: "140px", md: "9vh"}}

      //   contentBottom={{ base: "50px", lg: "auto" }}>
      <div>
        <img
          src={configs?.logo}
          alt=""
          width="300px"
          style={{ display: "block", margin: "30px auto", cursor: "pointer" }}
          onClick={handleClick}
        />
        <Flex
          direction="column"
          alignSelf="center"
          justifySelf="center"
          // marginTop="50px"
          overflow="hidden"
        >
          <Flex
            direction="column"
            textAlign="center"
            justifyContent="center"
            align="center"
            // mb="38px"
          >
            <Text
              color={textColor}
              fontWeight="bold"
              fontSize="34px"
              mb="30px"
              textTransform="uppercase"
              // margin="0"
            >
              {t("PrivacyPolicy")}
            </Text>
            <Text
              color={textColor}
              fontWeight="normal"
              fontSize="16px"
              mb="30px"
            >
              {t("PrivacyDate")}
            </Text>
            <SimpleGrid
              columns={{ md: "1", lg: "1" }}
              spacing="60px"
              width={{ base: "95%", lg: "80%", "2xl": "1170px" }}
              maxW={"100%"}
              padding={{ base: "0px 20px", xl: "0px 30px" }}
              mx="auto"
              id="WP___privacy"
            >
              <Box>
                <Box className="content-page">
                  <Text
                    textAlign="start"
                    color="secondaryGray.600"
                    fontWeight="500"
                    fontSize="md"
                    mb="30px"
                    dangerouslySetInnerHTML={{ __html: t("PrivacyOne") }}
                  ></Text>
                  <Text
                    textAlign="start"
                    color="secondaryGray.600"
                    fontWeight="500"
                    fontSize="md"
                    mb="30px"
                    dangerouslySetInnerHTML={{ __html: t("PrivacyTwo") }}
                  ></Text>
                  <Text
                    textAlign="start"
                    color="secondaryGray.600"
                    fontWeight="500"
                    fontSize="md"
                    mb={"30px"}
                    dangerouslySetInnerHTML={{ __html: t("PrivacyThree") }}
                  ></Text>
                </Box>
                <Box>
                  <Text
                    textAlign="start"
                    color={textColor}
                    fontWeight="500"
                    fontSize="2xl"
                    mb="30px"
                  >
                    {t("PInterpretationD")}
                  </Text>
                  <Text
                    textAlign="start"
                    color={textColor}
                    fontWeight="500"
                    fontSize="20px"
                    mb="30px"
                  >
                    {t("PInterpretation")}
                  </Text>
                  <Text
                    textAlign="start"
                    color="secondaryGray.600"
                    fontWeight="500"
                    fontSize="md"
                    mb="30px"
                    dangerouslySetInnerHTML={{
                      __html: t("PInterpretationDesc"),
                    }}
                  ></Text>

                  <Text
                    textAlign="start"
                    color={textColor}
                    fontWeight="500"
                    fontSize="20px"
                    mb="30px"
                  >
                    {t("PDefinitions")}
                  </Text>
                  <Text
                    textAlign="start"
                    color="secondaryGray.600"
                    fontWeight="500"
                    fontSize="md"
                    mb="30px"
                    dangerouslySetInnerHTML={{ __html: t("PDefinitionsDesc") }}
                  ></Text>

                  <Text
                    textAlign="start"
                    color="secondaryGray.600"
                    fontWeight="500"
                    fontSize="md"
                    mb="30px"
                    dangerouslySetInnerHTML={{
                      __html: t("PDefinitionsAccountDesc"),
                    }}
                  ></Text>
                  {/* 
                <Text
                  textAlign="start"
                  color="secondaryGray.600"
                  fontWeight="500"
                  fontSize="md"
                  mb="30px"
                  dangerouslySetInnerHTML={{
                    __html: t("PDefinitionsAffDesc"),
                  }}
                ></Text> */}

                  <Text
                    textAlign="start"
                    color="secondaryGray.600"
                    fontWeight="500"
                    fontSize="md"
                    mb="30px"
                    dangerouslySetInnerHTML={{
                      __html: t("PDefinitionsAppDesc"),
                    }}
                  ></Text>

                  <Text
                    textAlign="start"
                    color="secondaryGray.600"
                    fontWeight="500"
                    fontSize="md"
                    mb="30px"
                    dangerouslySetInnerHTML={{
                      __html: t("PDefinitionsComDesc"),
                    }}
                  ></Text>
                  <Text
                    textAlign="start"
                    color="secondaryGray.600"
                    fontWeight="500"
                    fontSize="md"
                    mb="30px"
                    dangerouslySetInnerHTML={{
                      __html: t("PDefinitionsCounDesc"),
                    }}
                  ></Text>
                  <Text
                    textAlign="start"
                    color="secondaryGray.600"
                    fontWeight="500"
                    fontSize="md"
                    mb="30px"
                    dangerouslySetInnerHTML={{
                      __html: t("PDefinitionsDevDesc"),
                    }}
                  ></Text>

                  <Text
                    textAlign="start"
                    color="secondaryGray.600"
                    fontWeight="500"
                    fontSize="md"
                    mb="30px"
                    dangerouslySetInnerHTML={{
                      __html: t("PDefinitionsPerDesc"),
                    }}
                  ></Text>

                  <Text
                    textAlign="start"
                    color="secondaryGray.600"
                    fontWeight="500"
                    fontSize="md"
                    mb="30px"
                    dangerouslySetInnerHTML={{
                      __html: t("PDefinitionsSerDesc"),
                    }}
                  ></Text>

                  <Text
                    textAlign="start"
                    color="secondaryGray.600"
                    fontWeight="500"
                    fontSize="md"
                    mb="30px"
                    dangerouslySetInnerHTML={{
                      __html: t("PDefinitionsSerPDesc"),
                    }}
                  ></Text>

                  <Text
                    textAlign="start"
                    color="secondaryGray.600"
                    fontWeight="500"
                    fontSize="md"
                    mb="30px"
                    dangerouslySetInnerHTML={{
                      __html: t("PDefinitionsUsaDesc"),
                    }}
                  ></Text>

                  <Text
                    textAlign="start"
                    color="secondaryGray.600"
                    fontWeight="500"
                    fontSize="md"
                    mb="30px"
                    dangerouslySetInnerHTML={{
                      __html: t("PDefinitionsYouDesc"),
                    }}
                  ></Text>

                  {/* New */}
                  <Text
                    textAlign="start"
                    color={textColor}
                    fontWeight="500"
                    fontSize="2xl"
                    mb="30px"
                    // textDecoration={"underline"}
                  >
                    {t("PrivacyTopic1.title")}
                  </Text>

                  <Text
                    textAlign="start"
                    color={textColor}
                    fontWeight="500"
                    fontSize="20px"
                    mb="30px"
                  >
                    {t("PrivacyTopic1.subTitle1")}
                  </Text>

                  <Text
                    textAlign="start"
                    // color="secondaryGray.600"
                    fontWeight="500"
                    mb="30px"
                    fontSize="md"
                    dangerouslySetInnerHTML={{
                      __html: t("PrivacyTopic1.point1"),
                    }}
                  ></Text>

                  <Text
                    textAlign="start"
                    color="secondaryGray.600"
                    fontWeight="500"
                    fontSize="md"
                    mb="30px"
                    dangerouslySetInnerHTML={{
                      __html: t("PrivacyTopic1.content1"),
                    }}
                  ></Text>

                  <Text
                    textAlign="start"
                    // color="secondaryGray.600"
                    fontWeight="500"
                    fontSize="md"
                    mb="30px"
                    dangerouslySetInnerHTML={{
                      __html: t("PrivacyTopic1.point2"),
                    }}
                  ></Text>

                  <Text
                    textAlign="start"
                    color="secondaryGray.600"
                    fontWeight="500"
                    fontSize="md"
                    mb="30px"
                    dangerouslySetInnerHTML={{
                      __html: t("PrivacyTopic1.content2"),
                    }}
                  ></Text>
                  <Text
                    textAlign="start"
                    // color="secondaryGray.600"
                    fontWeight="500"
                    fontSize="md"
                    mb="30px"
                    dangerouslySetInnerHTML={{
                      __html: t("PrivacyTopic1.point3"),
                    }}
                  ></Text>

                  <Text
                    textAlign="start"
                    color="secondaryGray.600"
                    fontWeight="500"
                    fontSize="md"
                    mb="30px"
                    dangerouslySetInnerHTML={{
                      __html: t("PrivacyTopic1.content3"),
                    }}
                  ></Text>

                  <Text
                    textAlign="start"
                    // color="secondaryGray.600"
                    fontWeight="500"
                    fontSize="md"
                    dangerouslySetInnerHTML={{
                      __html: t("PrivacyTopic1.point4"),
                    }}
                  ></Text>

                  <Text
                    textAlign="start"
                    color="secondaryGray.600"
                    fontWeight="500"
                    fontSize="md"
                    mb="30px"
                    dangerouslySetInnerHTML={{
                      __html: t("PrivacyTopic1.content4"),
                    }}
                  ></Text>
                  <Text
                    textAlign="start"
                    // color="secondaryGray.600"
                    fontWeight="500"
                    fontSize="md"
                    mb="30px"
                    dangerouslySetInnerHTML={{
                      __html: t("PrivacyTopic1.point5"),
                    }}
                  ></Text>

                  <Text
                    textAlign="start"
                    color="secondaryGray.600"
                    fontWeight="500"
                    fontSize="md"
                    mb="30px"
                    dangerouslySetInnerHTML={{
                      __html: t("PrivacyTopic1.content5"),
                    }}
                  ></Text>

                  <Text
                    textAlign="start"
                    // color="secondaryGray.600"
                    fontWeight="500"
                    fontSize="md"
                    mb="30px"
                    dangerouslySetInnerHTML={{
                      __html: t("PrivacyTopic1.point6"),
                    }}
                  ></Text>

                  <Text
                    textAlign="start"
                    color="secondaryGray.600"
                    fontWeight="500"
                    fontSize="md"
                    mb="30px"
                    dangerouslySetInnerHTML={{
                      __html: t("PrivacyTopic1.content6"),
                    }}
                  ></Text>

                  <Text
                    textAlign="start"
                    color={textColor}
                    fontWeight="500"
                    fontSize="20px"
                    mb="30px"
                  >
                    {t("PrivacyTopic1.subTitle2")}
                  </Text>

                  <Text
                    textAlign="start"
                    // color="secondaryGray.600"
                    fontWeight="500"
                    fontSize="md"
                    mb="30px"
                    dangerouslySetInnerHTML={{
                      __html: t("PrivacyTopic1.point7"),
                    }}
                  ></Text>

                  <Text
                    textAlign="start"
                    color="secondaryGray.600"
                    fontWeight="500"
                    fontSize="md"
                    mb="30px"
                    dangerouslySetInnerHTML={{
                      __html: t("PrivacyTopic1.content7"),
                    }}
                  ></Text>

                  <Text
                    textAlign="start"
                    // color="secondaryGray.600"
                    fontWeight="500"
                    fontSize="md"
                    mb="30px"
                    dangerouslySetInnerHTML={{
                      __html: t("PrivacyTopic1.point8"),
                    }}
                  ></Text>

                  <Text
                    textAlign="start"
                    color="secondaryGray.600"
                    fontWeight="500"
                    fontSize="md"
                    mb="30px"
                    dangerouslySetInnerHTML={{
                      __html: t("PrivacyTopic1.content8"),
                    }}
                  ></Text>

                  <Text
                    textAlign="start"
                    // color="secondaryGray.600"
                    fontWeight="500"
                    fontSize="md"
                    mb="30px"
                    dangerouslySetInnerHTML={{
                      __html: t("PrivacyTopic1.point9"),
                    }}
                  ></Text>

                  <Text
                    textAlign="start"
                    color="secondaryGray.600"
                    fontWeight="500"
                    fontSize="md"
                    mb="30px"
                    dangerouslySetInnerHTML={{
                      __html: t("PrivacyTopic1.content9"),
                    }}
                  ></Text>

                  <Text
                    textAlign="start"
                    // color="secondaryGray.600"
                    fontWeight="500"
                    fontSize="md"
                    mb="30px"
                    dangerouslySetInnerHTML={{
                      __html: t("PrivacyTopic1.point10"),
                    }}
                  ></Text>

                  <Text
                    textAlign="start"
                    color="secondaryGray.600"
                    fontWeight="500"
                    fontSize="md"
                    mb="30px"
                    dangerouslySetInnerHTML={{
                      __html: t("PrivacyTopic1.content10"),
                    }}
                  ></Text>

                  <Text
                    textAlign="start"
                    color={textColor}
                    fontWeight="500"
                    fontSize="2xl"
                    mb="30px"
                    // textDecoration={"underline"}
                  >
                    {t("PrivacyTopic2.title")}
                  </Text>

                  <Text
                    textAlign="start"
                    color="secondaryGray.600"
                    fontWeight="500"
                    fontSize="md"
                    mb="30px"
                    dangerouslySetInnerHTML={{
                      __html: t("PrivacyTopic2.content1"),
                    }}
                  ></Text>
                  <Text
                    textAlign="start"
                    color={textColor}
                    fontWeight="500"
                    fontSize="2xl"
                    mb="30px"
                    // textDecoration={"underline"}
                  >
                    {t("PrivacyTopic3.title")}
                  </Text>

                  <Text
                    textAlign="start"
                    color="secondaryGray.600"
                    fontWeight="500"
                    fontSize="md"
                    mb="30px"
                    dangerouslySetInnerHTML={{
                      __html: t("PrivacyTopic3.content1"),
                    }}
                  ></Text>
                  <Text
                    textAlign="start"
                    color={textColor}
                    fontWeight="500"
                    fontSize="2xl"
                    mb="30px"
                    // textDecoration={"underline"}
                  >
                    {t("PrivacyTopic4.title")}
                  </Text>

                  <Text
                    textAlign="start"
                    color="secondaryGray.600"
                    fontWeight="500"
                    fontSize="md"
                    mb="30px"
                    dangerouslySetInnerHTML={{
                      __html: t("PrivacyTopic4.content1"),
                    }}
                  ></Text>
                  <Text
                    textAlign="start"
                    color={textColor}
                    fontWeight="500"
                    fontSize="2xl"
                    mb="30px"
                    // textDecoration={"underline"}
                  >
                    {t("PrivacyTopic5.title")}
                  </Text>

                  <Text
                    textAlign="start"
                    color="secondaryGray.600"
                    fontWeight="500"
                    fontSize="md"
                    mb="30px"
                    dangerouslySetInnerHTML={{
                      __html: t("PrivacyTopic5.content1"),
                    }}
                  ></Text>
                </Box>
              </Box>
              {/* <Box>
              <Box mb="60px">
                <Text
                  textAlign="start"
                  color={textColor}
                  fontWeight="500"
                  fontSize="2xl"
                  mb="12px"
                >
                  {t("TermQ6")}
                </Text>
                <Text
                  textAlign="start"
                  color="secondaryGray.600"
                  fontWeight="500"
                  fontSize="md"
                  dangerouslySetInnerHTML={{ __html: t("TermA6") }}
                ></Text>
              </Box>
              <Box mb="60px">
                <Text
                  textAlign="start"
                  color={textColor}
                  fontWeight="500"
                  fontSize="2xl"
                  mb="12px"
                >
                  {t("TermQ7")}
                </Text>
                <Text
                  textAlign="start"
                  color="secondaryGray.600"
                  fontWeight="500"
                  fontSize="md"
                  dangerouslySetInnerHTML={{ __html: t("TermA7") }}
                ></Text>
              </Box>
              <Box mb="60px">
                <Text
                  textAlign="start"
                  color={textColor}
                  fontWeight="500"
                  fontSize="2xl"
                  mb="12px"
                >
                  {t("TermQ8")}
                </Text>
                <Text
                  textAlign="start"
                  color="secondaryGray.600"
                  fontWeight="500"
                  fontSize="md"
                  dangerouslySetInnerHTML={{ __html: t("TermA8") }}
                ></Text>
              </Box>
              <Box mb="60px">
                <Text
                  textAlign="start"
                  color={textColor}
                  fontWeight="500"
                  fontSize="2xl"
                  mb="12px"
                >
                  {t("TermQ9")}
                </Text>
                <Text
                  textAlign="start"
                  color="secondaryGray.600"
                  fontWeight="500"
                  fontSize="md"
                  dangerouslySetInnerHTML={{ __html: t("TermA9") }}
                ></Text>
              </Box>
            </Box> */}
            </SimpleGrid>
          </Flex>
        </Flex>
        <Footer />
      </div>
      // </PricingLayout>
    );
  }

  if (platform === "Questron") {
    return (
      // <PricingLayout
      //   // contentTop={{ base: "140px", md: "14vh" }}
      //   contentTop={{ base: "140px", md: "9vh"}}

      //   contentBottom={{ base: "50px", lg: "auto" }}>
      <div>
        <img
          src={configs?.logo}
          alt=""
          width="300px"
          style={{ display: "block", margin: "30px auto", cursor: "pointer" }}
          onClick={handleClick}
        />
        <Flex
          direction="column"
          alignSelf="center"
          justifySelf="center"
          // marginTop="50px"
          overflow="hidden"
        >
          <Flex
            direction="column"
            textAlign="center"
            justifyContent="center"
            align="center"
            // mb="38px"
          >
            <Text
              color={textColor}
              fontWeight="bold"
              fontSize="34px"
              mb="30px"
              textTransform="uppercase"
              // margin="0"
            >
              {t("PrivacyPolicyQuestron")}
            </Text>
            <Text
              color={textColor}
              fontWeight="normal"
              fontSize="16px"
              mb="30px"
            >
              {t("PrivacyDateQuestron")}
            </Text>
            <SimpleGrid
              columns={{ md: "1", lg: "1" }}
              spacing="60px"
              width={{ base: "95%", lg: "80%", "2xl": "1170px" }}
              maxW={"100%"}
              padding={{ base: "0px 20px", xl: "0px 30px" }}
              mx="auto"
              id="WP___privacy"
            >
              <Box>
                <Box className="content-page">
                  <Text
                    textAlign="start"
                    color="secondaryGray.600"
                    fontWeight="500"
                    fontSize="md"
                    mb="30px"
                    dangerouslySetInnerHTML={{
                      __html: t("PrivacyOneQuestron"),
                    }}
                  ></Text>
                  <Text
                    textAlign="start"
                    color="secondaryGray.600"
                    fontWeight="500"
                    fontSize="md"
                    mb="30px"
                    dangerouslySetInnerHTML={{
                      __html: t("PrivacyTwoQuestron"),
                    }}
                  ></Text>
                </Box>
                <Box>
                  {/* New */}
                  <Text
                    textAlign="start"
                    color={textColor}
                    fontWeight="500"
                    fontSize="2xl"
                    mb="30px"
                  >
                    {t("PrivacyTopic1Questron.title")}
                  </Text>

                  <Text
                    textAlign="start"
                    color="secondaryGray.600"
                    fontWeight="500"
                    fontSize="md"
                    mb="30px"
                    dangerouslySetInnerHTML={{
                      __html: t("PrivacyTopic1Questron.content"),
                    }}
                  ></Text>

                  <Text
                    textAlign="start"
                    color={textColor}
                    fontWeight="500"
                    fontSize="2xl"
                    mb="30px"
                  >
                    {t("PrivacyTopic2Questron.title")}
                  </Text>

                  <Text
                    textAlign="start"
                    color="secondaryGray.600"
                    fontWeight="500"
                    fontSize="md"
                    mb="30px"
                    dangerouslySetInnerHTML={{
                      __html: t("PrivacyTopic2Questron.content"),
                    }}
                  ></Text>
                  <Text
                    textAlign="start"
                    color={textColor}
                    fontWeight="500"
                    fontSize="2xl"
                    mb="30px"
                  >
                    {t("PrivacyTopic3Questron.title")}
                  </Text>

                  <Text
                    textAlign="start"
                    color="secondaryGray.600"
                    fontWeight="500"
                    fontSize="md"
                    mb="30px"
                    dangerouslySetInnerHTML={{
                      __html: t("PrivacyTopic3Questron.content"),
                    }}
                  ></Text>
                  <Text
                    textAlign="start"
                    color={textColor}
                    fontWeight="500"
                    fontSize="2xl"
                    mb="30px"
                  >
                    {t("PrivacyTopic4Questron.title")}
                  </Text>

                  <Text
                    textAlign="start"
                    color="secondaryGray.600"
                    fontWeight="500"
                    fontSize="md"
                    mb="30px"
                    dangerouslySetInnerHTML={{
                      __html: t("PrivacyTopic4Questron.content"),
                    }}
                  ></Text>
                  <Text
                    textAlign="start"
                    color={textColor}
                    fontWeight="500"
                    fontSize="2xl"
                    mb="30px"
                  >
                    {t("PrivacyTopic5Questron.title")}
                  </Text>

                  <Text
                    textAlign="start"
                    color="secondaryGray.600"
                    fontWeight="500"
                    fontSize="md"
                    mb="30px"
                    dangerouslySetInnerHTML={{
                      __html: t("PrivacyTopic5Questron.content"),
                    }}
                  ></Text>
                  <Text
                    textAlign="start"
                    color={textColor}
                    fontWeight="500"
                    fontSize="2xl"
                    mb="30px"
                  >
                    {t("PrivacyTopic6Questron.title")}
                  </Text>

                  <Text
                    textAlign="start"
                    color="secondaryGray.600"
                    fontWeight="500"
                    fontSize="md"
                    mb="30px"
                    dangerouslySetInnerHTML={{
                      __html: t("PrivacyTopic6Questron.content"),
                    }}
                  ></Text>
                  <Text
                    textAlign="start"
                    color={textColor}
                    fontWeight="500"
                    fontSize="2xl"
                    mb="30px"
                  >
                    {t("PrivacyTopic7Questron.title")}
                  </Text>

                  <Text
                    textAlign="start"
                    color="secondaryGray.600"
                    fontWeight="500"
                    fontSize="md"
                    mb="30px"
                    dangerouslySetInnerHTML={{
                      __html: t("PrivacyTopic7Questron.content"),
                    }}
                  ></Text>
                  <Text
                    textAlign="start"
                    color={textColor}
                    fontWeight="500"
                    fontSize="2xl"
                    mb="30px"
                  >
                    {t("PrivacyTopic8Questron.title")}
                  </Text>

                  <Text
                    textAlign="start"
                    color="secondaryGray.600"
                    fontWeight="500"
                    fontSize="md"
                    mb="30px"
                    dangerouslySetInnerHTML={{
                      __html: t("PrivacyTopic8Questron.content"),
                    }}
                  ></Text>
                </Box>
              </Box>
              {/* <Box>
                        <Box mb="60px">
                          <Text
                            textAlign="start"
                            color={textColor}
                            fontWeight="500"
                            fontSize="2xl"
                            mb="12px"
                          >
                            {t("TermQ6")}
                          </Text>
                          <Text
                            textAlign="start"
                            color="secondaryGray.600"
                            fontWeight="500"
                            fontSize="md"
                            dangerouslySetInnerHTML={{ __html: t("TermA6") }}
                          ></Text>
                        </Box>
                        <Box mb="60px">
                          <Text
                            textAlign="start"
                            color={textColor}
                            fontWeight="500"
                            fontSize="2xl"
                            mb="12px"
                          >
                            {t("TermQ7")}
                          </Text>
                          <Text
                            textAlign="start"
                            color="secondaryGray.600"
                            fontWeight="500"
                            fontSize="md"
                            dangerouslySetInnerHTML={{ __html: t("TermA7") }}
                          ></Text>
                        </Box>
                        <Box mb="60px">
                          <Text
                            textAlign="start"
                            color={textColor}
                            fontWeight="500"
                            fontSize="2xl"
                            mb="12px"
                          >
                            {t("TermQ8")}
                          </Text>
                          <Text
                            textAlign="start"
                            color="secondaryGray.600"
                            fontWeight="500"
                            fontSize="md"
                            dangerouslySetInnerHTML={{ __html: t("TermA8") }}
                          ></Text>
                        </Box>
                        <Box mb="60px">
                          <Text
                            textAlign="start"
                            color={textColor}
                            fontWeight="500"
                            fontSize="2xl"
                            mb="12px"
                          >
                            {t("TermQ9")}
                          </Text>
                          <Text
                            textAlign="start"
                            color="secondaryGray.600"
                            fontWeight="500"
                            fontSize="md"
                            dangerouslySetInnerHTML={{ __html: t("TermA9") }}
                          ></Text>
                        </Box>
                      </Box> */}
            </SimpleGrid>
          </Flex>
        </Flex>
        <Footer />
      </div>
      // </PricingLayout>
    );
  }

  return (
    // <PricingLayout
    //   // contentTop={{ base: "140px", md: "14vh" }}
    //   contentTop={{ base: "140px", md: "9vh"}}

    //   contentBottom={{ base: "50px", lg: "auto" }}>
    <div>
      <img
        src={configs?.logo}
        alt=""
        width="300px"
        style={{ display: "block", margin: "30px auto", cursor: "pointer" }}
        onClick={handleClick}
      />
      <Flex
        direction="column"
        alignSelf="center"
        justifySelf="center"
        // marginTop="50px"
        overflow="hidden"
      >
        <Flex
          direction="column"
          textAlign="center"
          justifyContent="center"
          align="center"
          // mb="38px"
        >
          <Text
            color={textColor}
            fontWeight="bold"
            fontSize="34px"
            mb="30px"
            textTransform="uppercase"
            // margin="0"
          >
            {t("PrivacyPolicy")}
          </Text>
          <Text color={textColor} fontWeight="normal" fontSize="16px" mb="30px">
            {t("PrivacyDate")}
          </Text>
          <SimpleGrid
            columns={{ md: "1", lg: "1" }}
            spacing="60px"
            width={{ base: "95%", lg: "80%", "2xl": "1170px" }}
            maxW={"100%"}
            padding={{ base: "0px 20px", xl: "0px 30px" }}
            mx="auto"
            id="WP___privacy"
          >
            <Box>
              <Box className="content-page">
                <Text
                  textAlign="start"
                  color="secondaryGray.600"
                  fontWeight="500"
                  fontSize="md"
                  mb="30px"
                  dangerouslySetInnerHTML={{ __html: t("PrivacyOne") }}
                ></Text>
                <Text
                  textAlign="start"
                  color="secondaryGray.600"
                  fontWeight="500"
                  fontSize="md"
                  mb="30px"
                  dangerouslySetInnerHTML={{ __html: t("PrivacyTwo") }}
                ></Text>
                <Text
                  textAlign="start"
                  color="secondaryGray.600"
                  fontWeight="500"
                  fontSize="md"
                  mb={"30px"}
                  dangerouslySetInnerHTML={{ __html: t("PrivacyThree") }}
                ></Text>
              </Box>
              <Box>
                <Text
                  textAlign="start"
                  color={textColor}
                  fontWeight="500"
                  fontSize="2xl"
                  mb="30px"
                >
                  {t("PInterpretationD")}
                </Text>
                <Text
                  textAlign="start"
                  color={textColor}
                  fontWeight="500"
                  fontSize="20px"
                  mb="30px"
                >
                  {t("PInterpretation")}
                </Text>
                <Text
                  textAlign="start"
                  color="secondaryGray.600"
                  fontWeight="500"
                  fontSize="md"
                  mb="30px"
                  dangerouslySetInnerHTML={{ __html: t("PInterpretationDesc") }}
                ></Text>

                <Text
                  textAlign="start"
                  color={textColor}
                  fontWeight="500"
                  fontSize="20px"
                  mb="30px"
                >
                  {t("PDefinitions")}
                </Text>
                <Text
                  textAlign="start"
                  color="secondaryGray.600"
                  fontWeight="500"
                  fontSize="md"
                  mb="30px"
                  dangerouslySetInnerHTML={{ __html: t("PDefinitionsDesc") }}
                ></Text>

                <Text
                  textAlign="start"
                  color="secondaryGray.600"
                  fontWeight="500"
                  fontSize="md"
                  mb="30px"
                  dangerouslySetInnerHTML={{
                    __html: t("PDefinitionsAccountDesc"),
                  }}
                ></Text>
                {/* 
                <Text
                  textAlign="start"
                  color="secondaryGray.600"
                  fontWeight="500"
                  fontSize="md"
                  mb="30px"
                  dangerouslySetInnerHTML={{
                    __html: t("PDefinitionsAffDesc"),
                  }}
                ></Text> */}

                <Text
                  textAlign="start"
                  color="secondaryGray.600"
                  fontWeight="500"
                  fontSize="md"
                  mb="30px"
                  dangerouslySetInnerHTML={{
                    __html: t("PDefinitionsAppDesc"),
                  }}
                ></Text>

                <Text
                  textAlign="start"
                  color="secondaryGray.600"
                  fontWeight="500"
                  fontSize="md"
                  mb="30px"
                  dangerouslySetInnerHTML={{
                    __html: t("PDefinitionsComDesc"),
                  }}
                ></Text>
                <Text
                  textAlign="start"
                  color="secondaryGray.600"
                  fontWeight="500"
                  fontSize="md"
                  mb="30px"
                  dangerouslySetInnerHTML={{
                    __html: t("PDefinitionsCounDesc"),
                  }}
                ></Text>
                <Text
                  textAlign="start"
                  color="secondaryGray.600"
                  fontWeight="500"
                  fontSize="md"
                  mb="30px"
                  dangerouslySetInnerHTML={{
                    __html: t("PDefinitionsDevDesc"),
                  }}
                ></Text>

                <Text
                  textAlign="start"
                  color="secondaryGray.600"
                  fontWeight="500"
                  fontSize="md"
                  mb="30px"
                  dangerouslySetInnerHTML={{
                    __html: t("PDefinitionsPerDesc"),
                  }}
                ></Text>

                <Text
                  textAlign="start"
                  color="secondaryGray.600"
                  fontWeight="500"
                  fontSize="md"
                  mb="30px"
                  dangerouslySetInnerHTML={{
                    __html: t("PDefinitionsSerDesc"),
                  }}
                ></Text>

                <Text
                  textAlign="start"
                  color="secondaryGray.600"
                  fontWeight="500"
                  fontSize="md"
                  mb="30px"
                  dangerouslySetInnerHTML={{
                    __html: t("PDefinitionsSerPDesc"),
                  }}
                ></Text>

                <Text
                  textAlign="start"
                  color="secondaryGray.600"
                  fontWeight="500"
                  fontSize="md"
                  mb="30px"
                  dangerouslySetInnerHTML={{
                    __html: t("PDefinitionsUsaDesc"),
                  }}
                ></Text>

                <Text
                  textAlign="start"
                  color="secondaryGray.600"
                  fontWeight="500"
                  fontSize="md"
                  mb="30px"
                  dangerouslySetInnerHTML={{
                    __html: t("PDefinitionsYouDesc"),
                  }}
                ></Text>

                {/* New */}
                <Text
                  textAlign="start"
                  color={textColor}
                  fontWeight="500"
                  fontSize="2xl"
                  mb="30px"
                  // textDecoration={"underline"}
                >
                  {t("PrivacyTopic1.title")}
                </Text>

                <Text
                  textAlign="start"
                  color={textColor}
                  fontWeight="500"
                  fontSize="20px"
                  mb="30px"
                >
                  {t("PrivacyTopic1.subTitle1")}
                </Text>

                <Text
                  textAlign="start"
                  // color="secondaryGray.600"
                  fontWeight="500"
                  mb="30px"
                  fontSize="md"
                  dangerouslySetInnerHTML={{
                    __html: t("PrivacyTopic1.point1"),
                  }}
                ></Text>

                <Text
                  textAlign="start"
                  color="secondaryGray.600"
                  fontWeight="500"
                  fontSize="md"
                  mb="30px"
                  dangerouslySetInnerHTML={{
                    __html: t("PrivacyTopic1.content1"),
                  }}
                ></Text>

                <Text
                  textAlign="start"
                  // color="secondaryGray.600"
                  fontWeight="500"
                  fontSize="md"
                  mb="30px"
                  dangerouslySetInnerHTML={{
                    __html: t("PrivacyTopic1.point2"),
                  }}
                ></Text>

                <Text
                  textAlign="start"
                  color="secondaryGray.600"
                  fontWeight="500"
                  fontSize="md"
                  mb="30px"
                  dangerouslySetInnerHTML={{
                    __html: t("PrivacyTopic1.content2"),
                  }}
                ></Text>
                <Text
                  textAlign="start"
                  // color="secondaryGray.600"
                  fontWeight="500"
                  fontSize="md"
                  mb="30px"
                  dangerouslySetInnerHTML={{
                    __html: t("PrivacyTopic1.point3"),
                  }}
                ></Text>

                <Text
                  textAlign="start"
                  color="secondaryGray.600"
                  fontWeight="500"
                  fontSize="md"
                  mb="30px"
                  dangerouslySetInnerHTML={{
                    __html: t("PrivacyTopic1.content3"),
                  }}
                ></Text>

                <Text
                  textAlign="start"
                  // color="secondaryGray.600"
                  fontWeight="500"
                  fontSize="md"
                  dangerouslySetInnerHTML={{
                    __html: t("PrivacyTopic1.point4"),
                  }}
                ></Text>

                <Text
                  textAlign="start"
                  color="secondaryGray.600"
                  fontWeight="500"
                  fontSize="md"
                  mb="30px"
                  dangerouslySetInnerHTML={{
                    __html: t("PrivacyTopic1.content4"),
                  }}
                ></Text>
                <Text
                  textAlign="start"
                  // color="secondaryGray.600"
                  fontWeight="500"
                  fontSize="md"
                  mb="30px"
                  dangerouslySetInnerHTML={{
                    __html: t("PrivacyTopic1.point5"),
                  }}
                ></Text>

                <Text
                  textAlign="start"
                  color="secondaryGray.600"
                  fontWeight="500"
                  fontSize="md"
                  mb="30px"
                  dangerouslySetInnerHTML={{
                    __html: t("PrivacyTopic1.content5"),
                  }}
                ></Text>

                <Text
                  textAlign="start"
                  // color="secondaryGray.600"
                  fontWeight="500"
                  fontSize="md"
                  mb="30px"
                  dangerouslySetInnerHTML={{
                    __html: t("PrivacyTopic1.point6"),
                  }}
                ></Text>

                <Text
                  textAlign="start"
                  color="secondaryGray.600"
                  fontWeight="500"
                  fontSize="md"
                  mb="30px"
                  dangerouslySetInnerHTML={{
                    __html: t("PrivacyTopic1.content6"),
                  }}
                ></Text>

                <Text
                  textAlign="start"
                  color={textColor}
                  fontWeight="500"
                  fontSize="20px"
                  mb="30px"
                >
                  {t("PrivacyTopic1.subTitle2")}
                </Text>

                <Text
                  textAlign="start"
                  // color="secondaryGray.600"
                  fontWeight="500"
                  fontSize="md"
                  mb="30px"
                  dangerouslySetInnerHTML={{
                    __html: t("PrivacyTopic1.point7"),
                  }}
                ></Text>

                <Text
                  textAlign="start"
                  color="secondaryGray.600"
                  fontWeight="500"
                  fontSize="md"
                  mb="30px"
                  dangerouslySetInnerHTML={{
                    __html: t("PrivacyTopic1.content7"),
                  }}
                ></Text>

                <Text
                  textAlign="start"
                  // color="secondaryGray.600"
                  fontWeight="500"
                  fontSize="md"
                  mb="30px"
                  dangerouslySetInnerHTML={{
                    __html: t("PrivacyTopic1.point8"),
                  }}
                ></Text>

                <Text
                  textAlign="start"
                  color="secondaryGray.600"
                  fontWeight="500"
                  fontSize="md"
                  mb="30px"
                  dangerouslySetInnerHTML={{
                    __html: t("PrivacyTopic1.content8"),
                  }}
                ></Text>

                <Text
                  textAlign="start"
                  // color="secondaryGray.600"
                  fontWeight="500"
                  fontSize="md"
                  mb="30px"
                  dangerouslySetInnerHTML={{
                    __html: t("PrivacyTopic1.point9"),
                  }}
                ></Text>

                <Text
                  textAlign="start"
                  color="secondaryGray.600"
                  fontWeight="500"
                  fontSize="md"
                  mb="30px"
                  dangerouslySetInnerHTML={{
                    __html: t("PrivacyTopic1.content9"),
                  }}
                ></Text>

                <Text
                  textAlign="start"
                  // color="secondaryGray.600"
                  fontWeight="500"
                  fontSize="md"
                  mb="30px"
                  dangerouslySetInnerHTML={{
                    __html: t("PrivacyTopic1.point10"),
                  }}
                ></Text>

                <Text
                  textAlign="start"
                  color="secondaryGray.600"
                  fontWeight="500"
                  fontSize="md"
                  mb="30px"
                  dangerouslySetInnerHTML={{
                    __html: t("PrivacyTopic1.content10"),
                  }}
                ></Text>

                <Text
                  textAlign="start"
                  color={textColor}
                  fontWeight="500"
                  fontSize="2xl"
                  mb="30px"
                  // textDecoration={"underline"}
                >
                  {t("PrivacyTopic2.title")}
                </Text>

                <Text
                  textAlign="start"
                  color="secondaryGray.600"
                  fontWeight="500"
                  fontSize="md"
                  mb="30px"
                  dangerouslySetInnerHTML={{
                    __html: t("PrivacyTopic2.content1"),
                  }}
                ></Text>
                <Text
                  textAlign="start"
                  color={textColor}
                  fontWeight="500"
                  fontSize="2xl"
                  mb="30px"
                  // textDecoration={"underline"}
                >
                  {t("PrivacyTopic3.title")}
                </Text>

                <Text
                  textAlign="start"
                  color="secondaryGray.600"
                  fontWeight="500"
                  fontSize="md"
                  mb="30px"
                  dangerouslySetInnerHTML={{
                    __html: t("PrivacyTopic3.content1"),
                  }}
                ></Text>
                <Text
                  textAlign="start"
                  color={textColor}
                  fontWeight="500"
                  fontSize="2xl"
                  mb="30px"
                  // textDecoration={"underline"}
                >
                  {t("PrivacyTopic4.title")}
                </Text>

                <Text
                  textAlign="start"
                  color="secondaryGray.600"
                  fontWeight="500"
                  fontSize="md"
                  mb="30px"
                  dangerouslySetInnerHTML={{
                    __html: t("PrivacyTopic4.content1"),
                  }}
                ></Text>
                <Text
                  textAlign="start"
                  color={textColor}
                  fontWeight="500"
                  fontSize="2xl"
                  mb="30px"
                  // textDecoration={"underline"}
                >
                  {t("PrivacyTopic5.title")}
                </Text>

                <Text
                  textAlign="start"
                  color="secondaryGray.600"
                  fontWeight="500"
                  fontSize="md"
                  mb="30px"
                  dangerouslySetInnerHTML={{
                    __html: t("PrivacyTopic5.content1"),
                  }}
                ></Text>
              </Box>
            </Box>
            {/* <Box>
              <Box mb="60px">
                <Text
                  textAlign="start"
                  color={textColor}
                  fontWeight="500"
                  fontSize="2xl"
                  mb="12px"
                >
                  {t("TermQ6")}
                </Text>
                <Text
                  textAlign="start"
                  color="secondaryGray.600"
                  fontWeight="500"
                  fontSize="md"
                  dangerouslySetInnerHTML={{ __html: t("TermA6") }}
                ></Text>
              </Box>
              <Box mb="60px">
                <Text
                  textAlign="start"
                  color={textColor}
                  fontWeight="500"
                  fontSize="2xl"
                  mb="12px"
                >
                  {t("TermQ7")}
                </Text>
                <Text
                  textAlign="start"
                  color="secondaryGray.600"
                  fontWeight="500"
                  fontSize="md"
                  dangerouslySetInnerHTML={{ __html: t("TermA7") }}
                ></Text>
              </Box>
              <Box mb="60px">
                <Text
                  textAlign="start"
                  color={textColor}
                  fontWeight="500"
                  fontSize="2xl"
                  mb="12px"
                >
                  {t("TermQ8")}
                </Text>
                <Text
                  textAlign="start"
                  color="secondaryGray.600"
                  fontWeight="500"
                  fontSize="md"
                  dangerouslySetInnerHTML={{ __html: t("TermA8") }}
                ></Text>
              </Box>
              <Box mb="60px">
                <Text
                  textAlign="start"
                  color={textColor}
                  fontWeight="500"
                  fontSize="2xl"
                  mb="12px"
                >
                  {t("TermQ9")}
                </Text>
                <Text
                  textAlign="start"
                  color="secondaryGray.600"
                  fontWeight="500"
                  fontSize="md"
                  dangerouslySetInnerHTML={{ __html: t("TermA9") }}
                ></Text>
              </Box>
            </Box> */}
          </SimpleGrid>
        </Flex>
      </Flex>
      <Footer />
    </div>
    // </PricingLayout>
  );
}

export default Terms;
