// Chakra imports
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Spinner,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import Card from "components/card/Card.js";
import InputField from "components/fields/InputField";
import { userPrivateRequest } from "config/axios.config";
import { AuthContext } from "contexts/AuthContext";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import { toast } from "react-toastify";

export default function Password({primaryColor,primaryHoverColor, ...rest }) {
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "secondaryGray.600";
  const inputBorderColor = "secondaryGray.100";
  const { t } = useTranslation();
  const [correctPassword, setCorrectPassword] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [data, setData] = useState({});
  const [show, setShow] = useState(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);
  const authData = useContext(AuthContext);

  const changeForm = (event) => {
    let updated = {
      ...data,
      [event.target.name]: event.target.value,
    };
    setCorrectPassword(
      updated?.password && updated?.password == updated?.confirmPassword
    );
    setData(updated);
  };

  const handleSubmit = (event) => {
    setSubmitLoading(true);
    userPrivateRequest
      .post("/profile/updatePassword", data)
      .then((response) => {
        toast.success(
          response?.data?.message
            ? response?.data?.message
            : "Updated successfully",
          {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        );
        authData.profileFetch();
      })
      .catch((error) => {
        setSubmitLoading(false);
        const toastId = "signin-error-toast";
        if (!toast.isActive(toastId)) {
          toast.error(
            error?.response?.data?.message
              ? error?.response?.data?.message
              : "Failed to Update",
            {
              position: "bottom-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              toastId: toastId,
            }
          );
        }
      })
      .finally(() => {
        setSubmitLoading(false);
      });
  };

  const handleShow = (type) => {
    if (type === "password") {
      setShow(!show);
    } else if (type === "confirmPassword") {
      setShowRepeatPassword(!showRepeatPassword);
    }
  };
console.log("data",data)
  return (
    <FormControl {...rest}>
      <Card>
        <Flex direction="column" mb="20px" ms="10px">
          <Text fontSize="xl" color={textColorPrimary} fontWeight="bold">
            Create Your New Password
          </Text>
          <Text fontSize="md" color={textColorSecondary}>
            Set a secure password to access Chingu AGI.
          </Text>
        </Flex>
        <FormControl>
          <Flex flexDirection="column">
            <FormLabel
              display="flex"
              ms="10px"
              htmlFor={"password"}
              fontSize="sm"
              color={textColorPrimary}
              fontWeight="bold"
              _hover={{ cursor: "pointer" }}
            >
              {t("Password")}
            </FormLabel>
            <InputGroup
              size="md"
              style={{
                borderRadius: "16px",
              }}
              border={"1px solid"}
              borderColor={inputBorderColor}
              mb="12px"
              id="password"
            >
              <Input
                isRequired={true}
                fontSize="sm"
                ms={{ base: "0px", md: "4px" }}
                placeholder="Enter your password"
                // mb="12px"
                size="md"
                type={show ? "text" : "password"}
                value={data?.password}
                onChange={changeForm}
                color={"black"}
                fontWeight="500"
                variant="main"
                _placeholder={{ fontWeight: "400", color: "secondaryGray.600" }}
                style={{
                  border: "none",
                  // padding: "8px",
                  height: "44px",
                  borderRadius: "16px",
                }}
                name="password"
              />
              <InputRightElement display="flex" alignItems="center">
                <Icon
                  color={textColorSecondary}
                  _hover={{ cursor: "pointer" }}
                  as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                  onClick={() => handleShow("password")}
                />
              </InputRightElement>
            </InputGroup>

            <FormLabel
              display="flex"
              ms="10px"
              htmlFor={"password"}
              fontSize="sm"
              color={textColorPrimary}
              fontWeight="bold"
              _hover={{ cursor: "pointer" }}
            >
              {t("ConfirmPassword")}
            </FormLabel>
            <InputGroup
              size="md"
              style={{
                borderRadius: "16px",
              }}
              border={"1px solid"}
              borderColor={inputBorderColor}
              mb="12px"
              id="password"
            >
              <Input
                isRequired={true}
                variant="auth"
                fontSize="sm"
                ms={{ base: "0px", md: "4px" }}
                placeholder="Confirm your password"
                // mb="12px"
                size="md"
                type={showRepeatPassword ? "text" : "password"}
                value={data?.confirmPassword}
                onChange={changeForm}
                color={"black"}
                style={{
                  border: "none",
                  height: "44px",
                  borderRadius: "16px",
                }}
                name="confirmPassword"
              />
              <InputRightElement display="flex" alignItems="center">
                <Icon
                  color={textColorSecondary}
                  _hover={{ cursor: "pointer" }}
                  as={
                    showRepeatPassword ? RiEyeCloseLine : MdOutlineRemoveRedEye
                  }
                  onClick={() => handleShow("confirmPassword")}
                />
              </InputRightElement>
            </InputGroup>
          </Flex>
        </FormControl>
        <Button
          variant="brand"
          minW="183px"
          fontSize="sm"
          fontWeight="500"
          ms="auto"
          disabled={!correctPassword || submitLoading}
          onClick={handleSubmit}
          bg={primaryColor}
          _hover={{
            bg: primaryHoverColor,
          }}
          _active={{
            bg: primaryHoverColor,
          }}
          _focus={{
            bg: primaryHoverColor,
          }}
        >
          {submitLoading ? <Spinner /> : t("SavePassword")}
        </Button>
      </Card>
    </FormControl>
  );
}
