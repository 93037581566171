import { userPrivateRequest } from "config/axios.config";
import { createContext, useEffect, useReducer, useState } from "react";
import AuthReducer from "./reducers/AuthReducer";
import Cookies from "js-cookie";

export const AuthContext = createContext();

const initialState = {
  // isAuthenticated: true,
  isAuthenticated: Cookies.get("token") ? true : false,
  token: Cookies.get("token"),
  expires_at: Cookies.get("expires_at"),
  membership: "guest",
  // user: {
  //   // full_name: Cookies.get('full_name'),
  //   email: Cookies.get("email"),
  //   role: Cookies.get("role"),
  // },
};

const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AuthReducer, initialState);
  const [chatList, setChatListe] = useState(false);
  const [modal, setModal] = useState(false);
  const [isFetched, setIsFetched] = useState(false);
  const [depositModal, setDepositModal] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const profileFetch = async () => {
    try {
      const result = await userPrivateRequest.get("/profile");
      dispatch({
        type: "PROFILE",
        payload: result.data.user,
      });
      setIsFetched(true);
    } catch (err) {
      // console.log(err.message, 'error?.response');
      setIsFetched(true);
      dispatch({
        type: "LOGOUT",
      });
    }
  };

  useEffect(() => {
    profileFetch();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        authState: state,
        dispatch,
        profileFetch,
        chatList,
        setChatListe,
        modal,
        setModal,
        isFetched,
        depositModal,
        setDepositModal,
        setConfirmationModal,
        confirmationModal,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
