import { List, Text } from "@chakra-ui/react";
import { AuthContext } from "contexts/AuthContext";
import mediaUrlParser from "js-video-url-parser";
import { useContext, useState } from "react";
import AllResultVideo from "./AllResultVideo";
import GeneralSearch from "./GeneralSearch";

export default function AllSearch({ results, config, usedReferences }) {
  const authData = useContext(AuthContext);
  let isAuthenticated = authData.authState.isAuthenticated || false;
  let membership = authData?.authState?.membership;
  let searchEngineResultLimit =
    config[membership].searchEngineResultLimit || 10;

  const numberOfReferenceInPrompt =
    config[membership]?.numberOfReferenceInPrompt || 3;
  let totalRow = results.length;
  if (totalRow > searchEngineResultLimit) {
    totalRow = searchEngineResultLimit;
  }
  return (
    <>
      <List spacing={3}>
        {results.length > 0 &&
          results.slice(0, numberOfReferenceInPrompt).map((gs) => {
            if (usedReferences?.indexOf(gs.position.toString()) == -1) {
              return;
            }
            let linkInfo = mediaUrlParser.parse(gs.link);
            if (linkInfo && linkInfo.mediaType === "video") {
              return <AllResultVideo gs={gs} mediaInfo={linkInfo} />;
            } else {
              return <GeneralSearch gs={gs} />;
            }
          })}
      </List>

      {/* <GoogleSearchPagination
        limit={searchEngineResultPerPage}
        page={page}
        totalRow={totalRow}
        handlePageChange={(limit, newOffset, page) => {
          setOffset(newOffset);
          SetPage(page);
        }}
      /> */}
    </>
  );
}
