import {
  Box,
  Button,
  Heading,
  List,
  ListItem,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Tooltip,
  Icon,
} from "@chakra-ui/react";
import axios from "axios";
import { useContext, useEffect, useRef, useState } from "react";
import { FiMic } from "react-icons/fi";
import { Check, List as ListA, Loader } from "react-feather";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import { AuthContext } from "contexts/AuthContext";
import { ConfigContext } from "contexts/ConfigContext";
import { PagiContext } from "contexts/PagiContext";
import { ModalContext } from "contexts/ModalContext";
import { userPublicRequest, userPrivateRequest } from "config/axios.config";
import useCustomHistory from "langHoc/useCustomHistory";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { RecordButton } from "./RecordButton";
import "./micstyles.css";

const API_KEY = process.env.REACT_APP_OPENAI_API_KEY;

const containerStyle = {
  width: "150px",
  height: "40px",
  margin: "0 auto",
  borderRadius: "100%",
  position: "relative",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

const MicV2 = ({
  audioTextBack,
  ml,
  recording,
  setRecording,
  auto,
  setAuto,
  setMicModal,
  micModal,
  hideIcon,
  question,
  setQuestion,
  isSetQuestion,
  color,
  hoverColor,
  w,
  h,
}) => {
  const { transcript, resetTranscript } = useSpeechRecognition();
  const formData = new FormData();
  const authData = useContext(AuthContext);
  const configData = useContext(ConfigContext);
  const config = configData.config || {};
  const { setDepositModal } = authData;
  const membership = authData?.authState?.membership;
  let timeLimit = config[membership]?.voicePromptLimitInSec || 300;
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const [audioURL, setAudioURL] = useState(null);
  const [transcribeText, setTranscribeText] = useState("");
  const [translateText, setTranslateText] = useState("");
  const [loading, setLoading] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [ended, setEnded] = useState(false);
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const [messageText, setMessaageText] = useState("");
  const [startRecord, setStartRecord] = useState(false);
  let pagiContextHandler = useContext(PagiContext);
  const { modal, setModal, isCancelled } = pagiContextHandler;
  const modalData = useContext(ModalContext);
  const lang = useLocation();
  const isKor = lang?.pathname.includes("/ko");

  const [buttonColor, setButtonColor] = useState("#4FDABC");
  const [buttonHoverColor, setButtonHoverColor] = useState(
    "rgba(79, 218, 188, .8)"
  );

  useEffect(() => {
    if (process.env.REACT_APP_PLATFORM === "Chingu") {
      setButtonColor("#4FDABC");
      setButtonHoverColor("rgba(79, 218, 188, .8)");
    }
    if (process.env.REACT_APP_PLATFORM === "Questron") {
      setButtonColor("#09D809");
      setButtonHoverColor("#08a108");
    }
    if (process.env.REACT_APP_PLATFORM === "DappStoreAi") {
      setButtonColor("#0060ff");
      setButtonHoverColor("#0060ff");
    }
  }, []);

  useEffect(() => {
    if (auto && Object.keys(config).length > 0 && startRecord) {
      startRecording(true);
    }
  }, [config, auto, startRecord]);

  useEffect(() => {
    if (micModal) {
      setModal(true);
      setMicModal(true);
    }
  }, [micModal]);

  useEffect(() => {
    if (question && micModal) {
      makeStateFalseForResult();
      setStartRecord(true);
      pagiContextHandler.handle__pagi__call(question);
      setMessaageText(question);
      setProcessing(true);
    }
  }, [micModal, question, auto]);

  useEffect(() => {
    if (recording) {
      setStartRecord(true);
    }
    let timeoutId;
    if (mediaRecorder && mediaRecorder.state == "recording") {
      if (recording) {
        timeoutId = setTimeout(() => {
          stopRecording();
        }, timeLimit * 1000);
      }
    }
    return () => clearTimeout(timeoutId);
  }, [mediaRecorder, recording]);

  let silenceTimer = useRef(null);

  useEffect(() => {
    let isMounted = true;
    if (isMounted && pagiContextHandler?.modules?.length > 0) {
      setEnded(
        pagiContextHandler?.modules?.every((md) => md.status === "confirmed")
      );
    }
    return () => {
      isMounted = false;
    };
  }, [pagiContextHandler]);

  function startRecording() {
    setModal(true);
    setMicModal(true);
    isCancelled.current = false;
    makeStateFalseForResult();
    navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
      const recorder = new MediaRecorder(stream);
      recorder.addEventListener("dataavailable", handleDataAvailable);
      recorder.start();
      setMediaRecorder(recorder);
      setRecording(true);
      SpeechRecognition.startListening();
    });
  }

  const makeStateFalseForResult = () => {
    setEnded(false);
    pagiContextHandler.setModulesResponse([]);
    setProcessing(false);
    setQuestion("");
    setAuto(false);
    pagiContextHandler.makeEmpty();
  };

  function stopRecording() {
    setLoading(true);
    if (mediaRecorder && recording) {
      mediaRecorder.addEventListener("stop", () => {
        setRecording(false);
        requestAnimationFrame(() => {
          const tracks = mediaRecorder.stream.getTracks();
          tracks.forEach((track) => track.stop());
        });
      });
      if (mediaRecorder.state == "recording") {
        mediaRecorder.stop();
        SpeechRecognition.stopListening();
      }
    }
  }

  const transcribeFunc = () => {
    const headers = {
      Authorization: `Bearer ${API_KEY}`,
      "Content-Type": "multipart/form-data",
    };

    userPrivateRequest
      .post("/openai/transcription", formData, { headers })
      .then((response) => {
        let transcribeText = response.data?.data;
        setLoading(false);
        setTranslateText(transcribeText);
        if (isSetQuestion) {
          audioTextBack(transcribeText);
          // closeModal();
        } else {
          pagiContextHandler.handle__pagi__call(transcribeText);
          setMessaageText(transcribeText);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  function handleDataAvailable(event) {
    if (!event.data) {
      return;
    } else if (isCancelled.current) {
      isCancelled.current = false;
      return;
    }
    setProcessing(true);
    const audioBlob = new Blob([event.data], { type: "audio/mp3" });
    const audioUrl = URL.createObjectURL(audioBlob);
    setAudioURL(audioUrl);
    formData.append("file", audioBlob, "recorded_audio.mp3");
    formData.append("model", "whisper-1");
    formData.append("response_format", "text");
    transcribeFunc();
  }

  const closeModal = () => {
    setModal(false);
    setMicModal(false);
    resetTranscript();
    setLoading(false);
    stopRecording();
    makeStateFalseForResult();
    setMessaageText("");
    setStartRecord(false);
    setRecording(false);
    setProcessing(false);

    isCancelled.current = true;
  };

  const retryHanlder = () => {
    setEnded(false);
    pagiContextHandler.setModulesResponse([]);
    setQuestion("");
    setAuto(false);
    setProcessing(true);
    pagiContextHandler.handle__pagi__call(messageText);
    isCancelled.current = false;
  };

  let exucation =
    pagiContextHandler.modules.length > 0 &&
    !pagiContextHandler.modules.find((mdl) => mdl.status === "confirmed");

  const loadingMessageStep1 = isKor
    ? config?.global?.PAGI_CONFIGURATION?.value?.loadingMessageStep1Kr
    : config?.global?.PAGI_CONFIGURATION?.value?.loadingMessageStep1;
  const loadingMessageStep2 = isKor
    ? config?.global?.PAGI_CONFIGURATION?.value?.loadingMessageStep2Kr
    : config?.global?.PAGI_CONFIGURATION?.value?.loadingMessageStep2;
  const loadingMessageStep3 = isKor
    ? config?.global?.PAGI_CONFIGURATION?.value?.loadingMessageStep3Kr
    : config?.global?.PAGI_CONFIGURATION?.value?.loadingMessageStep3;
  const loadingMessageStep4 = isKor
    ? config?.global?.PAGI_CONFIGURATION?.value?.loadingMessageStep4Kr
    : config?.global?.PAGI_CONFIGURATION?.value?.loadingMessageStep4;

  return (
    <div>
      {modal && (
        <Modal
          isOpen={modal}
          onClose={closeModal}
          isCentered
          className="first-modal-area"
        >
          <ModalOverlay
            onClick={closeModal}
            backdropFilter={"blur(11px) opacity(0.6) hue-rotate(282deg)"}
          />
          <ModalContent
            className="first-modal"
            width={{ md: "900px", base: "calc(100% - 20px)" }}
            maxW={"100%"}
            borderRadius={"20px"}
            boxShadow={
              "0 2px 1px -1px #0003, 0 1px 1px 0 #00000024, 0 1px 3px 0 #0000001f"
            }
            my={{ base: "auto", lg: "auto" }}
          >
            <ModalBody pt={0} pb={0} position={"relative"} id="MIC_2">
              <Box p={"50px 16px 50px 16px"} textAlign={"center"}>
                <Box>
                  <Box
                    d="flex"
                    justifyContent={"center"}
                    alignItems={"center"}
                    h="100%"
                    flexWrap={"wrap"}
                    position={"relative"}
                  >
                    <Box w={"100%"}>
                      {recording ? (
                        <div
                          onMouseEnter={() => setIsHovered(true)}
                          onMouseLeave={() => setIsHovered(false)}
                          style={containerStyle}
                          className="cursor-pointer"
                        >
                          <RecordButton />
                        </div>
                      ) : (
                        processing &&
                        !ended && (
                          <div
                            onMouseEnter={() => setIsHovered(true)}
                            onMouseLeave={() => setIsHovered(false)}
                            style={containerStyle}
                          >
                            {pagiContextHandler?.modules?.length < 1 && (
                              <>
                                <Loader className="rotate-icon" size={40} />{" "}
                              </>
                            )}
                            {pagiContextHandler?.modules?.length > 0 && (
                              <ListA size={40} />
                            )}
                          </div>
                        )
                      )}
                      {ended && (
                        <div
                          onMouseEnter={() => setIsHovered(true)}
                          onMouseLeave={() => setIsHovered(false)}
                          style={containerStyle}
                        >
                          <Check size={40} />
                        </div>
                      )}
                      <Box id="MIC_W">
                        <Heading
                          id="Transcript"
                          color={"#27272a"}
                          fontSize={"1.125rem"}
                          fontWeight={500}
                          paddingBottom={"20px"}
                          mt="25px"
                        ></Heading>
                        {recording || !startRecord ? (
                          <Heading
                            id="Transcript"
                            color={"#27272a"}
                            fontSize={"1.125rem"}
                            fontWeight={500}
                            paddingBottom={"20px"}
                          >
                            {loadingMessageStep1}
                          </Heading>
                        ) : (
                          processing &&
                          !ended && (
                            <Heading
                              id="Transcript"
                              color={"#27272a"}
                              fontSize={"1.125rem"}
                              fontWeight={500}
                              paddingBottom={"20px"}
                            >
                              {pagiContextHandler?.modules?.length < 1 &&
                                loadingMessageStep2}
                              {pagiContextHandler?.modules?.length > 0 &&
                                loadingMessageStep3}
                            </Heading>
                          )
                        )}
                        {ended && (
                          <Heading
                            id="Transcript"
                            color={"#27272a"}
                            fontSize={"1.125rem"}
                            fontWeight={500}
                            paddingBottom={"20px"}
                          >
                            {loadingMessageStep4}
                          </Heading>
                        )}
                        <List spacing={3} mb="20px">
                          {pagiContextHandler?.modules &&
                            pagiContextHandler?.modules.map((item) => (
                              <ListItem
                                key={item.id}
                                d="flex"
                                justifyContent={"center"}
                                alignItems={"center"}
                              >
                                {item.taskPlan}
                              </ListItem>
                            ))}
                        </List>
                        {ended && (
                          <Button
                            fontWeight="700"
                            color={"#fff"}
                            fontFamily={"Nunito Sans, sans-serif"}
                            bg={buttonColor}
                            py="5px"
                            px="25px"
                            borderRadius={"10px"}
                            width="110px"
                            textAlign={"center"}
                            _hover={{ bg: buttonColor }}
                            _active={{ bg: buttonColor }}
                            _focus={{ bg: buttonColor }}
                            onClick={retryHanlder}
                            mr="20px"
                          >
                            Retry
                          </Button>
                        )}
                        <Button
                          fontWeight="700"
                          color={"#fff"}
                          fontFamily={"Nunito Sans, sans-serif"}
                          bg={buttonColor}
                          py="5px"
                          px="25px"
                          borderRadius={"10px"}
                          width="110px"
                          textAlign={"center"}
                          _hover={{ bg: buttonColor }}
                          _active={{ bg: buttonColor }}
                          _focus={{ bg: buttonColor }}
                          onClick={closeModal}
                        >
                          {ended ? "Done" : "Cancel"}
                        </Button>
                        {!startRecord && (
                          <Button
                            fontWeight="700"
                            color={"#fff"}
                            fontFamily={"Nunito Sans, sans-serif"}
                            bg={buttonColor}
                            py="5px"
                            px="25px"
                            borderRadius={"10px"}
                            width="110px"
                            textAlign={"center"}
                            _hover={{ bg: buttonColor }}
                            _active={{ bg: buttonColor }}
                            _focus={{ bg: buttonColor }}
                            onClick={() => {
                              setStartRecord(true);
                              startRecording(true);
                            }}
                            ml="20px"
                          >
                            Start
                          </Button>
                        )}
                        {startRecord && recording && (
                          <Button
                            fontWeight="700"
                            color={"#fff"}
                            fontFamily={"Nunito Sans, sans-serif"}
                            bg={buttonColor}
                            py="5px"
                            px="25px"
                            borderRadius={"10px"}
                            width="110px"
                            textAlign={"center"}
                            _hover={{ bg: buttonColor }}
                            _active={{ bg: buttonColor }}
                            _focus={{ bg: buttonColor }}
                            onClick={stopRecording}
                            ml="20px"
                          >
                            Stop
                          </Button>
                        )}
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
      <Tooltip
        label="Dictate"
        borderRadius="8px"
        fontSize="md"
        isOpen={isTooltipOpen}
        onClose={() => setIsTooltipOpen(false)}
      >
        <Button
          bg="transparent"
          _hover={{ bg: "transparent", color: "inherit", transform: "none" }}
          onMouseEnter={() => {
            setIsTooltipOpen(true);
            setIsHovered(true);
          }}
          onMouseLeave={() => {
            setIsTooltipOpen(false);
            setIsHovered(false);
          }}
          onClick={() => {
            let web3Auth =
              configData?.config?.global?.WEB3_AUTHENTICATION?.value === "YES";
            let balance = authData?.authState?.user?.creditBalance ?? 0;
            if (!isSetQuestion && !web3Auth && balance <= 0) {
              modalData.setCurrentModalName("moduleEligibilityException");
            } else if (!isSetQuestion && web3Auth && balance <= 0) {
              setDepositModal(true);
            } else {
              if (isSetQuestion) {
                recording ? stopRecording() : startRecording();
                setModal(false);
                setMicModal(false);
              } else {
                setModal(true);
                setMicModal(true);
              }
            }
          }}
          p="0px"
          pt="3px"
          display={hideIcon ? "none" : "block"}
        >
          <Icon
            as={FiMic}
            color={recording ? "red" : isHovered ? hoverColor : color}
            w={w ?? "18px"}
            h={h ?? "18px"}
          />
        </Button>
      </Tooltip>
    </div>
  );
};

export default MicV2;
