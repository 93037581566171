// Chakra imports
import {
  Box,
  Flex,
  SimpleGrid,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { ConfigContext } from "contexts/ConfigContext";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import Footer from "components/footer/FooterAdmin.js";

export default function QuestronTerms(props) {
  const platform = process.env?.REACT_APP_PLATFORM;
  let configs = useContext(ConfigContext);
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const history = useHistory();
  const { t } = useTranslation();
  function handleClick() {
    history.push("/"); // navigate to the main page
  }
  return (
    <>
      <div>
        <img
          src={configs?.logo}
          alt=""
          width="300px"
          style={{ display: "block", margin: "30px auto", cursor: "pointer" }}
          onClick={handleClick}
        />
        <Flex
          direction="column"
          alignSelf="center"
          justifySelf="center"
          overflow="hidden"
          id="__etaaaa"
        >
          <Flex
            direction="column"
            textAlign="center"
            justifyContent="center"
            align="center"
          >
            <Text
              color={textColor}
              fontWeight="bold"
              fontSize={{ base: "24px", xl: "34px" }}
              mb="30px"
            >
              {t("TermHeadQuestron")}
            </Text>
            <Text
              color={textColor}
              fontWeight="bold"
              fontSize={{ base: "24px", xl: "34px" }}
              mb="30px"
            >
              {t("TermHead2Questron")}
            </Text>
            <SimpleGrid
              columns={{ md: "1", lg: "1" }}
              mx="auto"
              width={{ base: "95%", lg: "80%", "2xl": "1170px" }}
              maxW={"100%"}
              padding={{ base: "0px 20px", xl: "0px 30px" }}
              id="WP___terms"
            >
              <Box>
                <Box>
                  <Text
                    textAlign="start"
                    color={textColor}
                    fontWeight="500"
                    fontSize="2xl"
                    mb="30px"
                  >
                    {t("TermQ1Questron")}
                  </Text>

                  <Text
                    textAlign="start"
                    color="secondaryGray.600"
                    fontWeight="500"
                    fontSize="md"
                    mb="30px"
                    dangerouslySetInnerHTML={{ __html: t("TermA1Questron") }}
                  ></Text>
                </Box>
                <Box>
                  <Text
                    textAlign="start"
                    color={textColor}
                    fontWeight="500"
                    fontSize="2xl"
                    mb="30px"
                  >
                    {t("TermQ2Questron")}
                  </Text>
                  <Text
                    textAlign="start"
                    color="secondaryGray.600"
                    fontWeight="500"
                    fontSize="md"
                    mb="30px"
                    dangerouslySetInnerHTML={{ __html: t("TermA2Questron") }}
                  ></Text>
                </Box>
                <Box>
                  <Text
                    textAlign="start"
                    color={textColor}
                    fontWeight="500"
                    fontSize="2xl"
                    mb="30px"
                  >
                    {t("TermQ3Questron")}
                  </Text>
                  <Text
                    textAlign="start"
                    color="secondaryGray.600"
                    fontWeight="500"
                    fontSize="md"
                    mb="30px"
                    dangerouslySetInnerHTML={{ __html: t("TermA3Questron") }}
                  ></Text>
                </Box>
                <Box>
                  <Text
                    textAlign="start"
                    color={textColor}
                    fontWeight="500"
                    fontSize="2xl"
                    mb="30px"
                  >
                    {t("TermQ4Questron")}
                  </Text>
                  <Text
                    textAlign="start"
                    color="secondaryGray.600"
                    fontWeight="500"
                    fontSize="md"
                    mb="30px"
                    dangerouslySetInnerHTML={{ __html: t("TermA4Questron") }}
                  ></Text>
                </Box>

                <Box>
                  <Text
                    textAlign="start"
                    color={textColor}
                    fontWeight="500"
                    fontSize="2xl"
                    mb="30px"
                  >
                    {t("TermQ5Questron")}
                  </Text>
                  <Text
                    textAlign="start"
                    color="secondaryGray.600"
                    fontWeight="500"
                    fontSize="md"
                    mb="30px"
                    dangerouslySetInnerHTML={{ __html: t("TermA5Questron") }}
                  ></Text>
                </Box>
              </Box>
              <Box>
                <Box>
                  <Text
                    textAlign="start"
                    color={textColor}
                    fontWeight="500"
                    fontSize="2xl"
                    mb="30px"
                  >
                    {t("TermQ6Questron")}
                  </Text>
                  <Text
                    textAlign="start"
                    color="secondaryGray.600"
                    fontWeight="500"
                    fontSize="md"
                    mb="30px"
                    dangerouslySetInnerHTML={{ __html: t("TermA6Questron") }}
                  ></Text>
                </Box>
                <Box>
                  <Text
                    textAlign="start"
                    color={textColor}
                    fontWeight="500"
                    fontSize="2xl"
                    mb="30px"
                  >
                    {t("TermQ7Questron")}
                  </Text>
                  <Text
                    textAlign="start"
                    color="secondaryGray.600"
                    fontWeight="500"
                    fontSize="md"
                    mb="30px"
                    dangerouslySetInnerHTML={{ __html: t("TermA7Questron") }}
                  ></Text>
                </Box>
                <Box>
                  <Text
                    textAlign="start"
                    color={textColor}
                    fontWeight="500"
                    fontSize="2xl"
                    mb="30px"
                  >
                    {t("TermQ8Questron")}
                  </Text>
                  <Text
                    textAlign="start"
                    color="secondaryGray.600"
                    fontWeight="500"
                    fontSize="md"
                    mb="30px"
                    dangerouslySetInnerHTML={{ __html: t("TermA8Questron") }}
                  ></Text>
                </Box>
                <Box>
                  <Text
                    textAlign="start"
                    color={textColor}
                    fontWeight="500"
                    fontSize="2xl"
                    mb="30px"
                  >
                    {t("TermQ9Questron")}
                  </Text>
                  <Text
                    textAlign="start"
                    color="secondaryGray.600"
                    fontWeight="500"
                    fontSize="md"
                    mb="30px"
                    dangerouslySetInnerHTML={{ __html: t("TermA9Questron") }}
                  ></Text>
                </Box>
                <Box>
                  <Text
                    textAlign="start"
                    color={textColor}
                    fontWeight="500"
                    fontSize="2xl"
                    mb="30px"
                  >
                    {t("TermQ10Questron")}
                  </Text>
                  <Text
                    textAlign="start"
                    color="secondaryGray.600"
                    fontWeight="500"
                    fontSize="md"
                    mb="30px"
                    dangerouslySetInnerHTML={{ __html: t("TermA10Questron") }}
                  ></Text>
                </Box>
              </Box>
            </SimpleGrid>
          </Flex>
        </Flex>
        <Footer />
      </div>
    </>
  );
}
