import {
  Box,
  Button,
  Checkbox,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { ConfigContext } from "contexts/ConfigContext";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { useLocation } from "react-router-dom";
import { useViewportHeight } from "layouts/HomeNew/useViewportHeight";
import useCustomHistory from "langHoc/useCustomHistory";
import { ModalContext } from "contexts/ModalContext";
import { AuthContext } from "contexts/AuthContext";
// import { useViewportHeight } from "../useViewportHeight";

const ModuleEligibilityModal = () => {
  const lang = useLocation();
  const isEng = lang?.pathname.includes("/en");
  const isKor = lang?.pathname.includes("/ko");
  const modalData = useContext(ModalContext);

  const configData = useContext(ConfigContext);
  let data = configData?.config?.global?.PRO_MODULE_EXCEPTION?.value;
  const { dispatch, authState, depositModal, setDepositModal } =
    useContext(AuthContext);

  const [buttonColor, setButtonColor] = useState("#4FDABC");
  const [buttonHoverColor, setButtonHoverColor] = useState(
    "rgba(79, 218, 188, .8)"
  );
  useEffect(() => {
    if (process.env.REACT_APP_PLATFORM === "Chingu") {
      setButtonColor("#4FDABC");
      setButtonHoverColor("rgba(79, 218, 188, .8)");
    }

    if (process.env.REACT_APP_PLATFORM === "Questron") {
      setButtonColor("#09D809");
      setButtonHoverColor("#08a108");
    }

    if (process.env.REACT_APP_PLATFORM === "DappStoreAi") {
      setButtonColor("#0060ff");
      setButtonHoverColor("#0060ff");
    }
  }, []);

  const [isOpen, setIsOpen] = useState(true);
  const vh = useViewportHeight();

  const fullHeightStyle = {
    maxHeight: `calc(${vh}px * 88)`,
  };
  const customHistory = useCustomHistory();
  return (
    <div className="first-modal">
      <Modal
        isOpen={isOpen}
        // onClose={onClose}
        closeOnEsc={false}
        closeOnOverlayClick={false}
        isCentered
        className="first-modal-area"
      >
        <ModalOverlay
          onClick={() => {}}
          // backdropFilter={"blur(30px) brightness(100%) contrast(60%) invert(100%) opacity(0.4)"}
          backdropFilter={"blur(11px) opacity(0.6) hue-rotate(282deg)"}
        />
        <ModalContent
          className="first-modal"
          width={{
            md: "500px",
            base: "calc(100% - 20px)",
          }}
          maxW={"100%"}
          borderRadius={"20px"}
          boxShadow={
            "0 2px 1px -1px #0003, 0 1px 1px 0 #00000024, 0 1px 3px 0 #0000001f"
          }
          my={{
            base: "auto",
            lg: "auto",
          }}
        >
          <ModalHeader
            textAlign={"center"}
            fontSize={"20px"}
            width={{
              md: "100%",
              base: "80%",
            }}
            margin={"auto"}
            pt="25px"
            pb="0px"
          >
            {isEng ? data?.titleEn : data?.titleKo}
          </ModalHeader>
          <ModalBody pt={0} pb={0} position={"relative"}>
            <Box p={"18px 16px 16px 16px"} textAlign={"center"}>
              <Text fontSize={"18px"} fontWeight={500}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: isEng ? data?.messageEn : data?.messageKo,
                  }}
                />
              </Text>
              <Box id="media-box" mt="25px"></Box>
            </Box>

            <Box></Box>
          </ModalBody>

          <ModalFooter
            display={"flex"}
            alignItems={"center"}
            pt="0px"
            pb="28px"
            gap="20px"
            justifyContent={"center"}
          >
            <Button
              fontWeight="700"
              color={"#fff"}
              fontFamily={"Nunito Sans, sans-serif"}
              bg={buttonColor}
              py="5px"
              px="25px"
              borderRadius={"10px"}
              width="110px"
              textAlign={"center"}
              _hover={{
                bg: buttonHoverColor,
              }}
              _active={{
                bg: buttonHoverColor,
              }}
              _focus={{
                bg: buttonHoverColor,
              }}
              onClick={() => {
                setIsOpen(false);

                modalData.setCurrentModalName(null);
              }}
            >
              {isKor ? data?.cancelButtonTextKo : data?.cancelButtonTextEn}
            </Button>
            <Button
              fontWeight="700"
              color={"#fff"}
              fontFamily={"Nunito Sans, sans-serif"}
              bg={buttonColor}
              py="5px"
              px="25px"
              borderRadius={"10px"}
              width="110px"
              textAlign={"center"}
              _hover={{
                bg: buttonHoverColor,
              }}
              _active={{
                bg: buttonHoverColor,
              }}
              _focus={{
                bg: buttonHoverColor,
              }}
              onClick={() => {
                if (
                  configData?.config?.global?.WEB3_AUTHENTICATION?.value ===
                  "YES"
                ) {
                  setDepositModal(true);
                  setIsOpen(false);

                  modalData.setCurrentModalName(null);
                  return;
                }
                modalData.setCurrentModalName("membershipPackageModal");
              }}
            >
              {isKor ? data?.confirmButtonTextKo : data?.confirmButtonTextEn}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default ModuleEligibilityModal;
