import {
  Box,
  Flex,
  IconButton,
  Input,
  Text,
  useDisclosure,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
} from '@chakra-ui/react'
import { userPublicRequest } from 'config/axios.config'
import { ConfigContext } from 'contexts/ConfigContext'
import { useContext, useEffect, useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import {
  FacebookShareCount,
  PinterestShareCount,
  VKShareCount,
  OKShareCount,
  RedditShareCount,
  TumblrShareCount,
  HatenaShareCount,
  FacebookShareButton,
  FacebookMessengerShareButton,
  FacebookMessengerIcon,
  LinkedinShareButton,
  TwitterShareButton,
  PinterestShareButton,
  VKShareButton,
  OKShareButton,
  TelegramShareButton,
  WhatsappShareButton,
  RedditShareButton,
  EmailShareButton,
  TumblrShareButton,
  LivejournalShareButton,
  MailruShareButton,
  ViberShareButton,
  WorkplaceShareButton,
  LineShareButton,
  WeiboShareButton,
  PocketShareButton,
  InstapaperShareButton,
  HatenaShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  PinterestIcon,
  VKIcon,
  OKIcon,
  TelegramIcon,
  WhatsappIcon,
  RedditIcon,
  TumblrIcon,
  MailruIcon,
  EmailIcon,
  LivejournalIcon,
  ViberIcon,
  WorkplaceIcon,
  LineIcon,
  PocketIcon,
  InstapaperIcon,
  WeiboIcon,
  HatenaIcon,
} from 'react-share'
import { ToastContainer, toast } from 'react-toastify'

const ShareModal = ({ lan = 'en', sessionId, onShareModalClose, memoryType }) => {
  // // console.log(memoryType, 'memoryType memoryType')
  const lang = useLocation()
  const isKor = lang?.pathname.includes('/ko')
  let configs = useContext(ConfigContext)
  const [copyUrl, setCopyUrl] = useState(`${process.env.REACT_APP_APP_LINK}${lan}/chat?sharedSession=${sessionId}`) 
  const [loading, setLoading] = useState(true)
  const { i18n } = useTranslation()
  const updateCopyUrl = async () => {
    try {
      const fullUrl = `/chat?sharedSession=${sessionId}`
      const result = await userPublicRequest.post('/create-url', {fullUrl})
      let updateLan = lan
      // if(lan !== "en" || lan ) 
      setCopyUrl(`${process.env.REACT_APP_APP_LINK}?q=${result?.data?.url?.shortUrl}`)
      // // console.log(result?.data?.url?.shortUrl, 82)
      setLoading(false)
    }catch(err) {
      setLoading(false)
    }
  }
  const updateCopyUrlAgi = async () => {
    try {
      const fullUrl = `/${i18n.language}/?memoryId=${sessionId}`
      const result = await userPublicRequest.post('/create-url', {fullUrl})

      setCopyUrl(`${process.env.REACT_APP_AGI_APP}?q=${result?.data?.url?.shortUrl}`)
      // // console.log(result?.data?.url?.shortUrl, 82)
      setLoading(false)
    }catch(err) {
      setLoading(false)
    }
  }


  useEffect(() => {
 

    if(memoryType === 'agi') {
      updateCopyUrlAgi()
    }else {
      updateCopyUrl()
    }
  }, [])


  return (
    <Modal
      id="SHARE_MODAL"
      onClose={onShareModalClose}
      isOpen={true}
      isCentered={true}
    >
      <ModalOverlay
        backdropFilter={'blur(2px) opacity(0.9) hue-rotate(20deg)'}
      />
      <ModalContent
        borderRadius={'20px'}
        boxShadow="rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px"
        my="auto"
      >
        <ModalHeader>Share</ModalHeader>
        <ModalCloseButton
          autoFocus={false}
          bg="transparent"
          top="16px"
          right="16px"
          _focus={{
            boxShadow: 'none',
          }}
        />
        {loading && (
          <ModalBody padding="0px 24px 16px 24px">
            <Box d="flex" alignItems={'center'} justifyContent={'center'}>
            <Spinner />
            </Box>
             
            </ModalBody>
        )}
        {!loading && (
   <ModalBody padding="0px 24px 16px 24px">
   <Box padding="0px 24px 16px 0px">
     <p>
       {
       isKor ? configs?.config?.global?.MEMORY_SHARE_CONFIG?.value
       ?.modalDescriptionKr :  configs?.config?.global?.MEMORY_SHARE_CONFIG?.value
           ?.modalDescription
       }{' '}
     </p>
   </Box>
   <Box>
     <Box display="flex" gap="10px 10px" flexWrap="wrap">
       <FacebookShareButton
         url={copyUrl}
         quote={''}
         hashtag=""
         className=""
       >
         <FacebookIcon size={42} round />
       </FacebookShareButton>

       <LinkedinShareButton url={copyUrl} className="">
         <LinkedinIcon size={42} round />
       </LinkedinShareButton>

       <TwitterShareButton url={copyUrl} className="">
         <TwitterIcon size={42} round />
       </TwitterShareButton>
       {/* <PinterestShareButton url={copyUrl} className="">
           <PinterestIcon size={42} round />
         </PinterestShareButton> */}
       <WhatsappShareButton url={copyUrl} className="">
         <WhatsappIcon size={42} round />
       </WhatsappShareButton>
       <InstapaperShareButton url={copyUrl} className="">
         <InstapaperIcon size={42} round />
       </InstapaperShareButton>
       <TelegramShareButton url={copyUrl} className="">
         <TelegramIcon size={42} round />
       </TelegramShareButton>
       <FacebookMessengerShareButton
         url={copyUrl}
         appId="521270401588372"
         className="Demo__some-network__share-button"
       >
         <FacebookMessengerIcon size={42} round />
       </FacebookMessengerShareButton>
       <VKShareButton
         url={copyUrl}
         // image={`${String(window.location)}/${exampleImage}`}
         className="Demo__some-network__share-button"
       >
         <VKIcon size={42} round />
       </VKShareButton>
       <OKShareButton
         url={copyUrl}
         // image={`${String(window.location)}/${exampleImage}`}
         className="Demo__some-network__share-button"
       >
         <OKIcon size={42} round />
       </OKShareButton>
       <RedditShareButton
         url={copyUrl}
         windowWidth={660}
         windowHeight={460}
         className="Demo__some-network__share-button"
       >
         <RedditIcon size={42} round />
       </RedditShareButton>
       <TumblrShareButton
         url={copyUrl}
         className="Demo__some-network__share-button"
       >
         <TumblrIcon size={42} round />
       </TumblrShareButton>
       <LivejournalShareButton
         url={copyUrl}
         className="Demo__some-network__share-button"
       >
         <LivejournalIcon size={42} round />
       </LivejournalShareButton>
       <EmailShareButton
         url={copyUrl}
         body="body"
         className="Demo__some-network__share-button"
       >
         <EmailIcon size={42} round />
       </EmailShareButton>
       <ViberShareButton
         url={copyUrl}
         title={'title'}
         className="Demo__some-network__share-button"
       >
         <ViberIcon size={42} round />
       </ViberShareButton>
       <WorkplaceShareButton
         url={copyUrl}
         // quote={title}
         className="Demo__some-network__share-button"
       >
         <WorkplaceIcon size={42} round />
       </WorkplaceShareButton>
       <LineShareButton
         url={copyUrl}
         title={'title'}
         className="Demo__some-network__share-button"
       >
         <LineIcon size={42} round />
       </LineShareButton>
       <PocketShareButton
         url={copyUrl}
         title={'title'}
         className="Demo__some-network__share-button"
       >
         <PocketIcon size={42} round />
       </PocketShareButton>
       <HatenaShareButton
         url={copyUrl}
         title={'title'}
         windowWidth={660}
         windowHeight={460}
         className="Demo__some-network__share-button"
       >
         <HatenaIcon size={42} round />
       </HatenaShareButton>
     </Box>
     <Box padding="16px 0px">
       <Box
         p="8px 12px"
         border={'1px solid #ddd'}
         display="flex"
         alignItems={'center'}
         justifyContent="space-between"
         borderRadius={'12px'}
         gap="0px 10px"
       >
         <input
           type="text"
           value={copyUrl}
           readOnly
           style={{ flex: 'auto' }}
         />
         <CopyToClipboard
           text={copyUrl}
           onCopy={() => toast.success('Copied')}
         >
           <button
             style={{
               background: '#065fd4',
               color: '#fff',
               fontSize: '14px',
               lineHeight: '16px',
               padding: '12px 18px',
               borderRadius: '20px',
             }}
           >
             <span>Copy</span>
           </button>
         </CopyToClipboard>
       </Box>
     </Box>
   </Box>
 </ModalBody>
        )}
     
      </ModalContent>
    </Modal>
  )
}

export default ShareModal
