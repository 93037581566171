// Chakra imports
import {
  Box,
  Button,
  Flex,
  Icon,
  Text,
  useColorModeValue,
  SimpleGrid,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";
import Rating from "views/admin/main/account/coursePage/components/Rating";
import Instructor from "views/admin/main/account/coursePage/components/Instructor";
import React, { useState } from "react";

// Assets
import { IoMdStar, IoMdStarHalf } from "react-icons/io";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";

export default function CourseInfo(props) {
  let [tabState, setTabState] = useState("notes");

  // Chakra Color Mode
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = useColorModeValue("secondaryGray.700", "white");
  const textColorTertiary = useColorModeValue(
    "secondaryGray.600",
    "secondaryGray.500"
  );
  const borderColor = useColorModeValue("secondaryGray.500", "white");
  const hover = useColorModeValue(
    { bg: "secondaryGray.100" },
    { bg: "whiteAlpha.100" }
  );
  const { ...rest } = props;
  const panelExample = () => (
    <SimpleGrid
      columns='1'
      gap='40px'
      {...rest}
      maxW='100%'
      w={{ base: "100%", md: "unset" }}>
      <Text fontSize='lg' color={textColorSecondary} fontWeight='400'>
      As the world continues to experience rapid advancements in technology, artificial intelligence (AI) is proving to be a game-changer. AI has already made its presence felt in numerous industries, from healthcare and education to entertainment and transportation. As we embrace the future of technology, there is much to be excited about, especially when it comes to AI. In this blog post, we will explore the top reasons why everyone should be thrilled about the AI-powered world that awaits us.
      </Text>
      <Text fontSize='lg' color={textColorSecondary} fontWeight='400'>
      One of the key benefits of AI is its ability to offer personalized user experiences. As AI algorithms become more sophisticated, they can analyze user preferences, behaviors, and interests to deliver tailor-made content and services. This personalization extends to online shopping, entertainment platforms, and even educational resources, ensuring that users get the most relevant and engaging experiences possible.
      </Text>
      <Text fontSize='lg' color={textColorSecondary} fontWeight='400'>
      AI has the potential to revolutionize healthcare by providing accurate diagnoses, personalized treatment plans, and early detection of diseases. Machine learning algorithms can analyze vast amounts of data to identify patterns, which can lead to breakthroughs in medical research. This could result in more effective treatments and better patient outcomes. Furthermore, AI can assist in drug discovery and development, potentially saving time and resources in the long run.
      </Text>

      <Text fontSize='lg' color={textColorSecondary} fontWeight='400'>
      AI-powered technologies are breaking down barriers and creating more inclusive environments for people with disabilities. Voice assistants, smart glasses, and other devices have made it possible for individuals with visual, auditory, or mobility impairments to access information, communicate, and navigate their surroundings more easily. As AI continues to improve, we can expect even greater strides in accessibility, opening up new opportunities for everyone.
      </Text>


      <Text fontSize='lg' color={textColorSecondary} fontWeight='400'>
      As urban populations grow, there is an increasing need for sustainable and efficient city management. AI can help create greener, smarter cities by optimizing energy consumption, reducing emissions, and managing traffic flow. For instance, AI can analyze data from sensors and IoT devices to implement energy-saving measures or predict and prevent traffic congestion. This not only improves the quality of life for city dwellers but also contributes to a more sustainable future.
      </Text>

      <Text fontSize='lg' color={textColorSecondary} fontWeight='400'>
      AI is not just about automation and efficiency; it also has the potential to augment human creativity. By analyzing and processing data, AI can help generate new ideas, identify patterns, and even create art, music, or literature. This can lead to unexpected collaborations between humans and machines, resulting in novel and innovative creations that push the boundaries of our imagination.
      </Text>

      <Text fontSize='lg' color={textColorSecondary} fontWeight='400'>
      The future of technology, powered by AI, is indeed something to be excited about. From personalized user experiences to smarter healthcare and greener cities, AI promises to improve our lives in numerous ways. It is also fostering creativity and making the world more accessible for everyone. As we continue to embrace AI and its potential, we can look forward to a brighter, more connected, and innovative future. So, let's all sit back, relax, and enjoy the AI-powered ride into the future!
      </Text>
    </SimpleGrid>
  );
  return (
    <Card mt='20px' p={{ base: "20px", md: "20px 40px" }}>
      {/* <Flex align='center' w='100%'>
        <Text
          fontSize='lg'
          color={textColorSecondary}
          fontWeight='500'
          mb='12px'>
          Student feedback
        </Text>
        <Text
          ms='auto'
          color={textColorTertiary}
          me='20px'
          fontSize='lg'
          fontWeight='500'>
          CHAPTER 3/5
        </Text>
        <Button
          border='1px solid'
          borderColor={borderColor}
          bg='transparent'
          _hover={hover}
          me='10px'
          borderRadius='50%'
          h='38px'
          w='38px'>
          <Icon as={MdChevronLeft} color={borderColor} h='24px' w='24px' />
        </Button>
        <Button variant='brand' borderRadius='50%' h='38px' w='38px'>
          <Icon as={MdChevronRight} color={"white"} h='24px' w='24px' />
        </Button>
      </Flex> */}
      <Box w='100%' mb='40px'>
        <Flex direction={{ base: "column", "3xl": "row" }}>
          <Box me={{ md: "40px", "3xl": "40px" }}>
            {panelExample()}
            {/* <Tabs variant='soft-rounded' colorScheme='brandTabs' mb='60px'>
              <TabList overflowX={{ sm: "scroll", lg: "unset" }}>
                <Flex>
                  <Tab
                    pb='0px'
                    flexDirection='column'
                    onClick={function () {
                      setTabState("notes");
                    }}
                    me='10px'
                    bg='unset'
                    _selected={{
                      bg: "none",
                    }}
                    _focus={{ border: "none" }}
                    minW='max-content'>
                    <Flex align='center'>
                      <Text
                        color={
                          tabState === "notes" ? textColor : textColorTertiary
                        }
                        fontSize='lg'
                        fontWeight='500'>
                        Notes
                      </Text>
                    </Flex>
                    <Box
                      height='4px'
                      w='100%'
                      transition='0.1s linear'
                      bg={tabState === "notes" ? "brand.500" : "transparent"}
                      mt='15px'
                      borderRadius='30px'
                    />
                  </Tab>
                  <Tab
                    onClick={function () {
                      setTabState("resources");
                    }}
                    pb='0px'
                    me='10px'
                    bg='unset'
                    _selected={{
                      bg: "none",
                    }}
                    _focus={{ border: "none" }}
                    minW='max-content'
                    flexDirection='column'>
                    <Flex align='center'>
                      <Text
                        color={
                          tabState === "resources"
                            ? textColor
                            : textColorTertiary
                        }
                        fontSize='lg'
                        fontWeight='500'>
                        Resources
                      </Text>
                    </Flex>
                    <Box
                      height='4px'
                      w='100%'
                      transition='0.1s linear'
                      bg={
                        tabState === "resources" ? "brand.500" : "transparent"
                      }
                      mt='15px'
                      borderRadius='30px'
                    />
                  </Tab>
                  <Tab
                    pb='0px'
                    flexDirection='column'
                    onClick={function () {
                      setTabState("quiz");
                    }}
                    bg='unset'
                    _selected={{
                      bg: "none",
                    }}
                    _focus={{ border: "none" }}
                    minW='max-content'>
                    <Flex align='center'>
                      <Text
                        color={
                          tabState === "quiz" ? textColor : textColorTertiary
                        }
                        fontSize='lg'
                        fontWeight='500'>
                        Quiz (3)
                      </Text>
                    </Flex>
                    <Box
                      height='4px'
                      w='100%'
                      transition='0.1s linear'
                      bg={tabState === "quiz" ? "brand.500" : "transparent"}
                      mt='15px'
                      borderRadius='30px'
                    />
                  </Tab>
                </Flex>
              </TabList>

              <TabPanels pt='30px'>
                <TabPanel px='0px'>{panelExample()}</TabPanel>
                <TabPanel px='0px'>{panelExample()}</TabPanel>
                <TabPanel px='0px'>{panelExample()}</TabPanel>
              </TabPanels>
            </Tabs> */}
            {/* <Flex direction={{ base: "column", md: "row" }} align='center'>
              <Box
                w={{ base: "unset", md: "30%", "3xl": "50%" }}
                ms={{ base: "auto", md: "unset" }}
                me={{ base: "auto", "3xl": "50px" }}>
                <Text
                  fontSize={{ base: "70px", "3xl": "80px" }}
                  color='orange.500'
                  fontWeight='700'
                  lineHeight='105%'
                  maxW='max-content'>
                  4.8
                </Text>
                <Flex mb='8px' maxW='max-content'>
                  <Icon color='orange.500' h='24px' w='24px' as={IoMdStar} />
                  <Icon color='orange.500' h='24px' w='24px' as={IoMdStar} />
                  <Icon color='orange.500' h='24px' w='24px' as={IoMdStar} />
                  <Icon color='orange.500' h='24px' w='24px' as={IoMdStar} />
                  <Icon
                    color='orange.500'
                    h='24px'
                    w='24px'
                    as={IoMdStarHalf}
                  />
                </Flex>
                <Text
                  fontSize='lg'
                  color={textColorTertiary}
                  fontWeight='500'
                  maxW='max-content'
                  mb={{ base: "20px", md: "0px" }}>
                  Course Rating
                </Text>
              </Box>
              <Box>
                <Rating value='78' mb='5px' stars={5} />
                <Rating value='24' mb='5px' stars={4} />
                <Rating value='12' mb='5px' stars={3} />
                <Rating value='8' mb='5px' stars={2} />
                <Rating value='4' stars={1} />
              </Box>
            </Flex> */}
            
          </Box>

          <Box
            mx={{ base: "auto", xl: "unset" }}
            maxW={{
              sm: "100%",
              md: "550px",
              lg: "500px",
              "2xl": "800px",
              "3xl": "300px",
            }}>
            <Instructor />
          </Box>
        </Flex>
      </Box>
    </Card>
  );
}
