import React, { useState, useEffect, useContext, useMemo } from "react";
import { Box, Grid, useColorModeValue, useDisclosure } from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import Joyride, { STATUS } from "react-joyride";
import { useMount, useSetState } from "react-use";
import a11yChecker from "a11y-checker";

import Messages2WithMultiline from "views/admin/main/others/messages/components/Messages2WithMultiline";
import InitialModal from "./components/InitialModal";
import TutorialModal from "layouts/HomeNew/Search/TutorialModal";
import { ConfigContext } from "contexts/ConfigContext";
import { AuthContext } from "contexts/AuthContext";
import useCustomHistory from "langHoc/useCustomHistory";
import { userPrivateRequest } from "config/axios.config";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function Section(props) {
  return <Box color="#fff" height="100vh" px="md" py="xl" {...props} />;
}
function logGroup(type, data) {
  console.groupCollapsed(type);
  console.groupEnd();
}
export default function NewMessage2() {
  const configData = useContext(ConfigContext);
  const COLORS = configData?.config?.global?.COLORS?.value;
  const primaryColor = useColorModeValue(
    COLORS.primaryColor,
    COLORS.primaryDarkColor
  );
  const config = configData.config || {};
  const authData = useContext(AuthContext);
  const isAuthenticated = authData?.authState?.isAuthenticated;
  let tutorials = config?.global?.TUTORIAL_MESSAGE?.value;

  const lang = useLocation();
  const isKor = lang?.pathname.includes("/ko");

  const accessAbleUser =
    authData?.authState?.user?.membership === "pro" ||
    authData?.authState?.user?.usedFreeModeCredit === "yes";
  const first_step_message = isKor
    ? tutorials?.first_step_messageKr
    : tutorials?.first_step_message;

  const second_step_message = isKor
    ? tutorials?.second_step_messageKr
    : tutorials?.second_step_message;

  const third_step_message = isKor
    ? tutorials?.third_step_messageKr
    : tutorials?.third_step_message;

  const fourth_step_message = isKor
    ? tutorials?.fourth_step_messageKr
    : tutorials?.fourth_step_message;

  const [isTutorial, seIsTutorial] = useState(true);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [{ run, steps }, setState] = useSetState({
    run: true,
    steps: [],
  });

  const [isVideoTutorialDone, setIsVideoTutorialDone] = useState(true);
  useMount(() => {
    a11yChecker();
  });

  useEffect(() => {
    const checkTargets = () => {
      const targets = [".floatingMessage", "body"]; // Add all your target selectors here
      targets.forEach((selector) => {
        const element = document.querySelector(selector);
        console.log(
          `Checking existence and visibility for target: ${selector}`,
          element ? "Exists" : "Does not exist",
          element && element.offsetHeight > 0 ? "Visible" : "Not Visible"
        );
      });
    };

    checkTargets();
  }, []); // You might need to adjust the dependency array based on when your elements are expected to be in the DOM

  useEffect(() => {
    if (
      !localStorage.getItem("TUTORIAL_TOUR") &&
      tutorials &&
      isAuthenticated &&
      accessAbleUser &&
      isVideoTutorialDone
    ) {
      const newSteps = [
        {
          content: <h2>{first_step_message}</h2>,
          // locale: { skip: <strong aria-label="skip">S-K-I-P</strong> },
          placement: "center",
          target: ".firstjoyride",
        },
        {
          content: <h2>{second_step_message}</h2>,
          floaterProps: {
            disableAnimation: true,
          },
          // spotlightPadding: 40,
          //TODO FIX THE RIGHT TARGET HERE
          target: ".joyrideapps",
        },
        {
          content: third_step_message,
          placement: "top",
          styles: {
            options: {
              width: 300,
            },
          },
          target: ".css-r1ylyf",
          // title: 'Our projects',
        },
        {
          content: <div>{fourth_step_message}</div>,
          placement: "right-start",
          target: ".sidebarjoyride",
          // title: 'Our Mission',
        },
      ];
      setState({
        run: true,
        steps: newSteps,
      });
      seIsTutorial(true);
    } else if (isVideoTutorialDone) {
      if (accessAbleUser) {
        seIsTutorial(false);
      } else {
        seIsTutorial(true);
      }
    }
  }, [tutorials, isAuthenticated, accessAbleUser, isVideoTutorialDone]);

  const query = useQuery();
  const customhistory = useCustomHistory();
  const history = useCustomHistory();

  const applyExucation = async () => {
    try {
      const result = await userPrivateRequest
        .get(
          `/execute-agreement?token=${query.get("token")}&paymentId=${query.get(
            "paymentId"
          )}`
        )
        .then((response) => {
          authData.profileFetch();
        });
    } catch (err) {}

    customhistory.replace("/chat");
  };

  useEffect(() => {
    if (query.get("token")) {
      applyExucation();
    }
  }, [query.get("token")]);

  const lockScroll = () => {
    console.log("zz lock");
    document.body.classList.add("body-lock");
  };

  const unlockScroll = () => {
    console.log("zz unlock");
    document.body.classList.remove("body-lock");
  };

  const [joyrideActive, setJoyrideActive] = useState(false);

  useEffect(() => {
    lockScroll();
    return () => {
      unlockScroll();
    };
  }, [joyrideActive]);

  const handleJoyrideCallback = (data) => {
    const { action, index, status, type, step } = data;

    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      setJoyrideActive(false);
      setState({ run: false });
      localStorage.setItem("TUTORIAL_TOUR", "true");
      seIsTutorial(false);
    } else {
      setJoyrideActive(true);
    }

    if (finishedStatuses.includes(status)) {
      setState({ run: false });
      localStorage.setItem("TUTORIAL_TOUR", true);
      seIsTutorial(false);
    }

    logGroup(type, data); // Ensure logGroup also logs information
  };

  return (
    <Box pt="15px">
      <Grid
        gridTemplateColumns={{ xl: "repeat(3, 1fr)", "2xl": "1fr 0.46fr" }}
        gap={{ base: "10px", xl: "10px" }}
        display={{ base: "block", xl: "grid" }}
        className="firstjoyride"
      >
        <InitialModal isOpen={isOpen} onOpen={onOpen} onClose={onClose} />
        {!isVideoTutorialDone &&
          isAuthenticated &&
          (authData?.authState?.user?.membership === "pro" ||
            authData?.authState?.user?.usedFreeModeCredit === "yes") && (
            <>
              {config?.global?.PLAY_GROUND?.value === "YES" && (
                <TutorialModal
                  modalClose={() => {
                    setIsVideoTutorialDone(true);
                  }}
                />
              )}
            </>
          )}

        <Messages2WithMultiline
          status="active"
          name="Roberto Michael"
          onOpen={onOpen}
          isOpen={isOpen}
          isTutorial={isTutorial}
        />
      </Grid>
      {config?.global?.PLAY_GROUND?.value === "YES" && steps.length > 0 && (
        <Joyride
          callback={handleJoyrideCallback}
          continuous={true}
          hideCloseButton
          run={run}
          scrollToFirstStep
          showProgress
          steps={steps}
          styles={{
            options: {
              primaryColor,
              zIndex: 1000, // Ensure this is high enough if there are z-index issues
            },
            overlay: {
              zIndex: 1050, // Example value, adjust as needed
            },
            tooltip: {
              zIndex: 1051, // Example value, adjust as needed
            },
          }}
        />
      )}
    </Box>
  );
}
