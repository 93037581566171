import { Box, Flex, Image, Link, ListItem, Text } from "@chakra-ui/react";
import { useState } from "react";
import { MdPlayCircle } from "react-icons/md";
import ReactPlayer from "react-player";
import titleCase from "utils/titleCase";

const AllResultVideo = ({ gs, mediaInfo }) => {
  const [isPlaying, setIsplaying] = useState(false);
  const [isLight, setIsLight] = useState(true);

  return (
    <ListItem  className="search-root" m={0}>
      <Flex
        gap={"5"}
        direction={{ base: "column", xl: "row" }}
      >
        <Box
          onMouseEnter={() => {
            setIsLight(false);
            setIsplaying(true);
          }}
          onMouseOut={() => {
            setIsplaying(false);
          }}
          className="player-wrapper"
        >
          <Link
            color="#1c73e2"
            href={gs.link}
            target="_blank"
            fontWeight={"400"}
            className="google-search-item"
          >
            <span>{`[${gs.position}]  `}</span> {gs.title}
          </Link>
          
        </Box>
        <Box width={{ base: "100%", xl: "70%" }} display={"none"}>
          <Link
            color="#2b6cb0"
            href={gs.link}
            target="_blank"
            fontWeight={"400"}
            className="google-search-item"
          >
            <span>{`[${gs.position}]  `}</span> {gs.title}
          </Link>
          <p> {gs.snippet} </p>

          <Text
            color="#1c73e2"
            href={gs.link}
            target="_blank"
            fontWeight={"400"}
            display={"flex"}
          >
            {titleCase(mediaInfo.provider)}
          </Text>
          <p> {gs.date} </p>
          {gs?.sitelinks && (
            <ul
              style={{
                listStyle: "none",
                display: "flex",
                gap: "20px",
                marginTop: "5px",
              }}
            >
              <li
                style={{
                  fontWeight: "bold",
                }}
              >
                Reference:
              </li>
              {gs?.sitelinks?.map((sl) => {
                return (
                  <li>
                    <Link color="#2b6cb0" href={sl.link} target="_blank">
                      {sl.title}
                    </Link>
                  </li>
                );
              })}
            </ul>
          )}
        </Box>
      </Flex>
    </ListItem>
  );
};
export default AllResultVideo;
