import React, { useState, useContext, useEffect } from "react";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  useColorModeValue,
  Text,
  Spinner,
  Checkbox,
  Image,
} from "@chakra-ui/react";
import { FcGoogle } from "react-icons/fc";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine, RiKakaoTalkFill } from "react-icons/ri";
import ReCAPTCHA from "react-google-recaptcha";
import { HSeparator } from "components/separator/Separator";
import CenteredAuth from "layouts/auth/types/CenteredNew";
import { userPublicRequest } from "config/axios.config";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AuthContext } from "contexts/AuthContext";
import { ConfigContext } from "contexts/ConfigContext";
import CustomNavLink from "langHoc/CustomNavLink";
import useCustomHistory from "langHoc/useCustomHistory";
import { useTranslation } from "react-i18next";
import Card from "components/card/Card";
import { googleProvider } from "./SocialAuth/authMethods";
import socialMediaAuth from "./SocialAuth/auth";
import MetamaskLoginButton from "./MetamaskLoginButton";
import Footer from "components/footer/FooterAdmin";
import { useLocation } from "react-router-dom/cjs/react-router-dom";

function SignIn() {
  const { dispatch, profileFetch, authState } = useContext(AuthContext);
  const configData = useContext(ConfigContext);
  const textColor = useColorModeValue("navy.700", "navy.700");
  const textColorSecondary = "gray.400";
  const textColorDetails = useColorModeValue("navy.700", "navy.700");
  const textColorBrand = useColorModeValue("brand.500", "brand.500");
  const brandStars = useColorModeValue("brand.500", "brand.500");
  const googleBg = useColorModeValue("gray.100", "gray.700");
  const googleText = useColorModeValue("navy.700", "white");
  const googleHover = useColorModeValue({ bg: "gray.200" }, { bg: "gray.600" });
  const googleActive = useColorModeValue(
    { bg: "gray.300" },
    { bg: "gray.500" }
  );
  const kgoogleHover = useColorModeValue({ bg: "#fada2f" }, { bg: "#fada2f" });
  const kgoogleActive = useColorModeValue(
    { bg: "gray.300" },
    { bg: "gray.600" }
  );
  const [show, setShow] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [formData, setFormData] = React.useState({});
  const [accessToken, setAccessToken] = useState("");
  const handleClick = () => setShow(!show);
  const history = useCustomHistory();
  let configs = useContext(ConfigContext);
  const url = window.location.href;
  const urlObj = new URL(url);
  const params = new URLSearchParams(urlObj.search);
  const sourceValue = params.get("source");
  const langs = useLocation();
  const isKor = langs?.pathname.includes("/ko");

  function redirection() {
    const urlObj = new URL(url);
    const params = new URLSearchParams(urlObj.search);
    const sourceValue = params.get("source");

    if (sourceValue === "art") {
      setTimeout(() => {
        window.location.href = `${process.env.REACT_APP_ART_APP}`;
      }, 1500);

      return;
    } else if (sourceValue === "music") {
      setTimeout(() => {
        window.location.href = `${process.env.REACT_APP_MUSIC_APP}`;
      }, 1500);

      return;
    } else if (sourceValue === "agi") {
      setTimeout(() => {
        window.location.href = `${process.env.REACT_APP_AGI_APP}`;
      }, 1500);
    } else if (sourceValue === "video") {
      setTimeout(() => {
        window.location.href = `${process.env.REACT_APP_VIDEO_APP}`;
      }, 1500);
    } else {
      setTimeout(() => {
        history.push(sourceValue === "chat" ? "/chat" : "/");
      }, 1500);
    }
  }

  const { t } = useTranslation();
  const lang = window.location.pathname.split("/")[1] || "en";

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    userPublicRequest
      .post("/auth/login", { ...formData, lang })
      .then((res) => {
        let statusCode = res.status;
        if (statusCode == 205) {
          history.push(`/auth/verify?email=${formData.email}`);
          return;
        }
        const login = {
          data: {
            ...res.data.data.user,
          },
          token: res.data.data.token,
          remember_me: false,
        };

        dispatch({
          type: "LOGIN",
          payload: login,
        });
        profileFetch();
        redirection();
      })
      .catch((error) => {
        let message = error.response.data.message;
        if (message === "Your account has not been activated!") {
          message = t("YourAccountHasNotBeenActivated");
        }
        if (message === "Email or password did not match.") {
          message = t("EmailOrPasswordDidNotMatch");
        }
        const toastId = "signin-error-toast";
        if (!toast.isActive(toastId)) {
          toast.error(message, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            toastId: toastId,
          });
        }
      })
      .finally((e) => {
        setLoading(false);
      });
  };

  const changeForm = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const kakaoSubmit = (accessToken) => {
    setLoading(true);

    userPublicRequest
      .post("/auth/kakao", { token: accessToken })
      .then((res) => {
        const login = {
          data: {
            ...res.data.data.user,
          },
          token: res.data.data.token,
          remember_me: false,
          expires_at: "2023-01-11T10:54:32.795Z",
        };
        dispatch({
          type: "LOGIN",
          payload: login,
        });
        profileFetch();
        history.push("/chat");
      })
      .catch((error) => {
        const toastId = "signin-error-toast";
        if (!toast.isActive(toastId)) {
          toast.error(error.response.data.message, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            toastId: toastId,
          });
        }
      })
      .finally((e) => {
        setLoading(false);
      });
  };

  const kakaoLogin = () => {
    const { Kakao } = window;
    Kakao.Auth.login({
      scope: "profile_nickname, account_email",
      success: (response) => {
        setAccessToken(response.access_token);
        kakaoSubmit(response.access_token);
        redirection();
      },
      fail: (error) => {
        alert(error.error);
      },
    });
  };

  const handleGoogleLogin = async () => {
    try {
      googleProvider.setCustomParameters({
        login_hint: "",
      });
      const res = await socialMediaAuth(googleProvider.addScope("email"));
      userPublicRequest
        .post("/auth/google", { token: res?.credential?.idToken })
        .then((res) => {
          const login = {
            data: {
              ...res.data.data.user,
            },
            token: res.data.data.token,
            remember_me: false,
            expires_at: "2023-01-11T10:54:32.795Z",
          };

          dispatch({
            type: "LOGIN",
            payload: login,
          });
          profileFetch();
          redirection();
        })
        .catch((error) => {})
        .finally((e) => {
          setLoading(false);
        });
    } catch (err) {}
  };

  const handleCaptchaChange = (value) => {
    setFormData({
      ...formData,
      recaptcha: value,
    });
  };

  useEffect(() => {
    if (authState.isAuthenticated) {
      history.push("/");
    }
  }, [authState]);

  const CGSV = configData?.config?.global?.SEARCH_PAGE?.value;
  const subtitle = isKor ? CGSV?.searchSubTitleKr : CGSV?.searchSubTitle;
  const COLORS = configData?.config?.global?.COLORS?.value;

  return (
    <Flex
      direction="column"
      overflow="hidden"
      justifyContent="center"
      alignItems="center"
      mx={{ base: "0px", lg: "0px" }}
      bg={"white"}
      height={"100vh"}
      pb="10px"
      px="20px"
    >
      {Object.keys(configData?.config || {}).length > 0 &&
        configData?.config?.global?.WEB3_AUTHENTICATION?.value === "NO" && (
          <Box
            display="flex"
            justifyContent={"center"}
            alignItems={"center"}
            flexDirection="column"
            width="100%"
          >
            <CustomNavLink to="/">
              <Box position="relative">
                <Image
                  src={configs?.logo}
                  alt="logo"
                  width={{ base: "300px", lg: "300px" }}
                  marginBottom="40px"
                  marginX="auto"
                />
              </Box>
            </CustomNavLink>
            <Box
              height={"100%"}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              width={"100%"}
              maxW="600px" // Increased width here
            >
              <Card
                w={{ base: "100%", md: "max-content" }}
                h="max-content"
                mx="auto"
                maxW="100%"
                p="10px"
                pt="10px"
                bg={"transparent"} // Set background to transparent
                style={{ boxShadow: "unset" }}
              >
                <Box>
                  <Flex
                    maxW={{ base: "100%", md: "max-content" }}
                    w="100%"
                    mx={{ base: "auto", lg: "0px" }}
                    me="auto"
                    justifyContent="center"
                    px={{ base: "0px", md: "0px" }}
                    flexDirection="column"
                  >
                    <Box me="auto" textAlign="center">
                      <Heading
                        color={textColor}
                        fontSize="20px"
                        mb="12px"
                        lineHeight={1}
                      >
                        {t("Signin")}
                      </Heading>
                    </Box>
                    <Flex
                      zIndex="2"
                      direction="column"
                      w={{ base: "100%", md: "400px" }} // Increased width here
                      maxW="100%"
                      background="transparent"
                      borderRadius="10px"
                      mx={{ base: "auto", lg: "unset" }}
                      me="auto"
                      mb={{ base: "0px", md: "auto" }}
                    >
                      <Button
                        fontSize="sm"
                        me="0px"
                        mb="12px"
                        py="8px"
                        h="42px"
                        borderRadius="10px"
                        bg={googleBg}
                        color={googleText}
                        fontWeight="500"
                        _hover={googleHover}
                        _active={googleActive}
                        _focus={googleActive}
                        onClick={handleGoogleLogin}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          border: "none",
                        }}
                      >
                        <Icon as={FcGoogle} w="14px" h="14px" me="6px" />
                        {t("GoogleSigninBtn")}
                      </Button>

                      <Button
                        fontSize="sm"
                        me="0px"
                        mb="10px"
                        py="8px"
                        h="42px"
                        borderRadius="10px"
                        bg={"#FFEB00"}
                        color={googleText}
                        fontWeight="500"
                        _hover={kgoogleHover}
                        _active={kgoogleHover}
                        _focus={kgoogleHover}
                        onClick={kakaoLogin}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          border: "none",
                        }}
                      >
                        <Icon as={RiKakaoTalkFill} w="14px" h="14px" me="6px" />
                        {t("LoginKakao")}
                      </Button>

                      <Flex align="center" mb="10px">
                        <HSeparator />
                        <Text
                          color={textColorSecondary}
                          mx="8px"
                          fontSize={"12px"}
                          height={"12px"}
                          lineHeight={1}
                        >
                          or
                        </Text>
                        <HSeparator />
                      </Flex>

                      <form
                        onSubmit={handleSubmit}
                        style={{
                          background: "transparent",
                          padding: "0",
                          borderRadius: "8px",
                          width: "100%",
                        }}
                      >
                        <FormControl>
                          <FormLabel
                            display="flex"
                            ms="4px"
                            fontSize="sm"
                            fontWeight="500"
                            color={textColor}
                            mb="4px"
                            lineHeight={1}
                          >
                            {t("EmailAddress")}
                            <Text color={brandStars}>*</Text>
                          </FormLabel>
                          <Input
                            isRequired={true}
                            variant="auth"
                            fontSize="sm"
                            ms={{ base: "0px", md: "0px" }}
                            type="email"
                            placeholder="Enter your email"
                            mb="12px"
                            fontWeight="500"
                            size="md"
                            name="email"
                            onChange={changeForm}
                            value={formData.email}
                            color={"black"}
                            style={{
                              border: "1px solid #ccc",
                              borderRadius: "10px",
                              padding: "8px",
                            }}
                          />
                          <FormLabel
                            ms="4px"
                            fontSize="sm"
                            fontWeight="500"
                            color={textColor}
                            isRequired={true}
                            display="flex"
                            lineHeight={1}
                            mb="4px"
                          >
                            {t("Password")}
                            <Text color={brandStars}>*</Text>
                          </FormLabel>
                          <InputGroup
                            size="md"
                            style={{
                              border: "1px solid #ccc",
                              borderRadius: "10px",
                            }}
                          >
                            <Input
                              isRequired={true}
                              fontSize="sm"
                              ms={{ base: "0px", md: "0px" }}
                              placeholder="Enter your password"
                              size="md"
                              type={show ? "text" : "password"}
                              variant="auth"
                              onChange={changeForm}
                              name="password"
                              value={formData.password}
                              color={"black"}
                              style={{
                                border: "none",
                                padding: "8px",
                                height: "40px",
                                borderRadius: "10px",
                              }}
                            />
                            <InputRightElement
                              display="flex"
                              alignItems="center"
                            >
                              <Icon
                                color={textColorSecondary}
                                _hover={{ cursor: "pointer" }}
                                as={
                                  show ? RiEyeCloseLine : MdOutlineRemoveRedEye
                                }
                                onClick={handleClick}
                              />
                            </InputRightElement>
                          </InputGroup>

                          <FormControl
                            display="flex"
                            alignItems="center"
                            mb="12px"
                            mt="12px"
                            justifyContent="center" // Center the reCAPTCHA widget
                          >
                            <Box style={{ transform: "scale(0.75)" }}> 
                              <ReCAPTCHA
                                sitekey={
                                  process.env.REACT_APP_RECAPTCHA_SITE_KEY
                                }
                                onChange={handleCaptchaChange}
                              />
                            </Box>
                          </FormControl>

                          <FormControl
                            display="flex"
                            alignItems="center"
                            mb="12px"
                          >
                            <Checkbox
                              id="remember-login"
                              colorScheme="brandScheme"
                              me="10px"
                              size="sm"
                            />
                            <FormLabel
                              htmlFor="remember-login"
                              mb="0"
                              fontWeight="normal"
                              color={textColor}
                              fontSize="sm"
                              cursor="pointer"
                            >
                              {t("KeepMeLogin")}
                            </FormLabel>
                          </FormControl>

                          <Button
                            fontSize="sm"
                            variant="brand"
                            fontWeight="500"
                            w="100%"
                            h="40"
                            mb="12px"
                            type="submit"
                            bg="#6c00ff"
                            _hover={{
                              bg: "#5b00d9",
                            }}
                            _active={{
                              bg: "#5b00d9",
                            }}
                            _focus={{
                              bg: "#5b00d9",
                            }}
                            style={{ borderRadius: "10px", height: "40px" }}
                          >
                            {loading ? (
                              <>
                                <Spinner />
                              </>
                            ) : (
                              t("Signin")
                            )}
                          </Button>
                        </FormControl>
                      </form>

                      <Flex
                        flexDirection="row"
                        justifyContent="space-between"
                        alignItems="center"
                        maxW="100%"
                        mt="8px"
                      >
                        <Text
                          color={textColorDetails}
                          fontWeight="400"
                          fontSize="12px"
                        >
                          <CustomNavLink to="/auth/sign-up">
                            <Text
                              color={textColorBrand}
                              as="span"
                              ms="5px"
                              fontWeight="500"
                            >
                              {t("Signup")}
                            </Text>
                          </CustomNavLink>
                        </Text>
                        <CustomNavLink to="/auth/forgot">
                          <Text
                            color={textColorBrand}
                            fontSize="12px"
                            fontWeight="500"
                          >
                            {t("ForgotPassword")}
                          </Text>
                        </CustomNavLink>
                      </Flex>
                    </Flex>
                  </Flex>
                </Box>
              </Card>
            </Box>
          </Box>
        )}

      {Object.keys(configData?.config || {}).length > 0 &&
        configData?.config?.global?.WEB3_AUTHENTICATION?.value === "YES" && (
          <Box
            minH={"calc(100vh - 50px)"}
            display="flex"
            justifyContent={"center"}
            alignItems={"center"}
            p="85px 0px 50px 0px"
            flexDirection="column"
            maxW={{
              base: "100%",
              lg: "400px",
            }}
            w={{ base: "300px", lg: "100%" }}
            margin={"auto"}
          >
            <Box position={"relative"} w="100%">
              <Box
                position="absolute"
                w={{ base: "300px", lg: "100%" }}
                left="50%"
                maxW={{
                  base: "100%",
                  lg: "400px",
                }}
                marginLeft={{ base: "-0px", lg: "-0px" }}
                textAlign="center"
                transform="translateX(-50%)"
                bottom={{ base: "99.5%", lg: "100%" }}
              >
                <Image
                  src={configs?.logo}
                  alt="logo"
                  width={{ base: "400px", lg: "400px" }}
                  marginBottom="10px"
                  marginX="auto"
                />
                <Text
                  mb="40px"
                  color={"#707eae"}
                  fontWeight={500}
                  fontSize={"16px"}
                >
                  {" "}
                  {subtitle}{" "}
                </Text>
              </Box>
              <Box
                height={"100%"}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                width={"100%"}
              >
                <MetamaskLoginButton type={"signinPage"} />
              </Box>
            </Box>
          </Box>
        )}
      <Footer />
    </Flex>
  );
}

export default SignIn;
