import React, { useEffect, useState } from "react";

// Chakra imports
import { Flex, useColorModeValue } from "@chakra-ui/react";

// Custom components
import { HorizonLogo } from "components/icons/Icons";
import { HSeparator } from "components/separator/Separator";
import logoImage from "assets/img/CHINGU.svg";
import ChinguLogo from "assets/img/logo-a.png";
import DappxLogo from 'assets/img/dappx-logo-2.png'
import QuestronLogo from 'assets/img/u-logo-a.png'
export function SidebarBrand() {
  //   Chakra color mode
  let logoColor = useColorModeValue("navy.700", "white");
  const [logo, setLogo] = useState(ChinguLogo); // Default logo

  useEffect(() => {
    switch (process.env.REACT_APP_PLATFORM) {
      case "Chingu":
        setLogo(ChinguLogo);
        break;
      case "Questron":
        setLogo(QuestronLogo);
        break;
      case "DappStoreAi":
        setLogo(DappxLogo);
        break;
      default:
        setLogo(ChinguLogo);
    }
  }, []);
  return (

<Flex align='center' direction='column'>
  <a href="https://chingu.fun" rel="noopener noreferrer">
    <img src={logo} alt="logo" style={{
      width: '100%',
      marginTop: '8%',
      marginBottom: '5%',
    }}
    />
  </a>
  {/* <HorizonLogo h='26px' w='175px' my='32px' color={logoColor} /> */}
</Flex>


  );
}

export default SidebarBrand;
