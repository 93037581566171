import {
  Box,
  Flex,
  IconButton,
  Input,
  Text,
  useDisclosure,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Button,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  Select,
} from "@chakra-ui/react";
import { userPrivateRequest } from "config/axios.config";
import { ConfigContext } from "contexts/ConfigContext";
import { useEffect } from "react";
import { useState } from "react";
import { useContext } from "react";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import v from "voca";

const GetVideoConfirmation = ({
  generateOneClickVideo,
  onClose,
  selectedPrompt,
  setVoice,
  setOrientation,
  orientation,
  language,
  setLanguage,
  voice,
  setIncludeCaption,
  includeCaption,
}) => {
  const lang = useLocation()
  const isKor = lang?.pathname.includes('/ko')
  const configData = useContext(ConfigContext);
  const VIDEO_CONFIGURATION =
    configData?.config?.global?.VIDEO_CONFIGURATION?.value || {};
  // // console.log(VIDEO_CONFIGURATION, "config");
  const [shortGptForm, setShortGptForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [eleLanguage, setEleLanguage] = useState([
    "English",
    "Korean",
    "Spanish",
    "French",
    "Arabic",
    "German",
    "Polish",
    "Italian",
    "Portuguese",
  ]);
  const [voices, setVoices] = useState([]);

  const fetchData = async () => {
    try {
      // const resultEle = await userPrivateRequest.get(
      //   "/video-generation/languages/elevenLab"
      // );
      // setEleLanguage(resultEle?.data?.data);

      // const resultVoice = [
      //   "Mimi",
      //   "Giovanni",
      //   "Glinda",
      //   "Sam",
      //   "Ryan",
      //   "Jessie",
      //   "Nicole",
      //   "Adam",
      //   "Serena",
      //   "Daniel",
      //   "Grace",
      //   "Freya",
      //   "Gigi",
      //   "Ethan",
      //   "Michael",
      //   "Jeremy",
      //   "Joseph",
      //   "James",
      //   "Matthew",
      //   "Matilda",
      //   "Charlotte",
      //   "Arnold",
      //   "Josh",
      //   "Dorothy",
      //   "Liam",
      //   "Harry",
      //   "Patrick",
      //   "Callum",
      //   "Elli",
      //   "Emily",
      //   "Charlie",
      //   "Thomas",
      //   "Antoni",
      //   "Bella",
      //   "Fin",
      //   "Dave",
      //   "Domi",
      //   "Clyde",
      //   "Rachel",
      //   "Michael - Deep, Resonant, Confident",
      // ];


      const resultVoice = [
        "alloy", "echo", "fable", "onyx", "nova", "shimmer"
      ];
      // Set voice to a random voice from the API response
      const randomVoice =
        resultVoice[Math.floor(Math.random() * resultVoice?.length)];

      // setVoice(randomVoice);
      setVoices(resultVoice);
      // // console.log(resultVoice);
    } catch (err) {}
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    // // console.log(
    //   selectedPrompt,
    //   selectedPrompt?.name?.includes("Vertical"),
    //   626262
    // );
    if (!selectedPrompt?.name?.includes("Vertical")) {
      setOrientation("landscape");
    } else {
      setOrientation("vertical");
    }
  }, [selectedPrompt]);
  // console.log(voice, "voice");
  return (
    <Modal id="SHARE_MODAL" onClose={onClose} isOpen={true} isCentered={true}>
      <ModalOverlay
        backdropFilter={"blur(2px) opacity(0.9) hue-rotate(20deg)"}
      />
      <ModalContent
        borderRadius={"20px"}
        boxShadow="rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px"
        my="auto"
        w="800px"
        // maxWidth={"500px"}
        maxWidth={
          selectedPrompt?.allowVideoCreate === "BOTH" ? "800px" : "500px"
        }
      >
        <ModalCloseButton
          autoFocus={false}
          bg="transparent"
          top="16px"
          right="16px"
          _focus={{
            boxShadow: "none",
          }}
        />
        <ModalBody padding="16px 24px 16px 24px">
          {shortGptForm && (
            <Box>
              <ModalHeader pl="0">
              {isKor ? VIDEO_CONFIGURATION?.shortGptFormTitleKr : VIDEO_CONFIGURATION?.shortGptFormTitle}
              
              </ModalHeader>
              <form
              // onSubmit={handleSubmit}
              >
                <FormControl>
                  <FormLabel
                    fontSize="sm"
                    fontWeight="500"
                    color={"#000"}
                    mb="10px"
                  >
                    Orientation
                  </FormLabel>
                  <RadioGroup value={orientation} onChange={setOrientation}>
                    <Radio
                      value="portrait"
                      colorScheme="teal"
                      mb="20px"
                      mr="10px"
                    >
                      Portrait
                    </Radio>
                    <Radio value="landscape" colorScheme="teal">
                      Landscape
                    </Radio>
                  </RadioGroup>
{/* 
                  <FormLabel
                    fontSize="sm"
                    fontWeight="500"
                    color={"#000"}
                    mb="10px"
                  >
                    Language
                  </FormLabel>
                  <Select
                    value={language}
                    onChange={(e) => {
                      setLanguage(e.target.value);
                      if (e.target.value == "Korean") { 
                        setVoice("Michael - Deep, Resonant, Confident")
                      } else {
                        
                      }
                    }}
                    placeholder="Select language"
                    mb="20px"
                  >
                    {eleLanguage?.map((el) => {
                      return <option value={el}>{el}</option>;
                    })}
                  </Select> */}

                  <FormLabel
                    fontSize="sm"
                    fontWeight="500"
                    color={"#000"}
                    mb="10px"
                  >
                    Voice
                  </FormLabel>
                  <Select
                    value={voice}
                    onChange={(e) => setVoice(e.target.value)}
                    placeholder="Select voice"
                    mb="20px"
                  >
                    {voices?.map((el) => {
                      return <option value={el}>{v.titleCase(el)}</option>;
                    })}
                    {/* Add other voice options as needed */}
                  </Select>

                  {/* <FormLabel
                    fontSize="sm"
                    fontWeight="500"
                    color={"#000"}
                    mb="10px"
                  >
                    Captions
                  </FormLabel>
                  <RadioGroup
                    value={includeCaption}
                    onChange={setIncludeCaption}
                  >
                    <Radio value="yes" colorScheme="teal" mb="20px" mr="10px">
                      On
                    </Radio>
                    <Radio value="no" colorScheme="teal">
                      Off
                    </Radio>
                  </RadioGroup> */}

                  <Button
                    fontWeight="700"
                    color="#fff"
                    bg="#4FDABC"
                    py="5px"
                    px="25px"
                    borderRadius="10px"
                    width="110px"
                    _hover={{
                      bg: "rgba(79, 218, 188, .8)",
                    }}
                    _active={{
                      bg: "#4FDABC",
                    }}
                    _focus={{
                      bg: "#4FDABC",
                    }}
                    onClick={() => {
                      setShortGptForm(false);
                    }}
                    mr="20px"
                  >
                    Back
                  </Button>
                  <Button
                    fontWeight="700"
                    color="#fff"
                    bg="#4FDABC"
                    py="5px"
                    px="25px"
                    borderRadius="10px"
                    width="110px"
                    _hover={{
                      bg: "rgba(79, 218, 188, .8)",
                    }}
                    _active={{
                      bg: "#4FDABC",
                    }}
                    _focus={{
                      bg: "#4FDABC",
                    }}
                    onClick={() => {
                      onClose();
                      generateOneClickVideo(orientation, language, voice);
                    }}
                  >
                    Generate
                  </Button>
                </FormControl>
              </form>
            </Box>
          )}
          {!shortGptForm && (
            <Box d="flex" flexWrap={"wrap"} justifyContent={"space-between"}>
              {(selectedPrompt?.allowVideoCreate === "SHORTGPT" ||
                selectedPrompt?.allowVideoCreate === "BOTH") && (
                <Box
                  textAlign="center"
                  w={
                    selectedPrompt?.allowVideoCreate === "BOTH" ? "50%" : "100%"
                  }
                  borderRight={
                    selectedPrompt?.allowVideoCreate === "BOTH"
                      ? "1px solid #ddd"
                      : "0px"
                  }
                >
                  <ModalHeader>
                    {isKor ? VIDEO_CONFIGURATION?.shortGptTitleKr : VIDEO_CONFIGURATION?.shortGptTitle}
                  </ModalHeader>
                  <Box padding="0px 0px 16px 0px">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: isKor ? VIDEO_CONFIGURATION?.shortGptDescriptionKr : VIDEO_CONFIGURATION?.shortGptDescription 
                      }}
                    />
                  </Box>
                  {/* <Button
                    fontWeight="700"
                    color={"#fff"}
                    fontFamily={"Nunito Sans, sans-serif"}
                    bg="#FF0000"
                    py="5px"
                    px="25px"
                    borderRadius={"10px"}
                    width="110px"
                    textAlign={"center"}
                    _hover={{
                      bg: "rgba(79, 218, 188, .8)",
                    }}
                    _active={{
                      bg: "#FF0000",
                    }}
                    _focus={{
                      bg: "#FF0000",
                    }}
                    onClick={() => {
                      onClose();
                      generateOneClickVideo(orientation, language, voice);
                      //  setShortGptForm(true);
                    }}
                    mr="20px"
                  >
                    Cancel
                  </Button> */}
                  <Button
                    fontWeight="700"
                    color={"#fff"}
                    fontFamily={"Nunito Sans, sans-serif"}
                    bg="#4FDABC"
                    py="5px"
                    px="25px"
                    borderRadius={"10px"}
                    width="110px"
                    textAlign={"center"}
                    _hover={{
                      bg: "rgba(79, 218, 188, .8)",
                    }}
                    _active={{
                      bg: "#4FDABC",
                    }}
                    _focus={{
                      bg: "#4FDABC",
                    }}
                    onClick={() => {
                      // onClose();
                      // generateOneClickVideo(orientation, language, voice);
                      setShortGptForm(true);
                    }}
                  >
                    Generate
                  </Button>
                </Box>
              )}

              {(selectedPrompt?.allowVideoCreate === "ELAI" ||
                selectedPrompt?.allowVideoCreate === "BOTH") && (
                <Box
                  textAlign="center"
                  w={
                    selectedPrompt?.allowVideoCreate === "BOTH" ? "50%" : "100%"
                  }
                >
                  <ModalHeader>
                    
                     {/* {VIDEO_CONFIGURATION?.elaiTitle} */}
                     
                     {isKor ? VIDEO_CONFIGURATION?.elaiTitleKr : VIDEO_CONFIGURATION?.elaiTitle}
                     </ModalHeader>
                  <Box padding="0px 0px 16px 0px">
                    <p>{/* {VIDEO_CONFIGURATION?.elaiDescription} */}</p>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: isKor ? VIDEO_CONFIGURATION?.elaiDescriptionKr : VIDEO_CONFIGURATION?.elaiDescription 
                      }}
                    />
                  </Box>

                  <Button
                    fontWeight="700"
                    color={"#fff"}
                    fontFamily={"Nunito Sans, sans-serif"}
                    bg="#4FDABC"
                    py="5px"
                    px="25px"
                    borderRadius={"10px"}
                    width="110px"
                    textAlign={"center"}
                    _hover={{
                      bg: "rgba(79, 218, 188, .8)",
                    }}
                    _active={{
                      bg: "#4FDABC",
                    }}
                    _focus={{
                      bg: "#4FDABC",
                    }}
                    onClick={() => {
                      generateOneClickVideo();
                      onClose();
                    }}
                  >
                    Generate
                  </Button>
                </Box>
              )}
            </Box>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default GetVideoConfirmation;
