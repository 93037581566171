// Chakra imports
import {
  Box,
  Button,
  Flex,
  FormControl,
  PinInput,
  PinInputField,
  Heading,
  Text,
  useColorModeValue,
  Spinner,
  useBreakpointValue,
  Image,
  FormLabel,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import { userPublicRequest } from "config/axios.config";
import useCustomHistory from "langHoc/useCustomHistory";
import CenteredAuth from "layouts/auth/types/CenteredNew";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Footer from "components/footer/FooterAdmin.js";
import { useViewportHeight } from "layouts/HomeNew/useViewportHeight2";
import CustomNavLink from "langHoc/CustomNavLink";
import { AuthContext } from "contexts/AuthContext";
import axios from "axios";
import { ConfigContext } from "contexts/ConfigContext";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const lang = window.location.pathname.split("/")[1] || "en";
function PhoneVerificationCentered() {
  // Chakra color mode

  const { dispatch, profileFetch } = useContext(AuthContext);
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const borderColor = useColorModeValue("secondaryGray.400", "whiteAlpha.100");
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const [phoneOtp, setPhoneOtp] = useState();
  const [loading, setLoading] = useState(false);
  const [resendLoading, setResendLoading] = useState(false);
  const history = useCustomHistory();
  let query = useQuery();

  let configs = useContext(ConfigContext);

  const { t } = useTranslation();
  const submitHandler = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);
      const payload = {
        otp:phoneOtp,
        email: query.get("email"),
        lang,
      };
      await userPublicRequest
        .post("/auth/verifyPhone", payload)
        .then((res) => {
          // // console.log("daTA", res.data);
          const login = {
            data: {
              ...res.data.data.user,
            },
            token: res.data.data.token,
            remember_me: false,
            expires_at: "2023-01-11T10:54:32.795Z",
          };

          dispatch({
            type: "LOGIN",
            payload: login,
          });
          profileFetch();
          history.push("/chat");
        })
        // .catch((error) => {});
      // // console.log()
    } catch (error) {
      // // console.log(err);
      setLoading(false);

      // // console.log(error.response.data);
      let message = error?.response?.data?.message;
      if (message === "Invalid otp provided!") {
        message = t("InvalidOtpProvided");
      }
      // // console.log(message);
      const toastId = "signin-error-toast";
        if (!toast.isActive(toastId)) {
          toast.error(error.message, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            toastId: toastId,
          });
        }
    }
  };
  const handleResendCode = async () => {
    setResendLoading(true);
    // // console.log()
    const payload = {
      email: query.get("email"),
      lang,
      media:"phone"
    };
    await userPublicRequest
      .post("/auth/resend-token", payload)
      .then((response) => {
        setResendLoading(false);
      });
    toast.success(t("OtpSent"), {
      onClose: () => {
        // history.push(`/auth/verify?email=${payload.email}`);
      },
    });
  };
  const vh = useViewportHeight();
  const breakpoint = useBreakpointValue({ base: "base", lg: "lg" });

  const minH = {
    base: `calc(${vh}px - 120px)`,
    lg: `calc(${vh}px - 50px)`,
  };

  
  return (
    <Flex
      direction="column"
      alignSelf="center"
      justifySelf="center"
      overflow="hidden"
      mx={{ base: "10px", lg: "0px" }}
      pb={{ base: "0", lg: "0px" }}
    >
      <Box
        minH={minH[breakpoint]}
        display="flex"
        justifyContent={"center"}
        alignItems={"center"}
        py="50px"
        flexDirection="column"
      >
        <CustomNavLink to="/">
          <Image
            src={configs?.logo}
            alt="logo"
            width={{ base: "300px", lg: "300px" }}
            marginBottom="40px"
            marginX="auto"
          />
        </CustomNavLink>
        <Box
          height={"100%"}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          w="100%"
        >
          <Card
            w={{ base: "100%", md: "max-content" }}
            h="max-content"
            mx="auto"
            maxW="100%"
            p={{ base: "20px", md: "20px" }}
            pt={{ base: "20px", md: "20px" }}
          >

            <Box>
              <Flex
                maxW={{ base: "100%", md: "max-content" }}
                w="100%"
                mx={{ base: "auto", lg: "0px" }}
                me="auto"
                justifyContent="center"
                px={{ base: "0px", md: "0px" }}
                flexDirection="column"
              >
                <Box me="auto" mb="20px">
                  <Heading
                    color={textColor}
                    fontSize="30px"
                    mb="20px"
                    mx={{ base: "auto", lg: "unset" }}
                    textAlign={{ base: "center", lg: "left" }}
                    lineHeight={1}
                  >
                    {t("AccountVerification")}
                  </Heading>
                  <Text
                    color={textColorSecondary}
                    fontSize="md"
                    maxW={{ base: "95%", md: "100%" }}
                    mx={{ base: "auto", lg: "unset" }}
                    textAlign={{ base: "center", lg: "left" }}
                    lineHeight={1}
                  >
                    {t("UnlockPhoneContent")}
                  </Text>
                </Box>
                <Flex
                  zIndex="2"
                  direction="column"
                  w={{ base: "100%", md: "425px" }}
                  maxW="100%"
                  background="transparent"
                  borderRadius="15px"
                  mx={{ base: "auto", lg: "unset" }}
                  me="auto"
                  mb={{ base: "0", md: "auto" }}
                >
                  <form onSubmit={submitHandler}>
                    <FormControl>
                      <FormLabel
                        display="flex"
                        ms="4px"
                        fontSize="lg"
                        fontWeight="500"
                        color={textColor}
                        mb="20px"
                        lineHeight={1}
                      >
                        {t("PhoneVerificationCode")}
                      </FormLabel>
                      <Flex justify="center">
                        <PinInput
                          mx="auto"
                          value={phoneOtp}
                          onChange={(e) => setPhoneOtp(e)}
                        >
                          <PinInputField
                            fontSize="36px"
                            color={textColor}
                            borderRadius="16px"
                            borderColor={borderColor}
                            h={{ base: "63px", md: "95px" }}
                            w={{ base: "63px", md: "95px" }}
                            me="10px"
                            mb="20px"
                          />
                          <PinInputField
                            fontSize="36px"
                            color={textColor}
                            borderRadius="16px"
                            borderColor={borderColor}
                            h={{ base: "63px", md: "95px" }}
                            w={{ base: "63px", md: "95px" }}
                            me="10px"
                          />
                          <PinInputField
                            fontSize="36px"
                            color={textColor}
                            borderRadius="16px"
                            borderColor={borderColor}
                            h={{ base: "63px", md: "95px" }}
                            w={{ base: "63px", md: "95px" }}
                            me="10px"
                          />
                          <PinInputField
                            fontSize="36px"
                            color={textColor}
                            borderRadius="16px"
                            borderColor={borderColor}
                            h={{ base: "63px", md: "95px" }}
                            w={{ base: "63px", md: "95px" }}
                            me="10px"
                          />
                          <PinInputField
                            fontSize="36px"
                            color={textColor}
                            borderRadius="16px"
                            borderColor={borderColor}
                            h={{ base: "63px", md: "95px" }}
                            w={{ base: "63px", md: "95px" }}
                            me="10px"
                          />
                          <PinInputField
                            fontSize="36px"
                            color={textColor}
                            borderRadius="16px"
                            borderColor={borderColor}
                            h={{ base: "63px", md: "95px" }}
                            w={{ base: "63px", md: "95px" }}
                          />
                        </PinInput>
                      </Flex>

                      <Button
                        fontSize="14px"
                        variant="brand"
                        borderRadius="16px"
                        fontWeight="500"
                        w="100%"
                        h="50"
                        mb="20px"
                        // mt="12px"
                        type="submit"
                        disabled={loading}
                        bg="rgb(0,121,107)"
                        _hover={{
                          bg: "rgb(0,121,107)",
                        }}
                        _active={{
                          bg: "rgb(0,121,107)",
                        }}
                        _focus={{
                          bg: "rgb(0,121,107)",
                        }}
                      >
                        {loading ? (
                          <>
                            <Spinner />
                          </>
                        ) : (
                          t("Unlock")
                        )}
                      </Button>
                    </FormControl>
                  </form>

                  <Flex
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="start"
                    maxW="100%"
                    mt="0px"
                  >
                    <Text
                      color={textColorDetails}
                      fontWeight="400"
                      fontSize="14px"
                      mx={{ base: "auto", lg: "unset" }}
                      textAlign={{ base: "center", lg: "left" }}
                    >
                      {t("DidntReceiveOTP")}
                      <Text
                        color={textColorBrand}
                        cursor={"pointer"}
                        as="span"
                        ms="5px"
                        fontWeight="500"
                        onClick={handleResendCode}
                        disabled={resendLoading}
                      >
                        {resendLoading ? "Resending ..." : t("newOtpCode")}
                      </Text>
                    </Text>
                  </Flex>
                </Flex>
              </Flex>
            </Box>
          </Card>
        </Box>
      </Box>

      <Footer />
    </Flex>
  );
}

export default PhoneVerificationCentered;
