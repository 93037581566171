import { Box, Text } from '@chakra-ui/react'
import Skeleton from 'react-loading-skeleton'
import { useContext, useState } from 'react'
import { userPublicRequest } from 'config/axios.config'
import AllSearch from './google-search/all'

import { AuthContext } from 'contexts/AuthContext'
import ThumbnailSearch from './google-search/thumbnails'
const apiKey =
  process.env.REACT_APP_SERPER_KEY || '450aefb1d42258abd00d40f8c5409047e7916d05'

const SearchResult = ({ googleSearch, chatId, config }) => {
  const authData = useContext(AuthContext)
  let isAuthenticated = authData.authState.isAuthenticated || false
  const [currentTab, setCurrentTab] = useState(0)
  const [isImageLoading, setIsImageLoading] = useState(true)
  const [imageResults, setImageResults] = useState(googleSearch.images || [])
  const [isVideoLoading, setIsVideoLoading] = useState(true)

  const [videoResults, setVideoResults] = useState(
    googleSearch.video_results || []
  )

  const getVideoSearch = () => {
    setCurrentTab(2)
    if (videoResults.length <= 0) {
      userPublicRequest
        .get(`/chat/google-video-search?chatId=${chatId}`)
        .then(response => {
          setIsVideoLoading(false)
          let videos = response.data.data.video_results || []
          setVideoResults(videos)
          return response.data.data || []
        })
        .catch(err => {
          // console.log(err.message)
          return []
        })
    } else {
      setIsVideoLoading(false)
    }
  }

  return (
    <Box
      h="100%"
      w={'100%'}
      position={'relative'}
      className="google-box"
      pl="42px"
      ml='1.5%'
    >
      {googleSearch.loading && (
        <div className="loading-area" style={{ marginTop: '44px' }}>
          {/* <Spinner /> */}
          <Skeleton count={5} baseColor={'#bec0c0'} enableAnimation={true} />
        </div>
      )}

      <Box id="Search-Result-Image">
        <ThumbnailSearch
          results={googleSearch.organic}
          config={config}
          chatId={chatId}
        />
      </Box>

      <Box marginTop={'20px'}>
        {!googleSearch.loading && googleSearch.organic && (
          <AllSearch
            results={googleSearch.organic}
            usedReferences={googleSearch.usedReferences}
            config={config}
          />
        )}
      </Box>
    </Box>
  )
}

export default SearchResult
