// Chakra imports
import {
  Badge,
  List,
  ListItem,
  ListIcon,
  Text,
  Button,
  useColorModeValue,
  Box,
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card";
import { useTranslation } from "react-i18next";
// Assets
import { BsCircleFill } from "react-icons/bs";

export default function Pack(props) {
  const {
    title,
    desc,
    button,
    price,
    details,
    benefits,
    highlighted,
    topPage,
    buttonOnClick,
    mt,
    pt
  } = props;
  const textColor = useColorModeValue("secondaryGray.900", "white");

  const handleScroll = () => {
    window.scrollTo(0, 0);
  };

  const { t } = useTranslation();
  return (
    <Card
      p="30px 20px"
      alignItems="flex-start"
      // marginBottom={{base: "40px", xl: "0px"}}
      justifyContent="flex-start"
      position="relative"
      overflow="unset !important"
      backgroundColor={highlighted && "rgb(0,121,107)"}
      mt={mt}
      pt={pt ? pt : '30px'}
    >
      <Badge
        display={highlighted ? "block" : "none"}
        w="max-content"
        position="absolute"
        fontSize="sm"
        color="#ffffff"
        bg="rgb(150,114,187)"
        fontWeight="bold"
        textTransform="unset"
        left="50%"
        borderRadius="70px"
        transform="translate(-50%,-250%)"
      >
        {t("FeaturedPrice")}
      </Badge>
      <Text
        fontSize="30px"
        margin="auto"
        mb="20px"
        color={highlighted ? "#ffffff" : textColor}
        fontWeight="700"
      >
        {title}
      </Text>
      <Text
        mb="20px"
        margin="auto"
        // paddingBottom="20px"
        // fontSize="md"
        color={highlighted ? "#ffffff" : "secondaryGray.600"}
        fontWeight="500"
        fontSize={'15px'}
      >
        {desc}
      </Text>

      {price && (

<>

<Box d="flex" fontSize="40px" margin="auto" mb="0px" fontWeight="700" gap="10px" mt="15px">
<Text as="s" color={'#c2c7d7'} >
$9
</Text>
<Text color="#fff" >
$6
</Text>
</Box>


<Text
mb="20px"
margin="auto"
// paddingBottom="20px"
// fontSize="md"
color={highlighted ? "#ffffff" : "secondaryGray.600"}
fontWeight="500"
fontSize={'15px'}
>
per month
</Text>
</>

)}
      {highlighted ? (
        <Button
          w="100%"
          bg={"#ffffff"}
          marginTop={"30px"}
          whiteSpace={"break-spaces"}
          color={"rgb(0,121,107)"}
          _hover={{ backgroundColor: "#00877a", color: "#E2E8F0" }}
          _active={{background: 'transparent', color: '#fff', border: '1px solid #fff'}}
          _focus={{background: 'transparent', color: '#fff', border: '1px solid #fff'}}
          variant={"brand"}
          mb="30px"
          onClick={buttonOnClick}
        >
          {button}
        </Button>
      ) : (
        <>
         <Button
          w="100%"
          bg={"rgb(0,121,107)"}
          marginTop={"30px"}
          whiteSpace={"break-spaces"}
          _hover={{ backgroundColor: "#00877a", color: "#E2E8F0" }}
          color={"#fff"}
          variant={"lightBrand"}
          mb="30px"
          onClick={buttonOnClick}
        >
          {button}
        </Button>
        </>
       
      )}

      

      <Text fontSize="md" color="secondaryGray.600" fontWeight="500" mb={details ? "20px" : '0px'} >
        {details}
      </Text>
      <List justifyContent="flex-start" mt="20px" >
        {benefits.map((benefit, index) => (
          <ListItem
            key={index}
            display="flex"
            textAlign="start"
            fontSize="md"
            fontWeight="500"
            // color={textColor}
            color={highlighted ? "#ffffff" : textColor}
            align="center"
            lineHeight="100%"
            mb="30px"
          >
            <ListIcon
              w="10px"
              h="10px"
              as={BsCircleFill}
              my="auto"
              color={highlighted ? "#ffffff" : textColor}
            />
            {benefit}
          </ListItem>
        ))}{" "}
      </List>
    </Card>
  );
}
