// Chakra imports
import { Grid, useDisclosure } from "@chakra-ui/react";

import Messages from "views/admin/main/others/messages/components/Messages";
import FirstModal from "./components/FirstModal";
import InitialModal from "./components/InitialModal";
import { useContext } from "react";
import { AuthContext } from "contexts/AuthContext";

export default function NewMessage() {
  const { dispatch, authState } = useContext(AuthContext);
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Grid display={{ base: "block" }} className="h-100" id="chat__section">
      <InitialModal isOpen={isOpen} onOpen={onOpen} onClose={onClose} />
      {/* <FirstModal /> */}
      <Messages status="active" name="Roberto Michael" onOpen={onOpen} isOpen={isOpen} />
    </Grid>
  );
}
