import { SimpleGrid, Box } from "@chakra-ui/react";
import { userPublicRequest } from "config/axios.config";
import { AuthContext } from "contexts/AuthContext";
import { useContext, useEffect, useState } from "react";
import ThumbnailSingle from "./ThumbnailSingle";

export default function ThumbnailSearch({ results }) {
  const authData = useContext(AuthContext);
  let isAuthenticated = authData.authState.isAuthenticated || false;

  const [links, setLinks] = useState([]);
  const fetchThumbnails = async () => {
    let tempLinks = [];
    let datas = results || [];
    let iteration = 0;
    for (const thumbnail of datas) {
      iteration++;
      if (tempLinks.length >= 3) {
        continue;
      }
      let url = await userPublicRequest
        .post("/utils/get-url-thumbnail", {
          url: thumbnail.link,
        })
        .then((response) => {
          let imageUrl = response.data.image || null;
          if (imageUrl) {
            tempLinks.push({ image: imageUrl, link: thumbnail.link });
          }
          return imageUrl;
        })
        .catch((error) => {
          return null;
        });
    }
    if (datas.length == iteration) {
      setLinks(tempLinks);
    }

    // setTimeout(() => {
    //   window.scrollTo({
    //     top: document.body.scrollHeight,
    //     behavior: 'smooth'
    //   });
    // }, 500);
  };

  useEffect(() => {
    fetchThumbnails();
  }, [results]);
  return (
    <>
      <Box 
        display="flex"
        flexWrap="wrap"
        gap="10px"
      >
        {links.length > 0 &&
          links.map((thumbnail) => {
            return <ThumbnailSingle thumbnail={thumbnail} />;
          })}
      </Box>
    </>
  );
}
