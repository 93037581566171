// CustomRoute.js
import React from 'react';
import { Route } from 'react-router-dom';
import withLanguage from './withLanguage';

const CustomRoute = ({ component: Component, ...rest }) => {
  const WrappedComponent = withLanguage(Component);

  return <Route {...rest} component={WrappedComponent} />;
};

export default CustomRoute;
