import React, { useState, useEffect } from "react";
import MicV2 from "components/audio/MicV2";
import { Box } from "@chakra-ui/react";

const Recoder = () => {
  const [recording, setRecording] = useState(false);


  return (
    <div>
      <Box
        // paddingRight={{ base: '5px', md: '0px' }}
        width="40px"
        minWidth="40px"
        display="flex"
        mt="3px"
        alignItems="center"
        justifyContent="center"
      >
        <MicV2
          auto
          // audioTextBack={audioTextBack}
          recording={recording}
          setRecording={setRecording}
        />
      </Box>
    </div>
  );
};

export default Recoder;
