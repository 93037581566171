import { Box, Flex, Image, Link, useColorModeValue } from "@chakra-ui/react";
import { userPublicRequest } from "config/axios.config";
import { useState } from "react";

const ThumbnailSingle = ({ thumbnail }) => {

  
  const textColor = useColorModeValue("navy.700", "white");
  const textColorBid = useColorModeValue("brand.500", "white");
  const [imageUrl, setImageUrl] = useState("");

  const imgErrorHandler = event => event.currentTarget.className = "img-error";

  return (
    // <Flex direction={{ base: "column" }} justify="center">
      <Box
        // mb={{ base: "5px", "2xl": "5px" }}
        // position="relative"
        height={{ base: "150px" }}
      >
        <Link href={thumbnail.link} target="_blank">
          <img src={thumbnail.image} alt="" className="un-img" onError={imgErrorHandler} />
          {/* <Image
            src={thumbnail}
            w={{ base: "100%", "3xl": "100%" }}
            h={{ base: "100%", "3xl": "100%" }}
            objectFit="cover"
            objectPosition="center"
            borderRadius="10px"
            fallbackSrc='https://via.placeholder.com/150'
          /> */}
        </Link>
      </Box>
    // </Flex>
  );
};
export default ThumbnailSingle;
