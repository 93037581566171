import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'

// const firebaseConfig = {
//   apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
//   authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
//   projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID
//   // storageBucket: 'arkedison-651fb.appspot.com',
//   // messagingSenderId: '1044062372171',
//   // appId: '1:1044062372171:web:408cc5546aa7258d89e8ce'
// }
// const firebaseConfig = {
//   apiKey: "AIzaSyBMHWwbHSMeuClNcF6JjcwA2Yp9xphE7rk",
//   authDomain: "itagama-8f0ae.firebaseapp.com",
//   projectId: "itagama-8f0ae",
// };
const firebaseConfig = {
  // apiKey: "AIzaSyBFRmjJ5kwB__jfCudbL3xpjSs2wObhHRM",
  // authDomain: "evocative-nexus-384306.firebaseapp.com",
  // projectId: "evocative-nexus-384306",
  // storageBucket: "evocative-nexus-384306.appspot.com",
  // messagingSenderId: "754914818486",
  // appId: "1:754914818486:web:54b01bb5d7757c438a6917",
  // measurementId: "G-LSLH5CBZYS",

  apiKey: "AIzaSyBFRmjJ5kwB__jfCudbL3xpjSs2wObhHRM",
  authDomain: "evocative-nexus-384306.firebaseapp.com",
  projectId: "evocative-nexus-384306",
  storageBucket: "evocative-nexus-384306.appspot.com",
  messagingSenderId: "754914818486",
  appId: "1:754914818486:web:d594b1d9931bec8c8a6917",
  measurementId: "G-D4VTS8YXTF",
};


if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig)
}

if (firebase.app.length) {
  firebase.app()
}


export default firebase
