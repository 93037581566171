import { storeToken } from 'utils/auth'
import { removeToken } from 'utils/auth'

const AuthReducer = (state, action) => {
  switch (action.type) {
    case 'LOGIN':
      // localStorage.setItem('token', action.payload.token)
      // console.log("token",action.payload.token);
      let temp = storeToken(action.payload.token)
      
      // console.log("temp",temp);
      // localStorage.setItem('expires_at', action.payload.expires_at)
      // localStorage.setItem(
      //   'user',
      //   JSON.stringify({
      //     // localStorage.setItem("full_name", action.payload.data.full_name);
      //     email: action.payload.data.email,
      //     role: action.payload.data.role,
      //   })
      // )
      return {
        ...state,
        isAuthenticated: true,
        token: action.payload.token,
        remember_me: action.payload.remember_me,
        user: {
          // full_name: action.payload.user.full_name,
          email: action.payload.data.email,
          role: action.payload.data.role,
        },
      }
    case 'PROFILE':
      return {
        ...state,
        isAuthenticated: true,
        membership: action.payload.membership,
        user: action.payload,
      }
    case 'LOGOUT':
      // localStorage.clear();
      // console.log("err.message", 'error?.response');
      localStorage.removeItem('token')
      removeToken()
      // console.log("err.message", 'error?.response');
      localStorage.removeItem('expires_at')
      localStorage.removeItem('user')
      // console.log("err.message", 'error?.response');
      return {
        ...state,
        isAuthenticated: false,
        user: {},
      }
    default:
      return state
  }
}

export default AuthReducer
