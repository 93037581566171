import { useCallback, useState, useEffect, useContext } from "react";
import { random } from "lodash-es";
import AnimatedNumber from "react-awesome-animated-number";
import "react-awesome-animated-number/dist/index.css";
import { userPublicRequest } from "config/axios.config";
import { ConfigContext } from "contexts/ConfigContext";

export const WithController = () => {
  const [number, setNumber] = useState(30000);
  const [actualNumber, setActualNumber] = useState(0);
  const configData = useContext(ConfigContext);
  const config = configData.config || {};
  let multiplier = config.global?.QUERY_MULTIPLIER.value || 1;

  useEffect(() => {
    // setNumber(parseInt( number + (actualNumber * multiplier)));
    setNumber((number) => parseInt( number + (actualNumber * multiplier)));
  }, [actualNumber, multiplier]);

  function getRandomNumber() {
    const numbers = [1, 3, 5];
    const randomIndex = Math.floor(Math.random() * numbers.length);
    return numbers[randomIndex];
  }

  const increaseNumber = useCallback(() => {
    const randomNumber = getRandomNumber();
    setNumber((number) => number + randomNumber);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => increaseNumber(), 7000);
    return () => clearInterval(interval); // Clean up the interval on component unmount
  }, [increaseNumber, number]);

  const fetchStatistics = async () => {
    await userPublicRequest
      .get(`/utils/statistics`)
      .then((response) => {
        let queryCount = response.data.data.queryCount || 0;
        setActualNumber(queryCount);
      })
      .catch((error) => {
        setActualNumber(0);
      });
  };
  useEffect(() => {
    fetchStatistics();
  }, []);

  return (
    <>
      <AnimatedNumber
        value={number}
        hasComma={true}
        size={28}
        duration={1000}
        className="edit-count"
      />
    </>
  );
};
